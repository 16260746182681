<template>
  <div class="services-payment">
    <strong class="mr-2">Metodo de pago:</strong>
    <vSelect
      class="payment-select"
      :options="items"
      v-model="paymentSelected">
      <template #selected-option="{label}">
        <span>{{ formatPaymentType(label) }}</span>
      </template>
      <template #option="{label}">
        <span>{{ formatPaymentType(label) }}</span>
      </template>
      <template #no-options>
        No se encontraron coincidencias, favor de presionar ENTER para buscar otras opciones o verifique el código
      </template>
    </vSelect>
    <button
      class="btn-primary"
      @click="editPaymentType()"
      v-c-tooltip="'Para actualizar el método de pago seleccione uno de la lista y de clic en el botón para aplicarlo.'">
      <CIcon name="cil-pencil"></CIcon>
    </button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'

let vue = null
export default {
  name: 'PaymentType',
  components: {
    vSelect
  },
  data() {
    return {
      items: [],
      paymentSelected: '',
      paymentTypes: []
    }
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    serviceUuid: {
      type: String,
      required: true
    },
    paymentType: {
      type: String,
      required: false
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.paymentSelected = this.paymentType ? this.formatPaymentType(this.paymentType) : ''
        this.getPaymentsType()
      }
    },
    paymentSelected(value) {
      if (value === null) {
        this.getPaymentsType()
      }
    }
  },
  mounted() {
    this.getPaymentsType()
    this.paymentSelected = this.paymentType ? this.paymentType : ''
    vue = this
  },
  methods: {
    getPaymentsType() {
      this.axios.get('/services/payment-types')
        .then((response) => {
          const { data } = response
          this.paymentTypes = data
          this.items = data.map((obj) => {
            let paymentName = {}
            paymentName = obj.name
            return paymentName
          })
          const found = this.items.find((item) => item === vue.paymentType)
          if (!found) {
            this.items.push(this.paymentType)
          }
          this.paymentSelected = vue.paymentType
        }).catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    errors(message) {
      return { response: { data: message } }
    },
    editPaymentType() {
      const found = this.paymentTypes.find((item) => item.name === vue.paymentSelected)
      if (!found && found === undefined) {
        this.$emit('onError', this.errors(ERRORS_MESSAGES.INVALID_PAYMENT_TYPE))
        return
      }
      if (vue.paymentSelected === this.paymentType) {
        this.$emit('onError', this.errors(ERRORS_MESSAGES.CHANGE_PAYMENT_TYPE))
        return
      }
      this.axios.put(`services/${this.serviceUuid}/payment`, {
        paymentTypeId: found.id
      })
        .then(() => {
          this.$emit('onSuccess')
        }).catch((err) => {
          console.log(err)
          this.$emit('onError', err)
        })
    },
    resetOnBlur() {
      if (this.items.length === 1) {
        this.getPaymentsType()
      }
    },
    formatPaymentType(value) {
      return (value === 'Tarjeta (TDEB | TCR)') ? 'Pasarela de pago' : value
    }
  }
}
</script>

<style lang="scss" scoped>
.services-payment{
    display: flex;
    strong{
        margin: auto 0;
    }
    .payment-select{
        width: 36%;
    }
    button{
      border: 1px solid #5B2AE0;
    }
}
</style>
