export const WHATSAPP = `Cliente: {name}

*Folio:* {folio} 
🗓 *Fecha:* {date}
🕐 *Horario:* {time} hrs
💰 *Monto solicitado:* \${amount}
💰 *Cupón de descuento:* \${discount}
💰 *Total a pagar:* \${totalAmount}
💳 *Método de pago:* {paymentType}
📍 *A la dirección:* {address}
{googleMapsURL}
`
export const WHATSAPP_PREORDER = `Hola {name}, tienes una preorden de {serviceType} 🔥 agendada en okboy

🗓 *Fecha:* {date}
🕐 *Horario:* {time} hrs
💰 *Monto solicitado:* \${amount}
💳 *Método de pago:* {paymentType}
📍 *A la dirección:* {address}
{googleMapsURL}

Responde: 
CONFIRMAR para terminar de agendarlo
CANCELAR en caso de que ya no desees el servicio
`
