<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    class="create-promocode"
  >
    <div tabindex="0" ref="CreatePromocodeError">
      <CAlert
        :color="error.type || 'danger'"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>
    <template v-slot:header>
      <h5 class="modal-title">Crear código promocional</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <form
      id="createPromocode"
      ref="form"
      v-on:submit.prevent="onSubmit">
      <div class="massive-individual-wrapper">
        <div class="massive-individual-switch ml-auto">
          <label class="my-0">Agregar de forma</label>
          <toggle-button
            :value="isMasive"
            class="my-0 ml-2"
            :width="80"
            :color="{checked: '#321fdb', unchecked: '#3c4b64'}"
            :labels="{checked: 'Masiva', unchecked: 'Individual'}"
            @change="isMasive = !isMasive"
            :sync="true"
          />
        </div>
      </div>
      <div v-if="!isMasive">
        <div class="d-flex mb-2">
          <label class="my-auto">Código</label>
          <div
            v-c-tooltip="'Indica el nombre del código promocional'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          type="text"
          placeholder="Código"
          v-model="data.code"
          minlength="5"
          maxlength="15"
          pattern="^[A-Z0-9-]{5,15}$"
          title="Mínimo 5 caracteres, deben ser letras mayúsculas, pueden tener números y un guion medio"
          @keypress="alphaNumeric($event)"
          required>
      </div>
      <div
        v-if="isMasive"
        class="csv-import">
        <label> Importar csv</label>
        <vue-doc-reader
          @onLoad="onLoad"
          :includeRows="false"
          class="vue-reader"/>
      </div>
      <div>
        <div class="d-flex mb-2">
          <label class="my-auto">Descuento</label>
          <div
            v-c-tooltip="'Corresponde al descuento total del código promocional'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          type="text"
          placeholder="Descuento"
          v-model="data.discount"
          maxlength="4"
          pattern="^[0-9][0-9]*$"
          title="El número debe ser mayor a cero"
          @keypress="onlyNumbers($event)"
          required>
      </div>
      <div v-if="+(data.discount) != 0 || !data.discount">
        <div class="d-flex mb-2">
          <label class="my-auto">Mínimo de compra</label>
          <div
            v-c-tooltip="'Corresponde al mínimo de compra que debe realizar el cliente para poder usar el código promocional'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          type="text"
          placeholder="Mínimo de compra"
          v-model="data.minPurchase"
          maxlength="4"
          :pattern="+(data.discount) === 0 ? '^[0-9]+$' : '^[1-9][0-9]*$'"
          title="El número debe ser mayor a cero"
          @keypress="onlyNumbers($event)"
          required>
      </div>
      <div>
        <label for="">Caducidad</label>
        <date-range-picker
          ref="picker"
          v-model="datePicker"
          :locale-data="{format: 'dd/mm/yyyy hh:mm', daysOfWeek: data.daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
          :ranges="false"
          opens="center"
          :append-to-body="true"
          :single-date-picker="true"
          :time-picker="true"
          :time-picker24-hour="false"
          :date-format="disabledDates"
          :show-dropdowns="true"
          class="date-range"
          @update="updateDate()"
          v-c-tooltip="'Corresponde a la fecha en que vence/caduca el código promocional'"
          required
        >
          <template v-slot:input="picker" style="min-width: 350px;">
            {{ formatToDatePicker(picker.startDate) }}
          </template>
        </date-range-picker>
      </div>
      <div>
        <label for="">Estatus</label>
        <div
          class="tabs-selector"
          v-c-tooltip="'Indica si el código promocional está activo (si puede ser usado o no)'">
          <button
            @click.prevent="data.active = true"
            :class="data.active === true ? 'active' : ''">Activo</button>
          <button
            @click.prevent="data.active = false"
            :class="data.active === false ? 'active' : ''">Inactivo</button>
        </div>
      </div>
      <div>
        <label for="">Primera compra</label>
        <div
          class="tabs-selector"
          v-c-tooltip="'Indica si es un código promocional para uso en la primera compra'">
          <button
            @click.prevent="data.firstBuy = true"
            :class="data.firstBuy === true ? 'active' : ''">Si</button>
          <button
            @click.prevent="data.firstBuy = false"
            :class="data.firstBuy === false ? 'active' : ''">No</button>
        </div>
      </div>
      <div>
        <label for="">Un solo uso</label>
        <div
          class="tabs-selector"
          v-c-tooltip="'Indica si el código promocional es de solo un uso'">
          <button
            @click.prevent="data.singleUse = true"
            :class="data.singleUse === true ? 'active' : ''">Si</button>
          <button
            @click.prevent="data.singleUse = false"
            :class="data.singleUse === false ? 'active' : ''">No</button>
        </div>
      </div>
      <div v-if="!data.singleUse">
        <label for="">Usos por cliente</label>
        <input
          type="text"
          placeholder="usos por cliente"
          v-model="data.usesPerCustomer"
          maxlength="4"
          @keypress="onlyNumbers($event)"
          v-c-tooltip="'Corresponde al número de veces que puede ser usado el código promocional por el cliente'"
          required>
      </div>
      <div class="form-group">
        <div class="d-flex mb-2">
          <label for="serviceTypes" class="my-auto">Tipo de servicio</label>
          <div
            v-c-tooltip="'Corresponde al tipo de servicio al que se dirige el cupon'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <select
          class="form-control"
          name="serviceTypes"
          v-model="data.serviceTypeUuid"
          required
        >
          <option :value="null">Selecciona una opción</option>
          <option
            v-for="item in serviceTypes"
            :key="item.registryUuid"
            :value="item.registryUuid"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </form>
    <template v-slot:footer>
      <div>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="createPromocode"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Registra el código promocional con los datos ingresados en el formulario'">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  data: {
    code: '',
    discount: '',
    expiration: '',
    active: true,
    firstBuy: false,
    usesPerCustomer: 1,
    singleUse: false,
    minPurchase: 0,
    serviceTypeUuid: ''
  },
  datePicker: {
    startDate: new Date('2100-12-31 23:59:59'),
    endDate: new Date('2100-12-31 23:59:59')
  },
  daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  error: {},
  isErrorResponse: false,
  csv: [],
  csvArray: [],
  isMasive: false,
  serviceTypes: [],
  promoCodeExist: {
    code: [],
    message: ''
  },
  promoCodeMassiveError: {
    code: [],
    message: ''
  }
})

export default {
  name: 'CreatePromocode',
  components: {
    DateRangePicker
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return initialState()
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.isErrorResponse = false
        this.resetForm()
        this.updateDate()
        this.getServicesTypes()
      }
    },
    'data.code': function (value) {
      this.data.code = value.toUpperCase()
    },
    'data.singleUse': function (value) {
      if (value) {
        this.data.usesPerCustomer = 1
      }
    }
  },
  methods: {
    onLoad(results) {
      this.csv = results
    },
    cleanCsv() {
      const { data } = this.csv
      const codeString = []

      Object.keys(data).forEach((key) => {
        codeString.push({ code: data[key].code.toString() })
      })
      Object.keys(codeString).forEach((key) => {
        if (codeString[key].code.length >= 5 && codeString[key].code.length <= 15) {
          this.csvArray.push(codeString[key].code)
        } else {
          this.promoCodeMassiveError.code.push(` ${codeString[key].code}`)
        }
      })

      this.promoCodeMassiveError.message = `Los siguientes códigos no cumplen con el formato: ${this.promoCodeMassiveError.code}`
      this.$emit('onError', this.promoCodeMassiveError)
    },
    onSubmit() {
      this.data.minPurchase = (this.data.minPurchase).toString()
      if (+(this.data.discount) === 0) this.data.minPurchase = '0'
      this.updateDate()
      const promocode = {}
      Object.keys(this.data).forEach((key) => {
        if (this.data[key] || this.data[key] === false) {
          promocode[key] = this.data[key]
        }
      })
      if (this.isMasive) {
        this.cleanCsv()
        this.axios.post('/promocodes/upload', {
          ...promocode,
          promoCodes: this.csvArray
        }).then((response) => {
          const { data } = response
          if (data.promoCodeExist) {
            Object.keys(data.promoCodeExist).forEach((key) => {
              this.promoCodeExist.code.push(` ${data.promoCodeExist[key].code}`)
            })
            this.promoCodeExist.message = `Los siguientes códigos promocionales ya existen: ${this.promoCodeExist.code}`
            this.$emit('onErrorRepeated', this.promoCodeExist)
          }
          if (this.csvArray.length > this.promoCodeExist.code.length) {
            this.$emit('onSuccessMassive', this.promoCodeExist)
          }
          this.resetForm()
          this.isErrorResponse = false
        }).catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
      } else {
        this.axios.post('/promocodes', {
          ...this.data
        }).then(() => {
          this.resetForm()
          this.$emit('onSuccess')
          this.isErrorResponse = false
        }).catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
      }
    },
    onCancel() {
      this.$emit('onClose')
    },
    updateDate() {
      const dateExpiration = this.datePicker.startDate.toString().replace(/[GMT0-9-]{8}/g, '')
      this.data.expiration = moment.utc(dateExpiration).format()
    },
    disabledDates(classes, date) {
      const dd = new Date().getDate()
      const mm = new Date().getMonth() + 1
      const yyyy = new Date().getFullYear()
      const today = `${yyyy}-${mm}-${dd}`
      const getCalendarDate = moment(date.getTime()).format()

      if (!classes.disabled) {
        classes.disabled = getCalendarDate < moment(today).format()
      }
      return classes
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.CreatePromocodeError.focus()
      })
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    // formats
    formatToDatePicker(date) {
      return moment(date).format('DD/MM/YYYY hh:mm A')
    },
    // form validations.
    onlyNumbers(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault()
      }
    },
    alphaNumeric(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-z0-9-]$/.test(key)) {
        e.preventDefault()
      }
    },
    async getServicesTypes() {
      try {
        const params = {
          includeCategories: false,
          attributes: ['registryUuid', 'description']
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = data.map(({ registryUuid, description: name }) => ({ registryUuid, name }))
      } catch (err) {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  form{
    >div{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
        &.massive-individual-wrapper{
          border-bottom: 1px solid #ccc;
          padding: 0 0 8px 0;
          .massive-individual-switch{
            flex-direction: row;
          }
        }
        label{
            font-weight: bold;
        }
        input{
            padding: 6px 8px;
            width: 100%;
            height: 35px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
    }
    .tabs-selector{
      border: 1px solid #ccc;
      display: flex;
      height: 35px;
      border-radius: 4px;
      button{
        border: none;
        background-color: #fff;
        outline: none;
        color: #000015;
        width: calc(100% / 2);
        border-right: 1px solid #ccc;
        border-radius: 4px;
        &.active{
          background-color: #5B2AE0;
          color: white;
        }
        &:last-child{
          border-right: 0;
        }
      }
    }
    .tooltip-btn{
      width: 25px;
      height: 25px;
      border: 0;
      border-radius: 4px;
      margin-left: 4px;

      background-color: #3c4b64;
      color: #FFF;
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
