<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
  >
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="isErrorResponse">
      {{error.message}}
    </CAlert>
    <template v-slot:header>
      <h5 class="modal-title">Editar administrador</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <form
      id="editAdmin"
      ref="form"
      v-on:submit.prevent="onSubmit">
      <div>
        <label for="">Nombre*</label>
        <input
          type="text"
          placeholder="Nombre"
          v-model="data.name"
          maxlength="100"
          @keypress="onlyLetters($event)"
          v-c-tooltip="'Corresponde al nombre del administrador'"
          required>
      </div>

      <div>
        <label>Compañía</label>
        <select
          v-model="data.companyUuid"
          v-c-tooltip="'Indica la compañía a la que pertenece el administrador (opcional)'"
        >
          <option
            v-if="!data.companyUuid"
            :value="null"
            disabled
          >
            Selecciona una opción
          </option>
          <option
            v-for="company in companies"
            :key="company.registryUuid"
            :value="company.registryUuid"
          >
            {{ company.name }}
          </option>
        </select>
      </div>

      <div>
        <label for="">Correo electrónico*</label>
        <input
          type="email"
          placeholder="Correo electrónico"
          maxlength="100"
          v-model="data.email"
          v-c-tooltip="'Corresponde al correo con el que iniciará sesión el administrador'"
          required>
      </div>
      <div>
        <div class="d-flex mb-2">
          <label class="my-auto">Nueva contraseña</label>
          <div
            v-c-tooltip="'Corresponde a la contraseña con la que se accederá al panel de administrador'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          :type="isPasswordVisible ? 'text' : 'password'"
          placeholder="Ej: qwerty12"
          v-model="data.password"
          maxlength="8"
          minlength="8"
          pattern="^[a-zA-Z0-9]{8}$"
          autocomplete="new-password"
          title="8 caracteres de longitud, solo números y letras (sin acentos)"
          @keypress="alphaNumeric($event)">
        <a class="show-pass" @click="isPasswordVisible = !isPasswordVisible">
          <span v-if="!isPasswordVisible">Mostrar</span>
          <span v-if="isPasswordVisible">Ocultar</span>
        </a>
      </div>
      <div>
        <div class="d-flex mb-2">
          <label class="my-auto">Confirmar Contraseña</label>
          <div
            v-c-tooltip="'Corresponde a la corroboración de la contraseña de acceso'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          :type="isConfirmPasswordVisible ? 'text' : 'password'"
          placeholder="Ej: qwerty12"
          v-model="confirmPassword"
          maxlength="8"
          minlength="8"
          pattern="^[a-zA-Z0-9]{8}$"
          autocomplete="new-password"
          title="8 caracteres de longitud, solo números y letras (sin acentos)"
          @keypress="alphaNumeric($event)">
        <a class="show-pass" @click="isConfirmPasswordVisible = !isConfirmPasswordVisible">
          <span v-if="!isConfirmPasswordVisible">Mostrar</span>
          <span v-if="isConfirmPasswordVisible">Ocultar</span>
        </a>
      </div>
    </form>
    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="editAdmin"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Actualiza los datos del administrador editado'">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  data: {
    name: '',
    email: '',
    password: '',
    companyUuid: ''
  },
  confirmPassword: '',
  isPasswordVisible: false,
  isConfirmPasswordVisible: false,
  isRequesting: false,
  isErrorResponse: false,
  isValidRequest: false,
  error: {},
  companies: null
})
export default {
  name: 'EditAdmin',
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    dataAdmin: {
      type: Object,
      required: true
    }
  },
  data() {
    return initialState()
  },
  watch: {
    isModalActive(value) {
      this.fetchCompanies()
      if (value) {
        this.isErrorResponse = false
        this.isVisible = false
        this.data.name = this.dataAdmin.name
        this.data.email = this.dataAdmin.email
        this.data.companyUuid = this.dataAdmin.companyUuid || null
        this.data.password = ''

        this.confirmPassword = ''
      }
    }
  },
  methods: {
    async fetchCompanies() {
      this.isRequesting = true
      this.alertError = false

      try {
        const { data } = await this.axios.get('providers/companies?format=asgmt')
        this.companies = data
      } catch (err) {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    },
    onSubmit() {
      if (this.data.password || this.confirmPassword) {
        if (this.data.password === this.confirmPassword) {
          this.request()
        } else {
          this.isErrorResponse = true
          this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0002' } } })
          this.isRequesting = false
        }
      } else {
        this.request()
      }
    },
    request() {
      this.isRequesting = true
      const admin = {}
      Object.keys(this.data).forEach((key) => {
        if (this.data[key]) {
          admin[key] = this.data[key]
        }
      })
      this.axios.put(`/users/${this.dataAdmin.uuid}`, {
        ...admin
      }).then(() => {
        this.isErrorResponse = false
        this.$emit('onSuccess')
        this.resetForm()
      }).catch((err) => {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
      }).finally(() => {
        this.isRequesting = false
      })
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    onCancel() {
      this.$emit('onClose')
    },
    // validations
    onlyLetters(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-zÑñÁÉÍÓÚÜáéíóúü. ]$/.test(key)) {
        e.preventDefault()
      }
    },
    alphaNumeric(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-z0-9]$/.test(key)) {
        e.preventDefault()
      }
    }

  }
}
</script>

<style lang="scss" scoped>
form{
    >div{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
        label{
            font-weight: bold;
        }
        select,input{
            padding: 6px 8px;
            width: 100%;
            height: 35px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
        a.show-pass{
          position: absolute;
          top: 35px;
          right: 12px;
          color: #5B2AE0;
          &:hover{
            cursor: pointer;
          }
        }
    }
    .tooltip-btn{
      width: 25px;
      height: 25px;
      border: 0;
      border-radius: 4px;
      margin-left: 4px;

      background-color: #3c4b64;
      color: #FFF;
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
