<template>
  <div class="products">
    <div tabindex="0" ref="successServiceType">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div>
        <button
          id="add_product"
          type="button"
          @click="onOpenCreateModal(null)"
          class="btn-primary add-button"
        >
          +
        </button>
      </div>
      <div class="card-header">
        <h3>Listado de productos</h3>
        <p>
          Total registros: <strong>{{ allRecords }}</strong>
        </p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="false"
          :clickableRows="true"
          :noItemsView="noItemsView"
          hover
          class="main-table"
        >
          <template #edit="{item}">
            <td class="py-2">
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  @click="onOpenCreateModal(item)"
                >
                  Editar
                </button>
              </div>
            </td>
          </template>
          <template #delete="{item}">
            <td class="py-2">
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  @click="confirmDeleteModal(item)"
                >
                  Eliminar
                </button>
              </div>
            </td>
          </template>
        </CDataTable>
        <CPagination :active-page.sync="page" :pages="pages" align="center" />
        <b-modal
          id="modal-sm"
          title="Confirmar Eliminar"
          hide-footer
        >
          <label>¿Desea eliminar este Producto?</label>
          <div v-if="!showModalError" class="text center column w-100 " >
            <hr>
            <div class="d-flex justify-content-end">
              <b-button
                class="btn btn-secondary button-modal-sm ml-3 mt-2"
                block
                @click="$bvModal.hide('modal-sm')"
              >Cancelar</b-button>
              <b-button
                class="btn btn-danger button-modal-sm ml-3"
                block
                variant="danger"
                :disabled="disabledDeleteBtn"
                @click="deletePrice(deleteProductUuid)"
              >Eliminar</b-button>
            </div>
          </div>
          <div
            class="bg-danger error w-auto rounded d-flex justify-content-center"
            v-else
          >
            <label class="mt-2">{{ showModalError }}</label>
          </div>
        </b-modal>
        <CreateProduct
          :isModalActive="modals.createProductIsActive"
          :category="product"
          :isEditingProduct="isEditingProduct"
          @onSuccess="onSuccessCreateServiceType"
          @onClose="onCloseCreateModal"
        ></CreateProduct>
      </div>
    </div>
  </div>
</template>
<script>
import CreateProduct from '../components/serviceTypes/products/createProduct.vue'

const fields = [
  { key: 'serviceType', label: 'Tipo de Servicio', sorter: true },
  { key: 'name', label: 'Nombre', sorter: true },
  { key: 'description', label: 'Descripción', sorter: true },
  {
    key: 'edit',
    label: 'Editar',
    _style: 'width:10%',
    sorter: false
  },
  {
    key: 'delete',
    label: 'Eliminar',
    _style: 'width:10%',
    sorter: false
  }
]

export default {
  name: 'Products',
  components: {
    CreateProduct
  },
  data() {
    return {
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      product: null,
      items: [],
      isEditingProduct: false,
      fields,
      page: 1,
      pages: 0,
      allRecords: 0,
      deleteProductUuid: null,
      disabledDeleteBtn: false,
      modals: {
        createProductIsActive: false,
        editProductIsActive: false,
        deleteProduct: false
      },
      success: {
        hasSuccess: false,
        message: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const { data } = await this.$serverless.get('/products/productslist')
        this.items = data.data.map((info) => ({
          ...info,
          serviceType: info.service_type.description
        }))
        this.pages = 1
        this.allRecords = data.length
      } catch (error) {
        console.log(error)
      }
    },
    confirmDeleteModal(item) {
      this.deleteProductUuid = item.registryUuid
      this.$bvModal.show('modal-sm')
    },
    async deletePrice(registryUuid) {
      try {
        this.disabledDeleteBtn = true
        await this.$serverless.delete(`/products/productslist/${registryUuid}`)
        await this.getData()
        this.$bvModal.hide('modal-sm')
        this.disabledDeleteBtn = false
      } catch (error) {
        this.showModalError = error.message
        return this.showModalError
      }
    },
    onOpenCreateModal(item) {
      if (item) {
        this.isEditingProduct = true
        this.product = item
      }
      this.modals.createProductIsActive = true
    },
    onCloseCreateModal() {
      this.modals.createProductIsActive = false
      this.isEditingProduct = false
      this.product = null
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    async onSuccessCreateServiceType() {
      this.getData()
      this.onCloseCreateModal()
      this.setSuccessMessage('Producto creado con éxito')
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  .add-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .card {
    .card-header,
    .card-footer {
      display: flex;
      p {
        margin: auto 0 auto auto;
      }
    }
  }
  .tabs-selector {
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button {
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 100%;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active {
        background-color: #5b2ae0;
        color: white;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}
</style>
