<template>
  <div class="container bg-white rounded p-4">
    <p class="title text-center">Reporte de satisfacción del cliente</p>
    <div class="d-flex justify-content-center mb-2">
      <select
        name="type"
        class="select mr-1"
        @change="loadChart"
        :disabled="!isLoading"
        v-model="options.month">
        <option v-for="month in months" :value="month.value" :key="month.value">
          {{month.text}}
        </option>
      </select>
      <select
        name="year"
        class="select ml-1"
        @change="loadChart"
        :disabled="!isLoading"
        v-model="options.year">
        <option :value="2020">2020</option>
        <option :value="2021" disabled>2021</option>
      </select>
    </div>
    <canvas id="customer-chart"></canvas>
  </div>
</template>

<script>
import moment from 'moment'

import Chart from 'chart.js'
import queryparser from '../utils/queryparser'

export default {
  data: () => ({
    options: {
      month: moment().month() + 1,
      year: moment().year()
    },
    months: [
      {
        text: 'Enero',
        value: 1
      },
      {
        text: 'Febrero',
        value: 2
      },
      {
        text: 'Marzo',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Mayo',
        value: 5
      },
      {
        text: 'Junio',
        value: 6
      },
      {
        text: 'Julio',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Septiembre',
        value: 9
      },
      {
        text: 'Octubre',
        value: 10
      },
      {
        text: 'Noviembre',
        value: 11
      },
      {
        text: 'Diciembre',
        value: 12
      }],
    data: {},
    chart: null,
    isLoading: false
  }),
  mounted() {
    this.loadChart()
  },
  methods: {
    async loadChart() {
      this.isLoading = false
      const params = queryparser.parse(this.options)

      try {
        const date = moment().set({ month: this.options.month - 1, year: this.options.year })
        const endDay = moment(date).endOf('month').date()
        const labels = (new Array(endDay).fill(0)).map((_, i) => i + 1)

        const { data: dataset } = await this.axios.get(`providers/report/scores?${params}`)

        if (this.chart) {
          this.chart.destroy()
        }

        document.getElementById('customer-chart').innerHTML = '&nbsp;'
        document.getElementById('customer-chart').innerHTML = '<canvas id="canvas"></canvas>'
        const ctx = document.getElementById('customer-chart').getContext('2d')

        const datasetMap = new Map()
        Object.entries(dataset).map(([key, data]) => datasetMap.set(+key, data))

        const chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: 'Satisfacción del cliente',
                borderColor: '#2eb85c',
                data: labels.map((key) => {
                  const x = +key
                  if (!datasetMap.has(+key)) {
                    return { x, y: 0 }
                  }
                  const data = datasetMap.get(key)
                  return { x, y: +data.average, data }
                }),
                lineTension: 0,
                fill: false
              }
            ]
          },
          options: {
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                beforeLabel(item) {
                  let average = 0
                  let total = 0

                  if (datasetMap.has(+item.xLabel)) {
                    const d = datasetMap.get(+item.xLabel)
                    average = d.average
                    total = d.total
                  }

                  return [
                    `Promedio de calificaciónes: ${average}`,
                    `Servicios calificados: ${total}`
                  ].join('\n')
                },
                label() {
                  return null
                }
              }
            }
          }
        })

        this.chart = chart
        this.data = dataset
        this.isLoading = true
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
