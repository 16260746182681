<template>
  <div class="customers">
    <div tabindex="0" ref="successCustomers">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div class="card-header">
        <h3>Listado de clientes en lista negra</h3>
        <p>Total de clientes en lista negra: <strong>{{ allRecords }}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="false"
          :clickableRows="true"
          @row-clicked="onRowClicked"
          :noItemsView="noItemsView"
          hover
          class="main-table"
        >
          <!-- table filters -->
          <template #over-table>
            <div class="filters-wrapper">
              <div class="open-filter">
                <h3>Buscar por número telefónico</h3>
                <div class="open-filter-wrapper">
                  <input
                    v-model.trim="openFilterInput"
                    type="text"
                    :placeholder="'Teléfono'"
                    maxlength="10"
                    v-c-tooltip="'Búsqueda de clientes en lista negra por teléfono'"
                    @keypress.enter="filter()"
                    class="open-filter-input"
                  >
                  <button
                    class="btn btn-primary"
                    v-c-tooltip="'Ejecuta la acción de búsqueda de lo ingresado en el campo'"
                    @click="filter()"
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </template>
          <!-- table data -->
          <template #createdAt="{item}">
            <td>
              {{ formatDate(item.createdAt) }}
            </td>
          </template>
          <!-- <template #show_details="{item}">
                        <td class="py-2">
                            <CButton
                                :id="`detail_${item.id}`"
                                color="primary"
                                variant="outline"
                                square
                                size="sm"
                                @click="openModal(item)"
                            >
                                {{ Boolean(item._toggled) ? 'Hide' : 'Detalle' }}
                            </CButton>
                            <b-tooltip :target="`detail_${item.id}`" placement="left">
                                Despliega el modal de detalle de la información del cliente
                            </b-tooltip>
                        </td>
                    </template> -->
        </CDataTable>
        <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
      </div>
      <div class="card-footer">
        <p>Total: <strong>{{ allRecords }}</strong></p>
      </div>
    </div>
    <CModal
      title="Detalle de cliente"
      :show.sync="warningModal"
      :closeOnBackdrop="false"
    >
      <div tabindex="0" ref="ServicesAlertError">
        <CAlert
          :color="error.type || 'danger'"
          :closeButton="true"
          :show.sync="alertError">
          {{error.message}}
        </CAlert>
      </div>
      <div role="tablist">
        <!-- Detail -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="primary"
              v-c-tooltip="'Información referente al cliente seleccionado'">Detalles</b-button>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            accordion="my-accordion"
            v-model="collpaseOptions.details"
            role="tabpanel"
            class="p-3 edit-address">
            <div class="modal-text-wrapper p-3">
              <div>
                <strong>Nombre completo: </strong>
                <span v-if="customer.userName && customer.lastName && customer.motherLastName">{{ `${customer.userName} ${customer.lastName} ${customer.motherLastName}` }}</span>
                <span v-else>Sin nombre</span>
              </div>
              <div>
                <strong>Teléfono: </strong>
                <span>{{ customer.phoneNumber }}</span>
              </div>
              <div>
                <strong>Correo electrónico: </strong>
                <span>{{ customer.email }}</span>
              </div>
              <div>
                <strong>Fecha de nacimiento: </strong>
                <span>{{ formatDate(customer.birthday) }}</span>
              </div>
              <div>
                <strong>Dirección: </strong>
                <span>{{ customer.mainAddress ? customer.mainAddress : 'Sin dirección' }}</span>
              </div>
            </div>
          </b-collapse>
        </b-card>

      </div>
      <template #footer>

        <button
          class="btn btn-dark"
          @click="warningModal = false"
        >
          Salir
        </button>

      </template>
    </CModal>
  </div>
</template>

<script>
import moment from 'moment'
import queryparser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'

const fields = [
  { key: 'customerPhoneNumber', label: 'Teléfono' },
  { key: 'createdAt', label: 'Fecha de registro' }
  // {
  //   key: 'show_details',
  //   label: 'Acción',
  //   // _style: 'width:1%'
  // }
]
export default {
  name: 'BlackList',
  components: {
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      items: [],
      fields,
      page: 1,
      pages: 0,
      allRecords: 0,
      openFilterInput: '',
      warningModal: false,
      isClear: false,
      success: {
        hasSuccess: false,
        message: ''
      },
      error: {},
      customer: {
        userName: '',
        lastName: '',
        motherLastName: '',
        phoneNumber: '',
        email: '',
        mainAddress: '',
        birthday: ''
      },
      alertError: false,
      isCompanyAdmin: false,
      collpaseOptions: {
        details: true,
        card: false
      },
      subscription: false
    }
  },
  watch: {
    page() {
      if (!this.isClear) {
        this.filter()
      }
    },
    openFilterInput(value) {
      if (value.length === 10 || value.length === 0) {
        this.filter()
      }
    }
  },
  async mounted() {
    this.isCompanyAdmin = localStorage.getItem('isCompanyAdmin')
    try {
      return this.filter()
    } catch (err) {
      return false
    }
  },
  methods: {
    onSuccess() {
      this.filter()
      this.warningModal = false
      this.setSuccessMessage('Tarjeta eliminada correctamente')
    },
    filters() {
      const options = {
        page: this.page
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options.customerPhoneNumber = this.openFilterInput
      }

      return options
    },
    filter() {
      const queryString = queryparser.parse(this.filters())
      this.axios.get(`/customers/blacklist?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data
          this.pages = data.pagination.totalPages
          this.allRecords = data.pagination.total
        })
        .catch((e) => {
          this.alertError = true
          this.error = getErrorMessage(e)
        })
    },
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item) {
      // this.openModal(item)
    },
    clearFilters() {
      this.openFilterInput = ''
      this.page = 1

      this.isClear = true
      this.filter()
      this.isClear = false
    },
    openModal(item) {
      this.warningModal = true
      this.alertError = false
      this.customer = item
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    scrollTopError() {
      this.$nextTick(() => {
        this.$refs.customerFocusAlertError.focus()
      })
    },
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successCustomers.focus()
      })
    },
    // formats
    formatDate(date) {
      if (!date) {
        return 'No disponible'
      }
      return moment
        .utc(date, 'YYYYMMDD HH:mm:ss')
        .format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.customers{
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 2);
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
  .card{
    .card-header,
    .card-footer{
      display: flex;
      p{
        margin: auto 0 auto auto;
      }
    }
    .card-body{
      .filters-wrapper{
        display: flex;
        flex-direction: column;
        h3{
          font-size: 18px;
        }
        .open-filter{
          .open-filter-wrapper{
            display: flex;
            flex-direction: column;
            >div{
              display: flex;
              .btn-primary{
                margin: 0 auto;
              }
            }
            select,input{
              border: 1px solid #ccc;
              border-radius: 4px;
              margin-bottom: 16px;
              height: 35px;
            }
            button{
              height: 35px;
              border-radius: 4px;
              &:disabled{
                opacity: .5;
              }
            }
          }
        }
        .status-filter{
          margin-bottom: 16px;
          .tabs-selector{
            button{
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
  .modal-text-wrapper{
      >div{
        margin-bottom: 8px;
      }
      .amount-requested{
        display: flex;
        >div{
          margin-left: 4px;
          input{
            width: 30%;
            border: 1px solid #ccc;
          }
          button{
            border: 1px solid #5B2AE0;
          }
        }
      }
  }
}
@media(min-width: 1136px){
  .customers{
    .card{
      .card-body{
        .filters-wrapper{
          flex-direction: row;
          .open-filter{
            .open-filter-wrapper{
              flex-direction: row;
              >div{
                >div.filter-buttons-wrapper{
                  >button{
                    height: 39px;
                    &:last-child{
                      margin-left: 8px !important;
                    }
                  }
                }
                >*{
                  margin-right: 8px !important;
                }
              }
              select{
                margin-right: 8px;
              }
            }
          }
          > :nth-child(2){
            margin-left: auto;
          }
          > :nth-child(n+3){
            margin-left: 8px;
          }
          .status-filter{
            .completed-tabs-selector{
              button{
                width: 53px !important;
              }
            }
            .tabs-selector{
              button{
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 1135px){
  .customers{
    .card{
      .card-body{
        .filters-wrapper{
          flex-direction: column;
          .open-filter{
            .open-filter-wrapper{
              >div{
                button{
                  margin-left: 8px !important;
                  margin-right: 0px !important;
                }
              }
            }
          }
          .status-filter{
            margin-left: 0px;
          }
        }
      }
    }
  }
}
</style>
