<template>
  <div class="container">
    <CAlert
      v-if="success"
      color="success"
      :closeButton="true"
      :show.sync="success"
    >
      Archivo de configuración actualizado correctamente
    </CAlert>

    <CAlert
      v-if="error"
      :color="error.type || 'danger'"
      :closeButton="true"
    >
      {{error.message}}
    </CAlert>

    <div class="card">
      <div class="card-header">
        Archivo de Configuración
      </div>
      <div class="card-body">
        <JSONEditor
          ref="jsonEditor"
          :name="'Config File'"
          :data="data"
          :schemaURL="schemaURL"
          @change="onDataChange"
          @validate="onValidate"
        >
        </JSONEditor>
        <div class="mt-2 text-right">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!(!this.isLoading && !this.error && this.isValid && this.data)"
            @click="submit"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '../common/errors'
import JSONEditor from '../components/JSONEditor'

export default {
  components: {
    JSONEditor
  },
  data: () => ({
    schemaURL: process.env.VUE_APP_CONFIG_FILE_SCHEMA,
    configURL: process.env.VUE_APP_CONFIG_FILE,
    name: 'Config File',
    config: {},
    data: {},
    success: null,
    error: null,
    isValid: true,
    isLoading: true,
    isInitializing: true
  }),
  methods: {
    onDataChange(data) {
      this.data = data
    },
    onValidate(isValid) {
      this.isValid = isValid
    },
    async submit() {
      this.success = null

      try {
        this.error = null
        this.isLoading = true

        const data = this.$refs.jsonEditor.getData() // Prevent Editor Issues

        await this.axios.put('/config', { config: data })
        this.success = true
      } catch (error) {
        this.success = false
        this.error = getErrorMessage(error)
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted() {
    try {
      this.error = null
      this.isLoading = true

      const { data } = await this.$http.get(`${this.configURL}?d=${Date.now()}`)
      this.config = data
      this.data = data
    } catch (error) {
      this.error = getErrorMessage(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
