import { createInstance } from '../utils/axios'
import setupInterceptors from '../utils/axios-interceptors'

export default function plugin(Vue, axios) {
  const adminInstance = createInstance(axios)
  setupInterceptors(adminInstance)

  const rootInstance = createInstance(axios, process.env.VUE_APP_BASE_URL.replace('/v1/admin', '/v1/'))
  const serverlessInstance = createInstance(axios, process.env.VUE_APP_SERVERLESS_BASE_URL)
  setupInterceptors(rootInstance)
  setupInterceptors(serverlessInstance)

  // TODO: Better approach
  Vue.axios = adminInstance
  Vue.$axios = rootInstance
  Vue.$http = axios

  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return adminInstance
      }
    },
    $axios: {
      get() {
        return rootInstance
      }
    },
    $http: {
      get() {
        return axios
      }
    },
    $serverless: {
      get() {
        return serverlessInstance
      },
      post() {
        return serverlessInstance
      },
      put() {
        return serverlessInstance
      },
      delete() {
        return serverlessInstance
      }
    }
  })
}
