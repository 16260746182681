<template>
  <div class="notifications">
    <div>
      <div v-if="onResponse">
        <CAlert
          color="success"
          :closeButton="true"
          :show.sync="isSuccessResponse">
          Se envió la notificación correctamente
        </CAlert>
        <CAlert
          :color="error.type || 'danger'"
          :closeButton="true"
          :show.sync="isErrorResponse">
          {{error.message}}
        </CAlert>
      </div>
      <h2>Notificación personalizada</h2>
      <hr>
      <div>
        <label for="">Número de teléfono del cliente*</label>
        <input
          type="text"
          placeholder="Número"
          v-model="phoneNumber"
          maxlength="10"
          @keypress="onlyNumbers($event)"
          v-c-tooltip="'Corresponde al número de teléfono del cliente (a 10 dígitos)'">
      </div>
      <div>
        <label for="">Título de la notificación*</label>
        <input
          type="text"
          placeholder="Título"
          v-model="title"
          maxlength="20"
          v-c-tooltip="'Corresponde al Título de la notificación a mostrar en el móvil del cliente'">
      </div>
      <div>
        <label for="">Mensaje de la notificación*</label>
        <textarea
          cols="30"
          rows="10"
          maxlength="100"
          v-model="message"
          v-c-tooltip="'Corresponde al cuerpo de la notificación a mostrar en el móvil del cliente'"></textarea>
      </div>
      <div>
        <button
          class="btn btn-primary"
          @click="sendNotification()">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '../common/errors'

const initialState = () => ({
  title: '',
  phoneNumber: '',
  message: '',
  error: {},
  onResponse: false,
  isSuccessResponse: false,
  isErrorResponse: false
})

export default {
  name: 'Notifications',
  data() {
    return initialState()
  },
  methods: {
    sendNotification() {
      this.onResponse = true
      if (this.title && this.phoneNumber && this.message) {
        this.axios.post('/customers/notifications', {
          title: this.title,
          phoneNumber: this.phoneNumber,
          message: this.message
        }).then(() => {
          this.resetForm()
          this.onResponse = true
          this.isSuccessResponse = true
          this.isErrorResponse = false
        }).catch((err) => {
          this.isSuccessResponse = false
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
      } else {
        this.isSuccessResponse = false
        this.isErrorResponse = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EA0054C' } } })
      }
    },
    onlyNumbers(e) {
      if (!this.isNumber(e.charCode) && (e.charCode !== 0)) {
        e.preventDefault()
      }
    },
    isNumber(charCode) {
      return (charCode >= 48 && charCode <= 57)
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    }
  }
}
</script>

<style lang="scss" scoped>
    .notifications{
        display: flex;
        >div{
            background-color: white;
            padding: 16px;
            box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.06);
            width: 320px;
            margin: auto;
            border-radius: 4px;
            h2{
                font-size: 18px;
            }
            >div{
                display: flex;
                flex-direction: column;
                margin-bottom: 8px;
                &:last-child{
                    margin-bottom: 0;
                }
                label{
                    font-weight: bold;
                }
                input{
                    padding: 6px 8px;
                    height: 35px;
                }
                button{
                    margin-left: auto;
                }
            }
        }
    }
</style>
