<template>
  <div class="finish-service">
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="alertError">
      {{error.message}}
    </CAlert>
    <div
      v-if="activeProviders && typeof activeProviders === 'object'"
      class="d-flex flex-column">
      <select
        v-if="STATUS_SERVICES_TO_FINISH_SERVICE.includes(statusId) && !confirm"
        v-model="nearbyProviderSelected"
        v-c-tooltip="'Listado de los colaboradores activos para seleccionar el repartidor que realizó el servicio'"
      >
        <option
          :value="null"
          disabled
        >
          Selecciona una opción
        </option>
        <option
          v-for="item in activeProviders"
          :key="item.registryUuid"
          :value="item.registryUuid"
        >
          {{ `${item.shortName}-${item.phoneNumber}` }}
        </option>
      </select>
      <div
        class="confirm"
        :class="!confirm ? 'd-flex' : ''">
        <div
          v-if="confirm"
          class="d-flex">
          <span class="my-auto">¿Desea finalizar el servicio?</span>
          <button
            class="ml-auto btn btn-secondary"
            @click="confirm  = false">
            No
          </button>
          <button
            class="ml-2 btn btn-secondary"
            @click="finishService()">
            Si
          </button>
        </div>
        <button
          v-show="!confirm"
          @click="preConfirm()"
          class="ml-auto btn btn-secondary"
          v-c-tooltip="'Finaliza y registra el servicio despachado al colaborador'">
          Finalizar servicio
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '../../common/errors'
import {
  STATUS_SERVICES_TO_FINISH_SERVICE,
  STATUS_SERVICES_TO_REASSIGN,
  SERVICE_TYPES_UUID_WITH_REQUIRED_EVIDENCE
} from '../../common/enums'

export default {
  name: 'FinishService',
  data() {
    return {
      nearbyProviderSelected: null,
      activeProviders: [],
      error: {},
      alertError: false,
      confirm: false,
      // enums
      STATUS_SERVICES_TO_FINISH_SERVICE,
      STATUS_SERVICES_TO_REASSIGN,
      SERVICE_TYPES_UUID_WITH_REQUIRED_EVIDENCE
    }
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    statusId: {
      type: Number,
      required: true
    },
    serviceUuid: {
      type: String,
      required: true
    },
    addressUuid: {
      type: String,
      required: false
    },
    paymentTypeId: {
      type: Number,
      required: true
    },
    hasEvidence: {
      type: Boolean,
      required: true
    },
    serviceTypeUuid: {
      type: String
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.confirm = false
        this.alertError = false
        this.getNearbyProviders()
      }
    }
  },
  mounted() {
    this.getNearbyProviders()
  },
  methods: {
    preConfirm() {
      if (!this.SERVICE_TYPES_UUID_WITH_REQUIRED_EVIDENCE.includes(this.serviceTypeUuid) || this.hasEvidence) {
        if ((this.nearbyProviderSelected
          && this.STATUS_SERVICES_TO_FINISH_SERVICE.includes(this.statusId))
          || this.STATUS_SERVICES_TO_REASSIGN.includes(this.statusId)) {
          this.confirm = true
        } else {
          this.confirm = false
          this.alertError = true
          this.error = getErrorMessage({ response: { data: { codeApi: 'EA0094' } } })
        }
      } else {
        this.confirm = false
        this.alertError = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EA0080' } } })
      }
    },
    finishService() {
      this.axios.put(`/services/${this.serviceUuid}/finalize`, {
        providerUuid: this.nearbyProviderSelected
      }).then(() => {
        this.alertError = false
        this.$emit('onSuccess')
        this.confirm = false
      }).catch((err) => {
        this.confirm = false
        this.alertError = true
        this.error = getErrorMessage(err)
      })
    },
    getNearbyProviders() {
      this.activeProviders = []
      this.nearbyProviderSelected = null
      this.axios.get('providers?active=1&all=true')
        .then((response) => {
          const { data } = response.data
          this.activeProviders = data
        }).catch(() => {
          this.activeProviders = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.finish-service{
    select{
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 16px;
    }
    .confirm{
      >div{
        .btn-secondary{
          width: 45px;
        }
      }
    }
}
</style>
