// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/voximplant/processing.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".connection[data-v-1036abe5]{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;&[data-v-1036abe5] .sui-icon{--sui-icon-color:#fff!important;margin-right:4px}}.wrapper-process[data-v-1036abe5]{position:relative;width:24px;height:4px;margin:8px 0 16px 0}.processing[data-v-1036abe5]{width:24px;height:4px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.show-process[data-v-1036abe5]{position:absolute;height:100%;width:0;left:0;overflow:hidden;-webkit-animation:show-1036abe5 1s infinite;animation:show-1036abe5 1s infinite}@-webkit-keyframes show-1036abe5{0%{width:0}9%{width:0}10%{width:8px}39%{width:8px}40%{width:16px}69%{width:16px}70%{width:24px}to{width:24px}}@keyframes show-1036abe5{0%{width:0}9%{width:0}10%{width:8px}39%{width:8px}40%{width:16px}69%{width:16px}70%{width:24px}to{width:24px}}.state[data-v-1036abe5]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.text[data-v-1036abe5]{font-family:Roboto,sans-serif;font-size:16px;line-height:20px;color:#662eff;margin:16px 0 24px 0}", ""]);
// Exports
module.exports = exports;
