<template>
  <div class="assign-assing-company">
    <form v-on:submit.prevent="assignCompany">
      <div>
        <CAlert
          :color="error.type || 'danger'"
          :closeButton="true"
          :show.sync="alertError">
          {{error.message}}
        </CAlert>
        <div class="wrapper">
          <select
            v-model="selectedCompany"
            v-c-tooltip="'Listado de compañías para asignar el servicio a la empresa le dará seguimiento'"
            :disabled="STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED.includes(service.statusId) ? true : false"
          >
            <option
              :value="null"
              disabled
            >
              Sin compañía
            </option>
            <option
              v-for="company in companyList"
              required="true"
              :key="company.registryUuid"
              :value="company.registryUuid"
            >
              {{ company.name }}
            </option>
          </select>
          <button
            type="submit"
            class="btn btn-primary"
            v-if="isLoading || !STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED.includes(service.statusId)"
            v-c-tooltip="'Asigna el servicio a la empresa seleccionada'"
          >
            Asignar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { getErrorMessage } from '../../common/errors'
import { STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED } from '../../common/enums'

export default {
  name: 'AssignServiceCompany',
  data() {
    return {
      companies: [],
      selectedCompany: null,
      error: {},
      alertError: false,
      isLoading: false,
      isCompanyAdmin: false,
      STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED
    }
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    service: {
      type: Object,
      required: true
    },
    companyList: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.alertError = false
        this.selectedCompany = this.service.companyUuid
      }
    }
  },
  async mounted() {
    this.isCompanyAdmin = localStorage.getItem('isCompanyAdmin')
    this.selectedCompany = this.service.companyUuid
    if (this.companyList) {
      this.companies = this.companyList
      return true
    }
    await this.fetchCompanies()
    return true
  },
  methods: {
    async assignCompany() {
      const { registryUuid } = this.service
      this.isLoading = true
      this.alertError = false

      try {
        const company = this.companyList.find((obj) => obj.registryUuid === this.selectedCompany)
        if (!company) {
          return
        }
        await this.axios.put(`services/${registryUuid}/assign-company`, {
          companyUuid: this.selectedCompany,
          hasCoverage: company.isCovered
        })
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isLoading = false
      }
    },
    async fetchCompanies() {
      this.isLoading = true
      this.alertError = false

      try {
        const { data } = await this.axios.get('providers/companies?format=asgmt')
        this.companies = data
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-assing-company{
  margin-left: 8px;
    >div:first-child{
        display: flex;
        flex-direction: column;
    }
    .wrapper{
      display: flex;
      select{
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: calc(100% - 75px);
      }
      button{
          width: fit-content;
          margin-left: auto;
          margin-left: 8px;
          padding: 8px;
      }
    }
}
</style>
