const key = 'token'

export function setAuth({
  token, userHasCompany,
  isCompanyAdmin, userPermissions, permissions, userData, defaultRoute
}) {
  localStorage.setItem(key, token)
  if (isCompanyAdmin) {
    localStorage.setItem('isCompanyAdmin', isCompanyAdmin)
  }
  if (userHasCompany) {
    localStorage.setItem('userHasCompany', userHasCompany)
  }
  if (userPermissions) {
    localStorage.setItem('userPermissions', JSON.stringify(userPermissions))
  }
  if (permissions) {
    localStorage.setItem('permissions', JSON.stringify(permissions))
  }
  if (userData) {
    localStorage.setItem('userData', JSON.stringify(userData))
  }
  if (defaultRoute) {
    localStorage.setItem('defaultRoute', JSON.stringify(defaultRoute))
  }
}

export function getAuthToken() {
  return localStorage.getItem(key)
}
export function getUserData() {
  return localStorage.getItem('userData')
}

export function getAuth() {
  return {
    token: localStorage.getItem(key),
    isCompanyAdmin: !!localStorage.getItem('isCompanyAdmin'),
    userPermissions: JSON.parse(localStorage.getItem('userPermissions')),
    permissions: JSON.parse(localStorage.getItem('permissions')),
    userData: JSON.parse(localStorage.getItem('userData')),
    userHasCompany: JSON.parse(localStorage.getItem('userHasCompany')),
    defaultRoute: JSON.parse(localStorage.getItem('defaultRoute'))
  }
}

export function isAuth() {
  return getAuthToken()
}

export function clearAuth(isLogout = true) {
  if (!isLogout) {
    localStorage.setItem('sessionExpired', 'true')
  }

  localStorage.removeItem(key)
  localStorage.removeItem('isCompanyAdmin')
  localStorage.removeItem('userHasCompany')
}
