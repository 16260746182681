<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
  >
    <template v-slot:header>
      <h5 class="modal-title">Crear compañía</h5>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>

    <div tabindex="0" ref="createCompanyFocusAlertError">
      <CAlert
        :color="error.type || 'danger'"
        :closeButton="true"
        :show.sync="alertError">
        {{error.message}}
      </CAlert>
    </div>

    <form id="createCompany" ref="form" v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="">Nombre*</label>
        <input
          class="form-control"
          required
          type="text"
          placeholder="Nombre"
          minlength="3"
          maxlength="50"
          v-model="company.name"
          v-c-tooltip="'Corresponde al nombre de la empresa/compañía'"
        >
      </div>
      <div class="form-group">
        <label for="">Precio por unidad*</label>
        <DecimalInput
          ref="decimalInput"
          class="form-control"
          :required="true"
          :min="0"
          :max="9999999.99"
          placeholder="Precio por unidad"
          :value="company.pricePerLiter"
          @input="val => company.pricePerLiter = val"
          v-c-tooltip="'Corresponde al precio por unidad del producto'"
        />
      </div>
      <div class="form-group">
        <label for="">Comisión por unidad*</label>
        <DecimalInput
          ref="decimalInput"
          class="form-control"
          :required="true"
          :min="0"
          :max="99.99"
          placeholder="Comisión por unidad"
          :value="company.commissionPerLiter"
          @input="val => company.commissionPerLiter = val"
          v-c-tooltip="'Corresponde a la comisión por unidad del producto'"
        />
      </div>
      <div class="form-group">
        <label for="">Selecciona un estado</label>
        <br>
        <v-select
          multiple
          :options="['CDMX','Jalisco', 'México', 'Querétaro', 'Baja California Sur', 'San Luis Potosí', 'Colima', 'Tabasco', 'Guerrero', 'Morelos', 'Veracruz', 'Hidalgo', 'Teoloyucan', 'Nicolás Romero', 'Nicolás Romero', 'Ecatepec', 'Valle de Bravo', 'Toluca']"
          label="title"
          v-model="company.state">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </div>
      <div class="form-group">
        <label for="">Logo</label>
        <div class="m-1 p-1">
          <ImageInput
            ref="imageInput"
            :width="116"
            :height="48"
            @format="onInvalidFormat"
            @input="onImageInput"
            v-c-tooltip="'Corresponde al logo de la compañía'">
          </ImageInput>
          <small class="form-text text-muted">Dimensiones 116x48 px</small>
        </div>
      </div>
      <form id="addCompanyServiceTypeForm" ref="form" v-on:submit.prevent="addCompanyServiceType">
        <div class="form-group">
          <div class="d-flex mb-2">
            <label for="serviceTypes" class="my-auto">Tipo de servicio</label>
            <div
              v-c-tooltip="'Corresponde al tipo de servicio proporcionado por la compañía'"
              class="tooltip-btn d-flex">
              <span class="m-auto">?</span>
            </div>
            
          </div>
          <select
            class="form-control"
            name="serviceTypes"
            v-model="company.typeServiceId"
            required
          >
            <option :value="null">Selecciona una opción</option>
            <option
              v-for="item in serviceTypes"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <br />
          <div class="text-right">
              <button
                type="button"
                class="btn btn-secondary mr-1"
                v-c-tooltip="
                  'Limpiar Tipo de Servicio'
                "
                @click="clearTypeService"
              >
                Limpiar
              </button>
              <button

                type="submit"
                class="btn btn-primary ml-1"
                v-c-tooltip="
                  'Agregar Tipo de Servicio'
                "
              >
                Agregar
              </button>
            </div>
            <br />
            <div>
              <CDataTable
                :items="companyServiceTypes"
                :fields="companyServiceTypesFields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                hover
                class="main-table"
              >
                <template #delete="{item, index}">
                  <td class="py-2">
                    <div class="d-flex">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        @click="deleteCompanyServiceType(index)"
                      >
                        Eliminar
                      </button>
                      <b-tooltip
                        :target="`detail_${item.registryUuid}`"
                        placement="left"
                      >
                        Eliminar Tipo de Servicio
                      </b-tooltip>
                    </div>
                  </td>
                </template>
              </CDataTable>
            </div>
        </div>
      </form>
      <div class="form-group">
        <div class="d-flex mb-2">
          <label for="serviceTypes" class="my-auto">Tipo de esquema</label>
          <div
            v-c-tooltip="'Corresponde al tipo de esquema convenido con la compañía'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <select
          class="form-control"
          name="serviceTypes"
          v-model="workingSchemaId"
          required
        >
          <option :value="0" disabled>Selecciona una opción</option>
          <option
            v-for="item in schemas"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div
        v-if="schemaSelected"
        class="form-group">
        <div class="d-flex mb-2">
          <span><strong>Comisión por unidad: </strong>
            {{ schemaSelected.commissionPerLiter }}</span>
        </div>
        <div class="d-flex mb-2">
          <span><strong>Comisión por procesador de pagos: </strong>
            {{ schemaSelected.paymentProcessorCommission }}</span>
        </div>
      </div>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          v-b-toggle.accordion-fiscal
          variant="primary"
          v-c-tooltip="'Desglosa toda la información referente a Datos Físcales'">Datos Físcales</b-button>
      </b-card-header>
      <b-collapse
        v-model="collapseOptions.fiscalSchema"
        id="accordion-fiscal"
        accordion="fiscal-accordion"
        role="tabpanel"
        class="p-3">
        <div class="form-group">
          <label for="">Esquema Físcal</label>
          <br>
          <select
            class="form-control"
            name="fiscalSchema"
            v-model="company.fiscalSchemaId"
          >
            <option :value="0">Selecciona una opción</option>
            <option
              v-for="item in fiscalSchema"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="">Razón Social</label>
          <div class="input-group mb-3">
            <input
              class="form-control"
              type="text"
              placeholder="Razón Social"
              v-model="company.businessName"
              v-c-tooltip="'Corresponde a la razón social de la empresa/compañía'">
          </div>
        </div>
        <div class="form-group">
          <label for="">RFC</label>
          <div class="input-group mb-3">
            <input
              pattern="([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])"
              class="form-control"
              type="text"
              placeholder="RFC"
              v-model="company.rfc"
              v-c-tooltip="'Corresponde al RFC de la empresa/compañía'">
          </div>
        </div>
      </b-collapse>
    </form>

    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div v-else>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="createCompany"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Registra a la compañía con los datos ingresados en el formulario'">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import vSelect from 'vue-select'
import DecimalInput from '../DecimalInput'
import ImageInput from '../ImageInput'
import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'
import { fileToBase64 } from '../../utils'
import 'vue-select/dist/vue-select.css'

const companyServiceTypesFields = [
  { key: 'serviceTypeName', label: 'Tipo de Servicio', sorter: true },
  {
    key: 'delete',
    label: 'Eliminar',
    _style: 'width:10%',
    sorter: false
  }
]
export default {
  name: 'CreateCompanyModal',
  components: {
    DecimalInput,
    ImageInput,
    vSelect
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    schemas: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    noItemsView: {
      noResults: 'Registros no encontrados.',
      noItems: 'Registros no encontrados.'
    },
    isRequesting: false,
    companyServiceTypes: [],
    companyServiceTypesDeleted: [],
    serviceTypes: [],
    companyServiceTypesFields,
    company: {
      name: '',
      pricePerLiter: null,
      commissionPerLiter: null,
      typeServiceId: 1,
      state: [],
      fiscalSchemaId: 0
    },
    collapseOptions: {
      fiscalSchema: false
    },
    fiscalSchema: [],
    workingSchemaId: '0',
    schemaSelected: null,
    state: [],
    logo: null,
    error: {},
    alertError: false,
    selected: false
  }),
  watch: {
    workingSchemaId() {
      this.schemaSelected = this.workingSchemaId
        ? this.findSchema(this.workingSchemaId) : null
    },
    company() {
      this.error = {}
    },
    isModalActive(value) {
      if (!value) {
        this.$store.state.company.name = this.company.name
        this.$refs.decimalInput.storeData()
      } else {
        this.getFiscalSchemas()
        this.getServicesTypes()
        this.$refs.imageInput.reset()
        this.alertError = false
      }
    },
    'company.state': function () {
      this.selected = false
      if (this.company.state.length > 0) {
        this.selected = true
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    deleteCompanyServiceType(index) {
      const itemDeleted = this.companyServiceTypes[index]
      if (!itemDeleted) {
        return
      }
      if (itemDeleted.registryUuid && !itemDeleted.isNew) {
        this.companyServiceTypesDeleted.push({
          ...itemDeleted,
          deletedAt: true
        })
      }
      this.companyServiceTypes.splice(index, 1)
    },
    addCompanyServiceType() {
      this.error = {}
      this.alertError = false
      const typeService = this.serviceTypes.find((obj) => +obj.id === +this.company.typeServiceId)
    
      if (!typeService) {
        return
      }

      if (this.companyServiceTypes.find(
        obj => obj.serviceTypeId === this.company.typeServiceId
      )) {
        return
      }

      const companyTypeService = {
        serviceTypeId: this.company.typeServiceId,
        serviceTypeName: typeService.name,
        isNew: true
      }

      this.companyServiceTypes.push(companyTypeService)
      this.clearTypeService()
    },
    clearTypeService() {
      this.company.typeServiceId = 1
    },
    async getFiscalSchemas() {
      try {
        const { data } = await this.axios.get('/businessModel/fiscalSchema')
        this.fiscalSchema = data
      } catch (err) {
        this.error = getErrorMessage(err)
        this.$emit('onErrorInitial', err)
      }
    },
    findSchema(schemaId) {
      if (this.schemas.length) {
        return this.schemas.find((element) => element.id === schemaId)
      }
      return null
    },
    onImageInput(file) {
      if (file) {
        this.error = {}
        this.alertError = false
      }
      this.logo = file
    },
    getInfo() {
      this.company.name = this.$store.state.company.name
      this.company.pricePerLiter = this.$store.state.company.pricePerLiter
      this.company.typeServiceId = 1
    },
    resetData() {
      this.company = {
        typeServiceId: 1,
        state: []
      }
      this.companyServiceTypes = []
      this.companyServiceTypesDeleted = []
      this.error = {}
      this.logo = null
      this.$refs.imageInput.reset()
    },
    async getData() {
      const { logo } = this
      const data = {
        ...this.company,
        typeServiceId: this.companyServiceTypes[0].serviceTypeId,
        companyServiceTypes: [...this.companyServiceTypes, ...this.companyServiceTypesDeleted],
        fiscalSchemaId: +this.company.fiscalSchemaId === 0 ? null : +this.company.fiscalSchemaId,
        workingSchemaId: +this.workingSchemaId
      }
      if (logo) {
        data.logo = await fileToBase64(logo)
      }
      return data
    },
    onCancel() {
      this.$emit('onClose')
    },
    onSubmit() {
      if (!this.companyServiceTypes || this.companyServiceTypes.length == 0) {
        this.error = ERRORS_MESSAGES.SERVICE_REQUIRED
        this.alertError = true
        this.scrollTop()
        return;
      }

      this.createCompany()
    },
    onInvalidFormat() {
      this.alertError = true
      this.scrollTop()
      this.error = ERRORS_MESSAGES.INVALID_IMAGE
    },
    async getServicesTypes() {
      this.isRequesting = true
      this.alertError = false
      try {
        const params = {
          includeCategories: false,
          attributes: ['id', 'description']
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = data.map(({ id, description: name }) => ({ id, name }))
      } catch (err) {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    },
    async createCompany() {
      this.isRequesting = true
      try {
        const companyData = await this.getData()
        await this.axios.post('/providers/companies', companyData)
        this.alertError = false
        this.$emit('onSuccess')
        this.resetData()
      } catch (err) {
        this.alertError = true
        this.scrollTop()
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.createCompanyFocusAlertError.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.tooltip-btn{
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 4px;
  margin-left: 4px;

  background-color: #3c4b64;
  color: #FFF;
  &:hover{
    cursor: pointer;
  }
}
</style>
