<template>
  <div class="iframe">
    <iframe :src="`${basePath}${src}${hasParams ? '&' : '?'}token=${getToken()}`" width="100%" height="100%"
      frameBorder="0" id="reactSite"></iframe>

    <ServiceDetail :registryUuid="serviceDetails.data.registryUuid" :isModalActive="serviceDetails.isActive"
      @onClose="closeModals" />
    <CreateService :isModalActive="createServcice.isActive" @onClose="closeModals" @onSuccess="closeCreateServiceModal" />
    <PreorderDetail :infoModal="preorderDetails.data.data" :whatsAppData="preorderDetails.whatsAppData" :isModalActive="preorderDetails.isActive" @onClose="closeModals" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAuthToken, getUserData } from '../../utils/auth.js'
import ServiceDetail from '../services/ServiceDetail'
import PreorderDetail from '../preorders/PreorderDetail'
import CreateService from '../services/CreateService'
import {
  PAYMENT_TYPES
} from '../../common/enums'

export default {
  name: 'ReactSiteFrame',
  components: {
    ServiceDetail,
    CreateService,
    PreorderDetail
  },
  data() {
    return {
      basePath: process.env.VUE_APP_REACT_ADMIN_SITE_URL,
      serviceDetails: {
        isActive: false,
        data: {
          registryUuid: ''
        }
      },
      createServcice: {
        isActive: false
      },
      preorderDetails: {
        isActive: false,
        whatsAppData: {},
        data: {
          registryUuid: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin', 'userPermissions', 'permissions'])
  },
  mounted() {
    const [cHeader, cMain, containerFluid, cSidebar] = [
      document.querySelector('.c-header'),
      document.querySelector('.c-main'),
      document.querySelector('.container-fluid'),
      document.querySelector('.c-sidebar')
    ]
    if (!this.keepSidebar) {
      cHeader ? cHeader.style.setProperty('display', 'none') : null
    }
    cMain ? cMain.style.setProperty('padding-top', 0, 'important') : null
    cMain ? cMain.style.setProperty('height', '100%') : null
    containerFluid ? containerFluid.style.setProperty('padding-left', 0, 'important') : null
    containerFluid ? containerFluid.style.setProperty('padding-right', 0, 'important') : null

    window.addEventListener('message', (e) => {
      if(!e.data.origin) return
      if (e.data.hide) {
        cSidebar ?
          cSidebar.classList.remove('c-sidebar-lg-show') :
          null
      } else {
        cSidebar ?
          cSidebar.classList.add('c-sidebar-lg-show') :
          null
      }

      if (e.data.redirect) {
        cSidebar ? cSidebar.classList.add('c-sidebar-lg-show') : null
        this.$router.push(e.data.redirect)
      }

      if (e.data.logout) {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }

      if (e.data.component && this[e.data.component]) {
        this[e.data.component].isActive = false
        setTimeout(() => { 
          this[e.data.component].isActive = true 
        }, 100)
        this[e.data.component].data = { ...e.data.data }
        const { data } = { ...e.data.data }
        try{
          const isCurrency = data.details.length > 0 ? data.details[0].isCurrency : false
          const unitPrice = data.details.length > 0 ? data.details[0].showcase.price.price : 1
          this[e.data.component].whatsAppData = { 
              phoneNumber: data.customerPhoneNumber || '',
              serviceDate: data.serviceDate,
              address: data.address,
              name: data.name,
              paymentType: +data.paymentTypeId === PAYMENT_TYPES.CASH ? 'Efectivo' : 'Tarjeta (TDEB | TCR)',
              discount: data.discount,
              amount: isCurrency ? data.amount / 100 :  (data.amount * unitPrice)/100,
              totalAmount: data.totalAmount,
              serviceType: data.serviceType.description
          }
        }catch{}
      }
    })

    document.getElementById('reactSite').contentWindow.postMessage({
      startup: true,
      origin: 'waddi-saas',
      data: {
        permissions: this.permission,
        userPermissions: this.userPermissions,
        isCompanyAdmin: this.isCompanyAdmin,
        userData: this.getUser(),
        token: getAuthToken()
      }
    }, '*')
  },
  beforeDestroy() {
    const [cHeader, cMain, containerFluid] = [
      document.querySelector('.c-header'),
      document.querySelector('.c-main'),
      document.querySelector('.container-fluid')
    ]
    cHeader ? cHeader.style.removeProperty('display') : null
    cMain ? cMain.style.removeProperty('padding-top') : null
    containerFluid ? containerFluid.style.removeProperty('padding-left') : null
  },
  props: {
    src: {
      type: String,
      required: true
    },
    hasParams: {
      type: Boolean,
      required: false
    },
    keepSidebar: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    getToken() {
      return getAuthToken()
    },
    getUser() {
      return getUserData()
    },
    closeModals(refresh, message) {
      this.closeServiceDetailModal(refresh)
      this.closeCreateServiceModal(refresh, message)
      this.closePreorderDetailModal(refresh)
    },
    closeServiceDetailModal(refresh) {
      this.serviceDetails.isActive = false
      document.getElementById('reactSite').contentWindow.postMessage({ refresh, origin: 'waddi-saas' }, '*')
    },
    closeCreateServiceModal(refresh, message) {
      this.createServcice.isActive = false
      document.getElementById('reactSite').contentWindow.postMessage({ refresh, message, origin: 'waddi-saas' }, '*')
    },
    closePreorderDetailModal(refresh) {
      this.preorderDetails.isActive = false
      document.getElementById('reactSite').contentWindow.postMessage({ refresh, origin: 'waddi-saas' }, '*')
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  height: 100%
}

* {
  overflow: hidden;
}
</style>
