<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show.sync="show"
  >
    <div class="c-sidebar-brand">
      <a href="/">
        <img
          src="@/assets/images/DarkWaddiLogo.svg"
          alt="okboy logo"
          width="140"
          height="41"
        >
      </a>
    </div>
    <CRenderFunction
      flat
      :content-to-render="nav"
    />
  </CSidebar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import nav from './_nav'

export default {
  name: 'TheSidebar',
  data() {
    return {
      minimize: false,
      show: 'responsive'
    }
  },
  created() {
    this.getPreordersLength();
  },
  computed: {
    ...mapState('auth', ['userType']),
    ...mapGetters('auth', ['userData']),
    nav() {
      const [{ _children, ...base }] = nav
      const sidebarModules = []
      this.userData.modules.forEach(item => {
          if (item.sections && item.sections.length > 0) {
            sidebarModules.push({
              _children: [item.displayText],
              _name: 'CSidebarNavTitle'
            })

            item.sections.forEach(section => {
              sidebarModules.push({
                _name: "CSidebarNavItem",
                name: section.displayText,
                to: section.path,
                icon: section.icon ?? "cilList",
                target: section.uri ? '_blank' : '_self',
                href: section.uri ?? ''
              })
            })
          }
      })
      sidebarModules.push({_name: "CSidebarNavTitle"})
      return [{ ...base, _children: sidebarModules }]
    }
  },
  mounted() {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  },
  methods: {
    ...mapActions([
      'getPreordersLength',
    ])
  }
}
</script>
