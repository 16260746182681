export default {
  state: {
    provider: {
      name: '',
      lastName: '',
      shortName: '',
      phoneNumber: null,
      assistantName: '',
      plateNumber: '',
      schedule: '',
      zones: ''
    },
    terminalEditable: false,
    statusEditable: 0
  }
}
