import { store } from '../store/store'

export default async function ({ next }) {
  await store.dispatch('auth/status')
  const { isAuthenticated } = store.state.auth
  if (isAuthenticated) {
    return next()
  }
  return next({ path: '/login' })
}
