<template>
  <CModal :closeOnBackdrop="false" :show.sync="isModalActive">
    <template v-slot:header>
      <h5 class="modal-title">Crear tipo de servicio</h5>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>

    <div tabindex="0" ref="editServiceTypeFocusAlertError">
      <CAlert
        color="danger"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>

    <form id="editServiceType" ref="form" v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="">Nombre*</label>
        <input
          class="form-control"
          required
          type="text"
          placeholder="Nombre"
          minlength="3"
          maxlength="100"
          v-model="serviceType.description"
          v-c-tooltip="'Corresponde al nombre del tipo de servicio'"
        />
      </div>
      <div class="form-group">
        <label for="">Monto mínimo de compra para escuento vía cartera de referido*</label>
        <DecimalInput
          class="form-control"
          :required="true"
          :min="0"
          :max="9999999"
          placeholder="Monto mínimo de compra para escuento vía cartera de referido"
          :value="serviceType.referralWalletLowerLimit"
          @input="val => serviceType.referralWalletLowerLimit = val"
          v-c-tooltip="'Corresponde al monto mínimo que se requiere comprar para poder aplicar descuento vía cartera de referido'"
        />
      </div>
      <div class="form-group">
        <label for="">Monto máximo de descuento vía cartera de referido*</label>
        <DecimalInput
          class="form-control"
          :required="true"
          :min="0"
          :max="9999999"
          placeholder="Monto máximo de descuento vía cartera de referido"
          :value="serviceType.referralWalletUpperLimit"
          @input="val => serviceType.referralWalletUpperLimit = val"
          v-c-tooltip="'Corresponde al monto máximo de descuento vía cartera de referido'"
        />
      </div>
      <div class="form-group">
        <label for="">Icono</label>
        <img v-if="logoUrl" class="img-thumbnail my-1 mx-auto d-block" :src="`${logoUrl}?d=${Date.now()}`">
        <div class="m-1 p-1">
          <ImageInput
            :height="60"
            :isRequired="false"
            :width="60"
            @format="onInvalidFormat"
            @input="onImageInput"
            ref="imageInput"
            v-c-tooltip="'Corresponde al ícono del servicio'"
          >
          </ImageInput>
          <small class="form-text text-muted">Dimensiones 60x60 px</small>
        </div>
      </div>
    </form>

    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm" />
      <div v-else>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">
          Cancelar
        </button>
        <button
          type="submit"
          form="editServiceType"
          class="btn btn-primary ml-1"
          v-c-tooltip="
            'Registra el tipo de servicio con los datos ingresados en el formulario'
          "
        >
          Aceptar
        </button>
      </div>
    </template>
  </CModal>
</template>

<script>
import ImageInput from '../ImageInput'
import DecimalInput from '../DecimalInput'
import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'
import { fileToBase64 } from '../../utils'
import 'vue-select/dist/vue-select.css'

// TODO: crear vista para construir categorias
const dummyCategory = [
  {
    description: 'Garrafones',
    name: 'Garrafones',
    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAB',
    products: [
      {
        units: 1,
        unitPrice: 300,
        total: 30000,
        recommended: false
      },
      {
        units: 2,
        unitPrice: 300,
        total: 60000,
        recommended: false
      },
      {
        units: 3,
        unitPrice: 300,
        total: 90000,
        recommended: false
      },
      {
        units: 4,
        unitPrice: 300,
        total: 120000,
        recommended: true
      },
      {
        units: 5,
        unitPrice: 300,
        total: 150000,
        recommended: false
      },
      {
        units: 6,
        unitPrice: 300,
        total: 180000,
        recommended: false
      }
    ],
    isQuantity: false,
    minPurchase: 20000,
    unitPrice: 300
  }
]

const initialState = () => ({
  isRequesting: false,
  serviceType: {
    automaticAssignment: false,
    description: '',
    icon: '',
    isActive: false,
    referralWalletLowerLimit: '500',
    referralWalletUpperLimit: '100'
  },
  categories: dummyCategory,
  icon: null,
  logoUrl: null,
  error: {},
  isErrorResponse: false
})

export default {
  name: 'EditServiceTypeModal',
  components: {
    DecimalInput,
    ImageInput
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    registryUuid: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return initialState()
  },
  watch: {
    registryUuid(value) {
      if (value) {
        this.fetchServiceType()
      } else {
        this.resetData()
      }
    },
    isModalActive(value) {
      if (value) {
        this.$refs.imageInput.reset()
        this.resetForm()
      }
    }
  },
  methods: {
    onImageInput(file) {
      if (file) {
        this.error = {}
        this.alertError = false
      }
      this.icon = file
      this.logoUrl = ''
    },
    resetData() {
      this.serviceType = {
        description: ''
      }
      this.error = {}
      this.icon = null
      this.$refs.imageInput.reset()
    },
    async getData() {
      const { icon, categories } = this
      const data = this.serviceType
      data.referralWalletLowerLimit *= 100
      data.referralWalletLowerLimit += ''
      data.referralWalletUpperLimit *= 100
      data.referralWalletUpperLimit += ''
      if (icon) {
        data.icon = await fileToBase64(icon)
      }

      data.categories = categories
      return data
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    onCancel() {
      this.$emit('onClose')
    },
    async onSubmit() {
      this.isRequesting = true
      const data = await this.getData()
      this.axios.put(`/services/types/${this.registryUuid}`, data)
        .then(() => {
          this.$emit('onSuccess')
        })
        .catch(() => {
          this.isRequesting = false
          this.isErrorResponse = true
          this.error = ERRORS_MESSAGES.INVALID_DATA
        })
    },
    onInvalidFormat() {
      this.alertError = true
      this.scrollTop()
      this.error = ERRORS_MESSAGES.INVALID_IMAGE
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.editCompanyFocusAlertError.focus()
      })
    },
    async fetchServiceType() {
      this.isRequesting = true

      try {
        const params = {
          includeCategories: true
        }
        const { data } = await this.axios.get(`/services/types/${this.registryUuid}`, { params })
        this.setServiceType(data)
        this.isRequesting = false
      } catch (err) {
        this.error = getErrorMessage(err)
        this.$emit('onErrorInitial', err)
      } finally {
        this.isRequesting = false
        setTimeout(() => {
          if (this.$refs.geoEditor) {
            this.$refs.geoEditor.clearMap()
            this.$refs.geoEditor.invalidateSize()
          }
        }, 1000)
      }
    },
    setServiceType(data) {
      this.serviceType.description = data.description
      data.referralWalletLowerLimit /= 100
      data.referralWalletLowerLimit += ''
      data.referralWalletUpperLimit /= 100
      data.referralWalletUpperLimit += ''
      this.serviceType.referralWalletLowerLimit = data.referralWalletLowerLimit
      this.serviceType.referralWalletUpperLimit = data.referralWalletUpperLimit
      this.logoUrl = data.icon
    }
  }
}
</script>

<style lang="scss" scoped>
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.tooltip-btn {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 4px;
  margin-left: 4px;

  background-color: #3c4b64;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
}
</style>
