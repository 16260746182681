<template>
  <div class="container bg-white rounded p-4">
    <p class="title text-center">Registro de usuarios</p>
    <div class="d-flex justify-content-center mb-2">
      <select
        name="type"
        class="select mr-1"
        @change="loadChart"
        :disabled="!isLoading"
        v-model="options.type">
        <option value="monthly">Mensual</option>
        <option value="weekly">Semanal</option>
      </select>
      <select
        v-if="options.type === 'weekly'"
        name="type"
        class="select mx-1"
        @change="loadChart"
        :disabled="!isLoading"
        v-model="options.week">
        <option value="">
          Seleccione una semana
        </option>
        <option
          v-for="option in selects.weeks"
          :value="option.value"
          :key="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <select
        name="year"
        class="select ml-1"
        @change="loadChart"
        :disabled="!isLoading"
        v-model="options.year">
        <option :value="2020">2020</option>
        <option :value="2021" disabled>2021</option>
      </select>
    </div>
    <canvas id="customer-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import moment from 'moment'
import queryparser from '../utils/queryparser'
import { monthlyLabels, months, weeklyDayNumbersLabels } from '../utils/charts'
import { getSelectOptions, getWeekLabels } from '../utils/date'

export default {
  data: () => {
    const year = new Date().getFullYear()
    const isoWeekNumber = moment().set({ year }).isoWeek()

    return {
      year,
      week: isoWeekNumber,
      options: {
        type: 'monthly',
        year,
        week: ''
      },
      selects: {
        weeks: [],
        years: []
      },
      data: {},
      chart: null,
      isLoading: false
    }
  },
  mounted() {
    this.setSelectsOptions()
    this.loadChart()
  },
  computed: {
    isWeekly() {
      return this.options.type === 'weekly'
    }
  },
  watch: {
    'options.year': function () {
      this.setSelectsOptions()
    },
    'options.type': function () {
      if (this.isWeekly && this.options.year === null) {
        this.options.year = this.year
      }
    }
  },
  methods: {
    setSelectsOptions() {
      const { years, weeks } = getSelectOptions(this.options.year, { baseYear: this.year })
      this.selects.years = years
      this.selects.weeks = weeks
    },
    weeklyMapFormat(weeks = []) {
      const weeksObject = {}
      weeks.forEach((week) => {
        weeksObject[week.text] = week.value
      })
      return weeksObject
    },
    async loadChart() {
      this.isLoading = false
      const isWeekly = this.options.type === 'weekly'
      const options = {
        ...this.options,
        week: isWeekly ? this.options.week : undefined
      }
      const params = queryparser.parse(options)

      try {
        const date = moment().set({ year: this.options.year })
        const { data } = await this.axios.get(`customers/report/chart?${params}`)
        if (data.dates.length > 0) {
          const { dates, type, ...dataset } = data

          const isWeeklyWithWeek = isWeekly && this.options.week
          const weeksLabels = getWeekLabels(dates, { keys: true })
          const weeksMap = weeksLabels
            .reduce((weeks, { key, label }) => ({ ...weeks, [label]: key }), {})

          // eslint-disable-next-line no-nested-ternary
          const labels = !isWeekly
            ? monthlyLabels(dates)
            : isWeeklyWithWeek
              ? weeklyDayNumbersLabels(date, this.options.week)
              : weeksLabels.map(({ label }) => label)

          if (this.chart) {
            this.chart.destroy()
          }

          document.getElementById('customer-chart').innerHTML = '&nbsp;'
          document.getElementById('customer-chart').innerHTML = '<canvas id="canvas"></canvas>'
          const ctx = document.getElementById('customer-chart').getContext('2d')

          const monthDatasetMapper = (dKey) => Object.entries(dataset)
            .map(([key, { [dKey]: y }]) => ({ x: `${months[+key - 1]}`, y }))
          const weekDaysDatasetMapper = (dKey) => labels
            .map((key) => (dataset[key]
              ? { x: `${key}`, y: dataset[key][dKey] }
              : { x: `${key}`, y: 0 }))
          const weekDatasetMapper = (dKey) => labels
            .map((key) => (dataset[weeksMap[key]]
              ? { x: `${weeksMap[key]}`, y: dataset[weeksMap[key]][dKey] }
              : { x: `${weeksMap[key]}`, y: 0 }))

          const chart = new Chart(ctx, {
            type: 'line',
            data: {
              labels,
              datasets: [
                {
                  label: 'Nuevos clientes registrados',
                  borderColor: '#2eb85c',
                  // eslint-disable-next-line no-nested-ternary
                  data: !isWeekly
                    ? monthDatasetMapper('total')
                    : isWeeklyWithWeek
                      ? weekDaysDatasetMapper('total')
                      : weekDatasetMapper('total'),
                  lineTension: 0,
                  fill: false
                }
              ]
            },
            options: {}
          })

          this.chart = chart
          this.data = data
          this.isLoading = true
        } else {
          const ctx = document.getElementById('customer-chart').getContext('2d')
          const chart = new Chart(ctx, {
            type: 'line',
            data: {
              labels: [],
              datasets: [
                {
                  label: 'Nuevos clientes registrados',
                  borderColor: '#2eb85c',
                  data: [],
                  lineTension: 0,
                  fill: false
                }
              ]
            },
            options: {}
          })
          this.chart = chart
          this.data = []
          this.isLoading = true
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
