<template>
  <ReactSiteFrame :src="src" :hasParams="true" />
</template>
<script>
import ReactSiteFrame from '../components/react/iframe'

export default {
  name: 'CustomersReact',
  components: {
    ReactSiteFrame
  },
  mounted() {
    if(this.$route.params.hideSidebar) {
      const cSidebar = document.querySelector('.c-sidebar')
      cSidebar ? cSidebar.classList.remove('c-sidebar-lg-show') : null
    }
  },
  data() {
    return {
      src: `/view/customers?registryUuid=${this.$route.params.registryUuid}&phoneNumber=${this.$route.params.phoneNumber}`
    }
  }
}
</script>
