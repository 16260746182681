<template>
  <div class="mic-permission">
    <div class="info">
      <div class="icon"></div>
      <div class="text">Favor de permitirnos el acceso a tu micrófono</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicPermission'
}
</script>

<style>
  .text{
    color: red;
  }
  .icon {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/voximplant/ic24-info-fill.svg');
  }
</style>
