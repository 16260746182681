<template>
  <div style="height: 700px; width: 700px">
    <l-map
      ref="map"
      :zoom="map.zoom"
      :center="map.center"
      :options="map.options"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      style="height: 100%; width: 100%; border: solid;"
    >
      <v-tilelayer-googlemutant
        ref="layer"
        apikey=""
        lang="es_mx"
        region="mx" />
      <!--l-tile-layer ref="layer" :url="map.url" /-->
    </l-map>
    <!--pre>{{ ldata }}</!--pre-->
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import { latLng, Icon } from 'leaflet'
import {
  LMap
} from 'vue2-leaflet'
import '@geoman-io/leaflet-geoman-free'
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant'

/* eslint-disable global-require */
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  components: {
    LMap,
    // LTileLayer,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    geojson: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  watch: {
    geojson(geojson) {
      if (!geojson) {
        return
      }

      if (window.L) {
        this.setGeoJSON(geojson)
        return
      }

      this.$$nextTick(() => this.setGeoJSON(geojson))
    },
    ldata(value) {
      this.$emit('change', value)
    }
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_GOOGLE_API_KEY
    }
  },
  data() {
    return {
      map: {
        // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 13,
        currentZoom: 11.5,
        center: latLng(21.923, -102.211),
        currentCenter: latLng(21.923, -102.211),
        options: {
          zoomSnap: 0.5
        }
      },
      isLoaded: false,
      lmap: {},
      layer: {},
      ldata: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.lmap = this.$refs.map.mapObject
      this.layer = this.$refs.layer.mapObject
      this.initPlugins()

      if (this.geojson) {
        this.setGeoJSON({ ...this.geojson })
      }
      this.isLoaded = true
    })
  },
  methods: {
    initPlugins() {
      this.lmap.pm.addControls({
        position: 'topleft'
      })
      this.lmap.pm.enableGlobalEditMode({
        preventMarkerRemoval: false
      })
      this.lmap.on('pm:create', () => {
        this.ldata = this.toGeoJSON()
      })
    },
    setGeoJSON(geojson) {
      this.clearMap()
      const c = JSON.parse(JSON.stringify(geojson))
      if (this.isLoaded) {
        window.L.geoJSON(c).addTo(this.lmap)
      } else {
        this.$nextTick(() => {
          window.L.geoJSON(c).addTo(this.lmap)
        })
      }
    },
    toGeoJSON() {
      const layers = window.L.featureGroup()
      this.lmap.eachLayer((layer) => {
        if (layer instanceof window.L.Path) {
          layers.addLayer(layer)
        }
      })
      return layers.toGeoJSON()
    },
    clearMap() {
      this.lmap.eachLayer((layer) => {
        if (layer instanceof window.L.Path) {
          layer.remove()
        }
      })
    },
    invalidateSize() {
      this.lmap.invalidateSize()
    },
    mergeGeoJSON(data) {
      return {
        type: 'FeatureCollection',
        features: [data.map(({ features }) => features)]
      }
    },
    // Map layour related
    zoomUpdate(zoom) {
      this.map.currentZoom = zoom
    },
    centerUpdate(center) {
      this.map.currentCenter = center
    }
  }
}
</script>

<style>
.horizontal-tabs .horizontal-tab-hidden {
    display: block;
    visibility: hidden;
    height: 0px;
}
.horizontal-tabs .horizontal-tabs-active-tab {
    width: 100% !important;
    height: auto !important;
    display: block;
    visibility: visible;
}
</style>
