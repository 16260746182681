<template>
  <div class="redial-call">
    <div class="text">Presionar el boton para llamar</div>
    <div class="call-button" @click="redial" tabindex="0">
      <svg class="call-key" width="44" height="44">
        <use :href="'/icons.svg#call-button'"></use>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RedialCall',
  methods: {
    redial() {
      this.$parent.createCall()
    }
  }
}
</script>

<style scoped>
.redial-call {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #555770;
}
.call-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 24px;
  cursor: pointer;
}
.call-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--sui-blue-500);
}
.call-key {
  --call-color: #5ad677;
}
.call-key:hover {
  --call-color: #2fbc4f;
}
</style>
