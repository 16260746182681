<template>
    <ReactSiteFrame :src="src" />
  </template>
  <script>
  import ReactSiteFrame from '../components/react/iframe'
  
  export default {
    name: 'Contact',
    components: {
      ReactSiteFrame
    },
    data() {
      return {
        src: '/view/contact'
      }
    }
  }
  </script>