export function fileToBase64(file) {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = (err) => reject(err)
    reader.readAsDataURL(file)
  })
}

export function getErrorCodeAPI(error) {
  const { data } = error.response || {}
  if (typeof data === 'object' && !Array.isArray(data)) {
    return data.response ? data.response.codeApi : data.codeApi
  }
  return null
}

export async function fileToImage(file) {
  const image = new Image()
  const base64 = await fileToBase64(file)
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image)
    image.onerror = (err) => reject(err)
    image.src = base64
  })
}

export function dataURLtoFile(dataURL, filename = 'image') {
  const arr = dataURL.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  const s = bstr.length
  const u8arr = new Uint8Array(s)

  for (let i = s; i >= 0; i -= 1) {
    u8arr[i] = bstr.charCodeAt(i)
  }

  return new File([u8arr], filename, { type: mime })
}

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    window.clipboardData.setData('Text', text)
    return true
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')

    textarea.textContent = text
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)

    textarea.select()

    try {
      return document.execCommand('copy')
    } catch (ex) {
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }

  return false
}

export function downloadFile(text, fileName = 'download') {
  const element = document.createElement('a')
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
  element.setAttribute('download', fileName)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
