<template>
  <keep-alive include="ConnectReact">
    <router-view />
  </keep-alive>
</template>

<script>
import { mapState } from 'vuex'
import sha256 from 'js-sha256'


export default {
  name: 'App',
  data: () => ({
    userId: '',
    name: '',
    email: ''
  }),
  computed: {
    ...mapState('auth', ['userData', 'isAuthenticated'])
  },
  watch: {
    isAuthenticated(isAuthenticated) {
      const { user } = this.userData
      if (isAuthenticated) {
        const userHash = sha256.hmac(process.env.VUE_APP_X_SECRET_KEY, user?.userUuid)
        this.userId = user?.userUuid
        this.name = user?.name
        this.email = user?.email
        this.$intercom.boot({
          user_id: this.userId,
          name: this.name,
          email: this.email,
          user_hash: userHash
        })
        this.$intercom.show()
      }
    },
    email(email) {
      this.$intercom.update({ email })
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/style';
button:focus {
  outline: 0;
}

/* Fuentes Waddi */

@font-face {
    font-family: 'Mulish';
    src: url("./assets/fonts/Mulish-Regular.ttf") format('truetype'),
    url("./assets/fonts/Mulish-MediumItalic.ttf") format('truetype'),
    url("./assets/fonts/Mulish-Medium.ttf") format('truetype'),
    url("./assets/fonts/Mulish-Italic.ttf") format('truetype'),
    url("./assets/fonts/Mulish-ExtraBoldItalic.ttf") format('truetype'),
    url("./assets/fonts/Mulish-ExtraBold.ttf") format('truetype'),
    url("./assets/fonts/Mulish-BlackItalic.ttf") format('truetype'),
    url("./assets/fonts/Mulish-Black.ttf") format('truetype');
}

body {
  font-family: 'Mulish'
}

</style>
