<template>
  <div>
    <CAlert
      color="danger"
      :closeButton="true"
      :show.sync="hasError">
      {{error.message}}
    </CAlert>
    <div class="services-order-performance">
      <div class="card">
        <div class="card-header">
          <div class="d-md-flex">
            <label for="">KPI por</label>
            <div class="my-auto d-md-flex tabs-selector">
              <button
                @click="changeType('monthly')"
                :class="options.type === 'monthly' ? 'active' : ''">
                Mes
              </button>
              <button
                @click="changeType('weekly')"
                :class="options.type === 'weekly' ? 'active' : ''">
                Semana
              </button>
            </div>

            <div class="my-auto mx-md-3 d-flex">
              <label>
                {{options.type === 'monthly' ? 'Mes' : 'Semana' }}
              </label>
              <select
                class="form-control"
                @change="getServiceOrdersReport"
                v-model="options.value">
                <option v-for="option in values" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>

            <div class="my-auto d-flex">
              <label>Año</label>
              <select
                class="form-control"
                @change="setSelectOptions"
                v-model="options.year"
              >
                <option v-for="option in selectOptions.years" :key="option" :value="option">
                  {{option}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div class="cards-wrapper">
              <div class="card finished-services">
                <p>Finalizados</p>
                <span class="text-success">{{performance.finished}}</span>
              </div>
              <div class="card big-card">
                <div class="card-header content-center">
                  <h3>Servicios que no se atendieron a tiempo</h3>
                </div>
                <div
                  v-if="performance.notAttendedOnTime"
                  class="card-body row text-center"
                  :class="+performance.notAttendedOnTime.percentage <= 20 ? 'text-success' : 'text-danger'"
                >
                  <div class="col">
                    <span class="text-value-xl">{{performance.notAttendedOnTime.total}}</span>
                  </div>
                  <div class="c-vr"></div>
                  <div class="col">
                    <span class="text-value-xl">{{performance.notAttendedOnTime.percentage}}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-wrapper">
              <div class="card canceled-services">
                <p>Cancelados</p>
                <span class="text-danger">{{performance.cancelled}}</span>
              </div>
              <div class="card big-card">
                <div class="card-header content-center">
                  <h3>Servicios que se atendieron a tiempo</h3>
                </div>
                <div
                  v-if="performance.attendedOnTime"
                  class="card-body row text-center"
                  :class="+performance.attendedOnTime.percentage >= 80 ? 'text-success' : 'text-danger'"
                >
                  <div class="col">
                    <span class="text-value-xl">{{performance.attendedOnTime.total}}</span>
                  </div>
                  <div class="c-vr"></div>
                  <div class="col">
                    <span class="text-value-xl">{{performance.attendedOnTime.percentage}}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="card big-card">
              <div class="card-header content-center">
                <h3>Crecimiento de órdenes de servicio</h3>
              </div>
              <div
                v-if="performance.increase"
                class="card-body row text-center"
                :class="performance.increase.total > 0 ? 'text-success' : 'text-danger'"
              >
                <div class="col">
                  <span class="text-value-xl" >
                    {{performance.increase.total}}
                  </span>
                </div>
                <div class="c-vr"></div>
                <div class="col">
                  <span class="text-value-xl">
                    {{performance.increase.percentage}}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment'
import { mapGetters } from 'vuex'

import 'moment-timezone'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { getErrorMessage } from '../common/errors'
import queryparser from '../utils/queryparser'
import { getSelectOptions } from '../utils/date'

export default {
  name: 'Services',
  data: () => ({
    error: {},
    performance: {},
    year: moment().year(),
    options: {
      type: 'monthly',
      param: 'month',
      year: moment().year(),
      value: moment().month() + 1
    },
    selectOptions: {
      monthly: [],
      weekly: [],
      years: []
    }
  }),
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin']),
    values() {
      const { type } = this.options
      const isWeekly = type === 'weekly'
      return isWeekly ? this.selectOptions.weekly : this.selectOptions.monthly
    },
    hasError() {
      return this.error && Object.keys(this.error).length
    }
  },
  watch: {
    'options.type': function () {
      this.getServiceOrdersReport()
    },
    'options.year': function () {
      this.getServiceOrdersReport()
    }
  },
  mounted() {
    this.getServiceOrdersReport()
    this.setSelectOptions()
  },
  methods: {
    setSelectOptions() {
      const { weeks, months, years } = getSelectOptions(this.options.year, { baseYear: this.year })
      this.selectOptions.weekly = weeks
      this.selectOptions.monthly = months
      this.selectOptions.years = years
      this.changeType(this.options.type)
    },
    changeType(type) {
      const isWeekly = type === 'weekly'
      const date = moment().set({ year: this.options.year })
      this.options.type = isWeekly ? 'weekly' : 'monthly'
      this.options.param = isWeekly ? 'week' : 'month'

      if (moment().year() === this.options.year) {
        this.options.value = isWeekly ? date.isoWeek() : date.month() + 1
      }
    },
    async getServiceOrdersReport() {
      try {
        const {
          type, param, value, year
        } = this.options
        const query = queryparser.parse({ type, [param]: value, year })
        const { data } = await this.axios.get(`services/report/performance?${query}`)
        this.performance = data
      } catch (err) {
        this.err = getErrorMessage(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.services-order-performance{
  > .card{
    > .card-header{
      display: flex;
      .tabs-selector{
        border: 1px solid #ccc;
        display: flex;
        height: 35px;
        border-radius: 4px;
        button{
          border: none;
          background-color: #fff;
          outline: none;
          color: #000015;
          min-width: 80px;
          border-right: 1px solid #ccc;
          border-radius: 4px;
          &.active{
            background-color: #5B2AE0;
            color: white;
          }
          &:last-child{
            border-right: 0;
          }
        }
      }
      >div{
        label{
          margin: auto 0;
        }
        select{
          margin: auto 0 auto 8px;
        }
        &:last-child{
          margin-left: 16px;
        }
      }
    }
    > .card-body{
      display: flex;
      flex-direction: column;
      @media(min-width: 768px){
        flex-direction: row;
      }
      >div{
        display: flex;
        .card{
          margin: 4px;
          padding: 8px;
          text-align: center;
          span{
            font-size: 24px;
            font-weight: bold;
            margin: auto;
          }
          &.finished-services,
          &.canceled-services{
            width: 110px;
          }
        }
        .big-card{
          .card-body{
            .col{
              display: flex;
              span{
                margin: auto;
              }
            }
          }
        }
        &:first-child{
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          @media(min-width: 768px){
            margin: 0 0 0 auto;
          }
          .cards-wrapper{
            display: flex;
          }
          .card{
            p{
              font-size: 15px;
              font-weight: bold;
            }
          }
          .big-card{
            width: calc(100% - 110px);
            @media(min-width: 1200px){
              max-width: 300px;
            }
          }
        }
        &:last-child{
          margin: 0 auto;
          @media(min-width: 768px){
            margin: 0 auto 0 0;
          }
        }
      }
    }
  }
}
</style>
