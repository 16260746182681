// TODO: Move Auth logic here
import axios from 'axios'

import {
  getAuthToken, clearAuth, setAuth, getAuth
} from '../../utils/auth'
import { createInstance, createServerlessInstance } from '../../utils/axios'

import { USER_TYPES } from '../../common/enums'

const instance = createInstance(axios)
const instanceServerless = createServerlessInstance(axios)

const initialState = () => {
  const { token, isCompanyAdmin, userPermissions, permissions, userData, userHasCompany, defaultRoute } = getAuth()

  return {
    token,
    userPermissions,
    userHasCompany,
    userType: isCompanyAdmin
      ? USER_TYPES.COMPANY_ADMIN
      : USER_TYPES.GENERAL_ADMIN,
    isAuthenticated: false,
    permissions: permissions,
    userData,
    defaultRoute
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    login(state, { token, userHasCompany,
      isCompanyAdmin = false, userPermissions, permissions, userData, defaultRoute }) {
      setAuth({
        token, userHasCompany,
        isCompanyAdmin, userPermissions, permissions, userData, defaultRoute
      })
      state.defaultRoute = defaultRoute
      state.token = token
      state.userType = isCompanyAdmin
        ? USER_TYPES.COMPANY_ADMIN
        : USER_TYPES.GENERAL_ADMIN
      state.isAuthenticated = true
      state.userPermissions = userPermissions
      state.permissions = permissions
      state.userData = userData
    },
    authenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
    logout: (state) => {
      clearAuth()
      Object.assign(state, initialState())
    }
  },
  actions: {
    async login({ commit }, credentials) {
      const { data } = await this._vm.axios.post('/login', credentials)
      const { data: userData } = await instanceServerless.get(`/administrator/users?username=${data.registryUuid}&accountCode=${credentials.accountCode}`,
        {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        })
      userData.config = data.config || {}
      commit('login', {
        ...data,
        userData,
        token: data.token,
        permissions: data.permissions || [],
        isCompanyAdmin: false,
        userHasCompany: data.isCompanyAdmin || false,
        defaultRoute: userData.defaultRoute || '/services'
      })
    },
    async status({ commit }) {
      const token = getAuthToken()

      try {
        await instance.get('/login/status', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        commit('authenticated', true)
      } catch (err) {
        commit('authenticated', false)
        commit('logout')
      }
    },
    logout({ commit }) {
      commit('logout')
    }
  },
  getters: {
    isGeneralAdmin(state) {
      return state.userType === USER_TYPES.GENERAL_ADMIN
    },
    isCompanyAdmin(state) {
      return state.userType === USER_TYPES.COMPANY_ADMIN
    },
    userHasCompany(state) {
      return state.userHasCompany
    },
    userPermissions(state) {
      return state.userPermissions
    },
    permissions(state) {
      return state.permissions
    },
    userData(state) {
      return state.userData
    },
    defaultRoute(state) {
      return state.defaultRoute
    }
  }
}
