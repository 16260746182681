<template>
  <div v-if="hasVoximplant">
    <MicPermission v-if="!isMicAccessGranted"></MicPermission>
    <div class="call" v-else-if="isMicAccessGranted">
      <div class="call-state">
        <Settings v-if="showSettings" @update:closeSettings="showSettings=false" :call="call"></Settings>
        <Connection v-if="callStateSelected===CallState.CONNECTING"></Connection>
        <RedialCall v-if="callStateSelected===CallState.DISCONNECTED || callStateSelected===CallState.NONE" @click="createCall"></RedialCall>
        <div class="controls" v-if="callStateSelected===CallState.CONNECTED">
          <div class="d-block">
            <div class="timer">{{currentTime ? currentTime: '00:00'}}</div>
          </div>
          <div class="d-block">
            <Decline @click="disconnect"></Decline>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as VoxImplant from 'voximplant-websdk'
import Connection from './connection'
import RedialCall from './redialCall'
import Decline from './decline'
import MicPermission from './micPermission'

const { VUE_APP_VOXIMPLANT_USER, VUE_APP_VOXIMPLANT_PASSWORD } = process.env

export default {
  name: 'Voximplant',
  data() {
    return {
      callStateSelected: null,
      CallState: {
        NONE: null,
        CONNECTING: 'Connecting',
        CONNECTED: 'Connected',
        DISCONNECTED: 'Disconnected',
        FAILED: 'failed'
      },
      showSettings: false,
      interval: null,
      sdkConected: null,
      sdk: null,
      currentCall: null,
      timerData: 0,
      isMicAccessGranted: true,
      hasVoximplant: false
    }
  },
  props: {
    number: {
      type: String,
      required: true
    }
  },
  components: {
    Connection,
    RedialCall,
    Decline,
    MicPermission
  },
  computed: {
    currentTime() {
      return this.timerData
    }
  },
  methods: {
    async createCall() {
      this.sdk.on(VoxImplant.Events.MicAccessResult, (e) => {
        if (e.result !== true) {
          this.isMicAccessGranted = false
        }
      })
      this.callStateSelected = this.CallState.NONE
      this.sdk.login(VUE_APP_VOXIMPLANT_USER, VUE_APP_VOXIMPLANT_PASSWORD).then(() => {
        try {
          this.currentCall = this.sdk.call(`+52${this.number}`)
          this.callStateSelected = this.CallState.CONNECTING
          this.currentCall.on(VoxImplant.CallEvents.Connected, () => {
            this.callStateSelected = this.CallState.CONNECTED
            this.startTimer()
          })
          this.currentCall.on(VoxImplant.CallEvents.Disconnected, () => {
            this.callStateSelected = this.CallState.DISCONNECTED
          })
          this.currentCall.on(VoxImplant.CallEvents.Failed, () => {
            this.callStateSelected = this.CallState.FAILED
          })
        } catch (error) {
          console.error(`Error trying to call Voximplant ${error}`)
        }
      })
    },
    disconnect() {
      try {
        this.callStateSelected = this.CallState.NONE // 0
        this.currentCall.hangup()
      } catch (error) {
        console.log(error)
      }
    },
    stopTimer() {
      clearInterval(this.interval)
      this.timerData = '00:00'
    },
    async startTimer() {
      let time = 0
      const second = 1
      this.interval = setInterval(() => {
        time += second
        const minutes = Math.floor((time % 3600) / 60)
        const seconds = time % 60
        this.timerData = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
      }, 1000)
    },
    async init() {
      this.sdk = VoxImplant.getInstance()
      if (this.sdk.getClientState() !== 'CONNECTED') {
        await this.sdk.init({
          micRequired: true,
          showDebugInfo: false,
          progressTone: true,
          progressToneCountry: 'US'
        })
        await this.sdk.connect(false)
      }
    }
  },
  watch: {
    callStateSelected() {
      if (this.callStateSelected === this.CallState.DISCONNECTED) {
        this.stopTimer()
      }
    }
  },
  async created() {
    if (VUE_APP_VOXIMPLANT_USER &&  VUE_APP_VOXIMPLANT_PASSWORD) {
      this.hasVoximplant = true
      await this.init()
    }
  }
}
</script>

<style scoped>
  .call {
    background: #dadada;
  }
  .settings-panel {
    padding: 5px;
    display: flex;
    position: relative;
    overflow: hidden;
    & >>> .sui-button {
      height: 64px;
      border-radius: 0;
    }
    & >>> .sui-icon {
      --sui-icon-color: #ffffff !important;
    }
  }
  .vector {
    position: absolute;
    width: 0;
    height: 64px;
    left: 175px;
    top: 0;
    border-right: 1px solid #8b55ff;
  }
  .call-state {
    position: relative;
    box-sizing: border-box;
    padding: 12px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .controls {
    margin-top: 24px;
    box-sizing: border-box;
    & .hint-container {
      width: 44px;
    }
    & >>> .sui-tooltip {
      padding: 2px 0;
      width: max-content;
      width: -moz-max-content;
      white-space: nowrap;
      min-width: 40px;
      min-height: 20px;
    }
    & >>> .sui-tooltip-message {
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
    }
  }
  .vector-horizontal {
    position: relative;
    width: 318px;
    height: 0;
    border-top: 1px solid #ebedf2;
  }

</style>