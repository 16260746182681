<template>
  <div>
    <label v-if="autocomplete && !readonly">
      AutoComplete
      <GmapAutocomplete @place_changed="setSelectedMark"></GmapAutocomplete>
      <button @click="useMark">Add</button>
    </label>
    <GmapMap
      ref="map"
      style="height: 300px;"
      :zoom="zoom"
      :center="center"
      @click="onMarkSelect">
      <GmapMarker
        v-if="this.mark"
        label="★"
        :clickable="!readonly"
        :draggable="!readonly"
        :position="mark"
        @dragend="onMarkSelect"
      />
    </GmapMap>
  </div>
</template>

<script>

export default {
  props: {
    autocomplete: {
      type: Boolean,
      required: false,
      default: () => false
    },
    defaultZoom: {
      type: Number,
      required: false,
      default: () => 12
    },
    defaultCenter: {
      type: Object,
      required: false,
      default: () => ({
        lat: 19.4122779,
        lng: -99.1736349
      })
    },
    defaultMark: {
      type: Object,
      required: false,
      default: () => ({
        lat: 19.4122779,
        lng: -99.1736349
      })
    }, 
    readonly : {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      geocoder: null,
      center: {},
      mark: {},
      zoom: 12,
      // Autocomplete mark
      selectedMark: null
    }
  },
  created() {
    this.setMark()
    this.setCenter()
    this.setZoom()
  },
  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      /* global google */
      /* eslint no-undef: "error" */
      this.geocoder = new google.maps.Geocoder()
    })
  },
  watch: {
    mark(mark) {
      this.center = mark
      this.$emit('markChange', mark)
    },
    defaultMark(mark) {
      this.setMark(mark)
    },
    defaultCenter(mark) {
      this.setMark(mark)
    },
    defaultZoom(zoom) {
      this.setZoom(zoom)
    }
  },
  methods: {
    setCenter(center) {
      this.center = { ...center || this.defaultCenter }
    },
    setMark(mark) {
      this.mark = { ...mark || this.defaultMark }
    },
    setZoom(zoom) {
      this.zoom = zoom || this.defaultZoom
    },
    setSelectedMark(mark) {
      this.selectedMark = mark
    },
    async setMarkFromAddress(address) {
      const result = await this.geocoding(address)
      if (!result) return this.$emit('error')
      this.setMark(result.mark)
      return result
    },
    async getAdressFromPoint(point) {
      const result = await this.reverseGeocode(point)
      if (!result) return this.$emit('error')
      return result
    },
    // Autocomplete
    onMarkSelect(mark) {
      const { latLng } = mark
      this.mark = {
        lat: latLng.lat(),
        lng: latLng.lng()

      }
    },
    useMark() {
      const { selectedMark } = this
      if (selectedMark) {
        this.mark = this.getMarkFromGeometry(selectedMark)
      }
    },
    getMarkFromGeometry(geometryMark) {
      return {
        lat: geometryMark.geometry.location.lat(),
        lng: geometryMark.geometry.location.lng()
      }
    },
    geocoding(address) {
      const { geocoder } = this

      return new Promise((resolve) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status !== 'OK') {
            return resolve(null)
          }
          const geometry = results[0]
          const mark = this.getMarkFromGeometry(geometry)
          return resolve({ mark, geometry })
        })
      })
    },
    reverseGeocode(latlng) {
      const { geocoder } = this
      return new Promise((resolve) => {
        if (!geocoder) {
          return resolve(null)
        }
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              return resolve(results[0].address_components)
            }
          }
          return resolve(null)
        })
      })
    }
  }
}
</script>
