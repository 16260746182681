export default {
    formOkBoy: {
        // Modelo de datos para el formulario.
        tipoServicio: 'Estacionario',
        cantidadGasEstacionario: null,
        tipoGasCilindro: '30 Kg',
        cantidadGasCilindro: null,
        montoTotalGas: 0,
        comision: '20',
        // Account
        nombreCliente: '',
        numeroCelular: '',
        usuarioNuevo: false,
        codigoConfirmacion: '',
        address: {
            state: '',
            street: '',
            municipality: '',
            suburb: '',
            numExt: '',
            numInt: '',
            type: 'Casa',
            reference: '',
            zipcode: '',
            longitude: '',
            latitude: ''
        },
        coordenadasMap: {
            lat: 0,
            lng: 0
        },
        // Date
        fechaServicio: '',
        horaServicio: '',
        dateServices: '',
        // Pay
        formaPago: 'Tarjeta (TDEB | TCR)',
        panelSelected: 0,
        formaPagoID: 1,
        titularTarjeta: '',
        numeroTarjeta: '',
        validaTarjeta: '',
        cvvTarjeta: '',
        cardCrypto: {},
        // Coupon
        cuponServicio: '',
        descuento: 0
    }
}