<template class="preorder-modal">
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    :size="showTranscript ? '' : 'xl'"
  >
    <template v-slot:header>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel($event, true)"
      >
        ×
      </button>
    </template>
    <CAlert
        :color="'danger'"
        :closeButton="true"
        :show.sync="hasError"
      >
        {{ error }}
    </CAlert>
    <form v-if="infoModal" novalidate v-show="infoModal" id="createRecurrence" ref="form" v-on:submit.prevent="onSubmit">
        <new-service
            v-show="!showTranscript"
            v-bind:service-detail="infoModal"
            v-bind:options="newServiceOptions" 
            v-bind:fetchService="fetchService"
            @onServiceConfigured="serviceConfigured"></new-service>
        
        <div v-show="showTranscript">
            <div role="tablist" v-if="infoModal.body">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="primary"
                            v-c-tooltip="'Desglosa toda la información referente a la llamada con el BOT'">
                                Información llamada
                        </b-button>
                    </b-card-header>
                    <b-collapse v-model="accordionOptions.call_bot" id="accordion-1" accordion="my-accordion" role="tabpanel" class="p-3">
                        <div class="modal-text-wrapper">
                            <div v-if="infoModal.body.name">
                                <strong>Nombre del cliente: </strong>
                                <span>
                                {{ infoModal.body.name }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.phoneNumber">
                                <strong>Número de teléfono del cliente: </strong>
                                <span>
                                {{ getFormattedPhoneNumber(infoModal.body.phoneNumber) }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.serviceDate">
                                <strong>Fecha del servicio: </strong>
                                <span>
                                {{ formatToDatePicker(infoModal.body.serviceDate) }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.preferredTime">
                                <strong>Hora del servicio: </strong>
                                <span>
                                {{ infoModal.body.preferredTime }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.showcaseUuid && infoModal.serviceType">
                                <strong>Tipo de servicio: </strong>
                                <span>
                                {{ infoModal.serviceType.description }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.showcaseUuid && infoModal.details && infoModal.details.length > 0 && infoModal.details[0].showcase">
                                <strong>Presentación: </strong>
                                <span>
                                {{ infoModal.details[0].showcase.name }}
                                </span>
                            </div>
                            <div v-if="infoModal.body.units">
                                <strong>Unidades: </strong>
                                <span>
                                {{ infoModal.body.units }}
                                </span>
                            </div>
                        </div>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </form>
    <template v-slot:footer>
        <div style="margin-right: auto">
            <button
                v-if="infoModal && infoModal.body"
                class="btn btn-info ml-2"
                target="_blank"
                @click="showTranscript = !showTranscript"
                >
                <CIcon :name="showTranscript ? 'cil-x' : 'cil-file'" />
            </button>
            <to-whats-app
                v-if="!showTranscript"
                v-bind:data="whatsAppData">
            </to-whats-app>
            <toggle-button
                class="ml-1"
                v-if="infoModal && infoModal.statusId === '1'"
                :width="85"
                :color="{ checked: '#28D7D8', unchecked: '#321FDB' }"
                :labels="{
                  checked: 'En Validacion',
                  unchecked: 'Creado',
                }"
                :disabled="isActive ? true : false"
                @change="updateStatus()"
                :key="infoModal.statusId"
            />
        </div>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div v-else>
        <button type="button" class="btn btn-secondary mr-1" :disabled="isRequesting" @click="onCancel">
            Cerrar
        </button>
        <button
          type="submit"
          form="createRecurrence"
          class="btn btn-primary ml-1"
          :disabled="showTranscript"
          v-c-tooltip="'Crea la suscripción con los datos ingresados'">  
            Guardar
        </button>
      </div>
    </template>
  </CModal>
</template>

<script>
import moment from 'moment'
import { mapFields } from "vuex-map-fields";
import NewService from '../services/NewService'
import ToWhatsApp from '../ToWhatsApp'


import {
  PAYMENT_TYPES
} from '../../common/enums'

import {
    MEXICO_CITY_TIMEZONE
} from '../../common/const'

export default {
  name: 'PreorderDetailModal',
  computed: {
  },
  watch: {
  },
  components: {
    NewService,
    ToWhatsApp
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    infoModal: {},
    whatsAppData: {}
  },
  async mounted () {
      this.fetchService = false
  },
  data: () => ({
        accordionOptions: {
            call_bot: true
        },
        preorderError: false,
        showTranscript: false,
        preorder: null,
        refresh: false,
        isActive: false,
        fetchService: false,
        newServiceOptions: {
            disableCustomerName: true,
            disableCustomerPhoneNumber: true
        },
        createServiceModal: {
            isActive: false
        },
        isRequesting: false,
        error: null,
        hasError: false
    }),
    methods: {
    async updateStatus() {
        try {
            this.refresh = true
            const preorderUuid = this.infoModal.registryUuid 
            await this.$serverless.put(`/services/preorders/status/${preorderUuid}`)
            this.isActive = !this.isActive
            return this.$emit('onSuccess')
        } catch (error) {
            console.log(error)
            this.error = `No se pudo modificar la preorden`
            this.hasError = true
        } finally {
            this.isRequesting = false
        }
    },
    onCancel(e, force = false) {
        this.emitMessageIframe();
        return this.$emit('onClose')
    },
    formatToDatePicker(date) {
      return date ? (
          moment(date).tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
      ) : moment().tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
    },
    serviceConfigured(service) {
        this.preorder = service
        this.refresh = true
        this.onSubmit()
    },
    emitMessageIframe(){
        document.getElementById('reactSite').contentWindow.postMessage({
            origin: 'waddi-saas',
            refresh: this.refresh
        }, '*')
        this.refresh = false
    },
    async onSubmit() {
        try {
            this.fetchService = true

            if(!this.preorder) {
                return setTimeout(() => this.fetchService = false, 500)
            }
            if (this.isRequesting) {
                return null
            }
            this.isRequesting = true
            
            const service = {
                registryUuid: this.infoModal.registryUuid,
                customerUuid: this.infoModal.customer.registryUuid,
                customerPhoneNumber: this.preorder.customerData.phoneNumber,
                paymentTypeId: this.preorder.paymentTypeId,
                serviceDate: this.preorder.serviceDate,
                preferredTime: this.preorder.preferredTime,
                details: this.preorder.details,
                preferredCompanyUuid: this.preorder.preferredCompanyUuid,
                address: this.preorder.customerData.address,
                tip: this.preorder.tip,
                promocode: this.preorder.promocode,
                serviceTypeUuid: this.preorder.serviceTypeUuid
            }

            const { data } = await this.$axios.put('preorder/', service)
            emitMessageIframe()
            return this.$emit('onSuccess')
        }catch (error) {
            this.error = `No se pudo modificar la preorden`
            this.hasError = true
        } finally {
            this.isRequesting = false
        }
    },
    getFormattedPhoneNumber(phoneNumber) {
        if(phoneNumber.toString().length > 10){
            return phoneNumber.toString().substr(phoneNumber.length - 10, phoneNumber.length)
        }
        
        return phoneNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.m1-1 {
    margin-top: 6px
}
.map > * {
    z-index: 9999
}
.clear {
    clear: left;
}
.client-data-third-container {
    float: left;
    width: 33.3%;
}
.label-text {
    color: #757575;
}
.client-data-container {
    float: left;
    width: 50%;
}
.service-data-container {
    padding: 0px 30px !important;
}
.showcase-container {
    margin-bottom: 5px;
}
.summary-subtotal {
    position: relative;
    top: -4px;
}
.summary-total {
    font-weight: 500;
    font-size: 25px
}
.button-unselected {
    color: #757575 !important;
}
.button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
    font-weight: bold;
    margin-right: 10px;
}
.showcase-button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
}
.showcase-button {
    background-color: #f5f5f5;
    color: #757575;
    font-weight: bold;
    margin-right: 10px;
}
.service-type-container {
    width: 95%
}
.summary-details {
    background-color: #f5f5f5;
    border-radius: 4px;
    min-height: 140px;
    padding: 15px !important;
}
.div-50-perc {
    width: 50%;
    padding: 0%;
    margin: 0%;
    height: 100%;
    float: left;
}
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}
.amount-container {
    display: inline-block;
    width: 93%;
}
.subtotal-container {
    display: inline-block;
}
.inline-container {
    display: inline-block;
    width: 97%;
}
.date-range {
    width: 100%;
}
.error {
    color: red;
}
input:focus:invalid {
    border-color: red;
}
</style>