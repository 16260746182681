// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB-tfkwforjpqsrhtWlXBNzz_0K8atEuu4',
  authDomain: 'okboy-admin.firebaseapp.com',
  projectId: 'okboy-admin',
  storageBucket: 'okboy-admin.appspot.com',
  messagingSenderId: '414733784569',
  appId: '1:414733784569:web:a252ea3e1db03a23d2d928',
  measurementId: 'G-LKEHZSHGTP'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export const messaging = getMessaging(app)

export default {
  analytics: getAnalytics(app),
  logEvent
}
