<template>
    <ReactSiteFrame :src="src" />
  </template>
  
  <script>
  import ReactSiteFrame from '../components/react/iframe'
  
  export default {
    name: 'PlantReact',
    components: {
      ReactSiteFrame
    },
    data() {
      return {
        src: '/view/factory'
      }
    }
  }
  </script>
  