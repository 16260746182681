<template>
  <div class="company-filter">
    <vSelect
      class="promocodes-select"
      :options="items"
      :map-keydown="handlers"
      v-model="itemSelected"
      label="name"
      placeholder="Seleccione el aliado"
      v-c-tooltip="'Enter para buscar un aliado que no esté en la lista.'"
      @search:blur="resetOnBlur()">
      <template #option="{name}">
        <span>{{name}}</span>
      </template>
      <template #no-options>
        No se encontraron coincidencias, favor de presionar ENTER para buscar otras opciones o verifique el nombre
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import queryparser from '../../utils/queryparser'

let vue = null

export default {
  name: 'CompanyFilter',
  components: {
    vSelect
  },
  data() {
    return {
      items: [],
      itemSelected: ''
    }
  },
  watch: {
    itemSelected(value) {
      if (value === null) {
        this.getCompanies()
      }
      this.$emit('onFilter', value)
    }
  },
  mounted() {
    this.getCompanies()
    vue = this
  },
  methods: {
    handlers: (map, vm) => ({
      ...map,
      13: () => {
        if (!vm.search) {
          return
        }
        if (vm.search.length >= 3) {
          vm.search = vm.search.toUpperCase()
          vue.getCompanies(vm.search)
        }
      }
    }),
    filter(value) {
      const options = {
        perPage: 100,
        active: 1
      }

      return options
    },
    getCompanies(value) {
      const queryString = queryparser.parse(this.filter(value))
      this.axios.get(`/providers/companies?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data
        })
    },
    resetOnBlur() {
      if (this.items.length === 1) {
        this.getCompanies()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .company-filter {
        margin-top: 10px
    }
</style>
