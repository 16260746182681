<template>
    <a
    class="btn btn-success ml-2"
    target="_blank"
    :href="generateUrl()"
    >
        <CIcon name="cib-whatsapp" />
    </a>
</template>

<script>
import moment from 'moment'
import * as MESSAGES from '../common/messages'

export default {
    name: 'ToWhatsApp',
    computed: {
    },
    watch: {
    },
    components: {
    },
    props: {
        data: {
            tooltipMessage: 'Enviar mensaje de confirmación',
            phoneNumber: ''
        }
    },
    async mounted () {
    },
    data: () => ({
    }),
    methods: {
        generateUrl() {
            const { phoneNumber } = this.data
            const urlEncodedText = window.encodeURIComponent(this.whatsappCopy())
            return `https://wa.me/52${phoneNumber}?text=${urlEncodedText}`
        },
        whatsappCopy() {
            if(!this.data.address) return
            const template = MESSAGES.WHATSAPP_PREORDER
            const serviceDate = moment.utc(this.data.serviceDate)
            const date = serviceDate.format('DD / MM / YYYY').toString()
            const time = serviceDate.format('HH:mm').toString()
            const address = this.formatAddress(this.data.address)
                .replace(/\n/g, '')
                .replace(/[ ]{2,}/g, ' ')
                .replace(/, , /g, ', ')

            const { latitude, longitude } = this.data.address

            const googleMapsURL = latitude && longitude
                ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
                : `https://www.google.com/maps/search/?api=1&query=${address.replace(
                /, /g,
                '+'
                )}`

            const {
                name, paymentType, discount, amount, totalAmount, serviceType
            } = this.data

            const text = template
                .replace(/{name}/g, name || '') // Default Value
                .replace(/{serviceType}/g, serviceType)
                .replace(/{date}/g, date || '')
                .replace(/{time}/g, time || '')
                .replace(/{amount}/g, amount || 0)
                .replace(/{paymentType}/g, paymentType || '')
                .replace(/{address}/g, address || '')
                .replace(/{googleMapsURL}/g, googleMapsURL || '')

            return text
        },
        formatAddress(data) {
            if (data) {
                const numInt = data.numInt ? data.numInt : ''
                return `${data.street}, ${data.numExt}, ${numInt}, ${data.zipcode}, 
                        ${data.suburb}, ${data.municipality}, ${data.state}`
            }
            return ''
        }
    }
}
</script>