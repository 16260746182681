<template>
  <div>
    <ServiceChart></ServiceChart>
    <br/>
    <CustomerChart></CustomerChart>
    <br/>
    <RepurchaseChart></RepurchaseChart>
  </div>
</template>

<script>
import CustomerChart from '../components/CustomerCharts'
import ServiceChart from '../components/ServiceCharts'
import RepurchaseChart from '../components/RepurchaseCharts'

export default {
  components: {
    CustomerChart,
    ServiceChart,
    RepurchaseChart
  }
}
</script>
