<template>
  <div class="cancel-service">
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="alertError">
      {{error.message}}
    </CAlert>
    <h5>Cancelar orden de servicio</h5>
    <select
      v-model="cancelOptionSelected"
      v-c-tooltip="'Indica el tipo de cancelación, puede ser sin costo o con costo, según el método de pago'"
    >
      <option
        v-if="paymentType === 'Tarjeta (TDEB | TCR)'"
        :value="false"
      >
        Cancelar orden con costo
      </option>
      <option :value="true">
        Cancelar orden sin costo
      </option>
    </select>
    <select
      v-model="selectedReason"
      @change="changeSelectReason"
      v-c-tooltip="'Campo para indicar la razón o motivo por la que se cancela el servicio'"
    >
      <option :value="0">
        Seleccionar motivo
      </option>
      <option
        v-for="reason in cancellationReasons"
        :key="reason.id"
        :value="reason.id"
      >
        {{ reason.reason }}
      </option>
    </select>
    <input
      v-if="displayOtherInput"
      v-model.trim="otherReason"
      type="text"
      :placeholder="'Escriba una razón'"
      maxlength="255"
      class="open-filter-input"
    />
    <button
      class="btn btn-primary"
      @click="cancelService()"
      v-c-tooltip="'Cancela el servicio; notifica al cliente y al colaborador (en caso de tener uno asignado)'"
    >
      Cancelar servicio
    </button>
  </div>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

export default {
  name: 'CancelService',
  data() {
    return {
      otherReason: null,
      displayOtherInput: false,
      cancelOptionSelected: true,
      cancellationReasons: [],
      cancelReason: '',
      selectedReason: 0,
      error: {},
      alertError: false
    }
  },
  props: {
    serviceUuid: {
      type: String,
      required: true
    },
    paymentType: {
      type: String,
      required: false
    },
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  async mounted() { 
    this.getCancellationReasons()
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.alertError = false
        this.resetForm()
      }
    }
  },
  methods: {
    changeSelectReason() {
      this.otherReason = null
      this.displayOtherInput = this.cancellationReasons.find(obj => obj.id === this.selectedReason).reason === 'Otra'
    },
    getCancellationReasons() {
      this.axios.get(`/services/cancelled/reasons`)
      .then(response => {
        const { data } = response
        this.cancellationReasons = data
      })
      .catch((err) => {
          this.alertError = true
          this.error = getErrorMessage(err)
        })
    },
    cancelService() {
      if (this.selectedReason) {
        const chosenReason = this.cancellationReasons.find(obj => obj.id === this.selectedReason).reason
        this.axios.post(`/services/${this.serviceUuid}/cancellation`, {
          isFree: this.cancelOptionSelected,
          reasonId: this.selectedReason,
          reason: chosenReason === 'Otra' ? this.otherReason : chosenReason
        }).then(() => {
          this.alertError = false
          this.$emit('onSuccess')
        }).catch((err) => {
          this.alertError = true
          this.error = getErrorMessage(err)
        })
      } else {
        this.alertError = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0001' } } })
      }
    },
    resetForm() {
      this.error = {}
      this.cancelReason = ''
      this.selectedReason = 0
      this.cancelOptionSelected = true
    }
  }
}
</script>

<style lang="scss" scoped>
    .cancel-service{
        display: flex;
        flex-direction: column;
        h5{
            margin-bottom: 16px;
        }
        select,input{
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-bottom: 16px;
        }
        button{
            width: fit-content;
            margin-left: auto;
            padding: 8px;
        }
    }
</style>
