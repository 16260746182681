<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
  >
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="isErrorResponse">
      {{error.message}}
    </CAlert>
    <template v-slot:header>
      <h5 class="modal-title">Crear administrador</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <form
      id="createAdmin"
      ref="form"
      v-on:submit.prevent="onSubmit">
      <div>
        <label for="">Nombre*</label>
        <input
          type="text"
          placeholder="Nombre"
          v-model="name"
          maxlength="100"
          @keypress="onlyLetters($event)"
          v-c-tooltip="'Corresponde al nombre del administrador'"
          required>
      </div>

      <div>
        <label>Compañía</label>
        <select
          v-model="selectedCompany"
          v-c-tooltip="'Indica la compañía a la que pertenece el administrador (opcional)'"
        >
          <option
            :value="null"
            disabled
          >
            Selecciona una opción
          </option>
          <option
            v-for="company in companies"
            :key="company.registryUuid"
            :value="company.registryUuid"
          >
            {{ company.name }}
          </option>
        </select>
      </div>

      <div>
        <label for="">Correo electrónico*</label>
        <input
          type="email"
          placeholder="Correo electrónico"
          maxlength="100"
          v-model="email"
          v-c-tooltip="'Corresponde al correo con el que iniciará sesión el administrador'"
          required>
      </div>
      <div>
        <div class="d-flex mb-2">
          <label class="my-auto">Contraseña*</label>
          <div
            v-c-tooltip="'Corresponde a la contraseña con la que se accederá al panel de administrador'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          :type="isPasswordVisible ? 'text' : 'password'"
          placeholder="Ej: qwerty12"
          v-model="password"
          maxlength="8"
          minlength="8"
          pattern="^[a-zA-Z0-9]{8}$"
          autocomplete="new-password"
          title="8 caracteres de longitud, solo números y letras (sin acentos)"
          required>
        <a class="show-pass" @click="isPasswordVisible = !isPasswordVisible">Mostrar</a>
      </div>
      <div>
        <div class="d-flex mb-2">
          <label class="my-auto">Confirmar Contraseña</label>
          <div
            v-c-tooltip="'Corresponde a la corroboración de la contraseña de acceso'"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <input
          :type="isConfirmPasswordVisible ? 'text' : 'password'"
          placeholder="Ej: qwerty12"
          v-model="confirmPassword"
          maxlength="8"
          minlength="8"
          pattern="^[a-zA-Z0-9]{8}$"
          autocomplete="new-password"
          title="8 caracteres de longitud, solo números y letras (sin acentos)"
          required>
        <a class="show-pass" @click="isConfirmPasswordVisible = !isConfirmPasswordVisible">Mostrar</a>
      </div>
    </form>
    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div v-else>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="createAdmin"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Registra al administrador con los datos ingresados en el formulario'">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  isPasswordVisible: false,
  isConfirmPasswordVisible: false,
  error: {},
  isErrorResponse: false,
  isRequesting: false,
  companies: null,
  selectedCompany: null
})
export default {
  name: 'CreateAdmin',
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return initialState()
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.isErrorResponse = false
      }
    }
  },
  created() {
    this.fetchCompanies()
  },
  methods: {
    async fetchCompanies() {
      this.isRequesting = true
      this.alertError = false

      try {
        const { data } = await this.axios.get('providers/companies?format=asgmt')
        this.companies = data
      } catch (err) {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    },
    onSubmit() {
      this.isRequesting = true
      if (this.confirmPassword === this.password) {
        this.axios.post('/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          companyUuid: this.selectedCompany || undefined
        }).then(() => {
          this.resetForm()
          this.$emit('onSuccess')
          this.isErrorResponse = false
        }).catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        }).finally(() => {
          this.isRequesting = false
        })
      } else {
        this.isErrorResponse = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0002' } } })
        this.isRequesting = false
      }
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    onCancel() {
      this.$emit('onClose')
    },
    // validations
    onlyLetters(e) {
      if (!this.isLetter(e.charCode) && !this.isEne(e.charCode) && (e.charCode !== 32) && (e.charCode !== 0)) {
        e.preventDefault()
      }
    },
    // char codes
    isLetter(charCode) {
      return ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
    },
    isEne(charCode) {
      return (charCode === 241 || charCode === 209)
    }
  }
}
</script>

<style lang="scss" scoped>
  form{
    >div{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
        label{
            font-weight: bold;
        }
        select,input{
            padding: 6px 8px;
            width: 100%;
            height: 35px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
        a.show-pass{
          position: absolute;
          top: 35px;
          right: 12px;
          color: #5B2AE0;
          &:hover{
            cursor: pointer;
          }
        }
    }
    .tooltip-btn{
      width: 25px;
      height: 25px;
      border: 0;
      border-radius: 4px;
      margin-left: 4px;

      background-color: #3c4b64;
      color: #FFF;
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
