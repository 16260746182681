<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    class="create-sensor">
    <div tabindex="0" ref="CreateSensor">
      <CAlert
        color="danger"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>
    <template v-slot:header>
      <h5 class="modal-title">Eliminar sensor</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <div>
      <label for="">¿Estás seguro que deseas eliminar el sensor?</label>
    </div>
    <template v-slot:footer>
      <div>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button class="btn btn-primary ml-1" @click="onDelete">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  error: {},
  isErrorResponse: false
})

export default {
  name: 'DeleteSensor',
  data() {
    return initialState()
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    uuid: {
      type: String,
      required: true
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.isErrorResponse = false
      }
    }
  },
  methods: {
    onDelete() {
      this.axios.delete(`/customers/sensor/${this.uuid}`)
        .then(() => {
          this.$emit('onSuccess')
        })
        .catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
    },
    onCancel() {
      this.$emit('onClose')
    }
  }
}
</script>
