<template>
  <div class="state-filter">
    <vSelect
      class="promocodes-select"
      :options="items"
      :map-keydown="handlers"
      v-model="itemSelected"
      placeholder="Seleccione el estado"
      v-c-tooltip="'Enter para buscar un estado que no esté en la lista.'"
      label="phoneNumber">
      <template #no-options>
        No se encontraron coincidencias, favor de presionar ENTER para buscar otras opciones o verifique el nombre
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import queryparser from '../../utils/queryparser'

let vue = null

export default {
  name: 'StateFilter',
  components: {
    vSelect
  },
  props: {
      items: {
        type: Array,
        required: true
      }
  },
  data() {
    return {
      itemSelected: ''
    }
  },
  watch: {
    itemSelected(value) {
      this.$emit('onFilter', value)
    }
  },
  mounted() {
    vue = this
  },
  methods: {
    handlers: (map, vm) => ({
      ...map,
      13: () => {
        if (!vm.search) {
          return
        }
        if (vm.search.length >= 3) {
          vm.search = vm.search.toUpperCase()
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
    .state-filter {
        margin-top: 10px
    }
</style>
