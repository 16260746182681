const parseProp = (prop, value) => `${prop}=${value}`
const parseArray = (prop, values) => values.map((value) => parseProp(prop, value))

const parse = (object) => {
  const queries = Object.keys(object).reduce((arr, prop) => {
    const value = object[prop]
    const hasValidValue = !(value == null || (Array.isArray(value) && value.length === 0) || value === '')
    if (hasValidValue) {
      arr.push(...Array.isArray(value) ? parseArray(prop, value) : [parseProp(prop, value)])
    }
    return arr
  }, [])
  queries.sort()
  return queries.join('&')
}

export default { parse }
