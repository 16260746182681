<template>
  <div class="services-report">
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="isErrorResponse">
      {{error.message}}
    </CAlert>
    <div class="card">
      <div class="card-header">
        <date-range-picker
          v-model="dateRange"
          :locale-data="{format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
          :ranges="false"
          opens="right"
          :append-to-body="false"
          @update="updateDate"
          class="date-range"
          v-c-tooltip="'Indica el rango de fechas por el que se mostrarán los reportes'"
        />
        <button
          class="btn btn-success btn-small mx-2"
          type="button"
          @click="downloadXLXS"
          v-c-tooltip="'Exporta en un excel con los datos de los reportes'"
        >
          <CIcon name="cilCloudDownload"/>
        </button>
      </div>
      <div
        v-if="!isRequesting"
        class="card-body">
        <div class="section-title">
          <h3
            v-c-tooltip="'Reporte de servicios solicitados en el cuál se muestra: número total de servicio solicitados, número de servicios solicitados por plataforma, número de servicios finalizados por plataforma y  porcentaje de servicios finalizados por plataforma'">
            Servicios solicitados
          </h3>
          <span v-if="data.servicesOrder">
            Total: {{data.servicesOrder.totalServices}}
          </span>
          <!-- <span v-if="data.servicesOrder">
                        Litros: {{data.servicesOrder.totalLiters}}
                    </span>
                    <span v-if="data.servicesOrder">
                        Cantidad: {{data.servicesOrder.totalAmount}}
                    </span> -->
        </div>
        <div class="table-wrapper">
          <CDataTable
            :items="itemsPlatforms"
            :fields="fieldsPlatform"
            hover
            class="main-table"
          >
          </CDataTable>
        </div>
        <div class="section-title">
          <h3
            v-c-tooltip="'Reporte de servicios finalizado, en él se observa el número total de servicios finalizados y el número de servicios finalizados desglosados por método de pago'">
            Servicios finalizados
          </h3>
          <span v-if="data.serviceFinished">
            Total: {{data.serviceFinished.totalServices}}
          </span>
          <span v-if="data.serviceFinished">
            Unidades: {{data.serviceFinished.totalLiters}}
          </span>
          <span v-if="data.serviceFinished">
            Cantidad: {{data.serviceFinished.totalAmount}}
          </span>
        </div>
        <div class="table-wrapper">
          <CDataTable
            :items="itemsServicesFinished"
            :fields="fieldsServicesFinished"
            hover
            class="main-table"
          >
            <template #paymentType="{item}">
              <td v-if="item.paymentType === 'card'">
                Tarjeta (TDEB | TCR)
              </td>
              <td v-if="item.paymentType === 'cash'">
                Efectivo
              </td>
              <td v-if="item.paymentType === 'terminal'">
                Terminal
              </td>
            </template>
          </CDataTable>
        </div>
        <div class="canceled-services">
          <h3 v-c-tooltip="'Indica el número total de servicios que fueron cancelados'">Servicios cancelados </h3>
          <div>
            <span>Total</span>
            <span v-if="data.servicesCanceled">
              {{data.servicesCanceled.totalServices}}
            </span>
          </div>
        </div>
        <div class="section-title">
          <h3
            v-c-tooltip="'Reporte en donde se muestra el número de servicios que fueron recompra, el monto total de ventas, el monto total de comisón cobrada y el número de unidades vendidas'">
            Resumen de ventas
          </h3>
        </div>
        <div class="table-wrapper">
          <CDataTable
            :items="itemsSalesResume"
            :fields="fieldsSalesResume"
            hover
            class="main-table"
          >
            <template #concept="{item}">
              <td v-if="item.concept === 'repurchase'">
                Recompras
              </td>
              <td v-else-if="item.concept === 'totalAmount'">
                Cantidad
              </td>
              <td v-else-if="item.concept === 'totalFee'">
                Comisión
              </td>
              <td v-else-if="item.concept === 'totalLiters'">
                Unidades
              </td>
            </template>
            <template #total="{item}">
              <td v-if="item.concept === 'totalAmount'||item.concept === 'totalFee'">
                ${{formatMoney(item.total)}}
              </td>
              <td v-else>
                {{ item.total }}
              </td>
            </template>
          </CDataTable>
        </div>
        <div class="section-title">
          <h3
            v-c-tooltip="'Reporte de los códigos promocionales usados desglosados por el número de uso por estado y el número total de  veces que se utilizó dicho código'">
            Códigos promocionales
          </h3>
        </div>
        <div class="table-wrapper">
          <CDataTable
            :items="itemsPromoCode"
            :fields="fieldsPromoCode"
            hover
            class="promocode-table main-table"
          >
            <template #code="{item}">
              <td>{{ formatSPC(item.code) }}</td>
            </template>
            <template #state="{item}">
              <tr
                v-for="(items) in item.state"
                :key="items">
                <td>{{ formatUnknown(items) }}</td>
              </tr>
            </template>
            <template #subtotal="{item}">
              <td class="p-0">
                <tr
                  v-for="(items, index) in item.subtotal"
                  :key="index">
                  <td>{{ items }}</td>
                </tr>
              </td>
            </template>
            <template #totalPromocode="{item}">
              <td class="p-0">
                <tr
                  v-for="(items, index) in item.totalPromocode"
                  :key="index">
                  <td>{{ items.promocode }}</td>
                </tr>
              </td>
            </template>
          </CDataTable>
        </div>
        <div class="section-title">
          <h3 v-c-tooltip="'Reporte de servicios por estado; en cada tabla se muestra el número de servicios solicitados, servicios finalizados, servicios que fueron re-compra, el monto total de ventas, el monto total de la comisión cobrada, el número de unidades vendidas y el número de servicios cancelados'">
            Servicios por estado:
          </h3>
        </div>
        <CDataTable
          :items="itemsServicesByState"
          :fields="fieldsServicesByState"
          hover
          class="main-table">
          <template #state="{item}">
            <td>
              {{formatUnknown(item.state)}}
            </td>
          </template>

          <template #totalAmount="{item}">
            <td>
              ${{formatMoney(item.totalAmount)}}
            </td>
          </template>

          <template #totalFee="{item}">
            <td>
              ${{formatMoney(item.totalFee)}}
            </td>
          </template>
          <!-- requested services -->
          <template #requestedServices="{item}">
            <td v-if="item.requestedServices" class="text-center">
              <button
                title="Desglose de servicios"
                @click="() => redirectClick(ALL_STATUS_SERVICES, item.state)"
                class="btn btn-outline-primary btn-sm"
              >
                {{item.requestedServices}}
              </button>
            </td>
            <td class="text-center" v-else>
              {{item.requestedServices}}
            </td>
          </template>
          <!-- finished services -->
          <template #finishedServices="{item}">
            <td v-if="item.finishedServices" class="text-center">
              <button
                title="Desglose de servicios finalizados"
                @click="() => redirectClick(STATUS_SERVICES.FINISHED.ID, item.state)"
                class="btn btn-outline-primary btn-sm"
              >
                {{item.finishedServices}}
              </button>
            </td>
            <td class="text-center" v-else>
              <span v-if="item.finishedServices">{{item.finishedServices}}</span>
              <span v-else>0</span>
            </td>
          </template>
          <!-- repurchase -->
          <template #repurchase="{item}">
            <td v-if="item.repurchase" class="text-center">
              <button
                title="Desglose de servicios que son re-compra"
                @click="() => redirectClick(undefined, item.state, { repurchase: 1 })"
                class="btn btn-outline-primary btn-sm"
              >
                {{item.repurchase}}
              </button>
            </td>
            <td class="text-center" v-else>
              {{item.repurchase}}
            </td>
          </template>
          <!-- services canceled -->
          <template #servicesCanceled="{item}">
            <td v-if="item.servicesCanceled" class="text-center">
              <button
                title="Desglose de servicios cancelados"
                @click="() => redirectClick(CANCELLED_STATUS, item.state)"
                class="btn btn-outline-primary btn-sm"
              >
                {{item.servicesCanceled}}
              </button>
            </td>
            <td class="text-center" v-else>
              {{item.servicesCanceled}}
            </td>
          </template>
        </CDataTable>
      </div>
      <div
        v-if="isRequesting"
        class="card-body d-flex">
        <CSpinner
          class="mx-auto"
          style="width:4rem;height:4rem;"
          color="#303034"
          grow
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import XLSX from 'xlsx'
import { ALL_STATUS_SERVICES, STATUS_SERVICES, CANCELLED_STATUS } from '../common/enums'
// eslint-disable-next-line import/no-extraneous-dependencies

import queryparser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const fieldsPlatform = [
  { key: 'platform', label: 'Plataforma' },
  { key: 'total', label: 'Servicios totales' },
  { key: 'finished', label: 'Finalizados' },
  { key: 'percentage', label: '% de servicios finalizados' },
  { key: 'liter', label: 'Unidades vendidas' },
  { key: 'amount', label: 'Cantidad' }

]

const fieldsServicesFinished = [
  { key: 'paymentType', label: 'Tipo de pago' },
  { key: 'total', label: 'Total' },
  { key: 'liter', label: 'Unidades' },
  { key: 'amount', label: 'Cantidad' }
]

const fieldsPromoCode = [
  { key: 'code', label: 'Código' },
  { key: 'discount', label: 'Descuento' },
  { key: 'state', label: 'Estado' },
  { key: 'subtotal', label: 'Usos por estado' },
  { key: 'totalPromocode', label: 'Cantidad en cupones' },
  { key: 'total', label: 'Total' },
  { key: 'amount', label: 'Cantidad' }
]

const fieldsSalesResume = [
  { key: 'concept', label: 'Concepto' },
  { key: 'total', label: 'Total' },
  { key: 'liter', label: 'Unidades' },
  { key: 'amount', label: 'Cantidad' }
]

const fieldsServicesByState = [
  { key: 'state', label: 'Estado' },
  { key: 'requestedServices', label: '# de servicios' },
  { key: 'finishedServices', label: 'Finalizados' },
  { key: 'repurchase', label: 'Re-compra' },
  { key: 'totalAmount', label: 'Cantidad' },
  { key: 'totalFee', label: 'Comisión' },
  { key: 'totalLiters', label: 'Unidades' },
  { key: 'servicesCanceled', label: 'Cancelados', _classes: 'text-center' }
]

const daysOfWeekSpanish = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

export default {
  name: 'ServiceOrdersReport',
  components: {
    DateRangePicker
  },
  data() {
    return {
      data: {},
      itemsPlatforms: [],
      itemsServicesFinished: [],
      itemsPromoCode: [],
      itemsServicesFinishedByStates: [],
      itemsServicesByState: [],
      itemsSalesResume: [],
      lastWeek: {
        monday: '',
        sunday: ''
      },
      fieldsPlatform,
      fieldsServicesFinished,
      fieldsPromoCode,
      fieldsSalesResume,
      fieldsServicesByState,
      dateRange: {
        startDate: '',
        endDate: ''
      },
      promoCodeData: {},
      daysOfWeekSpanish,
      isFirstLoad: true,
      isRequesting: false,
      error: {},
      isErrorResponse: false,
      ALL_STATUS_SERVICES,
      STATUS_SERVICES,
      CANCELLED_STATUS

    }
  },
  mounted() {
    this.getLastWeek()
  },
  methods: {
    redirect(data) {
      const startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      const endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      data.query.serviceDate = `${startDate}:${endDate}`
      this.$router.push(data)
    },
    downloadXLXS() {
      const {
        servicesOrder,
        serviceFinished,
        servicesCanceled,
        statesSales
      } = this.data

      const stateAliases = {
        Unknown: 'Desconocido'
      }

      const codesAliases = {
        SPC: 'SIN DESCUENTO'
      }

      const data = [
        ['SERVICIOS SOLICITADOS'],
        ['Plataforma', 'Servicios totales', 'Finalizados', '% de servicios finalizados', 'Unidades', 'Cantidad'],
        ['web', servicesOrder.web.totalServices, servicesOrder.web.finished, this.getPercentage(servicesOrder.web.finished, servicesOrder.web.totalServices), servicesOrder.web.liter, servicesOrder.web.amount],
        ['ios', servicesOrder.ios.totalServices, servicesOrder.ios.finished, this.getPercentage(servicesOrder.ios.finished, servicesOrder.ios.totalServices)],
        ['android', servicesOrder.android.totalServices, servicesOrder.android.finished, this.getPercentage(servicesOrder.android.finished, servicesOrder.android.totalServices)],
        ['TOTAL', servicesOrder.totalServices],
        [], // <br>
        ['SERVICIOS FINALIZADOS'],
        ['Tipo de pago', '# de servicios'],
        ['Tarjeta (TDEB | TCR)', serviceFinished.card],
        ['Efectivo', serviceFinished.cash],
        ['Terminal (TDEB | TCR)', serviceFinished.terminal],
        ['TOTAL', serviceFinished.totalServices],
        [],
        ['SERVICIOS CANCELADOS', servicesCanceled.totalServices],
        [],
        ['RESUMEN DE VENTAS'],
        ['Recompras', 'Cantidad', 'Comisión', 'Unidades'],
        [
          this.data.repurchase,
          this.formatMoney(this.data.totalAmount, true),
          this.formatMoney(this.data.totalFee, true),
          this.data.totalLiters
        ],
        [],
        ['CÓDIGOS PROMOCIONALES'],
        ['Código', 'Estado', 'Usos por estado', 'Cantidad en cupones', 'TOTAL'],
        ...Object
          .keys(serviceFinished.promoCode).map((promoKey) => {
            const code = codesAliases[promoKey] || promoKey
            const promoData = serviceFinished.promoCode[promoKey]
            const { states } = promoData

            return Object.keys(states)
              .map((stateKey, i) => {
                const state = stateAliases[stateKey] || stateKey
                const row = ['', state, states[stateKey], '']
                if (i === 0) {
                  row[0] = code
                  row[3] = promoData.totalServices
                }
                return row
              })
          })
          .reduce((arr, v) => arr.concat(v), []),
        [],
        ['SERVICIOS POR ESTADO'],
        ['Estado', '# de servicios', 'Finalizados', 'Re-compra', 'Cantidad', 'Comisión', 'Unidades', 'Cancelados'],
        ...Object
          .keys(statesSales)
          .map((key) => {
            const state = stateAliases[key] || key
            const stateData = statesSales[key]
            return [
              state, servicesOrder.states[key],
              serviceFinished.states[key] || 0,
              stateData.repurchase,
              this.formatMoney(stateData.totalAmount, true),
              this.formatMoney(stateData.totalFee, true),
              stateData.totalLiters,
              stateData.servicesCanceled.totalServices
            ]
          })
      ]

      const start = moment(this.dateRange.startDate).format('DD/MM/YYYY')
      const end = moment(this.dateRange.endDate).format('DD/MM/YYYY')

      const worksheet = XLSX.utils.aoa_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte de Servicios')
      XLSX.writeFile(workbook, `Servicios ${start}-${end}.xlsx`)
    },
    filter() {
      const options = {
        serviceDate: `${this.lastWeek.monday}:${this.lastWeek.sunday}`
      }

      return options
    },
    getData() {
      this.isRequesting = true
      const queryString = queryparser.parse(this.filter())
      if (this.getDiffBetweenDays() < 31) {
        this.axios.get(`report?${queryString}`)
          .then((response) => {
            this.isErrorResponse = false
            const { data } = response
            const { data: { servicesOrder } } = response
            this.data = data
            this.promoCodeData = data.serviceFinished.promoCode
            let totalLiters = 0
            let totalAmount = 0
            // filling platforms
            Object.keys(servicesOrder).forEach((platform) => {
              if (platform !== 'states' && platform !== 'totalServices') {
                this.itemsPlatforms.push({
                  platform,
                  total: servicesOrder[platform].totalServices,
                  finished: servicesOrder[platform].finished,
                  percentage: this.getPercentage(servicesOrder[platform].finished, servicesOrder[platform].totalServices),
                  liter: this.formatMoney(servicesOrder[platform].liter),
                  amount: this.formatMoney(servicesOrder[platform].amount, true)
                })
                totalLiters += servicesOrder[platform].liter
                totalAmount += servicesOrder[platform].amount
              }
            })
            this.data.servicesOrder.totalLiters = this.formatMoney(totalLiters)
            this.data.servicesOrder.totalAmount = this.formatMoney(totalAmount, true)
            // filling finished services
            totalLiters = 0
            totalAmount = 0
            Object.keys(this.data.serviceFinished).forEach((key) => {
              if (key !== 'totalServices' && key !== 'states' && key !== 'promoCode') {
                const name = `total${key[0].toUpperCase() + key.slice(1)}`
                this.itemsServicesFinished.push({
                  paymentType: key,
                  total: this.data.serviceFinished[key][name],
                  liter: this.formatMoney(this.data.serviceFinished[key].liter),
                  amount: this.formatMoney(this.data.serviceFinished[key].amount, true)
                })
                totalLiters += this.data.serviceFinished[key].liter
                totalAmount += this.data.serviceFinished[key].amount
              }
            })
            this.data.serviceFinished.totalLiters = this.formatMoney(totalLiters)
            this.data.serviceFinished.totalAmount = this.formatMoney(totalAmount, true)
            // filling promocode
            Object.keys(this.data.serviceFinished.promoCode).forEach((promocode) => {
              const arrayStates = []
              const arraySubtotal = []
              const arrayTotalPromocode = []
              Object.keys(this.data.serviceFinished.promoCode[promocode].states).forEach((state) => {
                arrayStates.push(state)
                arraySubtotal.push(this.data.serviceFinished.promoCode[promocode].states[state].amountUse)
                arrayTotalPromocode.push({ id: state, promocode: this.formatMoney((this.data.serviceFinished.promoCode[promocode].states[state].totalPromocode / 100), true) })
              })
              this.itemsPromoCode.push({
                code: promocode,
                discount: this.formatMoney(this.data.serviceFinished.promoCode[promocode].discount, true),
                state: arrayStates,
                subtotal: arraySubtotal,
                totalPromocode: arrayTotalPromocode,
                total: this.data.serviceFinished.promoCode[promocode].totalServices,
                amount: this.formatMoney(this.data.serviceFinished.promoCode[promocode].totalCash / 100, true)
              })
            })
            // filling services by states
            Object.keys(this.data.statesSales).forEach((state) => {
              this.itemsServicesByState.push({
                state,
                requestedServices: this.data.servicesOrder.states[state],
                finishedServices: this.data.serviceFinished.states[state],
                repurchase: this.data.statesSales[state].repurchase,
                totalAmount: this.data.statesSales[state].totalAmount,
                totalFee: this.data.statesSales[state].totalFee,
                totalLiters: this.data.statesSales[state].totalLiters,
                servicesCanceled: this.data.statesSales[state].servicesCanceled.totalServices
              })
            })
            // filling finished services by states
            Object.keys(this.data.serviceFinished.states).forEach((state) => {
              this.itemsServicesFinishedByStates.push({ state, total: this.data.serviceFinished.states[state] })
            })
            // filling sales resume
            Object.keys(this.data).forEach((key) => {
              if (key === 'repurchase' || key === 'totalAmount' || key === 'totalFee' || key === 'totalLiters') {
                let name = key
                if (key === 'repurchase') {
                  name = `total${key[0].toUpperCase() + key.slice(1)}`
                }
                this.itemsSalesResume.push({
                  concept: key, total: this.data[key][name], liter: (this.data[key].liters) ? this.formatMoney(this.data[key].liters) : '-', amount: this.data[key].amount ? this.formatMoney(this.data[key].amount, true) : '-'
                })
              }
            })
          })
          .catch((err) => {
            this.isErrorResponse = true
            this.error = getErrorMessage(err)
          })
          .finally(() => {
            this.isRequesting = false
          })
      } else {
        this.isErrorResponse = true
        this.isRequesting = false
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0003' } } })
      }
    },
    updateDate(dateSelected) {
      this.lastWeek.monday = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.lastWeek.sunday = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      this.getData()
      if (dateSelected !== undefined) {
        this.resetData()
      }
    },
    getLastWeek() {
      const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
      const beforeTwoWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000)

      if (moment().day() >= 1) {
        this.dateRange.startDate = moment(beforeOneWeek).day('Monday').format()
        this.dateRange.endDate = moment().day('Sunday').format()
      } else {
        this.dateRange.startDate = moment(beforeTwoWeek).day('Monday').format()
        this.dateRange.endDate = moment(beforeOneWeek).day('Sunday').format()
      }

      this.updateDate()
    },
    getDiffBetweenDays() {
      const a = moment(this.dateRange.startDate)
      const b = moment(this.dateRange.endDate)
      const diffDays = b.diff(a, 'days')
      return diffDays
    },
    getPercentage(finished, total) {
      const percentage = total ? ((finished * 100) / total).toFixed(2) : '0.00'
      return `${percentage}%`
    },
    formatUnknown(value) {
      if (value === 'Unknown') {
        return 'Desconocido'
      }
      return value
    },
    formatMoney(value, withMoneySign = false) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return `${withMoneySign ? '$' : ''}${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formatSPC(value) {
      return value === 'SPC' ? 'SIN DESCUENTO' : value
    },
    resetData() {
      this.itemsPlatforms = []
      this.itemsServicesFinished = []
      this.itemsPromoCode = []
      this.itemsServicesFinishedByStates = []
      this.itemsServicesByState = []
      this.itemsSalesResume = []
      this.data.servicesOrder.totalServices = 0
      this.data.serviceFinished.totalServices = 0
      this.data.servicesCanceled.totalServices = 0
    },
    redirectClick(statusId, state, extraArgs = {}) {
      const query = {
        state,
        ...extraArgs,
        ...(statusId ? { statusId } : {})
      }
      this.redirect({ name: 'Services', query })
    }
  }
}
</script>

<style lang="scss" scoped>
    .services-report{
        .card{
            .card-header {
              button {
                height: 34px;
              }
            }
            .card-body{
                .section-title{
                  display: flex;
                  margin-bottom: 8px;
                  h3{
                    margin-bottom: 0;
                  }
                  span{
                    margin: auto 0px auto 8px;
                    padding: 4px 8px;
                    background-color: #303034;
                    color: #FFFFFF;
                    border-radius: 4px;
                  }
                }
                .table-wrapper{
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                    margin-bottom: 16px;
                    .main-table{
                        border-bottom: 1px solid #d8dbe0;
                    }
                }
                .canceled-services{
                  margin-bottom: 16px;
                  h3{
                    width: fit-content;
                  }
                  >div{
                    display: flex;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                    >span{
                      width: 50%;
                      padding: 12px;
                    }
                  }
                }
            }
        }
    }
</style>
