<template>
  <div class="service-evidence">
    <form v-on:submit.prevent="updateEvidence()">
      <div>
        <div class="form-group">
          <div v-if="serviceEvidence && serviceEvidence.before">
            <div class="img-card">
              <img
                class="
                            evidence-img
                            figure-img
                            img-fluid
                            d-block
                            mx-auto
                            w-auto
                          "
                :src="
                  serviceEvidence.before.photo + `?d=${Date.now()}`
                "
                alt="After"
              />
              <figcaption class="figure-caption text-center">
                Antes del servicio
                {{ evidenceDateFormat(serviceEvidence.before) }}
              </figcaption>
            </div>
            <ImageInput
              ref="before"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'before')"
              v-c-tooltip="'Foto de la evidencia previa al servicio'"
            >
            </ImageInput>
          </div>
          <div v-else class="card-add-evidence">
            <ImageInput
              ref="after"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'before')"
              v-c-tooltip="'Foto de la evidencia previa al servicio'"
            >
            </ImageInput>
            <div class="date-picker">
              <DateRangePicker
                v-model="dateRangeEvidenceBefore"
                :locale-data="{
                  format: 'dd/mm/yyyy',
                  daysOfWeek: daysOfWeekSpanish,
                  applyLabel: 'Aplicar',
                  cancelLabel: 'Cancelar',
                }"
                :ranges="false"
                opens="right"
                :single-date-picker="true"
                :append-to-body="false"
                :time-picker="true"
                :time-picker24-hour="true"
                @update="updateEvidenceDate()"
                class="date-range"
                v-c-tooltip="
                  'Indica la fecha y hora en que se tomo la evidencia antes de realizar el servicio'
                "
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  <span v-if="picker.startDate">{{
                    formatToDatePicker(picker.startDate)
                  }}</span>
                  <span v-else>Seleccione fecha</span>
                </template>
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="form-group">
          <div v-if="serviceEvidence && serviceEvidence.after">
            <div class="img-card">
              <img
                class="
                            evidence-img
                            figure-img
                            img-fluid
                            d-block
                            mx-auto
                            w-auto
                          "
                :src="
                  serviceEvidence.after.photo + `?d=${Date.now()}`
                "
                alt="After"
              />
              <figcaption class="figure-caption text-center">
                Después del servicio
                {{ evidenceDateFormat(serviceEvidence.after) }}
              </figcaption>
            </div>
            <ImageInput
              ref="after"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'after')"
              v-c-tooltip="
                'Foto de la evidencia posterior al servicio'
              "
            >
            </ImageInput>
          </div>
          <div v-else class="card-add-evidence">
            <ImageInput
              ref="after"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'after')"
              v-c-tooltip="
                'Foto de la evidencia posterior al servicio'
              "
            >
            </ImageInput>
            <div class="date-picker">
              <DateRangePicker
                v-if="!serviceEvidence"
                v-model="dateRangeEvidenceAfter"
                :locale-data="{
                  format: 'dd/mm/yyyy',
                  daysOfWeek: daysOfWeekSpanish,
                  applyLabel: 'Aplicar',
                  cancelLabel: 'Cancelar',
                }"
                :ranges="false"
                opens="right"
                :single-date-picker="true"
                :append-to-body="false"
                :time-picker="true"
                :time-picker24-hour="true"
                @update="updateEvidenceDate()"
                class="date-range"
                v-c-tooltip="
                  'Indica la fecha y hora en que se tomo la evidencia una vez realizado el servicio'
                "
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  <span v-if="picker.startDate">{{
                    formatToDatePicker(picker.startDate)
                  }}</span>
                  <span v-else>Seleccione fecha</span>
                </template>
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="form-group">
          <div v-if="serviceEvidence && serviceEvidence.ticket">
            <div class="img-card">
              <img
                class="
                            evidence-img
                            figure-img
                            img-fluid
                            d-block
                            mx-auto
                            w-auto
                          "
                :src="
                  serviceEvidence.ticket.photo + `?d=${Date.now()}`
                "
                alt="After"
              />
              <figcaption class="figure-caption text-center">
                Ticket del servicio
                {{ evidenceDateFormat(serviceEvidence.ticket) }}
              </figcaption>
            </div>
            <ImageInput
              ref="ticket"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'ticket')"
              v-c-tooltip="'Foto del ticket del servicio'"
            >
            </ImageInput>
          </div>
          <div v-else class="card-add-evidence">
            <ImageInput
              ref="ticket"
              :width="116"
              :height="48"
              :showPreview="serviceEvidence ? false : true"
              :isRequired="true"
              @format="onInvalidFormat"
              @input="(file) => onEvidenceImageInputChange(file, 'ticket')"
              v-c-tooltip="'Foto del ticket del servicio'"
            >
            </ImageInput>
            <div class="date-picker">
              <DateRangePicker
                v-model="dateRangeEvidenceTicket"
                :locale-data="{
                  format: 'dd/mm/yyyy',
                  daysOfWeek: daysOfWeekSpanish,
                  applyLabel: 'Aplicar',
                  cancelLabel: 'Cancelar',
                }"
                :ranges="false"
                opens="right"
                :single-date-picker="true"
                :append-to-body="false"
                :time-picker="true"
                :time-picker24-hour="true"
                @update="updateEvidenceDate()"
                class="date-range"
                v-c-tooltip="
                  'Indica la fecha y hora en que se tomo la foto del ticket del servicio'
                "
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  <span v-if="picker.startDate">{{
                    formatToDatePicker(picker.startDate)
                  }}</span>
                  <span v-else>Seleccione fecha</span>
                </template>
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <button type="submit" class="btn btn-primary ml-auto">
          Actualizar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'
import ImageInput from '../ImageInput'
import { fileToBase64 } from '../../utils'
import {
  STATUS_SERVICES_TO_EVIDENCE
} from '../../common/enums'
import 'moment-timezone'

const daysOfWeekSpanish = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
export default {
  name: 'ServiceEvidence',
  components: {
    ImageInput,
    DateRangePicker
  },
  data() {
    return {
      dateRangeEvidenceBefore: {
        startDate: moment(),
        endDate: ''
      },
      dateRangeEvidenceAfter: {
        startDate: moment(),
        endDate: ''
      },
      dateRangeEvidenceTicket: {
        startDate: moment(),
        endDate: ''
      },
      daysOfWeekSpanish,
      STATUS_SERVICES_TO_EVIDENCE,
      evidence: {
        after: {
          timestamp: null,
          photo: null,
          range: {
            before: Date.now()
          }
        },
        before: {
          timestamp: null,
          photo: null,
          range: {
            before: Date.now()
          }
        },
        ticket: {
          timestamp: null,
          photo: null,
          range: {
            before: Date.now()
          }
        }
      }
    }
  },
  props: {
    setError: {
      type: Function,
      required: true
    },
    setAlertError: {
      type: Function,
      required: true
    },
    statusId: {
      type: Number,
      required: true
    },
    serviceUuid: {
      type: String,
      required: true
    },
    serviceEvidence: {
      type: Object,
      required: false
    },
    onSuccessEvidenceUpdate: {
      type: Function,
      required: true
    },
    scrollTopError: {
      type: Function,
      required: true
    },
    formatToDatePicker: {
      type: Function,
      required: true
    }
  },
  methods: {
    async updateEvidence() {
      const { serviceUuid, serviceEvidence, evidence } = this
      const { after, before, ticket } = evidence
      let beforeDate = ''
      let afterDate = ''
      let ticketDate = ''
      if (serviceEvidence) {
        beforeDate = serviceEvidence.before.timestamp
        afterDate = serviceEvidence.after.timestamp
        ticketDate = serviceEvidence.ticket.timestamp
      } else {
        const { beforePicker, afterPicker, ticketPicker } = this.updateEvidenceDate()
        beforeDate = beforePicker
        afterDate = afterPicker
        ticketDate = ticketPicker
      }
      try {
        const dataEvidence = {
          before: {
            photo: await fileToBase64(before.photo),
            timestamp: beforeDate
          },
          after: {
            photo: await fileToBase64(after.photo),
            timestamp: afterDate
          },
          ticket: {
            photo: await fileToBase64(ticket.photo),
            timestamp: ticketDate
          }
        }
        await this.axios.put(`services/evidence/${serviceUuid}`, { evidence: dataEvidence })
        this.onSuccessEvidenceUpdate()
        this.alertError = false
        this.dateRangeEvidenceAfter.startDate = ''
        this.dateRangeEvidenceBefore.startDate = ''
        this.dateRangeEvidenceTicket.startDate = ''
      } catch (err) {
        this.alertError = true
        this.scrollTopError()
        this.error = getErrorMessage(err)
      }
    },
    updateEvidenceDate() {
      let Dates = {}
      if (
        this.dateRangeEvidenceBefore.startDate
        && this.dateRangeEvidenceAfter.startDate
        && this.dateRangeEvidenceTicket.startDate
      ) {
        const before = moment(this.dateRangeEvidenceBefore.startDate).format()
        const after = moment(this.dateRangeEvidenceAfter.startDate).format()
        const ticket = moment(this.dateRangeEvidenceTicket.startDate).format()
        Dates = { beforePicker: before, afterPicker: after, ticketPicker: ticket }
      }
      return Dates
    },
    onInvalidFormat() {
      this.alertError = true
      this.error = ERRORS_MESSAGES.INVALID_IMAGE
      this.scrollTopError()
    },
    onEvidenceImageInputChange(file, fileName) {
      if (file) {
        this.alertError = false
        this.error = {}
      }
      this.evidence[fileName].photo = file
    },
    evidenceDateFormat({ timestamp }) {
      let date = ''
      if (!timestamp) {
        return ''
      }
      if (timestamp.includes('T-')) {
        date = new Date(timestamp.replace(/((T-)|(\dZ))/g, ' '))
      } else {
        date = new Date(timestamp.replace(/(\dZ)/g, ''))
      }

      return date.toLocaleString('es-MX')
    }
  }
}
</script>

<style lang="scss" scoped>
.service-evidence {
  .alerts:focus {
    outline: none;
  }
}
</style>
