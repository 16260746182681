<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    size="lg"
  >
    <template v-slot:header>
      <h5 class="modal-title">Editar compañia</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>

    <div role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-1
            variant="primary"
            v-c-tooltip="'Desglosa toda la información referente ala compañia'">Detalles</b-button>
        </b-card-header>
        <b-collapse
          v-model="collpaseOptions.details"
          id="accordion-1"
          accordion="my-accordion"
          role="tabpanel"
          class="p-3">
          <div class="modal-text-wrapper">
            <div tabindex="0" ref="editCompanyFocusAlertError">
              <CAlert
                :color="error.type || 'danger'"
                :closeButton="true"
                :show.sync="alertError">
                {{error.message}}
              </CAlert>
            </div>

            <form
              id="editCompany"
              ref="form"
              v-on:submit.prevent="onSubmit"
              class="my-1">
              <div class="form-group">
                <label for="">Nombre*</label>
                <div class="input-group mb-3">
                  <input
                    class="form-control"
                    required
                    type="text"
                    placeholder="Nombre"
                    v-model="data.name"
                    minlength="3"
                    maxlength="50"
                    v-c-tooltip="'Corresponde al nombre de la empresa/compañía'">
                </div>
              </div>
              <div class="form-group">
                <label for="">Precio por unidad*</label>
                <div class="input-group mb-3">
                  <DecimalInput
                    class="form-control"
                    :required="true"
                    :min="0"
                    :max="9999999.99"
                    placeholder="Precio por unidad"
                    :value="data.pricePerLiter"
                    @input="val => data.pricePerLiter = val"
                    v-c-tooltip="'Corresponde al precio por unidad del producto'"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="">Comisión por unidad*</label>
                <div class="input-group mb-3">
                  <DecimalInput
                    class="form-control"
                    :required="true"
                    :min="0"
                    :max="99.99"
                    placeholder="Comisión por unidad"
                    :value="data.commissionPerLiter"
                    @input="val => data.commissionPerLiter = val"
                    v-c-tooltip="'Corresponde a la comisión por unidad del producto'"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="">Selecciona un estado</label>
                <br>
                <v-select
                  multiple
                  :options="['CDMX','Jalisco', 'México', 'Querétaro', 'Baja California Sur', 'San Luis Potosí', 'Colima', 'Tabasco', 'Guerrero', 'Morelos', 'Veracruz', 'Hidalgo', 'Teoloyucan', 'Nicolás Romero', 'Nicolás Romero', 'Ecatepec', 'Valle de Bravo', 'Toluca']"
                  label="title"
                  v-model="data.state">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
              <div class="form-group">
                <label for="">Logo</label>
                <img
                  v-if="data.logoUrl"
                  class="img-thumbnail my-1 mx-auto d-block"
                  :src="data.logoUrl ? data.logoUrl : null"
                >
                <ImageInput
                  ref="imageInput"
                  :width="116"
                  :height="48"
                  @input="onImageInput"
                  @format="onInvalidFormat"
                  @change="imageUpdated"
                  v-c-tooltip="'Corresponde al logo de la compañía'">
                </ImageInput>
                <small class="form-text text-muted">Dimensiones 116x48 px</small>
              </div>
              <form id="addCompanyServiceTypeForm" ref="form" v-on:submit.prevent="addCompanyServiceType">
                <div class="form-group">
                  <div class="d-flex mb-2">
                    <label for="serviceTypes" class="my-auto">Tipo de servicio</label>
                    <div
                      v-c-tooltip="'Corresponde al tipo de servicio proporcionado por la compañía'"
                      class="tooltip-btn d-flex">
                      <span class="m-auto">?</span>
                    </div>
                  </div>
                  <select
                    class="form-control"
                    name="serviceTypes"
                    v-model="data.typeServiceId"
                    required
                  >
                    <option :value="null">Selecciona una opción</option>
                    <option
                      v-for="item in serviceTypes"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <br />
                  <div class="text-right">
                    <button
                      type="button"
                      class="btn btn-secondary mr-1"
                      v-c-tooltip="
                        'Limpiar Tipo de Servicio'
                      "
                      @click="clearTypeService"
                    >
                      Limpiar
                    </button>
                    <button

                      type="submit"
                      class="btn btn-primary ml-1"
                      v-c-tooltip="
                        'Agregar Tipo de Servicio'
                      "
                    >
                      Agregar
                    </button>
                  </div>
                  <br />
                  <div>
                    <CDataTable
                      :items="data.companyServiceTypes"
                      :fields="companyServiceTypesFields"
                      :sorter="false"
                      :clickableRows="true"
                      :noItemsView="noItemsView"
                      hover
                      class="main-table"
                    >
                      <template #delete="{item, index}">
                        <td class="py-2">
                          <div class="d-flex">
                            <button
                              type="button"
                              class="btn btn-outline-primary btn-sm"
                              @click="deleteCompanyServiceType(index)"
                            >
                              Eliminar
                            </button>
                            <b-tooltip
                              :target="`detail_${item.registryUuid}`"
                              placement="left"
                            >
                              Eliminar Tipo de Servicio
                            </b-tooltip>
                          </div>
                        </td>
                      </template>
                    </CDataTable>
                  </div>
                </div>
              </form>
              <div class="form-group">
                <div class="d-flex mb-2">
                  <label for="serviceTypes" class="my-auto">Tipo de esquema</label>
                  <div
                    v-c-tooltip="'Corresponde al tipo de esquema convenido con la compañía'"
                    class="tooltip-btn d-flex">
                    <span class="m-auto">?</span>
                  </div>
                </div>
                <select
                  class="form-control"
                  name="serviceTypes"
                  v-model="workingSchemaId"
                  required
                >
                  <option :value="0" disabled>Selecciona una opción</option>
                  <option
                    v-for="item in schemas"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="schemaSelected"
                class="form-group">
                <div class="d-flex mb-2">
                  <span><strong>Comisión por unidad: </strong>
                    {{ schemaSelected.commissionPerLiter }}</span>
                </div>
                <div class="d-flex mb-2">
                  <span><strong>Comisión por procesador de pagos: </strong>
                    {{ schemaSelected.paymentProcessorCommission }}</span>
                </div>
              </div>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-fiscal
                    variant="primary"
                    v-c-tooltip="'Desglosa toda la información referente a Datos Físcales'">Datos Físcales</b-button>
                </b-card-header>
                <b-collapse
                  v-model="collpaseOptions.fiscalSchema"
                  id="accordion-fiscal"
                  accordion="fiscal-accordion"
                  role="tabpanel"
                  class="p-3">
                  <div class="form-group">
                    <label for="">Esquema Físcal</label>
                    <br>
                    <select
                      class="form-control"
                      name="fiscalSchema"
                      v-model="data.fiscalSchemaId"
                    >
                      <option :value="0">Selecciona una opción</option>
                      <option
                        v-for="item in fiscalSchema"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="">Razón Social</label>
                    <div class="input-group mb-3">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Razón Social"
                        v-model="data.businessName"
                        v-c-tooltip="'Corresponde a la razón social de la empresa/compañía'">
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">RFC</label>
                    <div class="input-group mb-3">
                      <input
                        pattern="([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])"
                        class="form-control"
                        type="text"
                        placeholder="RFC"
                        v-model="data.rfc"
                        v-c-tooltip="'Corresponde al RFC de la empresa/compañía'">
                    </div>
                  </div>
                </b-collapse>
              </b-card>

              <button
                type="button"
                class="btn btn-primary btn-block"
                @click="goToProviderList(item)"
                v-c-tooltip="'Redirecciona a la vista del listado de colaboradores filtrados por compañía'">Listado de colaboradores</button>
            </form>

            <CSpinner v-if="isRequesting" color="warming" size="sm"/>
            <div v-else class="text-right">
              <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
              <button
                type="submit"
                form="editCompany"
                class="btn btn-primary ml-1"
                v-c-tooltip="'Guarda los cambios realizados en los datos de la compañía'"
              >
                Aceptar
              </button>
            </div>

          </div>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-2
            variant="primary"
            v-c-tooltip="'Crea/Edita el coverage de la compañia'">{{ hasCoverage  ? 'Editar' : 'Crear' }} polígono</b-button>
        </b-card-header>
        <b-collapse
          v-model="collpaseOptions.coverage"
          v-if="(data && data.registryUuid)"
          id="accordion-2"
          accordion="my-accordion"
          role="tabpanel"
          class="p-3">
          <div class="modal-text-wrapper">

            <div tabindex="0" ref="editCompanyFocusAlertError">
              <CAlert
                :color="error.type || 'danger'"
                :closeButton="true"
                :show.sync="alertError">
                {{error.message}}
              </CAlert>
            </div>

            <div v-if="collpaseOptions.coverage">
              <div v-if="hasCoverage">
                <div class="form-group">
                  <div class="d-flex mb-2">
                    <label class="my-auto">Cobertura</label>
                    <div
                      v-c-tooltip="'Corresponde a la cobertura a editar'"
                      class="tooltip-btn d-flex">
                      <span class="m-auto">?</span>
                    </div>
                  </div>
                  <select
                    class="form-control"
                    v-model="selectedCoverage"
                    name="coverage"
                    v-if="data.coverages.length > 1">
                    <option :key="i" :value="i" v-for="(x, i) in data.coverages">{{ i + 1 }}</option>
                  </select>
                </div>

                <center>
                  <EditCoverage ref="geoEditor" class="mb-4" :registryUuid="coverageUuid"></EditCoverage>
                </center>
                <CSpinner v-if="isRequesting" color="warming" size="sm"/>
                <div v-else class="text-center my-3">
                  <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
                  <button type="button" class="btn btn-danger mx-1" @click="deleteCoverage">Eliminar</button>
                  <button
                    type="button"
                    class="btn btn-primary ml-1"
                    @click="updateCoverage"
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
              <div v-else>
                <center>
                  <CreateCoverage ref="geoEditor" :companyUuid="data.registryUuid"></CreateCoverage>
                </center>
                <CSpinner v-if="isRequesting" color="warming" size="sm"/>
                <div v-else class="text-center my-3">
                  <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
                  <button
                    type="button"
                    class="btn btn-primary ml-1"
                    @click="createCoverage"
                  >
                    Crear
                  </button>
                </div>
              </div>
            </div>

          </div>
        </b-collapse>
      </b-card>
    </div>

    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div v-else>
        <button
          type="button"
          @click="onCancel"
          class="btn btn-primary ml-1"
        >Salir</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import vSelect from 'vue-select'
import ImageInput from '../ImageInput'
import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'
import { fileToBase64 } from '../../utils'
import 'vue-select/dist/vue-select.css'
import DecimalInput from '../DecimalInput'
import CreateCoverage from '../admin/CreateCoverage'
import EditCoverage from '../admin/EditCoverage'

const companyServiceTypesFields = [
  { key: 'serviceTypeName', label: 'Tipo de Servicio', sorter: true },
  {
    key: 'delete',
    label: 'Eliminar',
    _style: 'width:10%',
    sorter: false
  }
]

export default {
  name: 'EditCompanyModal',
  components: {
    ImageInput,
    vSelect,
    CreateCoverage,
    EditCoverage,
    DecimalInput
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    registryUuid: {
      type: String,
      required: false,
      default: null
    },
    schemas: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    noItemsView: {
      noResults: 'Registros no encontrados.',
      noItems: 'Registros no encontrados.'
    },
    data: {
      companyServiceTypes: []
    },
    companyServiceTypesDeleted: [],
    companyServiceTypesFields,
    selectedCoverage: 0,
    workingSchemaId: '0',
    schemaSelected: null,
    fiscalSchema: [],
    serviceTypes: [],
    isRequesting: false,
    logo: null,
    error: {},
    alertError: false,
    selected: false,
    collpaseOptions: {
      coverage: false,
      details: false,
      fiscalSchema: false
    }
  }),
  watch: {
    async registryUuid(value) {
      if (value) {
        await this.fetchCompany()
      }
    },
    async isModalActive(value) {
      if (value) {
        await this.getServicesTypes()
        this.getFiscalSchemas()
        this.$refs.form.reset()
        this.$refs.imageInput.reset()
        this.alertError = false
      }
    },
    data() {
      this.error = {}
    },
    'data.state': function () {
      this.selected = false
      if (this.data.state && this.data.state.length > 0) {
        this.selected = true
      }
    },
    workingSchemaId() {
      this.schemaSelected = this.workingSchemaId
        ? this.findSchema(this.workingSchemaId) : null
    }
  },
  computed: {
    hasCoverage() {
      return this.data.coverages && this.data.coverages.length
    },
    coverageUuid() {
      if (this.data.coverages?.length != null) {
        return this.data.coverages[this.selectedCoverage]?.registryUuid
      }
      return null
    },
    coveragesUuids() {
      if (this.data.coverages?.length > 1) {
        return this.data.coverages[0].registryUuid
      }
      return null
    }
  },
  methods: {
    deleteCompanyServiceType(index) {
      const itemDeleted = this.data.companyServiceTypes[index]
      if (!itemDeleted) {
        return
      }
      if (itemDeleted.registryUuid && !itemDeleted.isNew) {
        this.companyServiceTypesDeleted.push({
          ...itemDeleted,
          deletedAt: true
        })
      }
      this.data.companyServiceTypes.splice(index, 1)
    },
    addCompanyServiceType() {
      this.error = {}
      this.alertError = false
      const typeService = this.serviceTypes.find((obj) => +obj.id === +this.data.typeServiceId)
      if (!typeService) {
        return
      }
      if (this.data.companyServiceTypes.find(
        obj => obj.serviceTypeId === this.data.typeServiceId
      )) {
        return
      }
      const companyTypeService = {
        serviceTypeId: this.data.typeServiceId,
        serviceTypeName: typeService.name,
        isNew: true
      }

      this.data.companyServiceTypes.push(companyTypeService)
      this.clearTypeService()
    },
    clearTypeService() {
      this.data.typeServiceId = 1
    },
    async getFiscalSchemas() {
      try {
        const { data } = await this.axios.get('/businessModel/fiscalSchema')
        this.fiscalSchema = data
      } catch (err) {
        this.error = getErrorMessage(err)
        this.$emit('onErrorInitial', err)
      }
    },
    findSchema(schemaId) {
      if (this.schemas.length) {
        return this.schemas.find((element) => element.id === schemaId)
      }
      return null
    },
    onImageInput(file) {
      if (file) {
        this.error = {}
        this.alertError = false
      }
      this.logo = file
      this.imageUpdated()
    },
    resetData() {
      this.data = {}
      this.error = {}
      this.logo = null
      this.$refs.imageInput.reset()
    },
    setCompany(company) {
      this.data = company
      if (this.data.logoUrl === '') {
        this.data.logoUrl = null
      }
      this.workingSchemaId = '0'
      if (this.data.working_schema) {
        this.schemaSelected = this.data.working_schema
        this.workingSchemaId = this.data.working_schema.id
      }
      this.data.fiscalSchemaId = this.data.fiscalSchemaId || 0
      this.data.commissionPerLiter = this.data.commissionPerLiter.toString()

      this.data.companyServiceTypes = this.data.companyServiceTypes.map((companyService) => {
        const serviceTypeName =
          companyService.service_type ? companyService.service_type.description : 'Desconocido'

        return {
          ...companyService,
          serviceTypeName
        }
      })
    },
    async getData() {
      const { logo } = this
      const data = {
        ...this.data,
        typeServiceId: this.data.companyServiceTypes[0].serviceTypeId,
        fiscalSchemaId: +this.data.fiscalSchemaId === 0 ? null : +this.data.fiscalSchemaId,
        workingSchemaId: +this.workingSchemaId,
        companyServiceTypes: [...this.data.companyServiceTypes, ...this.companyServiceTypesDeleted]
      }
      if (logo) {
        data.logo = await fileToBase64(logo)
      }
      return data
    },
    onCancel() {
      this.$emit('onClose')
      this.resetData()
      this.selectedCoverage = 0
    },
    onSubmit() {
      if (!this.data.companyServiceTypes || this.data.companyServiceTypes.length == 0) {
        this.error = ERRORS_MESSAGES.SERVICE_REQUIRED
        this.alertError = true
        this.scrollTop()
        return
      }

      this.updateCompany()
    },
    onInvalidFormat() {
      this.alertError = true
      this.scrollTop()
      this.error = ERRORS_MESSAGES.INVALID_IMAGE
    },
    async fetchCompany() {
      this.isRequesting = true

      this.collpaseOptions.coverage = false
      this.collpaseOptions.details = true

      try {
        const { data } = await this.axios.get(`/providers/companies/${this.registryUuid}`)
        this.setCompany(data)
        this.isRequesting = false
      } catch (err) {
        this.error = getErrorMessage(err)
        this.$emit('onErrorInitial', err)
      } finally {
        this.isRequesting = false
        setTimeout(() => {
          if (this.$refs.geoEditor) {
            this.$refs.geoEditor.clearMap()
            this.$refs.geoEditor.invalidateSize()
          }
        }, 1000)
      }
    },
    async updateCompany() {
      this.isRequesting = true
      this.error = {}

      try {
        const data = await this.getData()
        await this.axios.put(`/providers/companies/${this.registryUuid}`, data)
        this.alertError = false
        this.errorMessage = null
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.scrollTop()
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    },
    goToProviderList() {
      const { registryUuid } = this
      this.$router.push({
        path: `providers?companyUuid=${registryUuid}`
      })
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.editCompanyFocusAlertError.focus()
      })
    },
    imageUpdated() {
      if (this.logo) {
        this.data.logoUrl = ''
      }
    },
    async createCoverage() {
      const geojson = this.$refs.geoEditor.toGeoJSON()

      try {
        await this.$axios.post('/admin/providers/companies/coverages', {
          companyUuid: this.data.registryUuid,
          coordinates: geojson
        })
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.scrollTop()
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      }
    },
    async updateCoverage() {
      const geojson = this.$refs.geoEditor.toGeoJSON()

      try {
        await this.$axios.put(`/admin/providers/companies/coverages/${this.coverageUuid}`, {
          companyUuid: this.data.registryUuid,
          coordinates: geojson
        })
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.scrollTop()
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      }
    },
    async deleteCoverage() {
      try {
        await this.$axios.delete(`/admin/providers/companies/coverages/${this.coverageUuid}`)
        this.$emit('onSuccess')
      } catch (err) {
        this.alertError = true
        this.scrollTop()
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      }
    },
    async getServicesTypes() {
      this.isRequesting = true
      this.alertError = false
      try {
        const params = {
          includeCategories: false,
          attributes: ['id', 'description']
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = data.map(({ id, description: name }) => ({ id, name }))
      } catch (err) {
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      } finally {
        this.isRequesting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.tooltip-btn{
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 4px;
  margin-left: 4px;

  background-color: #3c4b64;
  color: #FFF;
  &:hover{
    cursor: pointer;
  }
}
</style>
