import router from '../router'
import { getAuthToken, clearAuth } from './auth'
import { getErrorCodeAPI } from './index'

function loginFallback() {
  clearAuth(false)
  return router.push({ name: 'Login' })
}

export function jwtInterceptor(instance, getToken = getAuthToken) {
  instance.interceptors.request.use((config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })
}

export function jwtVerifyInterceptor(instance, fallback = loginFallback) {
  instance.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      const routerName = router.currentRoute
        ? router.currentRoute.name
        : null

      if (!['Login'].includes(routerName)) {
        fallback()
      }
    }
    return config
  })
}

export function unauthorizedInterceptor(instance, fallback = loginFallback) {
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      const codeAPI = getErrorCodeAPI(err)
      if (['EA0008'].includes(codeAPI)) {
        fallback()
      }
      return Promise.reject(err)
    }
  )
  return instance
}

export const interceptors = [jwtVerifyInterceptor, jwtInterceptor, unauthorizedInterceptor]

export default function setupInterceptors(instance) {
  interceptors.forEach((interceptor) => interceptor(instance))
  return instance
}
