<template>
  <div id="json-editor" ref="editor" class="w-100"></div>
</template>

<script>
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import isEqual from 'lodash.isequal'

export default {
  props: {
    name: {
      type: String,
      required: false,
      defaut: () => ''
    },
    schema: {
      type: Object,
      required: false,
      default: () => null
    },
    schemaRefs: {
      type: Object,
      required: false,
      default: () => null
    },
    schemaURL: {
      type: String,
      required: false,
      default: () => ''
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        mode: 'code',
        modes: ['code'] // -H
      })
    },
    data: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: () => ({
    isReady: false,
    editorData: {},
    jsonEditor: null
  }),
  watch: {
    data() {
      if (this.isReady) {
        const jsonEditorData = this.getEditorData()
        if (!isEqual(this.data, jsonEditorData)) {
          this.updateEditorData()
        }
      }
    },
    isReady() {
      this.updateEditorData()
    }
  },
  mounted() {
    const options = {
      ...this.options,
      name: this.name,
      onChange: () => this.jsonEditor.validate(),
      onValidationError: (errors) => this.onValidate(errors)
    }

    if (this.schema) {
      options.schema = this.schema
      if (this.schamaRefs) {
        options.schamaRefs = this.schamaRefs
      }
      this.onReady(options)
    } else if (this.schemaURL) {
      this.$http.get(this.schemaURL)
        .then(({ data }) => {
          options.schema = data
          this.onReady(options)
        }).catch((err) => {
          this.$emit('error', err)
        })
    } else {
      this.onReady(options)
    }
  },
  methods: {
    getData() {
      return this.jsonEditor.get()
    },
    onReady(options) {
      this.jsonEditor = new JSONEditor(
        this.$refs.editor,
        options,
        this.data
      )
      this.isReady = true
    },
    setEditorData(data) {
      this.jsonEditor.set(data)
    },
    updateEditorData() {
      this.jsonEditor.set(this.data)
    },
    getEditorData() {
      try {
        const data = this.jsonEditor.get()
        return data
      } catch (err) {
        return null
      }
    },
    onChange(json) {
      this.$emit('change', json)
    },
    onValidate(errors) {
      if (this.isReady) {
        const isValid = !errors.length
        this.$emit('validate', isValid)
        if (isValid) {
          this.onChange(this.getEditorData())
        }
      }
    }
  }
}
</script>

<style>
a.jsoneditor-poweredBy {
  display: none;
}
#json-editor {
  height: 500px;
  min-height: 500px;
}
</style>
