<template>
  <div class="kpi-providers">
    <div class="card">
      <div class="card-header">
        <div>
          <label for="">KPI por</label>
          <div
            class="tabs-selector my-auto"
            v-c-tooltip="'Indica si el colaborador está activo o inactivo (Restringe acceso a la aplicación)'">
            <button
              @click.prevent="changeType('monthly')"
              :class="options.type === 'monthly' ? 'active' : ''">
              Mes
            </button>
            <button
              @click="changeType('weekly')"
              :class="options.type === 'weekly' ? 'active' : ''">
              Semana
            </button>
          </div>
        </div>
        <div>
          <label
            class="my-auto"
            v-if="options.type === 'monthly'">Mes</label>
          <label
            class="my-auto"
            v-if="options.type === 'weekly'">Semana</label>
          <select
            class="form-control"
            @change="getData"
            v-model="options.value">
            <option v-for="option in values" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="card-body">
        <div class="tables-wrapper">
          <div>
            <h3>Calificaciones</h3>
            <div>
              <label for="">Mejor repartidor</label>
              <CDataTable
                :items="itemsBestScore"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
            <div>
              <label for="">Peor repartidor</label>
              <CDataTable
                :items="itemsWorstScore"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
          </div>
          <div>
            <h3>Servicios atendidos</h3>
            <div>
              <label for="">Mejor repartidor</label>
              <CDataTable
                :items="itemsBestAttendedServices"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
            <div>
              <label for="">Peor repartidor</label>
              <CDataTable
                :items="itemsWorsAttendedServices"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
          </div>
          <div>
            <h3>Unidades vendidas</h3>
            <div>
              <label for="">Mejor repartidor</label>
              <CDataTable
                :items="itemsBestLitersSold"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
            <div>
              <label for="">Peor repartidor</label>
              <CDataTable
                :items="itemsWorstLitersSold"
                :fields="fields"
                :sorter="false"
                :clickableRows="true"
                :noItemsView="noItemsView"
                class="main-table">
                <template #shortName="{item}">
                  <td>{{ avoidUndefined(item.shortName) }}</td>
                </template>
                <template #scoreAverage="{item}">
                  <td>{{ avoidNullValue(item.scoreAverage) }}</td>
                </template>
                <template #totalLitersSold="{item}">
                  <td>{{ twoDecimals(item.totalLitersSold) }}</td>
                </template>
              </CDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import 'moment-timezone'

import queryparser from '../utils/queryparser'
import { getSelectOptions } from '../utils/date'

const fields = [
  { key: 'shortName', label: 'Nombre' },
  { key: 'scoreAverage', label: 'Calificación' },
  { key: 'totalAttendedServices', label: 'Servicios atendidos' },
  { key: 'totalLitersSold', label: 'Unidades vendidas' }
]

export default {
  name: 'KPIProviders',
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      fields,
      itemsBestScore: [],
      itemsWorstScore: [],
      itemsBestAttendedServices: [],
      itemsWorsAttendedServices: [],
      itemsBestLitersSold: [],
      itemsWorstLitersSold: [],
      options: {
        type: 'monthly',
        param: 'month',
        value: moment().month() + 1,
        year: moment().year()
      },
      year: moment().year(),
      typeValues: {
        monthly: [],
        weekly: []
      }
    }
  },
  computed: {
    values() {
      const { type } = this.options
      const isWeekly = type === 'weekly'
      return isWeekly ? this.typeValues.weekly : this.typeValues.monthly
    }
  },
  watch: {
    'options.type': function () {
      this.getData()
    },
    'options.year': function () {
      this.getData()
    }
  },
  mounted() {
    this.setSelectOptions()
    this.getData()
  },
  methods: {
    setSelectOptions() {
      const { weeks, months } = getSelectOptions(this.options.year, { baseYear: this.year })
      this.typeValues.weekly = weeks
      this.typeValues.monthly = months
      this.changeType(this.options.type)
    },
    changeType(type) {
      const isWeekly = type === 'weekly'
      const date = moment().set({ year: this.options.year })
      this.options.type = isWeekly ? 'weekly' : 'monthly'
      this.options.param = isWeekly ? 'week' : 'month'

      if (moment().year() === this.options.year) {
        this.options.value = isWeekly ? date.isoWeek() : date.month() + 1
      }
    },
    getData() {
      const {
        type, param, value, year
      } = this.options
      const query = queryparser.parse({ type, [param]: value, year })

      this.axios.get(`providers/report?${query}`)
        .then((response) => {
          const { data } = response
          const bestScore = data.scoreAverage
          const attendesServices = data.attendedServices
          const litersSold = data.totalLitersSold
          this.resetData()
          // Filling tables
          // best score
          Object.keys(bestScore.best).forEach((key) => {
            this.itemsBestScore.push({
              shortName: bestScore.best[key].shortName,
              scoreAverage: bestScore.best[key].scoreAverage,
              totalAttendedServices: bestScore.best[key].totalAttendedServices,
              totalLitersSold: bestScore.best[key].totalLitersSold
            })
          })
          // worst score
          Object.keys(bestScore.worst).forEach((key) => {
            this.itemsWorstScore.push({
              shortName: bestScore.worst[key].shortName,
              scoreAverage: bestScore.worst[key].scoreAverage,
              totalAttendedServices: bestScore.worst[key].totalAttendedServices,
              totalLitersSold: bestScore.worst[key].totalLitersSold
            })
          })
          // best attended services
          Object.keys(attendesServices.best).forEach((key) => {
            this.itemsBestAttendedServices.push({
              shortName: attendesServices.best[key].shortName,
              scoreAverage: attendesServices.best[key].scoreAverage,
              totalAttendedServices: attendesServices.best[key].totalAttendedServices,
              totalLitersSold: attendesServices.best[key].totalLitersSold
            })
          })
          // worst attended services
          Object.keys(attendesServices.worst).forEach((key) => {
            this.itemsWorsAttendedServices.push({
              shortName: attendesServices.worst[key].shortName,
              scoreAverage: attendesServices.worst[key].scoreAverage,
              totalAttendedServices: attendesServices.worst[key].totalAttendedServices,
              totalLitersSold: attendesServices.worst[key].totalLitersSold
            })
          })
          // best liters sold
          Object.keys(litersSold.best).forEach((key) => {
            this.itemsBestLitersSold.push({
              shortName: litersSold.best[key].shortName,
              scoreAverage: litersSold.best[key].scoreAverage,
              totalAttendedServices: litersSold.best[key].totalAttendedServices,
              totalLitersSold: litersSold.best[key].totalLitersSold
            })
          })
          // worst liters sold
          Object.keys(litersSold.worst).forEach((key) => {
            this.itemsWorstLitersSold.push({
              shortName: litersSold.worst[key].shortName,
              scoreAverage: litersSold.worst[key].scoreAverage,
              totalAttendedServices: litersSold.worst[key].totalAttendedServices,
              totalLitersSold: litersSold.worst[key].totalLitersSold
            })
          })
        })
        .catch(() => {
          this.resetData()
        })
    },
    // formats
    twoDecimals(value) {
      return value.toFixed(2)
    },
    avoidNullValue(value) {
      if (!value) {
        return 0
      }
      return value
    },
    avoidUndefined(value) {
      if (!value) {
        return 'Sin nombre'
      }
      return value
    },
    resetData() {
      this.itemsBestScore = []
      this.itemsWorstScore = []
      this.itemsBestAttendedServices = []
      this.itemsWorsAttendedServices = []
      this.itemsBestLitersSold = []
      this.itemsWorstLitersSold = []
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header{
  display: flex;
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 64px;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
  >div{
    display: flex;
    label{
      margin: auto 0;
    }
    div{
      margin-left: 8px;
    }
    select{
      margin: auto 0 auto 8px;
    }
    &:last-child{
      margin-left: 16px;
    }
  }
}
.card-body{
  .tables-wrapper{
    h3{
      font-size: 18px;
      font-weight: bold;
      color: #616161;
    }
    label{
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
</style>
