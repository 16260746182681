<template>
  <div class="admins">
    <div tabindex="0" ref="successAdmin">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <button
        type="button"
        @click="openCreateAdmin"
        class="btn-primary add-button"
        v-c-tooltip="'Abre el modal para dar de alta un nuevo administrador'">+</button>
      <div class="card-header">
        <h3>Listado de administradores</h3>
        <p>Total de administradores: <strong>{{pagination.totalRecords}}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :clickableRows="true"
          @row-clicked="onRowClicked"
          :noItemsView="noItemsView"
          hover
          class="main-table"
        >
          <template #over-table>
            <div class="filter-section">
              <label for="">Tipo de administrador</label>
              <div class="tabs-selector">
                <button
                  @click="isGeneralAdmin = null"
                  :class="isGeneralAdmin === null ? 'active' : ''">Todos</button>
                <button
                  @click="isGeneralAdmin = 'admin'"
                  :class="isGeneralAdmin === 'admin' ? 'active' : ''">General</button>
                <button
                  @click="isGeneralAdmin = 'company'"
                  :class="isGeneralAdmin === 'company' ? 'active' : ''">Compañía</button>
              </div>
            </div>
          </template>
          <template #show_details="{item}">
            <td class="py-2">
              <CButton
                :id="`detail_${item.id}`"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="onRowClicked(item)"
              >
                {{ Boolean(item._toggled) ? 'Hide' : 'Detalle' }}
              </CButton>
              <b-tooltip :target="`detail_${item.id}`" placement="left">
                Despliega el modal de detalle/edición de la información del administrador
              </b-tooltip>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="pagination.page"
          :pages="pagination.pages"
          align="center"
        />
        <CreateAdmin
          :isModalActive="createAdmin.isActive"
          @onSuccess="onSuccessCreateAdmin"
          @onClose="closeCreateAdmin"
        />
        <EditAdmin
          :isModalActive="modalEditAdminOpened"
          :dataAdmin="editAdmin"
          @onSuccess="onSuccessEditAdmin"
          @onClose="closeEditAdmin"
        />
      </div>
      <div class="card-footer">
        <p>Total de servicios: <strong>{{ pagination.totalRecords }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
import queryparser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import CreateAdmin from '../components/admin/CreateAdmin'
import EditAdmin from '../components/admin/EditAdmin'

const fields = [
  { key: 'name', label: 'Nombre' },
  { key: 'email', label: 'Correo electrónico' },
  { key: 'show_details', label: 'Acción', _style: 'width:1%' }
]
export default {
  name: 'Admins',
  components: {
    CreateAdmin,
    EditAdmin
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      fields,
      items: [],
      pagination: {
        page: 1,
        pages: 0,
        totalRecords: 0
      },
      success: {
        hasSuccess: false,
        message: ''
      },
      error: {},
      createAdmin: {
        isActive: false
      },
      editAdmin: {
        uuid: '',
        name: '',
        email: ''
      },
      modalEditAdminOpened: false,
      isGeneralAdmin: null
    }
  },
  watch: {
    'pagination.page': function () {
      this.getAdmins()
    },
    isGeneralAdmin() {
      this.getAdmins()
    }
  },
  mounted() {
    this.getAdmins()
  },
  methods: {
    onRowClicked(item) {
      return item ? this.openEditAdmin(item) : this.openCreateAdmin()
    },
    filters() {
      const options = {
        page: this.pagination.page
      }
      if (this.isGeneralAdmin) {
        options.type = this.isGeneralAdmin
      }

      return options
    },
    getAdmins() {
      const queryString = queryparser.parse(this.filters())
      this.axios.get(`users?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data.map((item, id) => ({ ...item, id }))
          this.pagination.pages = data.pagination.totalPages
          this.pagination.totalRecords = data.pagination.total
        })
        .catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    openCreateAdmin() {
      this.createAdmin.isActive = true
    },
    closeCreateAdmin() {
      this.createAdmin.isActive = false
    },
    openEditAdmin(item) {
      this.modalEditAdminOpened = true
      this.editAdmin = item
    },
    closeEditAdmin() {
      this.modalEditAdminOpened = false
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    onSuccessCreateAdmin() {
      this.getAdmins()
      this.closeCreateAdmin()
      this.setSuccessMessage('Administrador creado con éxito')
      this.scrollTopSuccess()
    },
    onSuccessEditAdmin() {
      this.getAdmins()
      this.closeEditAdmin()
      this.setSuccessMessage('Administrador actualizado con éxito')
      this.scrollTopSuccess()
    },
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successAdmin.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admins{
  .add-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .card{
      .card-header,
      .card-footer{
          display: flex;
          p{
          margin: auto 0 auto auto;
          }
      }
      .card-body{
          .filter-by-name{
            margin-bottom: 8px;
            @media(min-width: 992px){
              display: flex;
            }
            >div{
              &:first-child{
                margin-bottom: 8px;
                @media(min-width: 992px){
                  margin-bottom: 0;
                }
                input{
                  width: 100%;
                  height: 35px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0 16px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                }
              }
              &:last-child{
                @media(min-width: 992px){
                  display: flex;
                  margin-left: 8px;
                }
                button{
                  width: calc(50% - 4px);
                  @media(min-width: 992px){
                    width: auto;
                  }
                  &:last-child{
                    margin-left: 8px;
                  }
                }
              }
            }
          }
      }
  }
  .filter-section{
    width: 40%;
    margin-bottom: 16px;
  }
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 100%;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
}
</style>
