<template>
  <div class="companies">
    <div tabindex="0" ref="successCompanies">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div>
        <button
          id="add_company"
          type="button"
          @click="openCreateModal"
          class="btn-primary add-company-button"
        >+
        </button>
        <b-tooltip target="add_company" placement="left">
          Abre el modal para dar de alta una nueva compañía
        </b-tooltip>
      </div>

      <div class="d-flex justify-content-between px-3 pt-3">
        <h3>Listado de compañías</h3>
        <p>Total de compañías: <strong>{{ pagination.totalRecords }}</strong></p>
      </div>
      <hr>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="{ external: true }"
          :sorterValue.sync="sort"
          :noItemsView="noItemsView"
          hover
        >
          <template #over-table>
            <div class="filter-by-name">
              <div>
                <input
                  v-model.trim="openFilterInput"
                  type="text"
                  placeholder="Búsqueda por nombre"
                  :disabled="!filterFieldSelected"
                  v-c-tooltip="'Búsqueda de compañías por nombre'"
                  @keypress.enter="search()"
                >
              </div>
              <div>
                <button
                  class="btn btn-primary"
                  @click="search()"
                  v-c-tooltip="'Ejecuta la acción de búsqueda de lo ingresado en el campo'"
                  :disabled="!filterFieldSelected || isRequesting"
                >
                  Buscar
                </button>
                <button
                  class="btn btn-light ml-2"
                  @click="resetFilters()"
                  v-c-tooltip="'Limpia el filtro de búsqueda por nombre'"
                >
                  Limpiar
                </button>
              </div>
            </div>
            <br>
            <!-- Select filters -->
            <div class="select-filters-wrapper">
              <div class="select-filter">
                <label for="">Búsqueda por estado</label>
                <v-select
                  multiple
                  :options="['CDMX','Jalisco', 'México', 'Querétaro', 'Baja California Sur', 'San Luis Potosí', 'Colima', 'Tabasco', 'Guerrero', 'Morelos', 'Veracruz', 'Hidalgo', 'Teoloyucan', 'Nicolás Romero', 'Nicolás Romero', 'Ecatepec', 'Valle de Bravo', 'Toluca']"
                  label="title"
                  v-model="states">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
            </div>
            <br>
            <div class="filter-section">
              <label for="">Filtro por estatus</label>
              <div class="tabs-selector">
                <button
                  @click="isActiveCompany = null"
                  :class="isActiveCompany === null ? 'active' : ''">Todos</button>
                <button
                  @click="isActiveCompany = true"
                  :class="isActiveCompany === true ? 'active' : ''">Activados</button>
                <button
                  @click="isActiveCompany = false"
                  :class="isActiveCompany === false ? 'active' : ''">Desactivados</button>
              </div>
            </div>
            <!-- Select filters -->
            <br>
          </template>
          <!-- table data -->
          <template #shortName="{item}">
            <td  :class="evidenceFormat(item.evidence, item.statusId)" v-if="item.shortName">
              {{ item.shortName }}
            </td>
            <td v-else>
              Sin nombre
            </td>
          </template>
          <template #company="{item}">
            <td v-if="item.company.name">
              {{ item.company.name }}
            </td>
            <td v-else>
              Sin compañía
            </td>
          </template>
          <template #price="{item}">
            <td>
              {{ item.company.pricePerLiter }}
            </td>
          </template>
          <template #state="{item}">
            <td>
              {{ stateFormat(item) }}
            </td>
          </template>
          <template #active="{item}">
            <td>
              <b-form-checkbox
                v-model="item.active"
                name="check-button"
                @change="activeCompany(item)"
                switch>
                {{item.active ? 'Activado' : 'Desactivado'}}
              </b-form-checkbox>
            </td>
          </template>
          <template #show_details="{item}">
            <td class="py-2">
              <div class="d-flex">
                <button
                  :id="`detail_${item.id}`"
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  @click="openEditModal(item)"
                >
                  {{ item._toggled ? 'Hide' : 'Detalle' }}
                </button>
                <b-tooltip :target="`detail_${item.id}`" placement="left">
                  Despliega el modal de detalle/edición de la información de compañía
                </b-tooltip>
              </div>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="pagination.page"
          :pages="pagination.pages"
          align="center"
        />

        <CreateCompany
          :isModalActive="createModal.isActive"
          :schemas="schemas"
          @onClose="closeCreateModal"
          @onSuccess="onSuccessCreation"
        />

        <EditCompany
          :isModalActive="editModal.isActive"
          :registryUuid="registryUuid"
          :schemas="schemas"
          @onErrorInital="closeEditModal"
          @onClose="closeEditModal"
          @onSuccess="onSuccessUpdate"
        />
      </div>
      <div class="card-footer d-flex">
        <p class="my-auto ml-auto">Total de compañías: <strong>{{ pagination.totalRecords }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import queryparser from '../utils/queryparser'
import EditCompany from '../components/company/EditCompany'
import CreateCompany from '../components/company/CreateCompany'
import 'vue-select/dist/vue-select.css'
import { getErrorMessage } from '../common/errors'

const fields = [
  {
    key: 'name',
    label: 'Nombre',
    sorter: true
  },
  {
    key: 'pricePerLiter',
    label: 'Precio por unidad',
    sorter: true
  },
  {
    key: 'state',
    label: 'Estado',
    sorter: false
  },
  {
    key: 'show_details',
    label: 'Acción',
    _style: 'width:10%',
    sorter: false
  },
  {
    key: 'active',
    label: 'Estatus',
    _style: 'width:10%',
    sorter: true
  }
]

export default {
  name: 'Companies',
  components: {
    CreateCompany,
    EditCompany,
    vSelect
  },
  data: () => ({
    isRequesting: false,
    fields,
    items: [],
    noItemsView: {
      noResults: 'Registros no encontrados.',
      noItems: 'Registros no encontrados.'
    },
    openFilterInput: '',
    filterFieldSelected: 'name',
    pagination: {
      page: 1,
      pages: 0,
      totalRecords: 0
    },
    sort: {
      column: 'name',
      asc: true
    },
    createModal: {
      isActive: false
    },
    editModal: {
      registryUuid: null,
      isActive: false
    },
    success: {
      hasSuccess: false,
      message: ''
    },
    states: [],
    schemas: [],
    isActiveCompany: null
  }),
  computed: {
    registryUuid() {
      return this.editModal.registryUuid
    }
  },
  watch: {
    sort() {
      this.fetchCompanies()
    },
    'pagination.page': function () {
      if (!this.isClear) {
        this.fetchCompanies()
      }
    },
    openFilterInput(value) {
      if (value.length >= 5) {
        this.search()
      } else if (value.length === 0) {
        this.search()
      }
    },
    states(state) {
      if (state.length === 1) {
        this.states = state
        this.fetchCompanies()
      } else if (state.length > 1) {
        const indice = state.indexOf('Sin estado')
        if (indice !== -1) {
          state.splice(indice, 1)
        }
        this.states = state
        this.fetchCompanies()
      }
      if (state.length === 0) {
        this.filterFieldSelected = 'name'
        this.sort.column = 'name'
        this.sort.asc = true
        this.pagination.page = 1
        this.fetchCompanies()
      }
    },
    isActiveCompany() {
      this.fetchCompanies()
    }
  },
  mounted() {
    this.fetchCompanies()
    this.getSchemas()
    const params = new URLSearchParams(window.location.search)
    const action = params.get('action')
    if (action === 'create') {
      this.openCreateModal()
    }
    if (action === 'edit') {
      const company = params.get('company')
      this.openEditModal({ registryUuid: company })
    }
  },
  methods: {
    async getSchemas() {
      try {
        const { data } = await this.axios.get('/providers/working-schemas')
        this.schemas = data
      } catch (error) {
        console.error(error)
      }
    },
    onRowClicked(item) {
      return item ? this.openEditModal(item) : this.openCreateModal()
    },
    openCreateModal() {
      this.createModal.isActive = true
    },
    openEditModal({ registryUuid }) {
      this.editModal.registryUuid = registryUuid
      this.editModal.isActive = true
    },
    closeCreateModal() {
      this.createModal.isActive = false
    },
    closeEditModal() {
      this.editModal.registryUuid = null
      this.editModal.isActive = false
    },
    onSuccessCreation() {
      this.closeCreateModal()
      this.resetFilters()
      this.setSuccessMessage('Compañía creada con éxito.')
      this.scrollTop()
    },
    onSuccessUpdate() {
      this.closeEditModal()
      this.resetFilters()
      this.setSuccessMessage('Compañía actualizada con éxito.')
      this.scrollTop()
    },
    filters() {
      const options = {
        page: this.pagination.page
      }

      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options[this.filterFieldSelected] = this.openFilterInput
      }

      if (this.sort.asc !== null) {
        options.orderBy = this.sort.column
        options.sortBy = this.sort.asc ? 'ASC' : 'DESC'
      }

      if (this.states.length > 0) {
        let states = ''
        this.states.forEach((value) => {
          if (value === 'Sin estado') {
            states = 'null,'
          } else {
            states += `${value},`
          }
        })
        options.states = states.slice(0, -1)
      }

      if ([true, false].includes(this.isActiveCompany)) {
        options.active = this.isActiveCompany
      }

      return options
    },
    async fetchCompanies() {
      this.isRequesting = true
      const queryString = queryparser.parse(this.filters())

      try {
        const { data } = await this.axios.get(`/providers/companies?${queryString}`)
        this.items = data.data.map((item, id) => ({ ...item, id }))
        this.items.forEach((value) => {
          const { state } = value

          if (state === null || state.length === 0) {
            // eslint-disable-next-line
            value._classes = 'alert-danger'
          }
        })
        this.pagination.pages = data.pagination.totalPages
        this.pagination.totalRecords = data.pagination.total
      } catch (err) {
        console.log(err)
        // TODO?
      } finally {
        this.isRequesting = false
      }
    },
    search() {
      if (this.isRequesting) {
        return false
      }

      if (this.openFilterInput) {
        if (this.filterFieldSelected === 'name') {
          if (this.openFilterInput.length < 3) {
            return false
          }
        }
      }

      return this.fetchCompanies()
    },
    resetFilters() {
      this.filterFieldSelected = 'name'
      this.sort.column = 'name'
      this.sort.asc = true
      this.openFilterInput = ''
      this.pagination.page = 1
      this.fetchCompanies()
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.successCompanies.focus()
      })
    },
    stateFormat(items) {
      if (items.state != null && items.state.length > 0) {
        return items.state.join(', ')
      }
      return 'Sin estado'
    },
    async activeCompany(item) {
      const { active } = item
      await this.axios.put(`/providers/companies/${item.registryUuid}/active`, { active })
        .then((response) => {
          if (response.status === 204) {
            this.setSuccessMessage('Estatus actualizado con éxito.')
          }
        }).catch((err) => {
          this.alertError = true
          this.error = getErrorMessage(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.companies{
  .add-company-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .filter-by-name{
    @media(min-width: 992px){
      display: flex;
    }
    >div{
      margin-left: 5px;
      margin-bottom: 5px;
      &:first-child{
        margin-bottom: 8px;
        @media(min-width: 992px){
          margin-bottom: 0;
        }
        input{
          width: 100%;
          height: 35px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 16px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
      &:last-child{
        @media(min-width: 992px){
          display: flex;
          margin-left: 8px;
        }
        button{
          width: calc(50% - 4px);
          @media(min-width: 992px){
            width: auto;
          }
          &:last-child{
            margin-left: 8px;
          }
        }
      }
    }
  }
  .select-filters-wrapper{
    display: flex;
    flex-direction: column;
    @media(min-width:992px){
      flex-direction: row;
    }
    .select-filter{
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .vs__search{
    margin: 4px;
  }
  .v-select-form{
    margin-left: 5px;
  }
  .filter-section{
    width: 40%;
    margin-bottom: 16px;
  }
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 100%;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
}
</style>
