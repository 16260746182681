<template>
  <CModal size="lg" :closeOnBackdrop="false" :show.sync="isModalActive">
    <template v-slot:header>
      <h5 class="modal-title">{{ isEditingProduct ? 'Editar' :'Crear' }} Producto</h5>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <div tabindex="0" ref="createFocusAlertError">
      <CAlert color="danger" :closeButton="true" :show.sync="isErrorResponse">
        {{ error.message }}
      </CAlert>
    </div>
    <div>
      <form id="createProductForm" ref="form" v-on:submit.prevent="onSubmit">
        <div class="form-group">
          <label for="">Tipo de Servicio*</label>
          <v-select
            label="description"
            :options="serviceTypes"
            v-model="serviceType"
            @input="serviceTypeOnChange"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <label for="">Nombre*</label>
          <input
            class="form-control"
            required
            type="text"
            placeholder="Nombre"
            minlength="3"
            maxlength="100"
            v-model="product.name"
            v-c-tooltip="'Corresponde al nombre del producto'"
          />
        </div>
        <div class="form-group">
          <label for="">Descripción*</label>
          <input
            class="form-control"
            required
            type="text"
            placeholder="Descripción"
            minlength="3"
            maxlength="100"
            v-model="product.description"
            v-c-tooltip="'Corresponde a la descripción del producto'"
          />
        </div>
        <div class="form-group">
          <label>Tag*</label>
          <v-select
            label="tag"
            :options="tags"
            v-model="tag"
            @input="tagsOnChange"
          >
            <template #tag="{ attributes, events }">
              <input
                class="vs__search"
                required
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <label for="">Icono</label>
          <div class="m-1 p-1">
            <img class="photo-width" :src="urlPhoto ? `${urlPhoto}?d=${Date.now()}` : null" />
            <ImageInput
              :height="60"
              :isRequired="false"
              :width="60"
              @format="onInvalidFormat"
              @input="onImageInput"
              ref="imageInput"
              v-c-tooltip="'Corresponde al ícono del producto'"
            >
            </ImageInput>
            <small class="form-text text-muted">Dimensiones 60x60 px</small>
          </div>
        </div>
      </form>
    </div>
    <div>
      <div>
        <div>
          <div class="text-right">
            <CButton
              @click="
                state.collapseShowcase = !state.collapseShowcase;
                isEditing = !isEditing;
                editingProduct = false
              "
              color="primary"
              class="mb-2"
            >
              + Presentación
            </CButton>
          </div>
          <CCollapse :show="!state.collapseShowcase && !state.collapseOptions">
            <CDataTable
              :items="product.productShowCase"
              :fields="fieldsShowCase"
              :sorter="false"
              :clickableRows="true"
              :noItemsView="noItemsView"
              hover
              class="main-table"
              v-if="renderComponent"
            >
              <template #measurement_unit="{item}">
                <td>
                  <p>{{measurement.name}}</p>
                </td>
              </template>
              <template #isDefault="{item}">
                <td>
                  <div>
                    <p>{{ item.isDefault ? 'Activado' : 'Desactivado' }}</p>
                  </div>
                </td>
              </template>
              <template #edit="{item, index}">
                <td class="py-2">
                  <div class="d-flex">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      @click="
                        showCaseEditing(item, index)
                      "
                    >
                      Editar
                    </button>
                    <b-tooltip
                      :target="`detail_${item.registryUuid}`"
                      placement="left"
                    >
                      Editar Presentación
                    </b-tooltip>
                  </div>
                </td>
              </template>
              <template #delete="{item, index}">
                <td class="py-2">
                  <div class="d-flex">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      @click="deleteShowCase(index)"
                    >
                      Eliminar
                    </button>
                    <b-tooltip
                      :target="`detail_${item.registryUuid}`"
                      placement="left"
                    >
                      Eliminar Presentación
                    </b-tooltip>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCollapse>
          <CCollapse :show="state.collapseShowcase">
            <form
              id="addShowCaseForm"
              ref="form"
              v-on:submit.prevent="addShowCase"
            >
              <div class="form-group">
                <template>
                  <div class="text-right">
                    <b-form-checkbox
                      v-model="showcase.isActive"
                      name="check-showcase-button"
                      switch
                    >
                      {{ showcase.isActive ? "Activado" : "Desactivado" }}
                    </b-form-checkbox>
                  </div>
                </template>
              </div>
              <div class="form-group">
                <label for="">Nombre*</label>
                <input
                  class="form-control"
                  required
                  type="text"
                  placeholder="Nombre"
                  minlength="3"
                  maxlength="100"
                  v-model="showcase.name"
                  v-c-tooltip="
                    'Corresponde a la descripción de la presentacion'
                  "
                />
              </div>
              <div class="form-group">
                <label for="">Descripción*</label>
                <input
                  class="form-control"
                  required
                  type="text"
                  placeholder="Descripción"
                  minlength="3"
                  maxlength="100"
                  v-model="showcase.description"
                  v-c-tooltip="
                    'Corresponde a la descripción de la presentacion'
                  "
                />
              </div>
              <div class="form-group">
                <label>Unidad de Medida*</label>
                <v-select
                  label="name"
                  :options="measurementUnit"
                  v-model="measurement"
                  @input="measurementOnChange"
                >
                  <template #measurement_unit="{ attributes, events }">
                    <input
                      class="vs__search"
                      required
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
              <div class="form-group">
                <label for="">Etiqueta</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Etiqueta"
                  minlength="3"
                  maxlength="100"
                  v-model="showcase.label"
                  v-c-tooltip="'Corresponde a la etiqueta de la presentacion'"
                />
              </div>
              <div class="form-group">
                <label for="">Por Defecto*</label>
                <b-form-checkbox
                  v-model="showcase.isDefault"
                  name="check-showcase-button"
                  switch
                >{{ showcase.isDefault ? 'Activado' : 'Desactivado' }}</b-form-checkbox>
              </div>
              <div class="text-right">
                <button
                  type="button"
                  class="btn btn-secondary mr-1"
                  @click="clearShowCase"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  form="addShowCaseForm"
                  class="btn btn-primary ml-1"
                  v-c-tooltip="
                    'Registra el producto con los datos ingresados en el formulario'
                  "
                >
                  {{ editingProduct ? 'Editar' : 'Agregar' }}
                </button>
              </div>
            </form>
          </CCollapse>
        </div>
      </div>
    </div>

    <!-- </form> -->

    <template v-slot:footer>
      <div v-if="!isEditing">
        <CSpinner v-if="isRequesting" color="warming" size="sm" />
        <div v-else>
          <button
            type="button"
            class="btn btn-secondary mr-1"
            @click="onCancel"
          >
            Cancelar
          </button>
          <button
            type="submit"
            form="createProductForm"
            class="btn btn-primary ml-1"
            v-c-tooltip="
              'Registra el producto con los datos ingresados en el formulario'
            "
          >
            Aceptar
          </button>
        </div>
      </div>
      <div v-else></div>
    </template>
  </CModal>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import ImageInput from '../../ImageInput'
import { fileToBase64 } from '../../../utils'
import { ERRORS_MESSAGES } from '../../../common/errors'

const fieldsShowCase = [
  { key: 'name', label: 'Nombre', sorter: true },
  { key: 'description', label: 'Descripción', sorter: true },
  { key: 'label', label: 'Etiqueta', sorter: true },
  { key: 'isDefault', label: 'Por Defecto', sorter: true },
  { key: 'measurement_unit', label: 'Unidad de Medida', sorter: true },
  {
    key: 'edit',
    label: 'Editar',
    _style: 'width:10%',
    sorter: false
  },
  {
    key: 'delete',
    label: 'Eliminar',
    _style: 'width:10%',
    sorter: false
  }
]

const fieldsOptions = [
  { key: 'description', label: 'Descripción', sorter: true },
  { key: 'price', label: 'Precio', sorter: true },
  {
    key: 'edit',
    label: 'Editar',
    _style: 'width:10%',
    sorter: false
  },
  {
    key: 'delete',
    label: 'Eliminar',
    _style: 'width:10%',
    sorter: false
  }
]

const sections = {
  general: 'General',
  showcase: 'Presentaciones',
  options: 'Opciones'
}

export default {
  name: 'CreateProductModal',
  components: {
    ImageInput,
    vSelect
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    category: {
      type: Object
    },
    isEditingProduct: {
      type: Boolean
    }
  },
  mounted() {
    this.getData()
    this.getGetMeasurement()
    this.getTags()
  },
  data: () => ({
    renderComponent: true,
    fieldsOptions,
    fieldsShowCase,
    urlPhoto: '',
    noItemsView: {
      noResults: 'Sin datos',
      noItems: 'Sin datos'
    },
    deletedShowCases: [],
    deletedOptions: [],
    product: {
      serviceTypeUuid: '-1',
      productShowCase: []
    },
    state: {
      collapseShowcase: false,
      collapseOptions: false
    },
    isEditing: false,
    serviceType: {},
    showcase: {
      isActive: true,
      isDefault: true
    },
    tag: null,
    measurement: null,
    measurementUnit: [],
    registryUuid: null,
    productToEdit: null,
    editingProduct: false,
    tags: [],
    icon: null,
    error: {},
    isErrorResponse: false,
    isRequesting: false,
    sections,
    selectedSection: sections.general,
    selectedShowCase: null,
    serviceTypes: []
  }),
  watch: {
    category(value) {
      if (value) {
        this.getProductByUuid(value.registryUuid)
        this.registryUuid = value.registryUuid
      } else {
        this.clear()
      }
    }
  },
  methods: {
    async getProductByUuid(registryUuid) {
      try {
        const { data } = await this.$serverless.get(`/products/productslist/${registryUuid}`)
        this.serviceType = data.service_type
        this.tag = data.tag
        this.product = {
          name: data.name,
          description: data.description,
          productShowCase: data.productShowCase || [],
          serviceTypeUuid: this.serviceType.registryUuid,
          tagUuid: this.tag ? this.tag.registryUuid : null
        }
        this.urlPhoto = data.icon
      } catch (error) {
        this.isRequesting = false
        this.isErrorResponse = true
        this.error = ERRORS_MESSAGES.UNKNOWN(error)
      }
    },
    async getData() {
      try {
        const { data } = await this.axios.get('/services/types')
        this.serviceTypes = data
        this.serviceType = this.serviceTypes[0]
        this.product.serviceTypeUuid = this.serviceType.registryUuid
        this.pages = 1
        this.allRecords = data.length
      } catch (error) {
        this.isRequesting = false
        this.isErrorResponse = true
        this.error = ERRORS_MESSAGES.UNKNOWN(error)
      }
    },
    async getGetMeasurement() {
      try {
        const { data } = await this.$serverless.get('/products/measurementUnit')
        this.measurementUnit = data
        this.measurement = this.measurementUnit[0]
      } catch (error) {
        this.isRequesting = false
        this.isErrorResponse = true
        this.error = ERRORS_MESSAGES.UNKNOWN(error)
      }
    },
    async getTags() {
      try {
        const { data } = await this.$serverless.get('/ally/tags')
        this.tags = data
        this.tag = this.tags[0]
        this.product.tagUuid = this.tag ? this.tag.registryUuid : null
      } catch (error) {
        this.isRequesting = false
        this.isErrorResponse = true
        this.error = ERRORS_MESSAGES.UNKNOWN(error)
      }
    },
    measurementOnChange(measurement) {
      this.measurement = measurement
    },
    tagsOnChange(tag) {
      this.tag = tag
      this.product.tagUuid = this.tag.registryUuid
    },
    serviceTypeOnChange(serviceType) {
      this.serviceType = serviceType
    },
    onImageInput(file) {
      if (file) {
        this.error = {}
        this.alertError = false
      }
      this.urlPhoto = false
      this.icon = file
    },
    onInvalidFormat() {
      this.alertError = true
      this.scrollTop()
      this.error = ERRORS_MESSAGES.INVALID_IMAGE
    },
    onCancel() {
      this.urlPhoto = ''
      this.$refs.imageInput.reset()
      this.isErrorResponse = false
      this.error = {}
      this.$emit('onClose')
    },
    clearShowCase() {
      this.state.collapseShowcase = false
      this.isEditing = false
      this.showcase = { isActive: true }
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    addShowCase() {
      if (this.showcase && this.showcase.registryUuid == null) {
        this.showcase.measurementUnit = this.measurement
        const data = {
          ...this.showcase,
          measurementUnitId: this.showcase.measurementUnit.id
        }
        if (!this.editingProduct) {
          this.product.productShowCase.push(data)
        } else {
          this.productToEdit = data
        }
        this.productToEdit = null
        this.editingProduct = false
        this.product.hideItem = false
        this.product.serviceTypeUuid = this.serviceType.registryUuid
        this.product.tagUuid = this.tag.registryUuid
      }
      this.$set(this.product, 'product', this.product.productShowCase)
      this.clearShowCase()
      this.forceRerender()
    },
    showCaseEditing(item, index) {
      this.showcase = item
      this.state.collapseShowcase = !this.state.collapseShowcase
      this.isEditing = !this.isEditing
      this.editingProduct = true
      this.productToEdit = this.product.productShowCase[index]
    },
    deleteShowCase(index) {
      const itemDeleted = this.product.productShowCase[index]
      if (itemDeleted.registryUuid && !itemDeleted.isNew) {
        this.deletedShowCases.push({ ...itemDeleted })
        this.product.deletedShowCases = this.deletedShowCases
      }
      this.product.productShowCase.splice(index, 1)
    },
    showDetails(item) {
      this.$set(item, 'show', !item.show)
    },
    clear() {
      this.product = {
        serviceTypeUuid: '-1',
        productShowCase: []
      }
      this.tag = null
      this.icon = null
    },
    async onSubmit() {
      this.product.product = null
      try {
        if (this.icon) {
          this.product.icon = await fileToBase64(this.icon)
        }

        const product = {
          ...this.product,
          tagUuid:(this.tag || {}).registryUuid,
          serviceTypeUuid: (this.serviceType || {}).registryUuid
        }

        if (this.isEditingProduct) {
          await this.$serverless.put(`/products/productslist/${this.registryUuid}`, product)
        } else {
          await this.$serverless.post('/products/productslist', product)
        }
        this.clear()
        this.$emit('onSuccess')
        this.$refs.imageInput.reset()
        this.icon = null
      } catch (error) {
        this.isRequesting = false
        this.isErrorResponse = true
        this.error = ERRORS_MESSAGES.INVALID_DATA
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.photo-width {
  width: 60px;
  height: auto;
  margin-left: 47%;
}
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.add-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
}
.tooltip-btn {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 4px;
  margin-left: 4px;

  background-color: #3c4b64;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.filter-section {
  margin-bottom: 16px;
}
.tabs-selector {
  border: 1px solid #ccc;
  display: flex;
  height: 35px;
  border-radius: 4px;
  button {
    border: none;
    background-color: #fff;
    outline: none;
    color: #000015;
    width: 100%;
    border-right: 1px solid #ccc;
    border-radius: 4px;
    &.active {
      background-color: #5b2ae0;
      color: white;
    }
    &:last-child {
      border-right: 0;
    }
  }
}
</style>
