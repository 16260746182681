<template>
  <div class="assign-reassign-service">
    <div v-if="typeof nearbyProviders === 'object'">
      <CAlert
        :color="error.type || 'danger'"
        :closeButton="true"
        :show.sync="alertError">
        {{error.message}}
      </CAlert>
      <h5
        v-if="STATUS_SERVICES_TO_ASSIGN.includes(statusId)"
      >
        Asignar orden de servicio
      </h5>
      <h5
        v-if="STATUS_SERVICES_TO_REASSIGN.includes(statusId)"
      >
        Reasignar orden de servicio
      </h5>
      <select
        v-model="nearbyProviderSelected"
        v-c-tooltip="'Listado de los colaboradores en línea, ordenados por la cercanía al servicio, para seleccionar al repartidor que realizará el servicio'"
      >
        <option
          :value="null"
          disabled
        >
          Selecciona una opción
        </option>
        <option
          v-for="item in nearbyProviders"
          :key="item.registryUuid"
          :value="item.registryUuid"
          :disabled="item.registryUuid === prevProviderUuid"
        >
          {{ (item.registryUuid === prevProviderUuid ? 'Asignado - ' : '') + formatNearbyProviders(item.shortName, item.phoneNumber, item.distance) }}
        </option>
      </select>
      <button
        v-show="STATUS_SERVICES_TO_ASSIGN.includes(statusId)"
        class="btn btn-primary"
        @click="assignProvider(serviceUuid)"
        v-c-tooltip="'Asigna el servicio; notifica al cliente y al colaborador'"
      >
        Asignar
      </button>
      <button
        v-show="STATUS_SERVICES_TO_REASSIGN.includes(statusId)"
        class="btn btn-primary"
        @click="reassignProvider(serviceUuid)"
        v-c-tooltip="'Asigna el servicio al nuevo colaborador; notifica al cliente, al colaborador anterior y al nuevo colaborador '"
      >
        Reasignar
      </button>
    </div>
    <!-- if address doesn't exist -->
    <div
      v-if="typeof nearbyProviders === 'boolean'"
      class="assign-service"
    >
      <p class="text-danger">
        El domicilio no existe.
      </p>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '../../common/errors'
import {
  STATUS_SERVICES_TO_ASSIGN, STATUS_SERVICES_TO_REASSIGN
} from '../../common/enums'

export default {
  name: 'AssignReassignService',
  data() {
    return {
      nearbyProviderSelected: null,
      nearbyProviders: [],
      error: {},
      alertError: false,
      // enums
      STATUS_SERVICES_TO_ASSIGN,
      STATUS_SERVICES_TO_REASSIGN
    }
  },
  props: {
    serviceUuid: {
      type: String,
      required: true
    },
    addressUuid: {
      type: String,
      required: false
    },
    prevProviderUuid: {
      type: String,
      required: true
    },
    statusId: {
      type: Number,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    paymentTypeId: {
      type: Number,
      required: true
    },
    companyUuid: {
      type: String,
      required: false
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.alertError = false
        this.getNearbyProviders()
      }
    },
    prevProviderUuid(value) {
      this.getNearbyProviders()
    }
  },
  mounted() {
    this.getNearbyProviders()
  },
  methods: {
    assignProvider() {
      this.axios.post('/schedules/assign/provider', {
        serviceUuid: this.serviceUuid,
        providerUuid: this.nearbyProviderSelected
      }).then(() => {
        this.alertError = false
        this.$emit('onSuccessAssignation')
      }).catch((err) => {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      })
    },
    reassignProvider() {
      this.axios.post('/schedules/assign/provider', {
        serviceUuid: this.serviceUuid,
        providerUuid: this.nearbyProviderSelected,
        prevProviderUuid: this.prevProviderUuid
      }).then(() => {
        this.alertError = false
        this.$emit('onSuccessReassignation')
      }).catch((err) => {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.$emit('onError', err)
      })
    },
    getNearbyProviders() {
      this.nearbyProviders = []
      this.nearbyProviderSelected = null
      const companyQuery = this.companyUuid ? `&companyUuid=${this.companyUuid}` : ''
      const getProvidersUrl = `/providers/nearby/${this.addressUuid}?serviceUuid=${this.serviceUuid}&paymentTypeId=${this.paymentTypeId}${companyQuery}`
      this.axios.get(getProvidersUrl)
        .then((response) => {
          const { data } = response
          this.nearbyProviders = data
          const hasProvider = this.nearbyProviders.find(({ registryUuid }) => registryUuid === this.prevProviderUuid)
          if (hasProvider) this.nearbyProviderSelected = this.prevProviderUuid
        }).catch(() => {
          this.nearbyProviders = false
        })
    },
    formatNearbyProviders(name, phone, distance) {
      const kilometers = distance / 1000
      return name === null ? `${phone} - ${kilometers.toFixed(2)} km` : `${name} - ${phone} - ${kilometers.toFixed(2)} km`
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-reassign-service{
    >div:first-child{
        display: flex;
        flex-direction: column;
    }
    h5{
        margin-bottom: 16px;
    }
    select,input{
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 16px;
    }
    button{
        width: fit-content;
        margin-left: auto;
        padding: 8px;
    }
}
</style>
