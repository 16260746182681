<template>
<div>
    <CAlert
        :color="'danger'"
        :closeButton="true"
        :show.sync="hasError"
      >
        {{ error }}
    </CAlert>
    <form id="createService" ref="form" v-on:submit.prevent="onSubmit">
        <div class="div-50-perc">
          <div v-if="serviceDetail && serviceDetail.origin !== 'recurrence'" class="div-50-perc">
                <div class="service-type-container">
                    <div class="form-group">
                        <v-select
                            label="description"
                            :options="serviceTypes"
                            @input="serviceTypeOnChange"
                            v-model="serviceType"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!serviceType"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <v-select
                            label="name"
                            :options="categories"
                             @input="categoryOnChange"
                            v-model="category"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!category"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <v-select
                            label="name"
                            :options="companies"
                            @input="companyOnChange"
                            v-model="company"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!company"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div v-if="serviceDetail && serviceDetail.origin !== 'recurrence'" class="div-50-perc summary-details">
                <span v-if="!showcase || showcase.length === 0">No hay productos que mostrar</span>
                <div class="summary-content"  v-if="showcase && showcase.length > 0">
                    <div class="showcase-container">
                        <button
                            type="button"
                            v-for="product in showcase"
                            v-bind:key="product.id"
                            v-bind:class="[
                                product && productShowCase && productShowCase.id === product.id ? 
                                    'btn showcase-button showcase-button-selected' : 'btn showcase-button'
                            ]"
                            @click="selectShowcase(product)"
                        >
                            {{product.name || product.description}}
                        </button>
                        <small class="help" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.units">
                            ({{serviceDetail.body.units}})
                        </small>
                    </div>
                    <div class="form-group">
                        <label v-if="productShowCase && productShowCase.isCurrency">$&nbsp;</label>
                        <div class="amount-container"> 
                            <DecimalInput
                                ref="decimalInput"
                                class="form-control"
                                placeholder="Cantidad"
                                :min="0"
                                :max="9999999.99"
                                v-model="amount"
                                :required="true"
                                @input="amountOnChange"
                            />
                        </div>
                    </div>
                    <div>
                        <span class="summary-total">${{parseFloat(summary.total || 0).toFixed(2)}}</span>
                        &nbsp;
                        <div class="subtotal-container">
                            <span class="summary-subtotal">Servicio: ${{parseFloat(summary.serviceFee || 0).toFixed(2)}}</span>
                            &nbsp;
                            <span class="summary-subtotal">Tarifa: ${{parseFloat(summary.saturationFee || 0).toFixed(2)}}</span>
                        </div>
                        
                    </div>
                </div> 
            </div>
            <div v-if="serviceDetail && serviceDetail.origin === 'recurrence'" class="table-responsive-xl">
              <CDataTable
                :items="serviceDetail.details"
                :fields="fieldsProduct"
              >
                <template #companyName="{item}">
                  <td>
                    {{ serviceDetail.company.name }}
                  </td>
                </template>
                <template #service="{item}">
                  <td>
                    {{ serviceDetail.serviceType.description }}
                  </td>
                </template>
                <template #name="{item}">
                  <td>
                    {{ item.showcase.name }}
                  </td>
                </template>
                <template #unit="{item}">
                  <td>
                    {{ item.isCurrency ? item.units / 100 : item.units }}
                  </td>
                </template>
                <template #unitPrice="{item}">
                  <td>
                    {{ formatMoney(removeDecimals(item.showcase.price.price)) }}
                  </td>
                </template>
                <template #price="{item}">
                  <td>
                    {{ calculateTotalPrice(item.units, item.showcase.price.price, item.isCurrency) }}
                  </td>
                </template>
              </CDataTable>
              <div class="">
                <span class="summary-total">Total: ${{parseFloat(summary.total || 0).toFixed(2)}}</span>&nbsp;
                <div class="subtotal-container">
                  <span v-if="summary.serviceFee !== 0" class="summary-subtotal">Servicio: ${{parseFloat(summary.serviceFee || 0).toFixed(2)}}</span>&nbsp;
                  <span v-if="summary.saturationFee !== 0" class="summary-subtotal">Tarifa: ${{parseFloat(summary.saturationFee || 0).toFixed(2)}}</span>&nbsp;
                  <span v-if="summary.shippingFee !== 0" class="summary-subtotal">Costo de envío: ${{parseFloat(summary.shippingFee || 0).toFixed(2)}}</span>
              </div>
            </div>
          </div>
            <div class="clear">
                <h4>Dirección</h4>
                <small class="help address-info" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.address">({{parseAddress(serviceDetail.body.address)}})</small>
                <div class="map">
                    <MapAddDirecctionVue @onAddressChange="updateCoverageCompanies" />
                </div>
                
                <span v-if="addressError" class="error">{{addressError}}</span>
                <div class="form-group">
                    <label class="label-text">Interior</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Interior"
                        v-model="interiorNumber"
                    />
                </div>
                <div class="form-group">
                    <label class="label-text">Referencia</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Referencia"
                        v-model="reference"
                    />
                </div>
            </div>
        </div>
        <div class="div-50-perc service-data-container">
            <h4>Datos del cliente</h4>
            <div class="form-group">
                <label class="label-text">Nombre</label>
                <small class="help name" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.name">
                    ({{serviceDetail.body.name}})
                </small>
                <input
                    class="form-control input-group"
                    type="text"
                    placeholder="Nombre del cliente"
                    v-model="clientName"
                    :disabled="options.disableCustomerName"
                    maxlength="200"
                    required
                />
            </div>
            <div class="form-group">
                <label class="label-text">Teléfono</label>
                <small class="help" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.phoneNumber">
                    ({{getFormattedPhoneNumber(serviceDetail.body.phoneNumber)}})
                </small>
                <input
                    class="form-control input-group"
                    placeholder="Teléfono del cliente"
                    v-model="clientPhoneNumber"
                    type="tel"
                    pattern="[0-9]{10}"
                    :disabled="options.disableCustomerPhoneNumber"
                    maxlength="10"
                    @paste="onPaste"
                    required
                />
            </div>
            <div v-show="!options.hideDate">
                <h4>Fecha del servicio</h4>
                <div class="form-group">
                    <label class="label-text">Fecha</label>
                    <small class="help date-info" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.serviceDate">
                        ({{formatToDatePicker(serviceDetail.body.serviceDate)}})
                    </small>
                    <div class="input-group">
                        <date-range-picker
                            ref="picker"
                            v-model="dateRange"
                            :locale-data="{format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
                            :ranges="false"
                            :single-date-picker="true"
                            opens="center"
                            :append-to-body="false"
                            class="date-range"
                            :date-format="disabledDates"
                            @update="updateDate()"
                            v-c-tooltip="'Fecha de Servicio'"
                        >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                {{ formatToDatePicker(dateRange.startDate, picker) }}
                            </template>
                        </date-range-picker>
                    </div>
                
                </div>
                <div class="form-group">
                    <label class="label-text">Hora</label>
                    <small class="help" v-if="serviceDetail && serviceDetail.body && serviceDetail.body.preferredTime">
                        ({{serviceDetail.body.preferredTime}})
                    </small>
                    <v-select
                        label="displayTime"
                        :options="time"
                        v-model="selectedTime"
                        @input="timeOnChange"
                    >
                        <template #search="{attributes, events}">
                            <input
                                class="vs__search"
                                :required="!selectedTime"
                                v-bind="attributes"
                                v-on="events"
                            />
                        </template>
                    </v-select>
                </div>
            </div>
            <h4 class="clear">Pago</h4>
            <div class="form-group client-data-container">
                <button
                    type="button"
                    v-bind:class="[
                        paymentType === PAYMENT_TYPES.CASH ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setPaymentType(PAYMENT_TYPES.CASH)"
                >
                    Efectivo
                </button>
                
            </div>
            <div class="form-group client-data-container">
                <button
                    type="button"
                    v-bind:class="[
                        paymentType === PAYMENT_TYPES.TERMINAL ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setPaymentType(PAYMENT_TYPES.TERMINAL)"
                >
                    Terminal
                </button>
            </div>
            <div class="form-group">
                <label class="label-text">Cupón</label>
                <input 
                    class="form-control input-group"
                    type="text"
                    placeholder="Ingresar cupón"
                    v-model="promocode"
                    :disabled="options.disableCoupon"
                    @change="promocodeOnChange"
                    @input="upper"
                />
                <span v-if="couponError" class="error">{{couponError}}</span>
                <span v-if="couponSuccess">{{couponSuccess}}</span>
            </div>
             <div class="form-group">
                <label class="label-text">Propina</label>
                <!-- <v-select
                    label="description"
                    placeholder="Ingresar propina"
                    :options="tips"
                    v-model="tip"
                >
                </v-select> -->
                <DecimalInput
                    id="amount"
                    ref="decimalInput"
                    class="form-control input-group"
                    placeholder="Cantidad"
                    :min="0"
                    :max="9999999.99"
                    :disabled="options.disableTip"
                    v-model="tip"
                    @input="tipOnChange"
                />
            </div>
            <div v-if="!options.hideOrigin">
                <h4 class="clear">Origen</h4>
                <div v-if="!serviceDetail">
                    <div class="form-group client-data-third-container">
                        <button
                            type="button"
                            v-bind:class="[
                                serviceOrigin === SERVICE_ORIGIN.MESSENGER.name ? 
                                    'form-control button-selected' : 'form-control button-unselected'
                            ]"
                            @click="setOrigin(SERVICE_ORIGIN.MESSENGER.name)"
                        >
                            Messenger
                        </button>
                        
                    </div>
                    <div class="form-group client-data-third-container">
                        <button
                            type="button"
                            v-bind:class="[
                                serviceOrigin === SERVICE_ORIGIN.CALL.name ? 
                                    'form-control button-selected' : 'form-control button-unselected'
                            ]"
                            @click="setOrigin(SERVICE_ORIGIN.CALL.name)"
                        >
                            Llamada
                        </button>
                    </div>
                    <div class="form-group client-data-third-container">
                        <button
                            type="button"
                            v-bind:class="[
                                serviceOrigin === SERVICE_ORIGIN.WHATSAPP.name ? 
                                    'form-control button-selected' : 'form-control button-unselected'
                            ]"
                            @click="setOrigin(SERVICE_ORIGIN.WHATSAPP.name)"
                        >
                            WhatsApp
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div v-if="serviceDetail.origin === SERVICE_ORIGIN.ADMIN.name" class="form-group client-data-full-container">
                        <button
                            type="button"
                            class="form-control button-selected"
                            @click="setOrigin(SERVICE_ORIGIN.ADMIN.name)"
                        >
                            Administrador
                        </button>
                    </div>
                    <div v-if="serviceDetail.origin === SERVICE_ORIGIN.CALL_BOT.name" class="form-group client-data-full-container">
                        <button
                            type="button"
                            class="form-control button-selected"
                            @click="setOrigin(SERVICE_ORIGIN.CALL_BOT.name)"
                        >
                            Bot llamada
                        </button>
                    </div>
                    <div v-if="serviceDetail.origin === SERVICE_ORIGIN.WHATSAPP_BOT.name" class="form-group client-data-full-container">
                        <button
                            type="button"
                            class="form-control button-selected"
                            @click="setOrigin(SERVICE_ORIGIN.WHATSAPP_BOT.name)"
                        >
                            Bot WhatsApp
                        </button>
                    </div>
                    <div v-if="serviceDetail.origin === SERVICE_ORIGIN.RECURRENCE.name" class="form-group client-data-full-container">
                        <button
                            type="button"
                            class="form-control button-selected"
                            @click="setOrigin(SERVICE_ORIGIN.RECURRENCE.name)"
                        >
                            Suscripción
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
</template>

<script>
import moment from 'moment'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue-select/dist/vue-select.css'
import DecimalInput from '../DecimalInput'
import { mapFields } from "vuex-map-fields";
import MapAddDirecctionVue from "../MapAddDirecction.vue";
import _ from 'lodash'

import {
    PAYMENT_TYPES,
    SERVICE_ORIGIN,
    SERVICE_TYPES,
    ALLOWED_DAY_CONFIG
} from '../../common/enums'

import {
    MEXICO_CITY_TIMEZONE
} from '../../common/const'

const fieldsProduct = [
  { key: 'companyName', label: 'Compañía', sorter: false },
  { key: 'service', label: 'Servicio', sorter: false },
  { key: 'name', label: 'Nombre', sorter: false },
  { key: 'unitPrice', label: 'Precio Unitario', sorter: false },
  { key: 'unit', label: 'Cantidad', sorter: false },
  { key: 'price', label: 'Precio', sorter: false }
]

export default {
  name: 'NewService',
  computed: {
    ...mapFields(["formOkBoy"]),
  },
  watch: {
    '$store.state.formOkBoy.address.latitude': async function() {
        this.addressError = null
        this.calculateSummary(this.amount)
    },
    serviceDetail: async function() {
        if(this.serviceDetail && this.serviceDetail.registryUuid) {
            await this.setIncomingService()
        }
    },
    fetchService: async function() {
        if (!this.fetchService) return

        const res = await this.onSubmit()
        if(!res) {
            this.$emit('onServiceConfiguredError')
        }
    }
  },
  components: {
    vSelect,
    DecimalInput,
    MapAddDirecctionVue,
    DateRangePicker
  },
  props: {
    serviceDetail: null,
    options: null,
    fetchService: false
  },
  async mounted () {
    await this.getSchedules()
    await this.getServicesTypes()
    setTimeout(() => {
        const elements = document.getElementsByClassName('pac-container pac-logo')
        elements[elements.length - 1].style.zIndex = 99999
    }, 1500)
    if(this.serviceDetail && this.serviceDetail.registryUuid) {
            await this.setIncomingService()
    }
  },
  data: () => ({
    fieldsProduct,
    PAYMENT_TYPES,
    SERVICE_ORIGIN,
    isRequesting: false,
    isErrorResponse: false,
    serviceType: null,
    category: null,
    company: null,
    productShowCase: null,
    amount: null,
    serviceTypes: [],
    categories: [],
    companies: [],
    servicePricing: [],
    showcase: [],
    tips: [],
    clientName: '',
    clientPhoneNumber: '',
    date: moment(),
    dateRange: {
        startDate: moment(),
        endDate: null
    },
    paymentType: PAYMENT_TYPES.CASH,
    serviceOrigin: SERVICE_ORIGIN.MESSENGER.name,
    promocode: null,
    tip: null,
    address: null,
    editAdress: {},
    interiorNumber: null,
    reference: null,
    summary: {
        total: 0,
        serviceFee: 0,
        saturationFee: 0
    },
    scheduleToday: [],
    scheduleWeekend: [],
    scheduleFull: [],
    selectedDate: null,
    selectedTime: null,
    time: [],
    discount: 0,
    daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    couponError: null,
    couponSuccess: null,
    addressError: null,
    error: null,
    hasError: false,
    companySummary: null
  }),
  methods: {
    calculateTotalPrice(units, price, isCurrency) {
      const total = (isCurrency ? (units / 100) * price : units * price)
      return this.formatMoney(this.removeDecimals(total))
    },
    async updateCoverageCompanies(address) {
        if(!address.coordenadasMap) {
            return
        }
        const { lat, lng } = address.coordenadasMap
        const data = await this.$axios.get(`/company/coverage?latitude=${lat}&longitude=${lng}&typeServicesId=${this.serviceType.id}`)
        this.companies = data.data.companies
        this.company = this.companies.length > 0 ? this.companies[0] : null
        if(!this.company){
            this.company = this.companySummary
        }
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      if (value) {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    removeDecimals(value) {
      return value ? value / 100 : value
    },
    async setIncomingService() {
        this.clientName = this.serviceDetail.name
        this.clientPhoneNumber = this.serviceDetail.phoneNumber
        this.setOrigin(this.serviceDetail.origin && this.serviceDetail.origin === SERVICE_ORIGIN.CALL_BOT.name
            ? SERVICE_ORIGIN.CALL_BOT.name :
            SERVICE_ORIGIN.ADMIN.name)
        this.setPaymentType(PAYMENT_TYPES.CASH)
        if(this.serviceDetail.tip) this.tip = (this.serviceDetail.tip / 100).toString()
        if(this.serviceDetail.promocode) {
            this.promocode = this.serviceDetail.promocode
            await this.promocodeOnChange()
        }

        this.formOkBoy.address = this.serviceDetail.address
        this.formOkBoy.coordenadasMap = new google.maps.LatLng(this.formOkBoy.address.latitude, this.formOkBoy.address.longitude)
        this.reference = this.serviceDetail.address.reference
        this.interiorNumber = this.serviceDetail.address.numInt
        try {   
            this.serviceType = this.serviceTypes.find(obj => obj.registryUuid === this.serviceDetail.ServiceTypeComplete.registryUuid)
            await this.serviceTypeOnChange(this.serviceType)
            this.category = this.categories.find(obj => obj.registryUuid === this.serviceDetail.serviceTypeCategory.registryUuid)
            this.categoryOnChange()

            await this.getServiceTypeCompanies(this.serviceType.registryUuid)
            this.company = this.serviceDetail.companyUuid ?
                this.companies.find(obj => obj.registryUuid === this.serviceDetail.companyUuid) :
                this.companies[0]
                
            this.companyOnChange(this.company)
            this.productShowCase = this.serviceDetail.showcase
            
            let amount = 
                this.serviceDetail.details && this.serviceDetail.details.length && this.serviceDetail.details[0].isCurrency ?
                (this.serviceDetail.amount / 100) :
                this.serviceDetail.amount
            if (this.serviceDetail.productShowcase && !this.serviceDetail.productShowcase.isCurrency && this.serviceDetail.productOption) {
                amount = this.serviceDetail.productOption.unit
            }

            this.amount = amount.toString()
            this.amountOnChange(amount)

            this.setAmountOnDelay(amount)
        } catch(err) {
            this.setAmountOnDelay(1)
        }
        setTimeout(() => this.setDOMElements(), 500)
    },
    setAmountOnDelay(amount) {
        setTimeout(() => {
            this.amountOnChange(amount.toString())
            if(document.querySelector('.amount-container > input')){
                document.querySelector('.amount-container > input').value = amount.toString()
            }
            if (this.serviceDetail.serviceDate) this.dateRange.startDate = this.serviceDetail.serviceDate
            this.amount = amount.toString()
            this.updateDate()
        }, 1000)
    },
    getFormattedPhoneNumber(phoneNumber) {
        if(phoneNumber.toString().length > 10){
            return phoneNumber.toString().substr(phoneNumber.length - 10, phoneNumber.length)
        }
        
        return phoneNumber
    },
    setDOMElements() {
        const amountEl = document.querySelector('.amount-container > input')
        const addressEl = document.querySelector('.inline-container input')
        const addrTextEl = document.querySelector('.map div small p')

        if(!amountEl || !addressEl || !addrTextEl) {
            return setTimeout(() => {
                this.setDOMElements()
            }, 500)
        }
        addressEl.value = addrTextEl.innerHTML.trim()
    },
    parseAddress(address) {
        return typeof address === 'string' ? address : `${address.street} #${address.numExt} ${address.suburb} ${address.city}`
    },
    onPaste(e) {
        e.preventDefault();
        const pastedText = 
            e.clipboardData.getData('text/plain')

        this.clientPhoneNumber =
            pastedText.replace(/\D/g, '')
    },
    formatToDatePicker(date) {
      return date ? (
          moment(date).tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
      ) : moment().tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
    },
    amountOnChange(value){
        if (this.couponError) {
            this.promocode = null
        }
        this.couponError = null
        this.calculateSummary(value)
    },
    async calculateSummary(value) {
        this.error = null
        this.hasError = false

        const {
            latitude,
            longitude
        } = this.formOkBoy.address

        const companyUuid =
            this.company ? this.company.registryUuid : null
        const serviceTypeUuid =
            this.serviceType ? this.serviceType.registryUuid : null

        const selectedAmount = value || 0
        const unitPrice = this.productShowCase ? this.productShowCase.unitPrice : 0
        const amount = !this.productShowCase || this.productShowCase.isCurrency ? selectedAmount * 100 : (
            unitPrice * selectedAmount
        )
        if(isNaN(amount)) {
            return setTimeout(async () => { await this.calculateSummary(value) }, 250)
        }

        const iva = amount / 1.16

        const tip = (this.tip || 0) * 100
        const discount = (this.discount || 0) * 100

        const service = {
            amount,
            serviceFee: 0,
            saturationFee: 0,
            total: amount + tip - discount,
            discount: 0,
            tip,
            promocode: this.promocode,
            iva,
            saturationMultiplier: 1
        }
        
        if (
            latitude &&
            latitude.trim() != '' &&
            longitude &&
            longitude.trim() != '' &&
            companyUuid &&
            serviceTypeUuid &&
            +amount > 0
        ) {
            const serviceDate = this.dateRange.startDate && this.dateRange.startDate != '' && this.selectedTime ?
                `${moment(this.dateRange.startDate).tz(MEXICO_CITY_TIMEZONE).format('YYYY-MM-DD')} ${this.selectedTime.hora}:00` : null
            
            const summaryDb = await this.getSummary(
                serviceTypeUuid,
                latitude,
                longitude,
                amount,
                companyUuid,
                serviceDate,
                service.tip,
                service.promocode,
            )
            if (summaryDb) {
                const {
                    total,
                    company,
                    discount,
                    tip,
                    serviceFee,
                    saturationFee,
                    iva,
                    saturationMultiplier,
                    shippingFee
                } = summaryDb
                service.total = total || 0
                service.serviceFee = serviceFee || 0
                service.shippingFee = shippingFee || 0
                service.saturationFee = saturationFee || 0
                service.discount = discount || 0
                service.tip = tip || 0
                service.iva = iva || 0
                service.saturationMultiplier = saturationMultiplier 
                this.companySummary = company
            }
        }

        this.summary.data = {
            ...service
        }
        this.summary.amount = service.amount / 100
        this.summary.total = service.total / 100
        this.summary.serviceFee = service.serviceFee / 100
        this.summary.saturationFee = service.saturationFee / 100
        this.summary.shippingFee = service.shippingFee / 100
    },
    upper(e) {
        const promocode = e.target.value
        this.promocode = promocode ? promocode.toUpperCase() : null
    },
    async promocodeOnChange(e) {
        try {
            this.discount = 0
            this.couponError = null
            this.couponSuccess = null
        
            const promocode = e.target.value
        
            if (!promocode || promocode.trim() === '') {
                throw 'Empty Promocode'
            }

            const discount =
                await this.getDiscount(promocode.toUpperCase())
            
            if (
                !this.serviceType || !discount || 
                !discount.serviceTypeUuid || 
                discount.serviceTypeUuid.trim() == ''
            ) {
                this.couponError = 'Código no válido'
                throw this.couponError
            }

            const serviceTypePromocode = 
                discount.serviceTypePromocode || []


            const serviceTypePromocodeObj = 
                serviceTypePromocode.find(
                    obj => obj.serviceTypeUuid &&
                        obj.serviceTypeUuid.trim() === this.serviceType.registryUuid.trim()
                )

            if (
                discount.serviceTypeUuid.trim() != this.serviceType.registryUuid.trim() && 
                !serviceTypePromocodeObj
            ) {
                this.couponError = 'Código no válido'
                throw this.couponError
            }
        
            const amount = this.summary.data.amount / 100 || 0
            const minPurchase = 
                serviceTypePromocodeObj ? (
                    serviceTypePromocodeObj.minPurchaseAmount || 0
                ) / 100 : discount.minPurchase

            if (minPurchase && minPurchase > 0 && minPurchase > amount) {
                this.couponError = `El servicio no cuenta con el mínimo de compra ($${minPurchase})`
                throw this.couponError
            }

            this.discount = Number(discount.discount)
            this.calculateSummary(this.amount)
            this.couponSuccess = `Descuento de: $${discount.discount}`
        }catch (error) {
            this.calculateSummary(this.amount)
            return null
        }
    },
    async checkIfCompanyHasCoverage(companyUuid, latitude, longitude) {
        try {
            const { data } = 
                await this.$axios.get(`/company/coverage/${companyUuid}?latitude=${latitude}&longitude=${longitude}`)

            return data
        }catch (error) {
            console.error(error)
            return null
        }
    },
    async getDiscount(promocode) {
        try {
            const { data } = 
                await this.$axios.get(`/services/promocodes/${promocode}/discount`)

            return data
        }catch (error) {
            console.error(error)
            return null
        }
    },
    async getSummary (serviceTypeUuid, latitude, longitude, amount, companyUuid, serviceDate, tip, promocode) {
        try {
            let url = 
                `/checkout/summary/web?serviceTypeUuid=${serviceTypeUuid}&latitude=${latitude}&longitude=${longitude}&amount=${amount}&selectedCompanyUuid=${companyUuid}`
            if (serviceDate) {
                url += `&serviceDate=${serviceDate}`
            }
            if (tip) {
                url += `&tip=${tip}`
            }
            if (promocode) {
                url += `&promocode=${promocode}`
            }

            const { data } = 
                await this.$axios.get(url)
            
            return data
        }catch (error) {
            console.error(error)
            return null
        }
    },
    updateDate() {
        const date = 
            moment(this.dateRange.startDate).tz(MEXICO_CITY_TIMEZONE).startOf('day')
        const day = date.day()

        if (
            date.format('YYYYMMDD') ===
            moment().tz(MEXICO_CITY_TIMEZONE).startOf('day').format('YYYYMMDD')
        ) {
            this.time = this.scheduleToday
        }else if (day === 0 || day === 6) {
            this.time = this.scheduleWeekend
        } else {
            this.time = this.scheduleFull
        }

        if (this.serviceDetail.preferredTime) {
           this.selectedTime = this.time.find(obj => obj.hora === this.serviceDetail.preferredTime)
        }
        if (!this.selectedTime) this.selectedTime = this.time[0]
        this.date = date
        this.calculateSummary(this.amount)
    },
    getValidDate() {
        try {
            let validDate =
                moment().tz(MEXICO_CITY_TIMEZONE).startOf('day')

            if (this.serviceType && [
                SERVICE_TYPES.WATER.ID,
                SERVICE_TYPES.HOME_CLEANER.ID,
                SERVICE_TYPES.UPHOLSTERY.ID,
                SERVICE_TYPES.FUMIGATION.ID
            ].includes(+this.serviceType.id)) {
                const addDays = ALLOWED_DAY_CONFIG[+this.serviceType.id] || 0
                validDate = validDate.add(addDays, 'days').startOf('day')
            }

            return validDate
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    disabledDates(classes, date) {
      const selectedDate =
        moment(date).tz(MEXICO_CITY_TIMEZONE)
    
      if (!classes.disabled) {
        const validDate = this.getValidDate()

        classes.disabled = selectedDate < validDate
      }
      return classes
    },
    clearAllServicesData() {
        this.clearServicesData()
        this.clearProductData()
    },
    clearProductData () {
        this.company = null
        this.category = null
        this.serviceType = null
    },
    clearServicesData () {
        this.error = null
        this.hasError = false
        this.showcase = []
        this.servicePricing = []
        this.productShowCase = null
        this.summary.total = 0
        this.summary.serviceFee = 0
        this.summary.saturationFee = 0
        this.amount = null
        this.companySummary = null
    },
    setSelectedMark(mark) {
      this.selectedMark = mark
    },
    setOrigin(serviceOrigin) {
        this.serviceOrigin = serviceOrigin
    },
    setPaymentType(paymentType) {
        this.paymentType = paymentType
    },
    selectShowcase(product) {
        try {
            this.productShowCase = product;
            this.calculateSummary(this.amount)

        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async getTips () {
       try {
            const { data } = await this.$axios.get(`/checkout/tips`)
            this.tips = data.tips
        }catch (error) {
            this.isErrorResponse = true
        }   
    },
    async getServicePricingByCompany(serviceTypeUuid, companyUuid) {
        try {
            const { data } = await this.$axios.get(`/services/categories/${serviceTypeUuid}/${companyUuid}`)
            this.servicePricing = data
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async updateServicePricingData() {
        try {
            if (!this.serviceType || !this.company) {
                return null
            }
            await this.getServicePricingByCompany(
                this.serviceType.registryUuid, 
                this.company.registryUuid
            )
            this.setPricingData()
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    tipOnChange() {
        try {
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    timeOnChange() {
        try {
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    categoryOnChange() {
        try {
            this.clearServicesData()
            this.updateServicePricingData()
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    companyOnChange(company) {
        try {
            this.addressError = null
            this.clearServicesData()
            this.updateServicePricingData()
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async serviceTypeOnChange(serviceType) {
        try {
            this.promocode = null
            this.discount = 0
            this.couponError = null
            this.couponSuccess = null
            this.clearAllServicesData()
            this.serviceType = serviceType
            await this.setServiceSelects(serviceType)
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async getServiceTypeCompanies(serviceTypeUuid) {
      try {
        this.isRequesting = true
        const { data } = await this.axios.get(`/providers/companies?serviceTypeUuid=${serviceTypeUuid}&active=${true}&perPage=40`)
        this.companies = data.data
        this.company = this.companies[0]
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    async getSchedules(selectedDate = null) {
        try {
            const { data } = await this.$axios.get(`/schedules/available`)
            this.scheduleToday = this.returnHoras(data.today);
            this.scheduleFull = this.returnHoras(data.full);
            this.scheduleWeekend = this.returnHoras(data.weekend);
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        }
    },
    returnHoras(horas) {
      let horasReturn = [];

        if(!horas.length) return horasReturn

      for (let i = 0; i < horas.length; i++) {
        horasReturn.push({
          displayTime: `${horas[i].minTime.slice(0, 5)}-${horas[i].value.slice(0, 5)}`,
          hora: horas[i].value.slice(0, 5),
          selected: "white",
          recommended: horas[i].recommended,
        });
      }
      return horasReturn;
    },
    setPricingData() {
        try {
            const category = 
                this.servicePricing.find(obj => +obj.id === +this.category.id)

            if (!category) {
                return false;
            }

            this.showcase = category.productShowCase
            const showcaseId = this.serviceDetail && this.serviceDetail.details.length > 0 ? this.serviceDetail.details[0].showcase.id : null
            this.productShowCase = showcaseId ? this.showcase.find(sw => sw.id == showcaseId) : this.showcase[0]
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        } 
    },
    async setServiceSelects(serviceType) {
        try {
            this.categories = serviceType.categories
            this.serviceType = serviceType
            this.category = serviceType.categories[0]
            this.dateRange.startDate = this.getValidDate()

            this.updateDate()

            await this.getServiceTypeCompanies(serviceType.registryUuid)
            await this.updateServicePricingData()
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        }
    },
    async getServicesTypes() {
      try {
        this.isRequesting = true
        const params = {
          includeCategories: true,
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = _.orderBy(data, ['listOrder'])
        const serviceType = this.serviceTypes[0]
        this.setServiceSelects(serviceType)
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    onCancel() {
      this.$emit('onClose')
    },
    async onSubmit() {
        try {
            if (this.isRequesting) {
                return null
            }
            this.isRequesting = true

            if (!this.formOkBoy || !this.formOkBoy.address || 
                !this.formOkBoy.address.latitude || this.formOkBoy.address.latitude.trim() == '' ||
                !this.formOkBoy.address.longitude || this.formOkBoy.address.longitude.trim() == '') {
                this.addressError = 'Dirección obligatoria'
                return null
            }

            if (!this.formOkBoy.address.numExt || this.formOkBoy.address.numExt.trim() === '') {
                this.addressError = 'Número exterior es obligatorio'
                return null
            }

            if (!this.formOkBoy.address.state || this.formOkBoy.address.state.trim() === '') {
                this.addressError = 'Estado es obligatorio'
                return null
            }
            
            if (!this.formOkBoy.address.zipcode || this.formOkBoy.address.zipcode.trim() === '') {
                this.addressError = 'Código postal es obligatorio'
                return null
            }

            if (this.addressError || this.couponError) {
                return null
            }
            const hasCoverage = 
                await this.checkIfCompanyHasCoverage(
                    this.company.registryUuid,
                    this.formOkBoy.address.latitude,
                    this.formOkBoy.address.longitude
                )

            if (!hasCoverage.coverage) {
                this.addressError = 'Empresa no tiene cobertura en esta zona'
                return null
            }

            const minPurchase = 
                this.productShowCase ? this.productShowCase.minPurchase || 0 : 0

            if (minPurchase &&  minPurchase > 0 && minPurchase > this.summary.data.amount) {
                this.error = `El servicio no cuenta con el mínimo de compra ($${parseFloat(minPurchase / 100).toFixed(2)})`
                this.hasError = true
                return null
            }

            if (!this.selectedTime) { this.selectedTime = '00:00' }
            const service = {
                amount: this.summary.data.amount,
                details: [{
                    units: Math.trunc((this.productShowCase.isCurrency ? (this.amount * 100) : this.amount)),
                    unitPrice: this.productShowCase.unitPrice,
                    isCurrency: this.productShowCase.isCurrency,
                    total: this.summary.data.amount,
                    serviceTypeCategoryId: +this.category.id,
                    showcaseUuid: this.productShowCase.registryUuid,
                    measurementUnitId: +this.productShowCase.measurementUnit.id,
                    minPurchase: this.productShowCase.minPurchase,
                    description: this.productShowCase.description
                }],
                serviceType: this.serviceType ? this.serviceType.registryUuid : null,
                fee:
                    (this.summary.data.serviceFee || 0) +
                    (this.summary.data.saturationFee || 0),
                serviceFee: (this.summary.data.serviceFee || 0),
                saturationMultiplier: this.summary.data.saturationMultiplier,
                iva: this.summary.data.iva || 0,
                tip: this.summary.data.tip || 0,
                saturationFee: this.summary.data.saturationFee || 0,
                discount: this.summary.data.discount || 0,
                serviceDate: `${this.date.format("YYYY-MM-DD")}T${this.selectedTime.hora}:00.000Z`,
                paymentTypeId: this.paymentType,
                promocode: this.promocode,
                origin: this.serviceOrigin,
                preferredCompanyUuid: this.company.registryUuid,
                customerData: {
                    phoneNumber: this.clientPhoneNumber,
                    name: this.clientName,
                    deviceType: "web",
                    address: {
                        ...this.formOkBoy.address,
                        numInt: this.interiorNumber,
                        reference: this.reference
                    },
                },
                invoiceAddress: {
                    street: this.formOkBoy.address.street,
                    numExt: this.formOkBoy.address.numExt,
                    postalCode: this.formOkBoy.address.zipcode,
                    state: this.formOkBoy.address.state,
                    city: this.formOkBoy.address.municipality,
                },
                serviceDescription: `${this.serviceType.description} - ${this.category.description} (${this.company.name})`,
                preferredTime: this.selectedTime,
                serviceTypeUuid: this.serviceType.registryUuid
            }
            
            return this.$emit('onServiceConfigured', service)
        }catch (error) {
            console.error(error)
            this.error = "No se pudo crear el pedido"
            this.hasError = true
        } finally {
            this.isRequesting = false
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.help {
    color: '#6c757d';

    &.address-info {
        position: absolute;
        margin-left: 95px;
        margin-top: -25px;
    }
}

.map > * {
    z-index: 9999
}
.clear {
    clear: left;
}
.client-data-third-container {
    float: left;
    width: 33.3%;
}
.client-data-full-container {
    width: 100%;
}
.label-text {
    color: #757575;
}
.client-data-container {
    float: left;
    width: 50%;
}
.service-data-container {
    padding: 0px 30px !important;
}
.showcase-container {
    margin-bottom: 5px;
}
.summary-subtotal {
    position: relative;
    top: -4px;
}
.summary-total {
    font-weight: 500;
    font-size: 25px
}
.button-unselected {
    color: #757575 !important;
}
.button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
    font-weight: bold;
    margin-right: 10px;
}
.showcase-button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
}
.showcase-button {
    background-color: #f5f5f5;
    color: #757575;
    font-weight: bold;
    margin-right: 10px;
}
.service-type-container {
    width: 95%
}
.summary-details {
    background-color: #f5f5f5;
    border-radius: 4px;
    min-height: 140px;
    padding: 15px !important;
}
.div-50-perc {
    width: 50%;
    padding: 0%;
    margin: 0%;
    height: 100%;
    float: left;
}
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}
.amount-container {
    display: inline-block;
    width: 93%;
}
.subtotal-container {
    display: inline-block;
}
.inline-container {
    display: inline-block;
    width: 97%;
}
.date-range {
    width: 100%;
}
.error {
    color: red;
}
input:focus:invalid {
    border-color: red;
}
</style>