<template>
  <CHeader
    fixed
    light
  >
    <CToggler
      v-c-emit-root-event:toggle-sidebar-mobile
      in-header
      class="ml-3 d-lg-none"
    />
    <CToggler
      v-c-emit-root-event:toggle-sidebar
      in-header
      class="ml-3 d-md-down-none"
    />
    <img
      class="my-auto ml-auto mr-3 d-lg-none "
      src="img/brand/okboy-logo-black.svg"
      alt="okboy Logo"
      width="140"
      height="41"
    >
  </CHeader>
</template>

<script>

export default {
  name: 'TheHeader'
}
</script>
