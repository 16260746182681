<template>
  <div class="decline" @click="disconnect">
    <svg class="decline-key" width="44" height="44">
      <use :href="'/icons.svg#decline'" width="44" height="44"></use>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Decline',
  methods: {
    disconnect() {
      this.$parent.disconnect()
    }
  },
  data() {
    return {
      declineImage: '/icons.svg#decline'
    }
  }
}
</script>

<style scoped>
.decline {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}
.decline-key {
  --decline-color: #ff7875;
}
.decline-key:hover {
  --decline-color: #ff4d4f;
}
.decline:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--sui-blue-500);
}
</style>
