<template>
  <GeoEditor ref="geomap"></GeoEditor>
</template>

<script>
import GeoEditor from './GeoEditor'

export default {
  components: {
    GeoEditor
  },
  props: {
    companyUuid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loaded: false,
    data: {
      coordinates: null
    }
  }),
  mounted() {
    this.$nextTick(() => {
      this.$refs.geomap.invalidateSize()
    })
  },
  methods: {
    loadGeoJSON() {
      this.$refs.geomap.setGeoJSON(this.geojson)
      this.$nextTick(() => {
        this.$refs.geomap.invalidateSize()
      })
    },
    clearMap() {
      this.$refs.geomap.clearMap()
    },
    toGeoJSON() {
      return this.$refs.geomap.toGeoJSON()
    }
  }
}
</script>

<style scoped>
.geo-modal {
  width: 500px;
}
</style>
