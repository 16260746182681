<template>
  <div class="masive-notifications">
    <div tabindex="0" ref="successNotifications" class="success-alert">
      <CAlert
        color="warning"
        :closeButton="true"
        :show.sync="isSuccessResponse">
        <div class="success">
          {{ status.success.message }}
        </div>
        <div class="error">
          {{ status.noRegistered.message }}
        </div>
        <div class="error">
          {{ status.deviceNoIdentified.message }}
        </div>
      </CAlert>
      <CAlert
        color="danger"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>
    <form
      id="masiveNotificationsForm"
      ref="form"
      v-on:submit.prevent="onSubmit()">
      <h2>Notificación masiva</h2>
      <hr>
      <div class="d-flex">
        <div class="d-flex">
          <label for="">Números de teléfono de los clientes*</label>
          <div
            v-c-tooltip="'Ingresa el número de cliente y presiona <strong>ENTER</strong>; repetir con cada cliente a notificar'"
            color="primary"
            class="tooltip-btn d-flex">
            <span class="m-auto">?</span>
          </div>
        </div>
        <VoerroTagsInput
          v-model="phoneNumbers"
          placeholder="Escribe el número de teléfono"
          :limit="20"
          :delete-on-backspace="false"
          title="Escribe el numero de teléfono que quieres agregar y presiona 'enter'"
          @keydown="onKeyDown($event)"
          @change="onChange"></VoerroTagsInput>
      </div>
      <div>
        <label for="">Título de la notificación*</label>
        <input
          type="text"
          placeholder="Título"
          v-model="title"
          maxlength="20"
          v-c-tooltip="'Corresponde al Título de la notificación a mostrar en el móvil de los clientes'">
      </div>
      <div>
        <label for="">Mensaje de la notificación*</label>
        <textarea
          cols="30"
          rows="10"
          maxlength="100"
          v-model="message"
          v-c-tooltip="'Corresponde al cuerpo de la notificación a mostrar en el móvil de los clientes'"></textarea>
      </div>
      <div>
        <button
          class="btn btn-primary"
          @click.prevent="openConfirmationModal()">Enviar
        </button>
      </div>
    </form>
    <CModal
      title="Confirmación"
      :show.sync="confirmationModal"
      :close-on-backdrop="false">
      <div class="confirmation-content">
        <div>
          <strong>Se enviará notificación a los siguientes números de teléfono: </strong>
          <ul>
            <li v-for="(phoneNumber, value) in phoneNumbers" :key="value">{{phoneNumber.value}}</li>
          </ul>
        </div>
        <div>
          <strong>Título: </strong>
          <span>{{ title }}</span>
        </div>
        <div>
          <strong>Mensaje: </strong>
          <span>{{ message }}</span>
        </div>
      </div>
      <template #footer>
        <div class="d-flex w-100 m-0">
          <span class="my-auto">¿La información anterior es correcta?</span>
          <div class="ml-auto">
            <button
              type="submit"
              form="masiveNotificationsForm"
              class="btn btn-primary mr-3">
              Confirmar
            </button>
            <button
              class="btn btn-outline-primary"
              @click="confirmationModal = false">Editar</button>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import VoerroTagsInput from '@voerro/vue-tagsinput'
import { getErrorMessage } from '../common/errors'
import '@voerro/vue-tagsinput/dist/style.css'

const initialState = () => ({
  title: '',
  phoneNumbers: [],
  message: '',
  error: {},
  status: {
    success: {
      numbers: [],
      message: ''
    },
    noRegistered: {
      numbers: [],
      message: ''
    },
    deviceNoIdentified: {
      numbers: [],
      message: ''
    }
  },
  currentTagValue: '',
  isSuccessResponse: false,
  isErrorResponse: false,
  confirmationModal: false
})
export default {
  name: 'MasiveNotifications',
  components: {
    VoerroTagsInput
  },
  data() {
    return initialState()
  },
  methods: {
    // open modal
    openConfirmationModal() {
      let invalidNumbers = 0
      Object.keys(this.phoneNumbers).forEach((key) => {
        if (this.phoneNumbers[key].value.length === 10) {
          invalidNumbers += 0
        } else {
          invalidNumbers += 1
        }
      })
      if (this.phoneNumbers.length > 0 && invalidNumbers === 0) {
        this.confirmationModal = true
        this.isErrorResponse = false
      }
      if (this.phoneNumbers.length === 0) {
        this.isSuccessResponse = false
        this.isErrorResponse = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0004' } } })
      }
      if (invalidNumbers > 0) {
        this.isSuccessResponse = false
        this.isErrorResponse = true
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0005' } } })
      }
    },
    // send data
    onSubmit() {
      const phoneNumbers = []
      Object.keys(this.phoneNumbers).forEach((key) => {
        phoneNumbers.push(this.phoneNumbers[key].value)
      })
      this.axios.post('/customers/notifications/bulk', {
        notification: {
          title: this.title,
          message: this.message
        },
        phoneNumbers
      }).then((response) => {
        this.resetForm()
        const { data } = response
        this.isSuccessResponse = true
        this.isErrorResponse = false
        this.NumbersError = []
        if (data.success) {
          Object.keys(data.success).forEach((key) => {
            this.status.success.numbers.push(` ${data.success[key]}`)
          })
          this.status.success.message = `Teléfonos enviados correctamente: ${this.status.success.numbers}`
        }
        if (data.errors.EA0028) {
          Object.keys(data.errors.EA0028).forEach((key) => {
            this.status.noRegistered.numbers.push(` ${data.errors.EA0028[key]}`)
          })
          this.status.noRegistered.message = `Teléfonos no registrados: ${this.status.noRegistered.numbers}`
        }
        if (data.errors.EA0097) {
          Object.keys(data.errors.EA0097).forEach((key) => {
            this.status.deviceNoIdentified.numbers.push(` ${data.errors.EA0097[key]}`)
          })
          this.status.deviceNoIdentified.message = `Télefonos sin identificador: ${this.status.deviceNoIdentified.numbers}`
        }
      }).catch((err) => {
        this.isSuccessResponse = false
        this.isErrorResponse = true
        this.error = getErrorMessage(err)
      })
    },
    onKeyDown(e) {
      // 10 characters + backspace + only numbers
      if (e.keyCode === 13) {
        this.currentTagValue = 0
        e.preventDefault()
      }
      if (!/^[0-9]$/.test(e.key) && e.keyCode !== 8) {
        e.preventDefault()
      }
      if (this.currentTagValue >= 10 && e.keyCode !== 8) {
        e.preventDefault()
      }
    },
    onChange(value) {
      this.currentTagValue = value.length
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    }
  }
}
</script>

<style lang="scss" scoped>
 .masive-notifications{
    display: flex;
    flex-flow: column;
    .success-alert{
      outline: none;
      .success{
        color: green;
      }
      .error{
        color: red;
      }
    }
    >form{
        background-color: white;
        padding: 16px;
        box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.06);
        width: 320px;
        border-radius: 4px;
        margin: auto;
        h2{
            font-size: 18px;
        }
        >div{
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0;
            }
            label{
                font-weight: bold;
            }
            input{
                padding: 6px 8px;
                height: 35px;
            }
            button{
                margin-left: auto;
            }
            .tooltip-btn{
              width: 25px;
              height: 25px;
              border: 0;
              border-radius: 4px;
              margin-left: 4px;

              background-color: #3c4b64;
              color: #FFF;
              &:hover{
                cursor: pointer;
              }
            }
        }
    }
    .confirmation-content{
      display: flex;
      flex-direction: column;
      >div{
        margin-bottom: 8px;
        ul{
          padding-left: 16px;
          margin-bottom: 0px;
        }
      }
    }
}
</style>
