import { getAuth, signInAnonymously } from 'firebase/auth'
import { getToken } from 'firebase/messaging'
import { messaging } from '../config/firebase'

export const Login = () => {
  signInAnonymously(getAuth())
}

export const activateMessage = async () => {
  const token = await getToken(messaging, {
    vapidKey: process.env.VUE_APP_VAPID_KEY
  })
  if (!token) {
    throw new Error('No se pudo generar el token')
  }
  return token
}
