<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    class="edit-sensor">
    <div tabindex="0" ref="EditSensor">
      <CAlert
        color="danger"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>
    <template v-slot:header>
      <h5 class="modal-title">Editar sensor</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <form
      id="editSensor"
      ref="form"
      v-on:submit.prevent="onSubmit">
      <div class="input-form-wrapper">
        <label for="">Nombre</label>
        <input
          type="text"
          placeholder="Identificador"
          v-model="sensorName"
          disabled>
      </div>
      <div class="input-form-wrapper">
        <label for="">Teléfono</label>
        <input
          type="text"
          placeholder="Identificador"
          v-model="phone"
          disabled>
      </div>
      <div class="input-form-wrapper">
        <label for="">Identificador del dispositivo</label>
        <input
          type="text"
          placeholder="Identificador"
          v-model="deviceIdentifier"
          required>
      </div>
    </form>
    <template v-slot:footer>
      <div>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="editSensor"
          class="btn btn-primary ml-1">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  name: '',
  phone: null,
  deviceIdentifier: '',
  error: {},
  isErrorResponse: false
})

export default {
  name: 'EditSensor',
  data() {
    return initialState()
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.name = this.item.item.customerName
        this.phone = this.item.item.customerPhone
        this.deviceIdentifier = this.item.item.deviceIdentifier
        this.isErrorResponse = false
      }
    }
  },
  computed: {
    sensorName() {
      return this.name ? this.name : 'Sin nombre'
    }
  },
  methods: {
    onSubmit() {
      this.axios.put(`/customers/sensor/${this.item.item.customerUuid}`, {
        deviceIdentifier: this.deviceIdentifier
      })
        .then(() => {
          this.$emit('onSuccess')
        })
        .catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
    },
    onCancel() {
      this.$emit('onClose')
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.EditSensor.focus()
      })
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    }
  }
}
</script>

<style lang="scss" scoped>
    .edit-sensor{
        form{
            .input-form-wrapper{
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
                label{
                    font-weight: bold;
                }
                input{
                    padding: 6px 8px;
                    width: 100%;
                    height: 35px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
            }
        }
    }
</style>
