<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <div id="parent">
                <div id="ccp"></div>
                <router-view />
              </div>
            </transition>
          </CContainer>
        </main>
      </div>
      <!-- <TheFooter /> -->
    </div>
  </div>
</template>

<script>
import TheSidebar from '../components/TheSidebar'
import TheHeader from '../components/TheHeader'
import "amazon-connect-streams"
import { getUserData } from '../utils/auth';
// import TheFooter from '../components/TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader
    // TheFooter
  },
  watch: {
    '$route.path'(route) {
      if (route === '/connect') {
        const userData = JSON.parse(getUserData() || {})
        if (!userData.config?.ccpUrl) {
          return
        }
        const subdomain = userData.config?.ccpUrl || process.env.VUE_APP_CONFIG_CONNECT_CCPURL
        connect.core.initCCP(document.getElementById('ccp'), {
          ccpUrl: `https://${subdomain}.my.connect.aws/ccp-v2/`,
          region: 'us-west-2',
          loginPopup: true,
          loginPopupAutoClose: true,
          softphone: {
            allowFramedSoftphone: true
          },
          pageOptions: {
            enableAudioDeviceSettings: true,
            enablePhoneTypeSettings: true
          }
        })

        // When an agent is logs in
        connect.agent(agent => {
          // gather information about the agent
          agent.onAfterCallWork(e => {
            try {
              const site = document.getElementById('reactSite')

              if (site && site.contentWindow) {
                site.contentWindow.postMessage({ contactData: { CustomerNumber: undefined } }, '*')
              }
            } catch (error) { }
          })
        })

        // On inbound communication
        connect.contact(contact => {
          // receive contact metadata
          this.contact = contact.getAttributes()
          console.log(this.contact)
          try {
            const site = document.getElementById('reactSite')

            if (site && site.contentWindow) {
              site.contentWindow.postMessage({ contactData: this.contact }, '*')
            }
          } catch (e) { }
        })
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#parent {
  height: calc(100% - 56px);
  ;
}

#ccp {
  width: 26%;
  height: calc(100% - 56px);
  position: absolute;
  display: none;
}
</style>
