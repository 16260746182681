<template>
  <div class="rating">
    <div class="card">
      <div class="card-header">
        <h3>Listado de calificaciones</h3>
        <p>Promedio: <strong>{{ average }} estrellas</strong></p>
        <p>Total de registros: <strong>{{ allRecords }}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="this.isCompanyAdmin ? fieldsCompanyAdmin : fields"
          :noItemsView="noItemsView"
          hover
          :sorter="{ external: true }"
          :sorterValue.sync="sort"
          @row-clicked="onRowClicked"
          class="main-table"
        >
          <!-- filters -->
          <template #over-table>
            <div class="filters-section">
              <div>
                <div class="open-filter">
                  <input
                    v-model.trim="openFilterInput"
                    type="text"
                    placeholder="Folio"
                    maxlength="8"
                    v-c-tooltip="'Búsqueda de servicios por folio'"
                    @keypress="alphaNumeric($event)"
                  >
                  <button
                    class="btn btn-primary"
                    v-c-tooltip="'Ejecuta la acción de búsqueda de lo ingresado en el campo'"
                    @click="search()"
                  >
                    Buscar
                  </button>
                  <button
                    class="btn btn-light"
                    @click="resetFilters()"
                    v-c-tooltip="'Limpia los filtros y retorna todas las calificaciones'">Limpiar
                  </button>
                </div>
                <div>
                  <date-range-picker
                    v-model="dateRange"
                    :locale-data="{format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
                    :ranges="false"
                    opens="left"
                    :append-to-body="false"
                    :date-format="disabledDates"
                    @update="dateRangeFilter"
                    class="date-range"
                    v-c-tooltip="'Filtra las calificaciones por el rango de fecha indicado'"
                  >
                    <template v-slot:input="picker" style="min-width: 350px;">
                      <span
                        v-if="!picker.startDate && !picker.endDate">
                        Ej: 01/01/2020 - 03/09/2020
                      </span>
                      <span v-else>
                        {{ formatToDatePicker(picker.startDate) }} - {{formatToDatePicker(picker.endDate)}}
                      </span>
                    </template>
                  </date-range-picker>
                </div>
              </div>
              <div class="select-filters-wrapper">
                <div class="select-filter">
                  <label>Filtro por calificación</label>
                  <select
                    v-model="ratingFilterSelected"
                    class="form-control">
                    <option :value="null">Selecciona la cantidad de estrellas</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div class="select-filter">
                  <label>Filtro por estado</label>
                  <select
                    v-model="stateSelected"
                    class="form-control">
                      <option value="" disabled>Seleccione un estado</option>
                      <option value="CDMX">CDMX</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="México">México</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Baja California Sur">Baja California Sur</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Colima">Colima</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Teoloyucan">Teoloyucan</option>
                      <option value="Nicolás Romero ">Nicolás Romero </option>
                      <option value="Ecatepec">Ecatepec</option>
                      <option value="Valle de Bravo">Valle de Bravo</option>
                      <option value="Toluca">Toluca</option>
                  </select>
                </div>
                <div class="select-filter">
                  <label>Filtro por ciudad</label>
                  <select
                    v-model="citySelected"
                    class="form-control"
                    :disabled="!stateSelected">
                    <option :value="null">Selecciona la ciudad</option>
                    <option
                      v-for="city in cityItems"
                      :key="city"
                      :value="city">
                      {{ city }}
                    </option>
                  </select>
                </div>
                <div>
                  <label for="">Filtro por comentarios</label>
                  <div class="tabs-selector">
                    <button
                      @click.prevent="byComments = null"
                      :class="byComments === null ? 'active' : ''">Todos</button>
                    <button
                      @click.prevent="byComments = 1"
                      :class="byComments === 1 ? 'active' : ''">Si</button>
                    <button
                      @click.prevent="byComments = 0"
                      :class="byComments === 0 ? 'active' : ''">No</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- custom table fields -->
          <template #folio="{item}">
            <td>{{item.folio ? item.folio : 'Sin folio'}}</td>
          </template>
          <template #score="{item}">
            <td>{{item.score || 'Sin calificación'}}</td>
          </template>
          <template #scoreService="{item}">
            <td>{{item.scoreService || 'Sin calificación'}}</td>
          </template>
          <template #comment="{item}">
            <td>{{item.comment || 'Sin comentario'}}</td>
          </template>
          <template #commentService="{item}">
            <td>{{item.commentService || 'Sin comentario'}}</td>
          </template>
          <template #show_details="{item}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="openModal(item)"
                v-c-tooltip="'Despliega el detalle del servicio de la calificación seleccionada'"
              >
                {{ Boolean(item._toggled) ? 'Hide' : 'Detalle' }}
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
      </div>
      <div class="card-footer">
        <p>Promedio: <strong>{{ average }} estrellas</strong></p>
        <p>Total de registros: <strong>{{ allRecords }}</strong></p>
      </div>
    </div>
    <CModal
      title="Detalle de servicio"
      color="primary"
      :show.sync="activeModal"
      :closeOnBackdrop="false">
      <div tabindex="0" ref="providerFocusAlertError">
        <CAlert
          :color="error.type || 'danger'"
          :closeButton="true"
          :show.sync="alertError">
          {{error.message}}
        </CAlert>
      </div>
      <div class="text-wrapper">
        <div><strong>Nombre del cliente: </strong><span v-if="infoModal.name">{{ infoModal.name }}</span></div>
        <div><strong>Teléfono: </strong><span>{{ infoModal.phoneNumber || '' }}</span></div>
        <div><strong>Folio: </strong><span>{{ infoModal.folio }}</span></div>
        <div><strong>Día y hora: </strong><span>{{ infoModal.serviceDate }}</span></div>
        <div class="d-flex">
          <strong>Estatus: </strong>
          <div>
            <span
              v-if="infoModal.statusId !== 8 && infoModal.statusId !== 1"
              class="ml-1">{{infoModal.status}}
            </span>
          </div>
          <div
            v-if="infoModal.statusId === 8"
            class="ml-1">
            <span v-if="infoModal.cancellation.isFree === false"> {{infoModal.status}} con costo</span>
            <span v-if="infoModal.cancellation.isFree === true"> {{infoModal.status}} sin costo</span>
          </div>
        </div>
        <div v-if="infoModal.cancellation"><strong>Razón de cancelación: </strong><span class="text-capitalize">{{infoModal.cancellation.reason}}</span></div>
        <div v-if="infoModal.provider">
          <strong>Colaborador: </strong><span class="text-capitalize">{{ formatProviders(infoModal.provider.shortName) }}</span>
        </div>
        <div v-else>
          <strong>Colaborador: </strong><span class="text-capitalize">Sin colaborador</span>
        </div>
        <div><strong>Dirección: </strong><span>{{ formatAddress(infoModal.address) }}</span></div>
        <div><strong>Coordenadas: </strong><span v-if="infoModal.address">{{ infoModal.address.latitude }}, {{infoModal.address.longitude}}</span></div>
        <div><strong>Tipo de domicilio: </strong><span v-if="infoModal.address">{{ infoModal.address.type }}</span></div>
        <div><strong>Referencia: </strong><span v-if="infoModal.address">{{ infoModal.address.reference }}</span></div>
        <div><strong>Método de pago: </strong><span>{{ infoModal.paymentType }}</span></div>
        <div><strong>Dispositivo: </strong><span class="text-capitalize">{{infoModal.deviceFrom}}</span></div>
        <div><strong>Monto solicitado: </strong><span v-if="infoModal.amount">${{ formatMoney(infoModal.amount) }}</span></div>
        <div><strong>Costo de servicio: </strong><span v-if="infoModal.serviceFee">${{ formatMoney(infoModal.serviceFee) }}</span></div>
        <div v-if="infoModal.promocode"><strong>Código promocional: </strong><span>{{ infoModal.promocode }}</span></div>
        <div><strong>Descuento: </strong><span v-if="infoModal.discount">${{ formatMoney(infoModal.discount) }}</span></div>
        <div><strong>Monto total: </strong><span v-if="infoModal.totalAmount">${{ formatMoney(infoModal.totalAmount) }}</span></div>
        <div><strong>Tipo de Compra: </strong><span>{{ !infoModal.hasBuyBack ? 'Primera vez' : 'Recompra' }}</span></div>
        <div>
          <strong>Contacto: </strong>
          <toggle-button
            :value="itemSelected.contacted"
            class="ml-1"
            :width="70"
            :sync="true"
            :color="{checked: '#321fdb' /*Azul*/, unchecked: '#d72030'/*Rojo*/}"
            v-c-tooltip="'No contactado (Rojo) y Contactado (Azul)'"
            @change="itemSelected.contacted = !itemSelected.contacted"
          />
        </div>
        <div>
          <strong>Retroalimentación: </strong>
          <div>
            <textarea
              class="form-control"
              rows="3"
              maxlength="255"
              v-model="itemSelected.feedback"> </textarea>
          </div>
        </div>
      </div>
      <template #footer>
        <div>
          <button
            class="btn btn-primary"
            v-c-tooltip="'Ejecuta la acción para guardar sus cambios'"
            @click="updateScore()"
          >
            Guardar
          </button>
        </div>
        <div>
          <button
            class="btn btn-dark"
            @click="activeModal = false">
            Salir
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import queryparser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const fields = [
  { key: 'folio', label: 'Folio', sorter: false },
  { key: 'createdAt', label: 'Fecha', sorter: true },
  { key: 'score', label: 'Calificación del colaborador', sorter: true },
  { key: 'comment', label: 'Comentario del colaborador', sorter: false },
  { key: 'scoreService', label: 'Calificación del servicio', sorter: false },
  { key: 'commentService', label: 'Comentario del servicio', sorter: false },
  {
    key: 'show_details', label: 'Acción', _style: 'width:1%', sorter: false
  }
]

const fieldsCompanyAdmin = [
  { key: 'folio', label: 'Folio', sorter: false },
  { key: 'createdAt', label: 'Fecha', sorter: true },
  { key: 'score', label: 'Calificación del colaborador', sorter: true },
  { key: 'comment', label: 'Comentario del colaborador', sorter: false },
  {
    key: 'show_details', label: 'Acción', _style: 'width:1%', sorter: false
  }
]
export default {
  name: 'RatingComments',
  components: {
    DateRangePicker
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      items: [],
      itemSelected: {},
      daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      fields,
      fieldsCompanyAdmin,
      companyInfo: {},
      page: 1,
      pages: 0,
      allRecords: 0,
      average: 0,
      dateRange: {
        startDate: '',
        endDate: ''
      },
      startDate: '',
      endDate: '',
      sort: {
        column: 'createdAt',
        asc: false
      },
      activeModal: false,
      infoModal: {},
      alertError: false,
      error: {},
      openFilterInput: '',
      ratingFilterSelected: null,
      citySelected: null,
      stateSelected: null,
      byComments: null,
      cityItems: []
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin'])
  },
  watch: {
    page() {
      this.getRating()
    },
    sort() {
      this.getRating()
    },
    openFilterInput(value) {
      this.openFilterInput = this.openFilterInput.toUpperCase()
      if (!value.length || value.length >= 5) {
        this.getRating()
      }
    },
    ratingFilterSelected() {
      this.getRating()
    },
    citySelected() {
      this.getRating()
    },
    stateSelected(value) {
      const cityItems = {
        CDMX: ['CDMX'],
        Jalisco: ['Guadalajara', 'Zapopan', 'San Pedro Tlaquepaque'],
        México: ['Estado de México'],
        Querétaro: ['Querétaro'],
        'Baja California Sur': ['Los Cabos'],
        'San Luis Potosí': ['San Luis Potosí'],
        Colima: ['Colima'],
        Hidalgo: ['Hidalgo'],
        Teoloyucan: ['Teoloyucan'],
        'Nicolás Romero': ['Nicolás Romero'],
        Ecatepec: ['Ecatepec'],
        'Valle de Bravo': ['Valle de Bravo'],
        Toluca: ['Toluca']
      }
      this.cityItems = cityItems[value] || []
      this.citySelected = null
      this.getRating()
    },
    byComments() {
      this.getRating()
    }
  },
  async mounted() {
    if (this.isCompanyAdmin) {
      await this.getCompanyInfo()
    } else {
      this.getRating()
    }
  },
  methods: {
    async updateScore() {
      try {
        const dataUpdate = { contacted: Boolean(this.itemSelected.contacted), feedback: this.itemSelected.feedback, registryUuid: this.itemSelected.registryUuid }
        const dataResult = await this.$serverless.put('/ally/score', dataUpdate)
        if (dataResult.status === 201) {
          this.activeModal = false
        }
      } catch (err) {
        this.alertError = true
        this.scrollTopError()
        this.error = getErrorMessage(err)
      }
    },
    async getCompanyInfo() {
      try {
        const path = 'providers/current'
        const { data } = await this.axios.get(path)
        this.companyInfo = data
        this.getRating()
      } catch (err) {
        this.companyInfo = {}
      }
    },
    filter() {
      const options = {
        page: this.page
      }
      if (this.startDate && this.endDate) {
        options.serviceDate = `${this.startDate}:${this.endDate}`
      }
      if (this.sort.asc !== null) {
        options.orderBy = this.sort.column
        options.sortBy = this.sort.asc ? 'ASC' : 'DESC'
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options.folio = this.openFilterInput
      }

      if (this.ratingFilterSelected) {
        options.score = this.ratingFilterSelected
      }

      if (this.citySelected) {
        options.city = this.citySelected
      }

      if (this.stateSelected) {
        options.state = this.stateSelected
      }

      if (this.byComments) {
        options.withComment = this.byComments
      }

      if (this.isCompanyAdmin) {
        options.companyId = this.companyInfo.id
      }

      return options
    },
    getRating() {
      const queryString = queryparser.parse(this.filter())
      this.axios.get(`scores?${queryString}`)
        .then((response) => {
          const { data } = response
          data.data.contacted = Boolean(data.data.contacted)
          this.items = data.data
          this.pages = data.pagination.totalPages
          this.allRecords = data.pagination.total
          this.average = data.average
        }).catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    dateRangeFilter() {
      this.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      this.getRating()
    },
    openModal(item) {
      this.activeModal = true
      const uuid = item.serviceUuid
      this.itemSelected = item
      this.itemSelected.contacted = !!this.itemSelected.contacted
      this.axios.get(`/services/${uuid}`)
        .then((response) => {
          this.alertError = false
          const { data } = response
          this.infoModal = data
        })
        .catch(() => {
          this.error = getErrorMessage({ response: { data: { codeApi: 'EA0015' } } })
          this.alertError = true
          this.infoModal = {}
        })
    },
    onRowClicked(item) {
      this.openModal(item)
    },
    resetFilters() {
      this.startDate = null
      this.endDate = null
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.page = 1
      this.sort.column = 'createdAt'
      this.sort.asc = false
      this.openFilterInput = ''
      this.getRating()
    },
    disabledDates(classes, date) {
      const getCalendarDate = moment(date.getTime()).format()

      if (!classes.disabled) {
        classes.disabled = getCalendarDate > moment(new Date()).format()
      }
      return classes
    },
    formatToDatePicker(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatProviders(name) {
      return name === null ? 'Sin nombre' : name
    },
    formatAddress(data) {
      let string = ''
      if (data) {
        const numInt = data.numInt ? data.numInt : ''
        string = `${data.street}, ${data.numExt}, ${numInt}, ${data.zipcode}, 
                ${data.suburb}, ${data.municipality}, ${data.state}`
      }
      return string
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    alphaNumeric(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-zÑñÁÉÍÓÚÜáéíóúü0-9- ]$/.test(key)) {
        e.preventDefault()
      }
    },
    search() {
      if (this.openFilterInput.length || this.openFilterInput.length >= 5) {
        this.getRating()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rating{
    .card{
        .card-header,
        .card-footer{
            display: flex;
            p{
                margin: auto 0 auto auto;
            }
        }
        .card-body{
         .date-range{
             margin-bottom: 8px;
         }
         .filters-section{
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          >div{
            display: flex;
            flex-direction: column;
            &:first-child{
              >div{
                &:last-child{
                  .date-range{
                    width: 100%;
                    @media(min-width: 992px){
                      width: auto;
                    }
                  }
                  @media(min-width: 992px){
                    margin-left: auto;
                  }
                }
              }
            }
            @media(min-width: 992px){
              flex-direction: row;
            }
          }
          .open-filter{
            margin-bottom: 16px;
            display: flex;
            input{
              height: 35px;
              box-sizing: content-box;
              padding: 0 16px;
              border: 1px solid #ccc;
              border-radius: 4px;
              width: calc(100% - 165px);
            }
            button{
              height: 35px;
              border-radius: 4px;
              margin-left: 4px;
            }
          }
          .select-filters-wrapper{
            display: flex;
            flex-direction: column;
            @media(min-width:992px){
              flex-direction: row;
            }
            .select-filter{
              display: flex;
              flex-direction: column;
              margin-right: 8px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
         }
        }
    }
    .text-wrapper{
      >div{
        margin-bottom: 8px
      }
    }
    .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 3);
      @media(min-width: 992px){
        width: 53px;
      }
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
}
</style>
