<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    size="xl"
  >
    <template v-slot:header>
      <h5 class="modal-title">Crear nuevo pedido</h5>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <CAlert
        :color="'danger'"
        :closeButton="true"
        :show.sync="hasError"
      >
        {{ error }}
    </CAlert>
    <form id="createService" ref="form" v-on:submit.prevent="onSubmit" v-if="isModalActive">
        <div class="div-50-perc">
            <div class="div-50-perc">
                <div class="service-type-container">
                    <div class="form-group">
                        <v-select
                            label="description"
                            :options="serviceTypes"
                            @input="serviceTypeOnChange"
                            v-model="serviceType"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!serviceType"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <v-select
                            label="name"
                            :options="categories"
                             @input="categoryOnChange"
                            v-model="category"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!category"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <v-select
                            label="name"
                            :options="companies"
                            @input="companyOnChange"
                            v-model="company"
                        >
                            <template #search="{attributes, events}">
                                <input
                                    class="vs__search"
                                    :required="!company"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="div-50-perc summary-details">
                <span v-if="!showcase || showcase.length === 0">No hay productos que mostrar</span>
                <div class="summary-content"  v-if="showcase && showcase.length > 0">
                    <div class="showcase-container">
                        <button
                            type="button"
                            v-for="product in showcase"
                            v-bind:key="product.id"
                            v-bind:class="[
                                product && productShowCase && productShowCase.id === product.id ? 
                                    'btn showcase-button showcase-button-selected' : 'btn showcase-button'
                            ]"
                            @click="selectShowcase(product)"
                        >
                            {{product.name || product.description}}
                        </button>
                    </div>
                    <div class="form-group">
                        <label v-if="productShowCase && productShowCase.isCurrency">$&nbsp;</label>
                        <div class="amount-container"> 
                            <DecimalInput
                                ref="decimalInput"
                                class="form-control"
                                placeholder="Cantidad"
                                :min="0"
                                :max="9999999.99"
                                v-model="amount"
                                :required="true"
                                @input="amountOnChange"
                                @blur="updateCompaniesFilter"
                                @KeyPressEnter="updateCompaniesFilter"
                            />
                        </div>
                    </div>
                    <div>
                        <span class="summary-total">${{parseFloat(summary.total || 0).toFixed(2)}}</span>
                        &nbsp;
                        <div class="subtotal-container">
                            <span class="summary-subtotal">Servicio: ${{parseFloat(summary.serviceFee || 0).toFixed(2)}}</span>
                            &nbsp;
                            <span class="summary-subtotal">Tarifa: ${{parseFloat(summary.saturationFee || 0).toFixed(2)}}</span><br/>
                            <span v-if="summary.shippingFee > 0" class="summary-subtotal">Costo de Envío: ${{ parseFloat(summary.shippingFee || 0).toFixed(2) }}</span>
                        </div>
                        
                    </div>
                </div> 
            </div>
            <div class="clear">
                <h4>Dirección</h4>
                <div class="map">
                    <MapAddDirecctionVue @onAddressChange="updateCompaniesFilter" />
                </div>
                
                <span v-if="addressError" class="error">{{addressError}}</span>
                <div class="form-group">
                  <div v-if="showExtInput()">
                    <label class="label-text">Exterior</label>
                    <input
                        class="form-control" 
                        type="text"
                        required
                        placeholder="Exterior"
                        v-model="formOkBoy.address.numExt"
                    spellcheck="false"
                    autocomplete="off"
                    aria-autocomplete="none"
                    >
                  </div>
                  <div v-if="showStateInput()">
                    <label class="label-text">Estado</label>
                    <input
                        class="form-control" 
                        type="text"
                        required
                        placeholder="Estado"
                        v-model="formOkBoy.address.state"
                        spellcheck="false"
                        autocomplete="off"
                        aria-autocomplete="none"
                    >
                  </div>
                  <div v-if="showZipcodeInput()">
                    <label class="label-text">Código Postal</label>
                    <input
                        class="form-control" 
                        type="number"
                        required
                        placeholder="Código Postal"
                        v-model="formOkBoy.address.zipcode"
                        spellcheck="false"
                        autocomplete="off"
                        aria-autocomplete="none"
                    >
                  </div>
                  <div v-if="showSuburbInput()">
                    <label class="label-text">Colonia</label>
                    <input
                        class="form-control" 
                        type="text"
                        required
                        placeholder="Colonia"
                        v-model="formOkBoy.address.suburb"
                        spellcheck="false"
                        autocomplete="off"
                        aria-autocomplete="none"
                    >
                  </div>
                    <label class="label-text">Interior</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Interior"
                        v-model="interiorNumber"
                        spellcheck="false"
                        autocomplete="off"
                        aria-autocomplete="none"
                    />
                </div>
                <div class="form-group">
                    <label class="label-text">Referencia</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Referencia"
                        v-model="reference"
                        spellcheck="false"
                        autocomplete="off"
                        aria-autocomplete="none"
                    />
                </div>
            </div>
        </div>
        <div class="div-50-perc service-data-container">
            <h4>Datos del cliente</h4>
            <div class="form-group">
                <label class="label-text">Nombre</label>
                <input
                    class="form-control input-group"
                    type="text"
                    placeholder="Nombre del cliente"
                    v-model="clientName"
                    maxlength="50"
                    required
                    spellcheck="false"
                    autocomplete="off"
                    aria-autocomplete="none"

                />
            </div>
            <div class="form-group">
                <label class="label-text">Teléfono</label>
                <input
                    class="form-control input-group"
                    placeholder="Teléfono del cliente"
                    v-model="clientPhoneNumber"
                    type="tel"
                    pattern="[0-9]{10}"
                    maxlength="10"
                    @paste="onPaste"
                    required
                    spellcheck="false"
                    autocomplete="off"
                    aria-autocomplete="none"
                />
            </div>
            <h4>Fecha del servicio</h4>
            <div class="form-group">
                <label class="label-text">Fecha</label>
                <div class="input-group">
                    <date-range-picker
                        ref="picker"
                        v-model="dateRange"
                        :locale-data="{format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
                        :ranges="false"
                        :single-date-picker="true"
                        opens="center"
                        :append-to-body="false"
                        class="date-range"
                        :date-format="disabledDates"
                        @update="updateDate()"
                        v-c-tooltip="'Fecha de Servicio'"
                    >
                        <template v-slot:input="picker" style="min-width: 350px;">
                            {{ formatToDatePicker(dateRange.startDate, picker) }}
                        </template>
                    </date-range-picker>
                </div>
               
            </div>
            <div class="form-group">
                <label class="label-text">Hora</label>
                <v-select
                    label="displayTime"
                    :options="time"
                    v-model="selectedTime"
                    @input="timeOnChange"
                >
                    <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            :required="!selectedTime"
                            v-bind="attributes"
                            v-on="events"
                        />
                    </template>
                </v-select>
            </div>
            <h4 class="clear">Pago</h4>
            <div class="form-group client-data-container">
                <button
                    type="button"
                    v-bind:class="[
                        paymentType === PAYMENT_TYPES.CASH ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setPaymentType(PAYMENT_TYPES.CASH)"
                >
                    Efectivo
                </button>
                
            </div>
            <div class="form-group client-data-container">
                <button
                    type="button"
                    v-bind:class="[
                        paymentType === PAYMENT_TYPES.TERMINAL ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setPaymentType(PAYMENT_TYPES.TERMINAL)"
                >
                    Terminal
                </button>
            </div>
            <div class="form-group client-data-container">
                <button
                    type="button"
                    v-bind:class="[
                        paymentType === PAYMENT_TYPES.CREDIT ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setPaymentType(PAYMENT_TYPES.CREDIT)"
                >
                    Crédito
                </button>
                
            </div>
            <div class="form-group">
                <label class="label-text">Cupón</label>
                <input 
                    class="form-control input-group"
                    type="text"
                    placeholder="Ingresar cupón"
                    v-model="promocode"
                    @change="promocodeOnChange"
                    @input="upper"
                    spellcheck="false"
                    autocomplete="off"
                    aria-autocomplete="none"
                />
                <span v-if="couponError" class="error">{{couponError}}</span>
                <span v-if="couponSuccess">{{couponSuccess}}</span>
            </div>
             <div class="form-group">
                <label class="label-text">Propina</label>
                <!-- <v-select
                    label="description"
                    placeholder="Ingresar propina"
                    :options="tips"
                    v-model="tip"
                >
                </v-select> -->
                <DecimalInput
                    ref="decimalInput"
                    class="form-control input-group"
                    placeholder="Cantidad"
                    :min="0"
                    :max="9999999.99"
                    v-model="tip"
                    @input="tipOnChange"
                    spellcheck="false"
                    autocomplete="off"
                    aria-autocomplete="none"
                />
            </div>
            <h4 class="clear">Origen</h4>
            <div class="form-group client-data-third-container">
                <button
                    type="button"
                    v-bind:class="[
                        serviceOrigin === SERVICE_ORIGIN.MESSENGER.name ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setOrigin(SERVICE_ORIGIN.MESSENGER.name)"
                >
                    Messenger
                </button>
                
            </div>
            <div class="form-group client-data-third-container">
                <button
                    type="button"
                    v-bind:class="[
                        serviceOrigin === SERVICE_ORIGIN.CALL.name ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setOrigin(SERVICE_ORIGIN.CALL.name)"
                >
                    Llamada
                </button>
            </div>
            <div class="form-group client-data-third-container">
                <button
                    type="button"
                    v-bind:class="[
                        serviceOrigin === SERVICE_ORIGIN.WHATSAPP.name ? 
                            'form-control button-selected' : 'form-control button-unselected'
                    ]"
                    @click="setOrigin(SERVICE_ORIGIN.WHATSAPP.name)"
                >
                    WhatsApp
                </button>
            </div>

        </div>
    </form>

    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm"/>
      <div v-else>
        <button type="button" class="btn btn-secondary mr-1" :disabled="isRequesting" @click="onCancel">Cerrar</button>
        <button
          type="submit"
          :disabled="isRequesting || !summarySuccess"
          form="createService"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Crea el pedido con los datos ingresados'">Finalizar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import moment from 'moment'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue-select/dist/vue-select.css'
import DecimalInput from '../DecimalInput'
import { mapFields } from "vuex-map-fields";
import MapAddDirecctionVue from "../MapAddDirecction.vue";
import _ from 'lodash'

import {
    PAYMENT_TYPES,
    SERVICE_ORIGIN,
    SERVICE_TYPES,
    ALLOWED_DAY_CONFIG,
    SERVICE_TYPE_VIEW
} from '../../common/enums'

import {
    ERRORS_MESSAGES
} from '../../common/errors'

import {
    MEXICO_CITY_TIMEZONE
} from '../../common/const'

const MIN_AMOUNT = 100

export default {
  name: 'CreateServiceModal',
  computed: {
    ...mapFields(["formOkBoy"]),
  },
  watch: {
    '$store.state.formOkBoy.address.latitude': async function() {
        this.addressError = null
        this.calculateSummary(this.amount)
    },
    isModalActive: async function(value) {
        await this.getSchedules()
        this.getServicesTypes()
        this.clientName = ''
        this.clientPhoneNumber = ''
        this.formOkBoy = {
            coordenadasMap: {
                lat: 19.4269903,
                lng: -99.1676463,
            },
            address: {
                latitude: 19.4269903,
                longitude: -99.1676463,
            }
        }
        this.amount = null
        this.amountOnChange(0)
        this.date = moment()
        this.dateRange = {
            startDate: moment(),
            endDate: null
        }
        this.paymentType = PAYMENT_TYPES.CASH
        this.serviceOrigin = SERVICE_ORIGIN.MESSENGER.name
        this.promocode = null
        this.tip = null
        this.address = null
        this.interiorNumber = null
        this.reference = null
        this.selectedDate = null
        this.selectedTime = null
        this.discount = 0
        this.couponError = null
        this.couponSuccess = null
        this.addressError = null
        this.error = null
        this.hasError = false
        this.latestCompany = {}
        this.selectedAddress = {}
    }
  },
  components: {
    vSelect,
    DecimalInput,
    MapAddDirecctionVue,
    DateRangePicker
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  async mounted () {
    await this.getSchedules()
    this.getServicesTypes()
  },
  updated () {
    const elements = document.getElementsByClassName('pac-container pac-logo')
    elements[elements.length - 1].style.zIndex = 99999
  },
  data: () => ({
    PAYMENT_TYPES,
    SERVICE_ORIGIN,
    isRequesting: false,
    isErrorResponse: false,
    serviceType: null,
    category: null,
    zipcodeMinLength: 5,
    company: null,
    productShowCase: null,
    amount: null,
    serviceTypes: [],
    categories: [],
    companies: [],
    servicePricing: [],
    showcase: [],
    tips: [],
    clientName: '',
    clientPhoneNumber: '',
    date: moment(),
    dateRange: {
        startDate: moment(),
        endDate: null
    },
    paymentType: PAYMENT_TYPES.CASH,
    serviceOrigin: SERVICE_ORIGIN.MESSENGER.name,
    promocode: null,
    tip: null,
    address: null,
    editAdress: {},
    interiorNumber: null,
    reference: null,
    summary: {
        total: 0,
        serviceFee: 0,
        saturationFee: 0,
        amount: 0
    },
    scheduleToday: [],
    scheduleWeekend: [],
    scheduleFull: [],
    selectedDate: null,
    selectedTime: null,
    time: [],
    discount: 0,
    daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    couponError: null,
    couponSuccess: null,
    addressError: null,
    error: null,
    hasError: false,
    latestCompany: {},
    summarySuccess: false
  }),
  methods: {
    showExtInput(){
        if (this.addressError === 'Número exterior es obligatorio') {
            return true
        }
    },
    handleBlur(e){
        this.addressError = null
        return false
    },
    showStateInput(){
        if (this.addressError === 'Estado es obligatorio') {
            return true
        }
        return false
    },
    showSuburbInput(){
        if (this.addressError === 'Colonia es obligatoria') {
            return true
        }
        return false
    },
    showZipcodeInput(){
        if (this.addressError === 'Código postal es obligatorio' ){
            if (this.formOkBoy.address.zipcode.length === this.zipcodeMinLength) {
                this.addressError = null
            }
            return true
        }
        return false
    },
    async updateCompaniesFilter(address = null) {
        this.addressError = null
        let query = `typeServicesId=${this.serviceType.id}`
        this.selectedAddress = address ? address : this.selectedAddress

        if (this.selectedAddress && this.selectedAddress.coordenadasMap) {
            const { lat, lng } = this.selectedAddress.coordenadasMap
            query += `&latitude=${lat}&longitude=${lng}`
        }

        if (this.summary.amount && +this.summary.amount > 0) {
            query += `&amount=${this.summary.amount * 100}`
        }

        if(this.company)
            this.latestCompany = this.company

        const { data } = await this.$serverless.get(`/ally/company/available?${query}`)
        data.companies.forEach(company => {
            if (company.activeServices) {
                company.name = `${company.name} (${company.activeServices})`
            }
        })
        this.companies = data.companies

        if (!this.companies.find(obj => this.company && (obj.registryUuid ===  this.company.registryUuid))) {
            this.company = this.companies.length > 0
            ? (this.companies.find(obj => obj.registryUuid === this.latestCompany.registryUuid) ? this.latestCompany : this.companies[0]) 
            : null
            if (this.company && (this.latestCompany.registryUuid !== this.company.registryUuid)) {
                await this.updateServicePricingData()
                this.amountOnChange(this.amount)
            } else {
                this.amountOnChange(this.amount)
            }
        }

        this.calculateSummary(this.amount)
    },
    onPaste(e) {
        e.preventDefault();
        const pastedText = 
            e.clipboardData.getData('text/plain')

        this.clientPhoneNumber =
            pastedText.replace(/\D/g, '')
    },
    formatToDatePicker(date) {
      return date ? (
          moment(date).tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
      ) : moment().tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
    },
    amountOnChange(value){
        if (this.couponError) {
            this.promocode = null
        }
        this.couponError = null
        this.calculateSummary(value)
    },
    async calculateSummary(value) {
        this.error = null
        this.hasError = false

        const {
            latitude,
            longitude
        } = this.formOkBoy.address

        const companyUuid =
            this.company ? this.company.registryUuid : null
        const serviceTypeUuid =
            this.serviceType ? this.serviceType.registryUuid : null

        const selectedAmount = value || 0

        const unitPrice = this.productShowCase ? this.productShowCase.unitPrice : 0

        const amount = !this.productShowCase || this.productShowCase.isCurrency ? selectedAmount * 100 : (
            unitPrice * selectedAmount
        )

        const iva = amount / 1.16

        const tip = (this.tip || 0) * 100
        const discount = (this.discount || 0) * 100

        const service = {
            amount,
            serviceFee: 0,
            saturationFee: 0,
            total: amount + tip - discount,
            discount: 0,
            tip,
            promocode: this.promocode,
            iva,
            saturationMultiplier: 1
        }
        
        if (
            latitude &&
            latitude.toString().trim() != '' &&
            longitude &&
            longitude.toString().trim() != '' &&
            companyUuid &&
            serviceTypeUuid
        ) {
            const serviceDate = this.dateRange.startDate && this.dateRange.startDate != '' && this.selectedTime ?
                `${moment(this.dateRange.startDate).tz(MEXICO_CITY_TIMEZONE).format('YYYY-MM-DD')} ${this.selectedTime.hora}:00` : null
            
            const summaryDb = await this.getSummary(
                serviceTypeUuid,
                latitude,
                longitude,
                amount,
                companyUuid,
                serviceDate,
                service.tip,
                service.promocode,
            )
            if (summaryDb) {
                const {
                    total,
                    discount,
                    tip,
                    serviceFee,
                    saturationFee,
                    iva,
                    saturationMultiplier,
                    shippingFee
                } = summaryDb
                service.total = total || 0
                service.serviceFee = serviceFee || 0
                service.saturationFee = saturationFee || 0
                service.discount = discount || 0
                service.tip = tip || 0
                service.iva = iva || 0
                service.shippingFee = shippingFee || 0
                service.saturationMultiplier = saturationMultiplier 
            }
        }

        this.summary.data = {
            ...service
        }
        this.summary.amount = service.amount / 100
        this.summary.total = service.total / 100
        this.summary.serviceFee = service.serviceFee / 100
        this.summary.saturationFee = service.saturationFee / 100
        this.summary.shippingFee = service.shippingFee / 100
    },
    upper(e) {
        const promocode = e.target.value
        this.promocode = promocode ? promocode.toUpperCase() : null
    },
    async promocodeOnChange(e) {
        try {
            this.discount = 0
            this.couponError = null
            this.couponSuccess = null
        
            const promocode = e.target.value
        
            if (!promocode || promocode.trim() === '') {
                throw 'Empty Promocode'
            }

            const discount =
                await this.getDiscount(promocode.toUpperCase())
            
            if (
                !this.serviceType || !discount || 
                !discount.serviceTypeUuid || 
                discount.serviceTypeUuid.trim() == ''
            ) {
                this.couponError = 'Código no válido'
                throw this.couponError
            }

            const serviceTypePromocode = 
                discount.serviceTypePromocode || []


            const serviceTypePromocodeObj = 
                serviceTypePromocode.find(
                    obj => obj.serviceTypeUuid &&
                        obj.serviceTypeUuid.trim() === this.serviceType.registryUuid.trim()
                )

            if (
                discount.serviceTypeUuid.trim() != this.serviceType.registryUuid.trim() && 
                !serviceTypePromocodeObj
            ) {
                this.couponError = 'Código no válido'
                throw this.couponError
            }
        
            const amount = this.summary.data.amount / 100 || 0
            const minPurchase = 
                serviceTypePromocodeObj ? (
                    serviceTypePromocodeObj.minPurchaseAmount || 0
                ) / 100 : discount.minPurchase

            if (minPurchase && minPurchase > 0 && minPurchase > amount) {
                this.couponError = `El servicio no cuenta con el mínimo de compra ($${minPurchase})`
                throw this.couponError
            }

            this.discount = Number(discount.discount)
            this.calculateSummary(this.amount)
            this.couponSuccess = `Descuento de: $${discount.discount}`
        }catch (error) {
            this.calculateSummary(this.amount)
            return null
        }
    },
    async checkIfCompanyHasCoverage(companyUuid, latitude, longitude) {
        try {
            const { data } = 
                await this.$axios.get(`/company/coverage/${companyUuid}?latitude=${latitude}&longitude=${longitude}`)

            return data
        }catch (error) {
            console.error(error)
            return null
        }
    },
    async getDiscount(promocode) {
        try {
            const { data } = 
                await this.$axios.get(`/services/promocodes/${promocode}/discount`)

            return data
        }catch (error) {
            console.error(error)
            return null
        }
    },
    async getSummary (serviceTypeUuid, latitude, longitude, amount, companyUuid, serviceDate, tip, promocode) {
        try {
            this.summarySuccess = false
            let url = 
                `/checkout/summary/web?serviceTypeUuid=${serviceTypeUuid}&latitude=${latitude}&longitude=${longitude}&amount=${amount}&selectedCompanyUuid=${companyUuid}`
            if (serviceDate) {
                url += `&serviceDate=${serviceDate}`
            }
            if (tip) {
                url += `&tip=${tip}`
            }
            if (promocode) {
                url += `&promocode=${promocode}`
            }

            const { data } = 
                await this.$axios.get(url)
            this.summarySuccess = true
            return data
        } catch (error) {
            if (error.response.data.codeApi === ERRORS_MESSAGES.EA0138.code) {
                return this.addressError = ERRORS_MESSAGES.EA0138.message
            } else {
                this.summarySuccess = false
            }
        }
    },
    updateDate() {
        const date = 
            moment(this.dateRange.startDate).tz(MEXICO_CITY_TIMEZONE).startOf('day')
        const day = date.day()

        if (
            date.format('YYYYMMDD') ===
            moment().tz(MEXICO_CITY_TIMEZONE).startOf('day').format('YYYYMMDD')
        ) {
            this.time = this.scheduleToday
        }else if (day === 0 || day === 6) {
            this.time = this.scheduleWeekend
        } else {
            this.time = this.scheduleFull
        }

        this.selectedTime = this.time[0]
        this.date = date
        this.calculateSummary(this.amount)
    },
    getValidDate() {
        try {
            let validDate =
                moment().tz(MEXICO_CITY_TIMEZONE).startOf('day')

            if (this.serviceType && [
                SERVICE_TYPES.WATER.ID,
                SERVICE_TYPES.HOME_CLEANER.ID,
                SERVICE_TYPES.UPHOLSTERY.ID,
                SERVICE_TYPES.FUMIGATION.ID
            ].includes(+this.serviceType.id)) {
                const addDays = ALLOWED_DAY_CONFIG[+this.serviceType.id] || 0
                validDate = validDate.add(addDays, 'days').startOf('day')
            }

            return validDate
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    disabledDates(classes, date) {
      const selectedDate =
        moment(date).tz(MEXICO_CITY_TIMEZONE)
    
      if (!classes.disabled) {
        const validDate = this.getValidDate()

        classes.disabled = selectedDate < validDate
      }
      return classes
    },
    clearAllServicesData() {
        this.clearServicesData()
        this.clearProductData()
    },
    clearProductData () {
        this.company = null
        this.category = null
        this.serviceType = null
    },
    clearServicesData () {
        this.showcase = []
        this.servicePricing = []
        this.productShowCase = null
        this.summary.total = 0
        this.summary.serviceFee = 0
        this.summary.saturationFee = 0
        this.amount = null
    },
    setSelectedMark(mark) {
      this.selectedMark = mark
    },
    setOrigin(serviceOrigin) {
        this.serviceOrigin = serviceOrigin
    },
    setPaymentType(paymentType) {
        this.paymentType = paymentType
    },
    selectShowcase(product) {
        try {
            this.productShowCase = product;
            this.calculateSummary(this.amount)

        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async getTips () {
       try {
            const { data } = await this.$axios.get(`/checkout/tips`)
            this.tips = data.tips
        }catch (error) {
            this.isErrorResponse = true
        }   
    },
    async getServicePricingByCompany(serviceTypeUuid, companyUuid) {
        try {
            const { data } = await this.$axios.get(`/services/categories/${serviceTypeUuid}/${companyUuid}`)
            this.servicePricing = 
                this.serviceType && +this.serviceType.serviceTypeViewId === SERVICE_TYPE_VIEW.MULTIPLE_TAGS_VIEW 
                ? data.categories 
                : data
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async updateServicePricingData() {
        try {
            if (!this.serviceType || !this.company) {
                return null
            }
            await this.getServicePricingByCompany(
                this.serviceType.registryUuid, 
                this.company.registryUuid
            )
            this.setPricingData()
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    tipOnChange() {
        try {
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    timeOnChange() {
        try {
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    categoryOnChange() {
        try {
            this.clearServicesData()
            this.updateServicePricingData()
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    companyOnChange(company) {
        try {
            this.addressError = null
            this.clearServicesData()
            this.updateServicePricingData()
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async serviceTypeOnChange(serviceType) {
        try {
            this.promocode = null
            this.discount = 0
            this.couponError = null
            this.couponSuccess = null
            this.clearAllServicesData()
            this.serviceType = serviceType
            await this.setServiceSelects(serviceType)
            this.calculateSummary(this.amount)
        }catch (error) {
            this.isErrorResponse = true
        }
    },
    async getServiceTypeCompanies(serviceTypeUuid) {
      try {
        this.isRequesting = true
        await this.updateCompaniesFilter()
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    async getSchedules(selectedDate = null) {
        try {
            const { data } = await this.$axios.get(`/schedules/available`)
            this.scheduleToday = this.returnHoras(data.today);
            this.scheduleFull = this.returnHoras(data.full);
            this.scheduleWeekend = this.returnHoras(data.weekend);
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        }
    },
    returnHoras(horas) {
      let horasReturn = [];
      for (let i = 0; i < horas.length; i++) {
        horasReturn.push({
          displayTime: `${horas[i].minTime.slice(0, 5)}-${horas[i].value.slice(0, 5)}`,
          hora: horas[i].value.slice(0, 5),
          selected: "white",
          recommended: horas[i].recommended,
        });
      }
      return horasReturn;
    },
    setPricingData() {
        try {
            const category = 
                this.servicePricing.find(obj => +obj.id === +this.category.id)

            if (!category) {
                return false;
            }

            this.showcase = category.productShowCase
            this.productShowCase = this.showcase[0]
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        } 
    },
    async setServiceSelects(serviceType) {
        try {
            this.categories = serviceType.categories
            this.serviceType = serviceType
            this.category = serviceType.categories[0]
            this.dateRange.startDate = this.getValidDate()

            this.updateDate()

            await this.getServiceTypeCompanies(serviceType.registryUuid)
            await this.updateServicePricingData()
        } catch (err) {
            this.isErrorResponse = true
        } finally {
            this.isRequesting = false
        }
    },
    async getServicesTypes() {
      try {
        this.isRequesting = true
        const params = {
          includeCategories: true,
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = _.orderBy(data, ['listOrder'])
        const serviceType = this.serviceTypes[0]
        this.setServiceSelects(serviceType)
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    onCancel() {
      this.$emit('onClose')
    },
    async onSubmit() {
        try {
            this.addressError = null
            if (this.isRequesting) {
                return null
            }
            this.isRequesting = true

            if (!this.formOkBoy || !this.formOkBoy.address || 
                !this.formOkBoy.address.latitude || this.formOkBoy.address.latitude.trim() == '' ||
                !this.formOkBoy.address.longitude || this.formOkBoy.address.longitude.trim() == '') {
                this.addressError = 'Dirección obligatoria'
                return null
            }

            if (!this.formOkBoy.address.numExt || this.formOkBoy.address.numExt.trim() === '') {
                this.addressError = 'Número exterior es obligatorio'
                return null
            }

            if (!this.formOkBoy.address.state || this.formOkBoy.address.state.trim() === '') {
                this.addressError = 'Estado es obligatorio'
                return null
            }
            
            if (!this.formOkBoy.address.zipcode || this.formOkBoy.address.zipcode.trim() === '') {
                this.addressError = 'Código postal es obligatorio'
                return null
            }

            if (!this.formOkBoy.address.suburb || this.formOkBoy.address.suburb.trim() === '') {
                this.addressError = 'Colonia es obligatoria'
                return null
            }

            if (this.addressError || this.couponError) {
                return null
            }
            
            const hasCoverage = 
                await this.checkIfCompanyHasCoverage(
                    this.company.registryUuid,
                    this.formOkBoy.address.latitude,
                    this.formOkBoy.address.longitude
                )

            if (!hasCoverage.coverage) {
                this.addressError = 'Empresa no tiene cobertura en esta zona'
                return null
            }

            const minPurchase = 
                this.productShowCase ? this.productShowCase.minPurchase || 0 : 0

            if (minPurchase &&  minPurchase > 0 && minPurchase > this.summary.data.amount) {
                this.error = `El servicio no cuenta con el mínimo de compra ($${parseFloat(minPurchase / 100).toFixed(2)})`
                this.hasError = true
                return null
            }

            const service = {
                amount: this.summary.data.amount,
                details: [{
                    units: this.productShowCase.isCurrency ? (
                        this.summary.data.amount / this.productShowCase.unitPrice
                    ) : this.amount,
                    unitPrice: this.productShowCase.unitPrice,
                    isCurrency: this.productShowCase.isCurrency,
                    total: this.summary.data.amount,
                    serviceTypeCategoryId: +this.category.id,
                    showcaseUuid: this.productShowCase.registryUuid,
                    measurementUnitId: +this.productShowCase.measurementUnit.id,
                    minPurchase: this.productShowCase.minPurchase
                }],
                serviceType: this.serviceType ? this.serviceType.registryUuid : null,
                fee:
                    (this.summary.data.serviceFee || 0) +
                    (this.summary.data.saturationFee || 0),
                serviceFee: (this.summary.data.serviceFee || 0),
                saturationMultiplier: this.summary.data.saturationMultiplier,
                iva: this.summary.data.iva || 0,
                tip: this.summary.data.tip || 0,
                saturationFee: this.summary.data.saturationFee || 0,
                discount: this.summary.data.discount || 0,
                serviceDate: `${this.date.format("YYYY-MM-DD")}T${this.selectedTime.hora}:00.000Z`,
                paymentTypeId: this.paymentType,
                promocode: this.promocode,
                origin: this.serviceOrigin,
                preferredCompanyUuid: this.company.registryUuid,
                customerData: {
                    phoneNumber: this.clientPhoneNumber,
                    name: this.clientName,
                    deviceType: "web",
                    address: {
                        ...this.formOkBoy.address,
                        numInt: this.interiorNumber,
                        reference: this.reference
                    },
                },
                invoiceAddress: {
                    street: this.formOkBoy.address.street,
                    numExt: this.formOkBoy.address.numExt,
                    postalCode: this.formOkBoy.address.zipcode,
                    state: this.formOkBoy.address.state,
                    city: this.formOkBoy.address.municipality,
                },
            }

            const { data } = 
                await this.$axios.post('/services', service)

            return this.$emit('onSuccess', true, 'Pedido creado con éxito.')
        }catch (error) {
            console.error(error)
            this.error = "No se pudo crear el pedido"
            this.hasError = true
        } finally {
            this.isRequesting = false
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.map > * {
    z-index: 9999
}
.clear {
    clear: left;
}
.client-data-third-container {
    float: left;
    width: 33.3%;
}
.label-text {
    color: #757575;
}
.client-data-container {
    float: left;
    width: 33%;
}
.service-data-container {
    padding: 0px 30px !important;
}
.showcase-container {
    margin-bottom: 5px;
}
.summary-subtotal {
    position: relative;
    top: -4px;
}
.summary-total {
    font-weight: 500;
    font-size: 25px
}
.button-unselected {
    color: #757575 !important;
}
.button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
    font-weight: bold;
    margin-right: 10px;
}
.showcase-button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
}
.showcase-button {
    background-color: #f5f5f5;
    color: #757575;
    font-weight: bold;
    margin-right: 10px;
}
.service-type-container {
    width: 95%
}
.summary-details {
    background-color: #f5f5f5;
    border-radius: 4px;
    min-height: 140px;
    padding: 15px !important;
}
.div-50-perc {
    width: 50%;
    padding: 0%;
    margin: 0%;
    height: 100%;
    float: left;
}
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}
.amount-container {
    display: inline-block;
    width: 93%;
}
.subtotal-container {
    display: inline-block;
}
.inline-container {
    display: inline-block;
    width: 97%;
}
.date-range {
    width: 100%;
}
.error {
    color: red;
}
input:focus:invalid {
    border-color: red;
}
</style>