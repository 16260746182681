<template>
  <div class="providers-filter">
    <vSelect
      class="promocodes-select"
      :options="items"
      :map-keydown="handlers"
      v-model="itemSelected"
      placeholder="Seleccione el número de colaborador"
      v-c-tooltip="'Enter para buscar un colaborador que no esté en la lista.'"
      label="phoneNumber"
      @search:blur="resetOnBlur()">
      <template #option="{shortName, phoneNumber}">
        <span v-if="shortName">{{shortName}} - {{phoneNumber}}</span>
        <span v-else>Sin nombre - {{phoneNumber}}</span>
      </template>
      <template #no-options>
        No se encontraron coincidencias, favor de presionar ENTER para buscar otras opciones o verifique el teléfono
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import queryparser from '../../utils/queryparser'

let vue = null

export default {
  name: 'ProvidersFilter',
  components: {
    vSelect
  },
  data() {
    return {
      items: [],
      itemSelected: ''
    }
  },
  watch: {
    itemSelected(value) {
      if (value === null) {
        this.getProviders()
      }
      this.$emit('onFilter', value)
    }
  },
  mounted() {
    this.getProviders()
    vue = this
  },
  methods: {
    handlers: (map, vm) => ({
      ...map,
      13: () => {
        if (!vm.search) {
          return
        }
        if (vm.search.length >= 3) {
          vm.search = vm.search.toUpperCase()
          vue.getProviders(vm.search)
        }
      }
    }),
    filter(value) {
      const options = {
        perPage: 100,
        active: 1
      }
      if (value && value.trim() !== '') {
        options.phoneNumber = value
      }
      return options
    },
    getProviders(value) {
      const queryString = queryparser.parse(this.filter(value))
      this.axios.get(`/providers?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data
        })
    },
    resetOnBlur() {
      if (this.items.length === 1) {
        this.getProviders()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
