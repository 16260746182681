<template>
  <div class="card">
    <div class="card-header">
      <h3>Precios de Productos</h3>
    </div>
    <button
      type="button"
      class="btn-primary add-service-button"
      @click="openCreateModal()"
    >+</button>
    <div class="card-body">
      <CDataTable
        :items="products"
        :fields="fields"
        :sorter="false"
        :clickableRows="true"
        :noItemsView="noItemsView"
        hover
        class="main-table"
        :pagination="true"
      >
        <template #over-table>
          <div class="filters-wrapper mb-3">
            <div class="open-filter">
              <h3>Buscar Precio de Producto</h3>
              <div class="open-filter-wrapper row ml-1">
                <input
                  v-model.trim="openFilterInput"
                  type="text"
                  :placeholder="'Producto'"
                  maxlength="30"
                  v-c-tooltip="'Búsqueda de Precio de Producto por Producto'"
                  class="form-control w-75 mr-2"
                >
                <button
                  class="btn btn-primary"
                  @click="filterSearch"
                >Buscar</button>
                <button
                  class="btn btn-secondary ml-2"
                  @click="clearSearch"
                >Limpiar</button>
              </div>
            </div>
          </div>
        </template>
        <template #company="{item}">
          <td>
            {{ item.company ? item.company.name : null }}
          </td>
        </template>
        <template #product_showcase="{item}">
          <td>
            {{ item.product_showcase && item.product_showcase.service_type_category ? item.product_showcase.service_type_category.name : null}}
          </td>
        </template>
        <template #price="{item}">
          <td>
            ${{ formatMoney(removeDecimals(item.price)) }}
          </td>
        </template>
        <template #minPurchase="{item}">
          <td>
            ${{ formatMoney(removeDecimals(item.minPurchase)) }}
          </td>
        </template>
        <template #actions="{item}">
          <td class="screenActions">
            <button
              @click="editPrice(item)"
              class="btn-width btn btn-success mr-2"
            >Editar</button>
            <button
              @click="confirmDeleteModal(item)"
              class="btn btn-danger ml-1"
            >Eliminar</button>
          </td>
        </template>
      </CDataTable>
      <b-modal
        id="modal-sm"
        title="Confirmar Eliminar"
        hide-footer
      >
        <div v-if="!showModalError" class="text center column w-100 " >
          <div>
            <p>¿Desea Eliminar Precio del Producto: {{ deleteNameProduct }}?</p>
          </div><hr>
          <div class="d-flex justify-content-end">
            <b-button
              class="btn btn-secondary button-modal-sm ml-3 mt-2"
              block
              @click="$bvModal.hide('modal-sm')"
            >Cancelar</b-button>
            <b-button
              class="btn btn-danger button-modal-sm ml-3"
              block
              variant="danger"
              @click="deletePrice(deletePriceId)"
            >Eliminar</b-button>
          </div>
        </div>
        <div
          class="bg-danger error w-auto rounded d-flex justify-content-center"
          v-else
        >
          <label class="mt-2">{{ showModalError }}</label>
        </div>
      </b-modal>
      <CreateProductPrice
        :isModalActive="formModal.isModalActive"
        @onClose="closeCreateModal"
        @onSuccess="onSuccessCreation"
        :isEditing="isEditing"
        :editProductId="editProductId"
      ></CreateProductPrice>
    </div>
  </div>
</template>
<script>
import CreateProductPrice from '../components/CreateProductPrice'

const fields = [
  {
    key: 'company',
    label: 'Empresa',
    _style: 'width: 29%;',
    sorter: false,
    filter: false
  },
  {
    key: 'product_showcase',
    label: 'Producto',
    _style: 'width: 25%;',
    sorter: false,
    filter: false
  },
  {
    key: 'price',
    label: 'Precio del Producto',
    sorter: false,
    filter: false
  },
  {
    key: 'minPurchase',
    label: 'Compra Minima',
    sorter: false,
    filter: false
  },
  {
    key: 'actions',
    label: 'Acciones',
    sorter: false,
    filter: false
  }
]

export default {
  components: {
    CreateProductPrice
  },
  data() {
    return {
      products: [],
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      openFilterInput: '',
      fields,
      formModal: {
        isModalActive: false
      },
      deletePriceId: null,
      deleteNameProduct: null,
      showModalError: null,
      isEditing: null,
      editProductId: null
    }
  },
  mounted() {
    this.getProductsPrice()
  },
  methods: {
    async getProductsPrice() {
      try {
        const { data } = await this.$serverless.get('/products/prices')
        this.products = data
      } catch (error) {
        console.error(error)
      }
    },
    editPrice(item) {
      this.isEditing = true
      this.editProductId = item.registryUuid
      this.formModal.isModalActive = true
    },
    confirmDeleteModal(item) {
      this.showModalError = null
      this.deletePriceId = item.registryUuid
      const { name } = item.product_showcase.service_type_category
      this.deleteNameProduct = name
      this.$bvModal.show('modal-sm')
    },
    async deletePrice(deletePriceId) {
      try {
        await this.$serverless.delete(`/products/prices/${deletePriceId}`)
        this.$bvModal.hide('modal-sm')
        this.getProductsPrice()
        this.deleteNameProduct = null
        this.deletePriceId = null
        this.openFilterInput = null
      } catch (error) {
        this.showModalError = error.message
        return this.showModalError
      }
    },
    async filterSearch(e) {
      e.preventDefault()
      try {
        const name = this.openFilterInput.charAt(0).toUpperCase() + this.openFilterInput.slice(1)
        const { data } = await this.$serverless.get(`/products/prices?name=${name}`)
        this.products = data
      } catch (error) {
        console.error(error)
      }
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      if (value) {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    removeDecimals(value) {
      return value ? value / 100 : value
    },
    clearSearch() {
      this.getProductsPrice()
      this.openFilterInput = ''
    },
    closeCreateModal() {
      this.isEditing = null
      this.editProductId = null
      this.formModal.isModalActive = false
    },
    openCreateModal() {
      this.formModal.isModalActive = true
    },
    onSuccessCreation() {
      this.isEditing = null
      this.editProductId = null
      this.formModal.isModalActive = false
      this.getProductsPrice()
    }
  }
}
</script>
<style>
  .screenActions{
      width: 20%;
    }
  .btn-width{
    width: auto;
  }
  .error{
    height: 15%;
  }
  @media screen and (max-width: 1300px) {
    .screenActions{
      width: 23%;
    }
  }
</style>
