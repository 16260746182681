<template>
  <div class="sales-report">
    <CAlert
      :color="error.type || 'danger'"
      :closeButton="true"
      :show.sync="isErrorResponse">
      {{error.message}}
    </CAlert>
    <div class="card">
      <div class="card-header">
        <date-range-picker
          v-model="dateRange"
          :locale-data="{format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar'}"
          :ranges="false"
          opens="right"
          :append-to-body="false"
          @update="updateDate"
          class="date-range"
          v-c-tooltip="'Indica el rango de fechas por el que se mostrarán los datos'"
        />
        <button
          class="btn btn-success btn-small mx-2"
          type="button"
          @click="downloadXLXS"
          v-c-tooltip="'Exporta en un excel con los datos del reporte'"
        >
          <CIcon name="cilCloudDownload"/>
        </button>
      </div>
      <div
        v-if="!isRequesting"
        class="card-body">
        <div class="section-title">
          <h3
            v-c-tooltip="'Este reporte se divide en tablas por estado, en las cuales se muestra el número total de servicios realizados y el número de servicios realizados por colaborador'">
            Distribución de ventas
          </h3>
        </div>
        <TableReport
          ref="tableReports"
          v-for="(data, key) in data.states"
          :key="key"
          :data="data"
          :state="key">
        </TableReport>
      </div>
      <div
        v-if="isRequesting"
        class="card-body d-flex">
        <CSpinner
          class="mx-auto"
          style="width:4rem;height:4rem;"
          color="#303034"
          grow
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
// eslint-disable-next-line import/no-extraneous-dependencies
import XLSX from 'xlsx'

import queryparser from '../utils/queryparser'
import TableReport from '../components/salesDistributionReport/TableReport'
import { getErrorMessage } from '../common/errors'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const daysOfWeekSpanish = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

export default {
  name: 'SalesDistributionReport',
  components: {
    DateRangePicker,
    TableReport
  },
  data() {
    return {
      data: {},
      daysOfWeekSpanish,
      lastWeek: {
        monday: '',
        sunday: ''
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      isRequesting: false,
      error: {},
      isErrorResponse: false
    }
  },
  mounted() {
    this.getLastWeek()
  },
  methods: {
    downloadXLXS() {
      const {
        states
      } = this.data

      const stateAliases = {
        Unknown: 'Desconocido'
      }

      const data = Object
        .keys(states)
        .map((stateKey) => {
          const state = stateAliases[stateKey] || stateKey
          const stateData = states[stateKey]

          const rows = Object.keys(stateData.providers).map((providerKey) => {
            const providerData = stateData.providers[providerKey]
            return [providerData.name, providerData.total]
          })

          rows.unshift(['Colaborador', '# de servicios'])
          rows.unshift([state])
          rows.push(['TOTAL', stateData.total])
          rows.push([])

          return rows
        })
        .reduce((arr, v) => arr.concat(v), [])

      const start = moment(this.dateRange.startDate).format('DD/MM/YYYY')
      const end = moment(this.dateRange.endDate).format('DD/MM/YYYY')

      const worksheet = XLSX.utils.aoa_to_sheet(data)
      const workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Distribución de ventas')
      XLSX.writeFile(workbook, `Ventas ${start}-${end}.xlsx`)
    },
    filter() {
      const options = {
        serviceDate: `${this.lastWeek.monday}:${this.lastWeek.sunday}`
      }

      return options
    },
    getData() {
      this.isRequesting = true
      const queryString = queryparser.parse(this.filter())
      if (this.getDiffBetweenDays() < 31) {
        this.axios.get(`report/provider?${queryString}`)
          .then((response) => {
            this.isErrorResponse = false
            const { data } = response
            this.data = data
          })
          .catch((err) => {
            this.isErrorResponse = true
            this.error = getErrorMessage(err)
          })
          .finally(() => {
            this.isRequesting = false
          })
      } else {
        this.isErrorResponse = true
        this.isRequesting = false
        this.error = getErrorMessage({ response: { data: { codeApi: 'EAF0003' } } })
        this.data = {}
      }
    },
    updateDate() {
      this.lastWeek.monday = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.lastWeek.sunday = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      this.getData()
    },
    getLastWeek() {
      const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
      const beforeTwoWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000)

      if (moment().day() >= 1) {
        this.dateRange.startDate = moment(beforeOneWeek).day('Monday').format()
        this.dateRange.endDate = moment().day('Sunday').format()
      } else {
        this.dateRange.startDate = moment(beforeTwoWeek).day('Monday').format()
        this.dateRange.endDate = moment(beforeOneWeek).day('Sunday').format()
      }

      this.updateDate()
    },
    getDiffBetweenDays() {
      const a = moment(this.dateRange.startDate)
      const b = moment(this.dateRange.endDate)
      const diffDays = b.diff(a, 'days')
      return diffDays
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  button {
    height: 34px
  }
}
.card-body{
  .section-title{
    display: flex;
    margin-bottom: 16px;
    h3{
      margin-bottom: 0;
    }
    span{
      margin: auto auto auto 8px;
      padding: 4px 8px;
      background-color: #303034;
      color: #FFFFFF;
      border-radius: 4px;
    }
  }
}
</style>
