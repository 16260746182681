<template>
  <div class="services cards-wrapper">
    <!-- semanal -->
    <div class="mb-3">
      <div class="accordeon-icon" v-b-toggle.collapse-1>
        <h2 class="ml-3" variant="primary">Resumen de servicios</h2>
      </div>
      <b-collapse id="collapse-1">
        <div class="services-overview">
          <div class="resume-card">
            <h2 class="ml-5">Resumen de servicios semanal</h2>
          </div>
          <div class="card">
            <div class="finished-services">
              <p>Finalizados</p>
              <span class="text-success">{{
                weeklyOrdersReportData.serviceFinished
              }}</span>
            </div>
            <div class="canceled-services">
              <p>Cancelados</p>
              <span class="text-danger">{{
                weeklyOrdersReportData.servicesCanceled
              }}</span>
            </div>
            <div class="pending-services">
              <p>Pendientes</p>
              <span>
                {{
                  weeklyOrdersReportData.servicesOrder -
                    (weeklyOrdersReportData.serviceFinished +
                      weeklyOrdersReportData.servicesCanceled)
                }}
              </span>
            </div>
            <div class="late-services">
              <p>Retrasados</p>
              <span class="text-danger">
                {{
                  weeklyOrdersReportData.lateOrders || 0
                }}
              </span>
            </div>
            <div class="finished-services-percentage">
              <p>% Finalizados</p>
              <span
                :class="
                  weeklyFinishedPercentage >= 70 ? 'text-success' : 'text-danger'
                "
              >
                {{ weeklyFinishedPercentage }}%
              </span>
            </div>
            <div class="canceled-services-percentage">
              <p>% Cancelados</p>
              <span
                :class="
                  weeklyCanceledPercentage >= 10 ? 'text-danger' : 'text-success'
                "
              >
                {{ weeklyCanceledPercentage }}%
              </span>
            </div>
            <div v-if="!isCompanyAdmin" class="liters-sold">
              <p>Unidades vendidas</p>
              <span>{{ weeklyOrdersReportData.totalLiters }}</span>
            </div>
          </div>
          <div class="resume-card">
            <h2 class="ml-5">Resumen de servicios diario</h2>
          </div>
          <div class="card">
            <div class="finished-services">
              <p>Finalizados</p>
              <span class="text-success">{{
                diaryOrdersReportData.serviceFinished
              }}</span>
            </div>
            <div class="canceled-services">
              <p>Cancelados</p>
              <span class="text-danger">{{
                diaryOrdersReportData.servicesCanceled
              }}</span>
            </div>
            <div class="pending-services">
              <p>Pendientes</p>
              <span>
                {{
                  diaryOrdersReportData.servicesOrder -
                    (diaryOrdersReportData.serviceFinished +
                      diaryOrdersReportData.servicesCanceled)
                }}
              </span>
            </div>
            <div class="late-services">
              <p>Retrasados</p>
              <span class="text-danger">
                {{
                  diaryOrdersReportData.lateOrders || 0
                }}
              </span>
            </div>
            <div class="finished-services-percentage">
              <p>% Finalizados</p>
              <span
                :class="
                  diaryFinishedPercentage >= 70 ? 'text-success' : 'text-danger'
                "
              >
                {{ diaryFinishedPercentage }}%
              </span>
            </div>
            <div class="canceled-services-percentage">
              <p>% Cancelados</p>
              <span
                :class="
                  diaryCanceledPercentage >= 10 ? 'text-danger' : 'text-success'
                "
              >
                {{ diaryCanceledPercentage }}%
              </span>
            </div>
            <div v-if="!isCompanyAdmin" class="liters-sold">
              <p>Unidades vendidas</p>
              <span>{{ diaryOrdersReportData.totalLiters }}</span>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <CAlert v-if="state" color="success">
      Filtro de servicios{{
        stateFilterLabel ? ` ${stateFilterLabel} ` : " "
      }}del estado de: "{{ state }}"
    </CAlert>
    <div tabindex="0" ref="successServices" class="alerts">
      <CAlert
        :color="error.type || 'danger'"
        :closeButton="true"
        :show.sync="topAlertError"
      >
        {{ error.message }}
      </CAlert>
      <CAlert
        :color="error2.type || 'danger'"
        :closeButton="true"
        :show.sync="topAlertError2"
      >
        {{ error2.message }}
      </CAlert>
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess"
      >
        {{ success.message }}
      </CAlert>
      <CAlert
        color="primary"
        :show.sync="newServicesAlert.hasSuccess"
        closeButton
      >
        {{ newServicesAlert.message }}
      </CAlert>
    </div>
    <div class="card">
      <div class="card-header">
        <h3>Listado de servicios</h3>
        <p>
          Total de servicios: <strong>{{ totalRecords }}</strong>
        </p>
      </div>
      <div v-if="!isCompanyAdmin && !readonly">
        <button
          id="add_service"
          type="button"
          @click="openCreateModal"
          class="btn-primary add-service-button"
        >+
        </button>
        <b-tooltip target="add_service" placement="left">
          Abre el modal para crear un nuevo pedido
        </b-tooltip>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="isCompanyAdmin ? fieldsCompanyAdmin : fields"
          :sorter="{ external: true }"
          :sorterValue.sync="sort"
          :clickableRows="true"
          @row-clicked="onRowClicked"
          :noItemsView="noItemsView"
          :column-filter="true"
          hover
          class="main-table"
        >
          <!-- table filters -->
          <template #over-table>
            <div class="filters-section">
              <div class="open-filter">
                <div>
                  <input
                    v-model.trim="openFilterInput"
                    type="text"
                    :placeholder="isCompanyAdmin ? 'Folio' : 'Teléfono - Folio'"
                    maxlength="13"
                    v-c-tooltip="'Búsqueda de servicios por teléfono o folio'"
                    @keypress.enter="openFilter(true)"
                    class="open-filter-input"
                  />
                  <button
                    class="btn btn-primary"
                    v-c-tooltip="
                      'Ejecuta la acción de búsqueda de lo ingresado en el campo'
                    "
                    @click="openFilter(true)"
                  >
                    Buscar
                  </button>
                  <button
                    class="btn btn-light"
                    v-c-tooltip="
                      'Limpia todos los filtros aplicados (incluyendo el de estatus y fecha)'
                    "
                    @click="clearFilters()"
                  >
                    Limpiar
                  </button>
                </div>
                <ProvidersFilter
                  @onFilter="onProviderFilter"
                  v-if="!isCompanyAdmin"
                >
                </ProvidersFilter>
                <StateFilter
                  @onFilter="onStateFilter"
                  :items="arrStates"
                  v-if="!isCompanyAdmin"
                >
                </StateFilter>
                <CompanyFilter
                  @onFilter="onCompanyFilter"
                  v-if="!isCompanyAdmin"
                >
                </CompanyFilter>
                <div v-if="isCompanyAdmin && companyInfo.pricePerLiter">
                  <h3>
                    Precio por unidad:
                    <span class="text-success">
                      ${{ companyInfo.pricePerLiter }}
                    </span>
                  </h3>
                </div>
              </div>
              <div class="second-fiters">
                <div>
                  <label for="">Filtro de evidencia</label>
                  <div class="tabs-selector">
                    <button
                      @click.prevent="hasEvidence = null"
                      :class="hasEvidence === null ? 'active' : ''"
                    >
                      Todos
                    </button>
                    <button
                      @click.prevent="hasEvidence = 1"
                      :class="hasEvidence === 1 ? 'active' : ''"
                    >
                      Con evidencia
                    </button>
                    <button
                      @click.prevent="hasEvidence = 0"
                      :class="hasEvidence === 0 ? 'active' : ''"
                    >
                      Sin evidencia
                    </button>
                  </div>
                </div>
                <div>
                  <label for="">Filtro de Cobertura</label>
                  <div class="tabs-selector">
                    <button
                      @click.prevent="hasCoverage = null"
                      :class="hasCoverage === null ? 'active' : ''"
                    >
                      Todos
                    </button>
                    <button
                      @click.prevent="hasCoverage = true"
                      :class="hasCoverage === true ? 'active' : ''"
                    >
                      Con cobertura
                    </button>
                    <button
                      @click.prevent="hasCoverage = false"
                      :class="hasCoverage === false ? 'active' : ''"
                    >
                      Sin cobertura
                    </button>
                  </div>
                </div>
                <br />
                <div>
                  <date-range-picker
                    v-model="dateRange"
                    :locale-data="{
                      format: 'dd/mm/yyyy',
                      daysOfWeek: daysOfWeekSpanish,
                      applyLabel: 'Aplicar',
                      cancelLabel: 'Cancelar',
                    }"
                    :ranges="false"
                    opens="left"
                    :append-to-body="false"
                    v-c-tooltip="
                      'Filtra los resultados por el rango de fecha de entrega de los servicios'
                    "
                    class="date-range"
                  />
                  <CDropdown
                    v-if="!repurchase"
                    color="primary"
                    toggler-text="Filtro Estatus"
                    class="status-filter"
                    placement="bottom-end"
                    v-c-tooltip="
                      'Filtra los servicios por el estatus en el que se encuentran'
                    "
                  >
                    <div v-if="!isCompanyAdmin">
                      Creado
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.CREATED.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.CREATED.ID)"
                      />
                    </div>
                    <div v-if="!isCompanyAdmin">
                      Por pagar
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.PAYMENT_PENDING.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.PAYMENT_PENDING.ID)"
                      />
                    </div>
                    <div>
                      Validado
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.ACTIVE.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.ACTIVE.ID)"
                        :disabled="
                          isOneLeft === STATUS_SERVICES.ACTIVE.ID ? true : false
                        "
                      />
                    </div>
                    <div>
                      Asignado
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.ASSIGNED.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.ASSIGNED.ID)"
                        :disabled="
                          isOneLeft === STATUS_SERVICES.ASSIGNED.ID
                            ? true
                            : false
                        "
                      />
                    </div>
                    <div>
                      Aceptado
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.TAKEN.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.TAKEN.ID)"
                        :disabled="
                          isOneLeft === STATUS_SERVICES.TAKEN.ID
                            ? true
                            : false
                        "
                      />
                    </div>
                    <div>
                      En camino
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.ACCEPTED.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.ACCEPTED.ID)"
                        :disabled="
                          isOneLeft === STATUS_SERVICES.ACCEPTED.ID
                            ? true
                            : false
                        "
                      />
                    </div>
                    <div>
                      Llegó
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.ARRIVED.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.ARRIVED.ID)"
                        :disabled="
                          isOneLeft === STATUS_SERVICES.ARRIVED.ID
                            ? true
                            : false
                        "
                      />
                    </div>
                    <div>
                      Terminado
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.FINISHED.ID)
                        "
                        @click="statusFilterParams(STATUS_SERVICES.FINISHED.ID)"
                      />
                    </div>
                    <div v-if="state && repurchase && !isCompanyAdmin">
                      Re-compra
                      <input
                        type="checkbox"
                        :checked="repurchase === 1"
                        @click="repurchase = repurchase ? null : 1"
                        @change="filter"
                      />
                    </div>
                    <div v-if="!isCompanyAdmin">
                      Cancelado por cliente
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(STATUS_SERVICES.CANCEL_BY_CLIENT.ID)"
                        @click="
                          statusFilterParams(
                            STATUS_SERVICES.CANCEL_BY_CLIENT.ID
                          )
                        "
                      />
                    </div>
                    <div v-if="!isCompanyAdmin">
                      Cancelado por colaborador
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(
                            STATUS_SERVICES.CANCEL_BY_PROVIDER.ID
                          )
                        "
                        @click="
                          statusFilterParams(
                            STATUS_SERVICES.CANCEL_BY_PROVIDER.ID
                          )
                        "
                      />
                    </div>
                    <div v-if="!isCompanyAdmin">
                      Cancelado por admin sin costo
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(
                            STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
                          )
                        "
                        @click="
                          statusFilterParams(
                            STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
                          )
                        "
                      />
                    </div>
                    <div v-if="!isCompanyAdmin">
                      Cancelado por admin con costo
                      <input
                        type="checkbox"
                        :checked="
                          statusArray.includes(
                            STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID
                          )
                        "
                        @click="
                          statusFilterParams(
                            STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID
                          )
                        "
                      />
                    </div>
                  </CDropdown>
                </div>
              </div>
            </div>
          </template>
          <template #serviceType-filter>
            <select
              v-model="serviceTypeSelected"
              v-c-tooltip="'Filtra los resultados por el tipo de servicio'"
              @change="filter"
              v-show="!isCompanyAdmin"
            >
              <option value="">Todos</option>
              <option
                v-for="serviceType in serviceTypeData"
                :key="serviceType.value"
                :value="serviceType.value"
              >
                {{ serviceType.text }}
              </option>
            </select>
          </template>
          <!-- column filter -->
          <template #deviceFrom-filter>
            <select
              v-model="deviceFilterSelected"
              v-c-tooltip="
                'Filtra los servicios por el tipo de dispositivo del que se realizó la orden'
              "
            >
              <option value="">Todos</option>
              <option value="android">Android</option>
              <option value="ios">iOS</option>
              <option value="web">Web</option>
            </select>
          </template>
          <!-- table data -->
          <template #phoneNumber="{ item }">
            <td :class="evidenceFormat(item.evidence, item.statusId)">
              {{ item.phoneNumber }}
            </td>
          </template>
          <template #amount="{ item }">
            <td>${{ formatMoney(item.totalAmount) }}</td>
          </template>
          <template #serviceDate="{ item }">
            <td>
              {{
                formatDate(
                  item.deliveryDate ? item.deliveryDate : item.serviceDate
                )
              }}
            </td>
          </template>
          <template #statusId="{ item }">
            <td>
              <CBadge
                v-if="!item.cancellation"
                :color="getBadge(item.statusId)"
              >
                {{ item.status }}
              </CBadge>
              <CBadge v-if="item.cancellation" :color="getBadge(item.statusId)">
                <span
                  v-if="item && item.cancellation && item.cancellation.isFree === false"
                >{{ item.status }} con costo</span
                >
                <span
                  v-if="item && item.cancellation && item.cancellation.isFree === true"
                >{{ item.status }} sin costo</span
                >
              </CBadge>
            </td>
          </template>
          <template #timeLeft="{ item }">
            <td>
              <CBadge v-if="!item.cancellation" :class="getTimeLeftClass(item)">
                {{ getTimeLeftLabel(item) }}
              </CBadge>
            </td>
          </template>
          <template #folio="{ item }">
            <td>
              <CBadge :style="getBranchClass(item)">
                {{ item.folio }}
              </CBadge>
            </td>
          </template>
          <template #serviceType="{ item }">
            <td :set="imageSrc = getServiceTypeIcon(item.serviceType)">
              <div class="center-image">
                <img v-if="imageSrc" width="24px" :src="imageSrc" />
                {{ item.serviceType }}
              </div>
            </td>
          </template>
          <template #priority_flag="{ item }">
            <td class="center-flag" v-if="item.priorityFlag">
              <CIcon name="cilFlagAlt"></CIcon>
            </td>
            <td v-else></td>
          </template>
          <template #provider="{ item }">
            <td v-if="item.provider">
              {{ formatProviders(item.provider.shortName) }}
            </td>
            <td v-else>Sin Colaborador</td>
          </template>
          <template #deviceFrom="{ item }">
            <td class="text-capitalize">
              {{ item.deviceFrom }}
            </td>
          </template>
          <template #location="{ item }">
            <td class="text-capitalize">
              {{ formatUnknown(item.location) }}
            </td>
          </template>
          <template #scheduleDay="{ item }">
            <td>
              {{ formatScheduleDay(item.scheduleDay) }}
            </td>
          </template>
          <template #show_details="{ item }">
            <td class="py-2">
              <CButton
                :id="`detail_${item.id}`"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="openModal(item)"
              >
                {{ Boolean(item._toggled) ? "Hide" : "Detalle" }}
              </CButton>
              <b-tooltip :target="`detail_${item.id}`" placement="left">
                Despliega el detalle del servicio y sus funciones (según el
                estatus)
              </b-tooltip>
            </td>
          </template>
        </CDataTable>
        <CPagination
          v-if="!repurchase"
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
      </div>
      <div class="card-footer">
        <p>
          Total de servicios: <strong>{{ totalRecords }}</strong>
        </p>
      </div>
    </div>
    <!-- modal -->
    <ServiceDetail 
      :registryUuid="serviceDetailUuid"
      :isModalActive="detailModal.isActive"
      @onClose="closeServiceDetailModal"
    />
    <CreateService
      v-if="createModal.isActive"
      :isModalActive="createModal.isActive"
      @onClose="closeCreateModal"
      @onSuccess="onSuccessCreation"
    />

  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex'

import { onMessage } from 'firebase/messaging'

import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { mapFields } from 'vuex-map-fields'
import { getErrorMessage, ERRORS_MESSAGES } from '../common/errors'
import PaymentType from '../components/services/PaymentType'
import ProvidersFilter from '../components/services/ProvidersFilter'
import StateFilter from '../components/services/StateFilter'
import CompanyFilter from '../components/services/CompanyFilter'
import CreateService from '../components/services/CreateService'
import ServiceDetail from '../components/services/ServiceDetail'
import 'moment-timezone'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import GoogleMapsMark from '../components/GoogleMapsMark'
import queryParser from '../utils/queryparser'
import {
  STATUS_SERVICES,
  STATUS_SERVICES_TO_CANCEL_BY_ADMIN,
  STATUS_SERVICES_FROM_ONE_TO_FIVE,
  STATUS_SERVICES_TO_ASSIGN,
  STATUS_SERVICES_TO_REASSIGN,
  STATUS_SERVICES_TO_CHANGE_STATUS,
  STATUS_SERVICES_TO_EVIDENCE,
  ALL_STATUS_SERVICES,
  CANCELLED_STATUS,
  STATUS_SERVICES_TO_ASSIGN_COMPANY,
  STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED,
  STATUS_SERVICES_FINISHED_SERVICE,
  SERVICE_TYPES,
  STATUS_SERVICES_TO_FINISH_SERVICE,
  PERMISSIONS,
  PAYMENT_TYPES,
  SERVICE_COMMENT_TYPES
} from '../common/enums'

import { messaging } from '../config/firebase'

import * as MESSAGES from '../common/messages'
import { copyToClipboard, downloadFile } from '../utils'
import { Login, activateMessage } from '../utils/notification'

import logo from '../../public/okboy.png'

const { audio } = require('../assets/audio/okboy-noti')

const detailFields = [
  { key: 'option', label: 'Cantidad' },
  { key: 'serviceTypeCategory', label: 'Categoría' },
  { key: 'showcase', label: 'Presentación' },
  { key: 'unitPrice', label: 'Precio Unitario' }
]

const notesField = { key: 'notes', label: 'Notas' }

const fields = [
  {
    key: 'phoneNumber',
    label: 'Teléfono',
    sorter: false,
    filter: false
  },
  {
    key: 'folio',
    label: 'Folio',
    sorter: false,
    filter: false
  },
  {
    key: 'timeLeft',
    label: 'Tiempo Faltante de Entrega',
    sorter: false,
    filter: false
  },
  {
    key: 'hasCoverage',
    label: '¿Tene Cobertura?',
    sorter: false,
    filter: false
  },
  {
    key: 'amount',
    label: 'Total de la orden',
    sorter: false,
    filter: false
  },
  {
    key: 'serviceType',
    label: 'Tipo de servicio',
    sorter: false,
    filter: false
  },
  { key: 'serviceDate', label: 'Fecha del servicio', filter: false },
  { key: 'createdAt', label: 'Fecha de registro', filter: false },
  {
    key: 'statusId',
    label: 'Estatus',
    sorter: false,
    filter: false
  },
  {
    key: 'priority_flag',
    label: 'Prioritario',
    sorter: false,
    filter: false
  },
  {
    key: 'provider',
    label: 'Colaborador',
    sorter: false,
    filter: false
  },
  {
    key: 'deviceFrom',
    label: 'Dispositivo',
    sorter: false,
    filter: true
  },
  {
    key: 'location',
    label: 'Estado',
    sorter: false,
    filter: false
  },
  {
    key: 'preferredCompanyLabel',
    label: 'Prefiere',
    sorter: false,
    filter: false
  },
  {
    key: 'scheduleDay',
    label: 'Horario',
    sorter: false,
    filter: false
  },
  {
    key: 'show_details',
    label: 'Acción',
    _style: 'width:1%',
    sorter: false,
    filter: false
  }
]

const fieldsCompanyAdmin = [
  {
    key: 'folio',
    label: 'Folio',
    sorter: false,
    filter: false
  },
  {
    key: 'timeLeft',
    label: 'Tiempo Faltante de Entrega',
    sorter: false,
    filter: false
  },
  {
    key: 'serviceType',
    label: 'Tipo de servicio',
    sorter: false,
    filter: false
  },
  {
    key: 'amount',
    label: 'Monto',
    sorter: false,
    filter: false
  },
  { key: 'serviceDate', label: 'Fecha del servicio', filter: false },
  {
    key: 'statusId',
    label: 'Estatus',
    sorter: false,
    filter: false
  },
  {
    key: 'priority_flag',
    label: 'Prioritario',
    sorter: false,
    filter: false
  },
  {
    key: 'provider',
    label: 'Colaborador',
    sorter: false,
    filter: false
  },
  {
    key: 'show_details',
    label: 'Acción',
    _style: 'width:1%',
    sorter: false,
    filter: false
  }
]

const daysOfWeekSpanish = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

const editAdressInitialState = () => ({
  street: '',
  numExt: '',
  numInt: '',
  zipcode: '',
  suburb: '',
  municipality: '',
  state: '',
  type: '',
  reference: ''
})

const editAdressIntegerInitialState = () => ({
  latitude: null,
  longitude: null
})

export default {
  name: 'Services',
  components: {
    DateRangePicker,
    GoogleMapsMark,
    ProvidersFilter,
    StateFilter,
    CompanyFilter,
    PaymentType,
    CreateService,
    ServiceDetail
  },
  props: {
    serviceTypeUuid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      serviceDetailUuid: '',
      isCreatingLink: false,
      readonly: false,
      refreshInterval: null,
      createModal: {
        isActive: false
      },
      NewServicesAlertTimer: 10,
      schedules: null,
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      items: [],
      fields,
      detailFields,
      notesField,
      companyInfo: {},
      firebaseToken: null,
      fieldsCompanyAdmin,
      detailModal: {
        isActive: false
      },
      infoModal: { registryUuid: '' },
      updateableStatus: [1],
      status: '',
      statusArray: [
        STATUS_SERVICES.ACTIVE.ID,
        STATUS_SERVICES.ACCEPTED.ID,
        STATUS_SERVICES.ARRIVED.ID,
        STATUS_SERVICES.FINISHED.ID,
        STATUS_SERVICES.ASSIGNED.ID,
        STATUS_SERVICES.TAKEN.ID
      ],
      statusString: '',
      sort: { column: 'serviceDate', asc: false },
      openFilterInput: '',
      page: 1,
      pages: 0,
      totalRecords: 0,
      // date range picker
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      dateSorter: null,
      prevProvider: '',
      addressUuid: '',
      serviceUuid: '',
      success: {
        hasSuccess: false,
        message: ''
      },
      newServicesAlert: {
        hasSuccess: false,
        message: ''
      },
      error: {},
      error2: {},
      daysOfWeekSpanish,
      isDatePicker: false,
      isClear: false,
      hasCost: false,
      editAdress: editAdressInitialState,
      editAdressInteger: editAdressIntegerInitialState,

      editDate: {
        startDate: '',
        endDate: ''
      },
      deviceFilterSelected: '',
      alertError: false,
      topAlertError2: false,
      // to edit status if created
      isActive: false,
      // enums
      PAYMENT_TYPES,
      STATUS_SERVICES,
      STATUS_SERVICES_TO_CANCEL_BY_ADMIN,
      STATUS_SERVICES_FROM_ONE_TO_FIVE,
      STATUS_SERVICES_TO_ASSIGN,
      STATUS_SERVICES_TO_REASSIGN,
      STATUS_SERVICES_TO_CHANGE_STATUS,
      STATUS_SERVICES_TO_EVIDENCE,
      STATUS_SERVICES_TO_ASSIGN_COMPANY,
      STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED,
      STATUS_SERVICES_FINISHED_SERVICE,
      STATUS_SERVICES_TO_FINISH_SERVICE,
      SERVICE_COMMENT_TYPES,
      editByAddress: true,
      textCopied: null,
      companies: [],
      serviceTypeSelected: '',
      serviceTypeData: [],
      isDownloadingCSV: false,
      topAlertError: false,
      finishService: true,
      newAmount: 0,
      state: null,
      stateFilterLabel: '',
      repurchase: null,
      isOneLeft: null,
      weeklyOrdersReportData: {},
      diaryOrdersReportData: {},
      providerUuid: '',
      hasEvidence: null,
      hasCoverage: null,
      specsService: {
        comment: '',
        serviceUuid: ''
      },
      comments: [],
      commentUuid: '',
      showListComments: false,
      showModify: false,
      registryUuidModify: '',
      loader: false,
      disabledToggle: false,
      companyUuid: '',
      state: '',
      arrStates: [],
      collapseDuration: 0,
      trackingLink: null
    }
  },
  computed: {
    ...mapFields(['formOkBoy']),
    checkValidServiceDate() {
      const minutes = 30
      const serviceDate = moment(this.infoModal.serviceDate)
        .subtract(minutes, 'minutes')
        .utc()
      const currentDate = moment().utc(true)
      return serviceDate > currentDate
    },
    hasError() {
      return Object.keys(this.error).length
    },
    isFree() {
      const canceledByAdminFree = this.statusArray.includes(
        STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
      )
      const canceledByAdminCost = this.statusArray.includes(
        STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID
      )

      if (!canceledByAdminFree && !canceledByAdminCost) {
        return undefined
      }
      return canceledByAdminFree && canceledByAdminCost
        ? undefined
        : canceledByAdminFree
    },
    whatsappCopy() {
      const template = MESSAGES.WHATSAPP
      const serviceDate = moment.utc(this.infoModal.deliveryDate ? this.infoModal.deliveryDate : this.infoModal.serviceDate)
      const date = serviceDate.format('DD / MM / YYYY').toString()
      const time = serviceDate.format('HH:mm').toString()
      const address = this.formatAddress(this.infoModal.address)
        .replace(/\n/g, '')
        .replace(/[ ]{2,}/g, ' ')
        .replace(/, , /g, ', ')

      const { latitude, longitude } = this.infoModal.address

      const googleMapsURL = latitude && longitude
        ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        : `https://www.google.com/maps/search/?api=1&query=${address.replace(
          /, /g,
          '+'
        )}`

      const {
        name, paymentType, discount, amount, totalAmount, folio
      } = this.infoModal

      const text = template
        .replace(/{name}/g, name || '') // Default Value
        .replace(/{folio}/g, folio) // Default Value
        .replace(/{date}/g, date)
        .replace(/{time}/g, time)
        .replace(/{amount}/g, amount)
        .replace(/{discount}/g, discount)
        .replace(/{totalAmount}/g, totalAmount)
        .replace(/{paymentType}/g, paymentType)
        .replace(/{address}/g, address)
        .replace(/{googleMapsURL}/g, googleMapsURL)
      return text
    },
    whatsappURL() {
      const { phoneNumber } = this.infoModal
      const urlEncodedText = window.encodeURIComponent(this.whatsappCopy)
      return `https://wa.me/52${phoneNumber}?text=${urlEncodedText}`
    },
    ...mapGetters('auth', ['isCompanyAdmin', 'userPermissions', 'permissions', 'isGeneralAdmin']),
    weeklyFinishedPercentage() {
      const number = (100 * this.weeklyOrdersReportData.serviceFinished)
        / this.weeklyOrdersReportData.servicesOrder
      return this.decimalRounded(Number.isNaN(Number(number)) ? 0 : number)
    },
    weeklyCanceledPercentage() {
      const number = (100 * this.weeklyOrdersReportData.servicesCanceled)
        / this.weeklyOrdersReportData.servicesOrder
      return this.decimalRounded(Number.isNaN(Number(number)) ? 0 : number)
    },
    diaryFinishedPercentage() {
      const number = (100 * this.diaryOrdersReportData.serviceFinished)
        / this.diaryOrdersReportData.servicesOrder
      return this.decimalRounded(Number.isNaN(Number(number)) ? 0 : number)
    },
    diaryCanceledPercentage() {
      const number = (100 * this.diaryOrdersReportData.servicesCanceled)
        / this.diaryOrdersReportData.servicesOrder
      return this.decimalRounded(Number.isNaN(Number(number)) ? 0 : number)
    }
  },
  watch: {
    infoModal(value) {
      switch (value.status) {
      case 'Creado':
        this.status = 'primary'
        break
      case 'Validado':
        this.status = 'info'
        break
      case 'Aceptado':
        this.status = 'info'
        break
      case 'En camino':
        this.status = 'info'
        break
      case 'Llegó':
        this.status = 'success'
        break
      case 'Asignado':
        this.status = 'success'
        break
      default:
        this.status = 'dark'
      }
    },
    dateRange() {
      this.isDatePicker = true
      this.filter()
    },
    page() {
      if (!this.isClear) {
        this.filter()
      }
    },
    sort() {
      this.dateSort()
    },
    openFilterInput(value) {
      if (!value.length || value.length >= 5) {
        this.openFilter(true)
      }
    },
    isActive(value) {
      if (value) {
        this.updateStatus()
      }
    },
    deviceFilterSelected() {
      this.filter()
    },
    hasEvidence() {
      this.filter()
    },
    hasCoverage() {
      this.filter()
    }
  },
  async created() {
    this.refreshInterval = setInterval(async () => {
      const previousItems = [...this.items]
      this.getPreordersLength()
      await this.filter()
      await this.getServiceOrdersReport()
      const existsDifferences = this.items.find((item) => (
        previousItems.find((prevItem) => prevItem.folio === item.folio)
            == null
      )) != null
      if (existsDifferences) {
        this.servicesUpdatedMessage()
        audio.play()
        this.getNotification('Okboy Administrador', 'Hay servicios nuevos que requieren tu atención.')
      }
    }, 20000)
  },
  async beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  async unmounted() {
    clearInterval(this.refreshInterval)
  },
  async mounted() {
    try {
      if (this.$route.query.registryUuid) {
        this.serviceDetailUuid = this.$route.query.registryUuid
        this.openModal()
      }

      this.readonly = this.permissions ? this.permissions.find((obj) => (
        obj.permissionList.name === PERMISSIONS.SERVICE_READ_ONLY
      )) != null : false
      // preload the first 4 status.
      if (!this.isCompanyAdmin) {
        this.statusArray.push(STATUS_SERVICES.CREATED.ID, STATUS_SERVICES.PAYMENT_PENDING.ID)
      } else {
        await this.getCompanyInfo()
      }

      const urlParams = new URLSearchParams(window.location.search)
      const state = urlParams.get('state')

      if (state) {
        const statusId = (urlParams.getAll('statusId') || []).map(
          (status) => +status
        )
        const serviceDate = urlParams.get('serviceDate')
        const repurchase = urlParams.get('repurchase')

        if (state && serviceDate) {
          const dates = serviceDate.split(':')

          this.state = state
          this.statusArray = [].concat(statusId).map((status) => +status)
          this.dateRange.startDate = new Date(`${dates[0]}T00:00:00`)
          this.dateRange.endDate = new Date(`${dates[1]}T00:00:00`)
          this.isDatePicker = true
          window.history.pushState(
            null,
            null,
            window.location.href.split('?')[0]
          )

          if (repurchase === '1') {
            this.repurchase = 1
            this.stateFilterLabel = 'que son re-compra'
          } else if (statusId.length === 1) {
            if (statusId.includes(STATUS_SERVICES.FINISHED.ID)) {
              this.stateFilterLabel = 'finalizados'
            }
          } else if (statusId.length === ALL_STATUS_SERVICES.length) {
            this.stateFilterLabel = ''
          } else if (statusId.length === CANCELLED_STATUS.length) {
            this.stateFilterLabel = 'cancelados'
          }
        }
      }

      this.filter()
      this.getServiceTypes()
      this.getServiceOrdersReport()
      onMessage(messaging, (message) => message)
      Login()
      this.firebaseToken = await activateMessage()
    } catch (ex) {
      console.log(ex)
    }
  },
  methods: {
    async getNotification(title, body) {
      const token = this.firebaseToken
      const notification = {
        destination: 'ADMIN',
        deviceId: token,
        title,
        body,
        data: {
          title,
          body
        },
        icon: logo,
        deviceType: 'Web',
        type: 'service_created'
      }
      await this.$serverless.post('/events/push/notification', notification)
    },
    toggleDetails(item) {
      this.$set(item, '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration })
    },
    ...mapActions([
      'getPreordersLength'
    ]),
    showCreatePaymentLinkButton() {
      return (
        this.infoModal
        && !this.isCompanyAdmin
        && (!this.infoModal.payment || !this.infoModal.payment.paymentLinkUrl)
        && [
          this.STATUS_SERVICES.FINISHED.ID,
          this.STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
          this.STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
          this.STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID
        ].includes(this.infoModal.statusId) === false
      )
    },
    async cancelUpdated() {
      this.showModify = false
      this.specsService.comment = ''
    },
    async toggleUpdateComment(comment, commentUuid, registryUuid) {
      this.commentUuid = commentUuid
      this.specsService.comment = comment
      this.showModify = true
      this.registryUuidModify = registryUuid
    },
    async updateComment() {
      try {
        this.loader = true
        this.error = {}
        this.showModify = false
        this.disabledToggle = true
        await this.$serverless.put('services/comment', {
          commentUuid: this.commentUuid,
          comment: this.specsService.comment
        })
        this.specsService.comment = ''
        this.getComments(this.registryUuidModify).finally(() => {
          this.loader = false
          this.disabledToggle = false
        })
      } catch (error) {
        this.error = getErrorMessage(error)
        this.disabledToggle = false
        this.loader = false
      }
    },
    async deleteComment(uuid, serviceUuid) {
      try {
        this.error = {}
        this.loader = true
        this.specsService.comment = ''
        this.disabledToggle = true
        this.showModify = false
        await this.$serverless.delete('services/comment', { data: { commentUuid: uuid } })
        this.getComments(serviceUuid).finally(() => {
          this.loader = false
          this.disabledToggle = false
        })
      } catch (error) {
        this.error = getErrorMessage(error)
        this.loader = false
        this.disabledToggle = false
      }
    },
    async getComments(serviceUuid) {
      const type = `types=${this.SERVICE_COMMENT_TYPES.ADMIN_SPECIFICATION}`
      try {
        this.error = {}
        const { data } = await this.$serverless.get(`services/comment/${serviceUuid}?${type}`)
        this.comments = data
      } catch (error) {
        this.error = getErrorMessage(error)
        this.loader = false
        this.disabledToggle = false
      }
    },
    async saveComment(uuid) {
      try {
        this.error = {}
        this.loader = true
        this.disabledToggle = true
        await this.$serverless.post('services/comment', {
          comment: this.specsService.comment,
          serviceUuid: uuid,
          type: this.SERVICE_COMMENT_TYPES.ADMIN_SPECIFICATION
        })
        this.specsService.comment = ''
        this.getComments(uuid).finally(() => {
          this.loader = false
          this.disabledToggle = false
        })
      } catch (error) {
        this.error = getErrorMessage(error)
        this.loader = false
        this.disabledToggle = false
      }
    },
    async createPaymentLink() {
      try {
        this.isCreatingLink = true
        const payment = await this.createPaymentLinkPost(this.infoModal.registryUuid)
        this.infoModal.payment = payment
      } catch (error) {
        console.error(error)
      } finally {
        this.isCreatingLink = false
      }
    },
    async onSuccessCreation() {
      this.closeCreateModal()
      this.setSuccessMessage('Pedido creado con éxito.')
      this.scrollTopSuccess()
      await this.filter()
    },
    getServiceTypeIcon(serviceType) {
      switch (serviceType) {
      case SERVICE_TYPES.GAS.NAME:
        // eslint-disable-next-line global-require
        return require('../../public/gasIcon.jpeg')
      case SERVICE_TYPES.GAS_CYLINDER.NAME:
        // eslint-disable-next-line global-require
        return require('../../public/cylinderIcon.jpeg')
      default:
        return null
      }
    },
    closeServiceDetailModal() {
      this.detailModal.isActive = false
      this.statusFilterParams(0)
      if(this.$route.query.back === 'true') {
        this.$router.back()
      }
    },
    closeCreateModal() {
      this.createModal.isActive = false
      this.formOkBoy.address = {
        state: '',
        street: '',
        municipality: '',
        suburb: '',
        numExt: '',
        numInt: '',
        type: 'Casa',
        reference: '',
        zipcode: '',
        longitude: '',
        latitude: ''
      }
    },
    openCreateModal() {
      this.createModal.isActive = true
      setTimeout(() => {
        const elements = document.getElementsByClassName('pac-container pac-logo')
        elements[elements.length - 1].style.zIndex = 99999
      }, 1500)
    },
    getBranchClass(item) {
      return {
        backgroundColor: item.branchColor || '#B0B8C4',
        color: '#fff'
      }
    },
    getTimeLeftClass(item) {
      if (
        [
          STATUS_SERVICES.CREATED.ID,
          STATUS_SERVICES.ACTIVE.ID,
          STATUS_SERVICES.ACCEPTED.ID,
          STATUS_SERVICES.ARRIVED.ID,
          STATUS_SERVICES.ASSIGNED.ID
        ].includes(item.statusId)
      ) {
        const { minutesDifference } = this.getTimeDifference(item)

        if (minutesDifference > 60) {
          return 'time-left-green'
        } if (minutesDifference > 5) {
          return 'time-left-yellow'
        }
        return 'time-left-red'
      }

      if (STATUS_SERVICES.FINISHED.ID === item.statusId) {
        return 'time-left-grey'
      }

      return 'time-left-none'
    },
    getTimeDifference(item) {
      const serviceDate = moment(
        moment.utc(item.deliveryDate ? item.deliveryDate : item.serviceDate).format('YYYY-MM-DD HH:mm:ss')
      )
      const today = moment()

      const hoursDifference = serviceDate.diff(today, 'Hours')

      const minutesDifference = serviceDate.diff(today, 'minutes')

      return {
        hoursDifference,
        minutesDifference
      }
    },
    getTimeDifferenceCompletedService(item) {
      const serviceDate = moment(
        moment.utc(item.deliveryDate ? item.deliveryDate : item.serviceDate).format('YYYY-MM-DD HH:mm:ss')
      )
      const attendedDate = moment(
        moment.utc(item.attendedDate ? item.attendedDate : item.serviceDate).format('YYYY-MM-DD HH:mm:ss')
      )

      const hoursDifference = serviceDate.diff(attendedDate, 'Hours')

      const minutesDifference = serviceDate.diff(attendedDate, 'minutes')

      return {
        hoursDifference,
        minutesDifference
      }
    },
    getTimeLeftLabel(item) {
      if (
        [
          STATUS_SERVICES.CREATED.ID,
          STATUS_SERVICES.ACTIVE.ID,
          STATUS_SERVICES.ACCEPTED.ID,
          STATUS_SERVICES.ARRIVED.ID,
          STATUS_SERVICES.ASSIGNED.ID
        ].includes(item.statusId)
      ) {
        const { hoursDifference, minutesDifference } = this.getTimeDifference(item)

        const label = hoursDifference === 0
          ? `${minutesDifference} Minutos`
          : `${hoursDifference} Horas`

        return label
      }

      if (STATUS_SERVICES.FINISHED.ID === item.statusId) {
        const { hoursDifference, minutesDifference } = this.getTimeDifferenceCompletedService(item)

        const label = hoursDifference === 0
          ? `${minutesDifference} Minutos`
          : `${hoursDifference} Horas`

        return label
      }

      return '-'
    },
    async getSchedule() {
      try {
        const { data } = await this.axios.get('/schedules/available')
        this.schedules = data
      } catch (error) {
        this.schedules = null
      }
    },
    async getCompanyInfo() {
      try {
        const path = 'providers/current'
        const { data } = await this.axios.get(path)
        this.companyInfo = data
      } catch (err) {
        this.companyInfo = {}
      }
    },
    async unlinkService() {
      const { registryUuid } = this.infoModal
      try {
        await this.axios.patch(
          `services/${registryUuid}/remove-assign-company`
        )
        this.onSuccessUnlinkService()
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.scrollTopError()
      }
    },
    getSwitchLabel(statusId, isChecked) {
      switch (statusId) {
      case this.STATUS_SERVICES.ACCEPTED.ID:
        return isChecked ? 'Llegó' : 'En Camino'
      case this.STATUS_SERVICES.ASSIGNED.ID:
        return isChecked ? 'En camino' : 'Asignado'
      default:
        return isChecked ? 'Validado' : 'Creado'
      }
    },
    getStatusTooltip(statusId) {
      switch (statusId) {
      case STATUS_SERVICES.ASSIGNED.ID:
        return 'Actualiza el estatus del servicio de Asignado a En Camino'
      case this.STATUS_SERVICES.ACCEPTED.ID:
        return 'Actualiza el estatus del servicio de En Camino a Llegó'
      default:
        return 'Actualiza el estatus del servicio de Creado a Validado'
      }
    },
    async downloadCSV() {
      this.topAlertError = false
      this.isDownloadingCSV = true
      const filters = {
        ...this.getFilters(),
        format: 'csv'
      }

      try {
        const queryString = queryParser.parse(filters)
        const { data } = await this.axios.get(`/services?${queryString}`)
        downloadFile(data, 'services.csv')
      } catch (err) {
        this.topAlertError = true
        this.error = getErrorMessage(err)
      } finally {
        this.isDownloadingCSV = false
      }
    },
    getServiceTypes() {
      this.axios.get('/services/types').then((response) => {
        const data = response.data.map((serviceType) => ({
          text: serviceType.description,
          value: serviceType.registryUuid
        }))
        this.serviceTypeData = data
      })
    },
    async createPaymentLinkPost(serviceUuid) {
      try {
        const { data } = await this.$axios.post('/payments/payment-link', {
          serviceUuid
        })
        return data
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async fetchTrackingLink(serviceUuid) {
      try {
        const { data } = await this.$serverless.post('/checkout/tracking/link', {
          serviceUuid
        })
        this.trackingLink = data.trackingLink
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async getServicePayment(uuid) {
      try {
        const { data } = await this.$axios.get(`/payments/${uuid}`)
        return data
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    copy() {
      this.textCopied = copyToClipboard(this.whatsappCopy)
      setTimeout(() => {
        this.textCopied = null
      }, 1000)
    },
    async fetchCompanies({
      latitude = null,
      longitude = null,
      serviceTypeUuid = null
    }) {
      // Cache
      try {
        let path = 'providers/companies?format=asgmt'
        if (latitude && longitude) {
          path = `providers/companies/reassign?latitude=${latitude}&longitude=${longitude}&serviceTypeUuid=${serviceTypeUuid}`
        }
        const { data } = await this.axios.get(path)

        this.companies = data.map((company) => {
          company.name = company.isCovered
            ? `${company.name} - SI`
            : `${company.name} - NO`
          return company
        })
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
      }
    },
    getFilters(ignoreFilters = false) {
      const options = {
        state: this.state,
        page: this.page,
        isFree: this.isFree,
        statusId: [...this.statusArray],
        serviceTypeUuid: this.serviceTypeUuid || this.serviceTypeSelected
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        const typeFilter = /^[A-Za-z]/.test(this.openFilterInput)
          ? 'folio'
          : 'phoneNumber'
        let value = this.openFilterInput.trim().toUpperCase()
        if (typeFilter === 'phoneNumber') {
          value = value.replace(/[\s-]/g, '').substring(0, 10)
        }
        this.openFilterInput = value
        if (this.isCompanyAdmin) {
          options.folio = value
        } else {
          options[typeFilter] = value
        }

        if (ignoreFilters) return options
      }

      if (this.isDatePicker) {
        const { dateRange } = this
        const start = moment(dateRange.startDate).format('YYYY-MM-DD')
        const end = moment(dateRange.endDate).format('YYYY-MM-DD')
        options.serviceDate = `${start}:${end}`
      } else {
        const { dateRange } = this
        const currentDayStart = moment(dateRange.startDate).format('YYYY-MM-DD')
        const currentDayEnd = moment(dateRange.endDate).format('YYYY-MM-DD')
        options.serviceDate = `${currentDayStart}:${currentDayEnd}`
      }

      if (this.dateSorter != null) {
        options.orderBy = 'serviceDate'
        options.sortBy = this.dateSorter ? 'ASC' : 'DESC'
      }
      if (this.repurchase) {
        options.repurchase = 1
        options.all = 'true'
        delete options.page
      }

      if (this.deviceFilterSelected) {
        options.deviceFrom = this.deviceFilterSelected
      }

      if (this.providerUuid !== '') {
        options.providerUuid = this.providerUuid
      }

      if (this.state !== '') {
        options.state = this.state
      }

      if (this.companyUuid !== '') {
        options.companyUuid = this.companyUuid
      }

      if (this.hasEvidence !== null) {
        options.withEvidence = this.hasEvidence
        options.statusId = []
        options.statusId.push(5)
      }

      if (this.hasCoverage !== null) {
        options.hasCoverage = this.hasCoverage
      }

      return options
    },
    getBadge(status) {
      return STATUS_SERVICES[status].TYPE
    },
    filterServiceOrdersReport() {
      const options = {}
      const monday = moment().startOf('isoweek').format('YYYY-MM-DD')
      const sunday = moment().endOf('isoweek').format('YYYY-MM-DD')
      options.serviceDate = `${monday}:${sunday}`

      return options
    },
    getServiceOrdersReport() {
      // Weekly
      const queryString = queryParser.parse(this.filterServiceOrdersReport())
      this.axios.get(`report/summary?${queryString}`).then((response) => {
        const { data } = response
        this.weeklyOrdersReportData = data
      })
      // Diario
      this.axios
        .get(
          `report/summary?serviceDate=${moment(new Date()).format(
            'YYYY-MM-DD'
          )}`
        )
        .then((response) => {
          const { data } = response
          this.diaryOrdersReportData = data
        })
    },
    onRowClicked(item) {
      this.openModal(item)
    },
    // fliters
    filter(ignoreFilters = false) {
      return new Promise((resolve, reject) => {
        const filters = this.getFilters(ignoreFilters)
        const queryString = queryParser.parse(filters)
        this.axios
          .get(`/services?${queryString}`)
          .then((response) => {
            const { data } = response
            this.items = data.data.map((item, id) => {
              item.phoneNumber = item.phoneNumber || ''
              if (!item.cancellation && item.statusId === 8) {
                data.data[id].cancellation = { isFree: true }
              }
              if (item.statusId === 9) {
                data.data[id].statusId = STATUS_SERVICES.ASSIGNED.ID
              }
              if (item.statusId === 3) {
                data.data[id].status = STATUS_SERVICES.EN_CAMINO.NAME
              }
              if (item.preferredCompany) {
                const { name, pricePerLiter } = item.preferredCompany
                item.preferredCompanyLabel = `${name} - $${pricePerLiter}`
              } else {
                item.preferredCompanyLabel = 'No disponible'
              }
              item.createdAt = this.formatDate(
                moment.tz(item.createdAt, 'America/Mexico_City').utc(true)
              )

              item.hasCoverage = item.hasCoverage === false ? 'No' : 'Si'
              if (this.arrStates.indexOf(item.location) < 0) this.arrStates.push(item.location)

              return { ...item, id }
            })

            const { pagination } = data

            this.pages = pagination.totalPages
            this.totalRecords = pagination.total
            return resolve()
          })
          .catch((err) => reject(err))
      })
    },
    // get params for status filter
    statusFilterParams(value) {
      const indexOfValue = this.statusArray.indexOf(value)
      if (indexOfValue !== -1) {
        this.statusArray.splice(indexOfValue, 1)
      } else if (value) {
        this.statusArray.push(value)
      }
      this.isOneLeft = this.statusArray.length <= 1 && this.isCompanyAdmin
        ? this.statusArray[0]
        : null
      this.filter()
    },
    openFilter(ignoreFilters = false) {
      this.filter(ignoreFilters)
    },
    clearFilters() {
      this.page = 1
      this.dateSorter = null
      this.isDatePicker = false
      this.dateRange.startDate = new Date()
      this.dateRange.endDate = new Date()
      this.isOneLeft = null
      this.openFilterInput = ''
      this.isClear = true
      this.sort.asc = false
      this.state = null
      this.filter()
      this.isClear = false
      this.repurchase = null
      this.all = null
    },
    dateSort() {
      this.dateSorter = !this.dateSorter
      this.filter()
    },
    openModal(item) {
        this.detailModal.isActive = true
      if(item) {
        this.serviceDetailUuid = item.registryUuid
      }
    },
    findSchedule(date) {
      if (!this.schedules) {
        return null
      }
      const momentDate = moment(date).utc()
      const dayOfWeek = momentDate.day()
      let schedule = this.schedules.full
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        schedule = this.schedules.weekend
      }
      return schedule.find((obj) => {
        const startTimeMeridianFormat = obj.max === '12' ? 'am' : obj.meridian
        const startTimeString = moment(
          `${obj.min} ${startTimeMeridianFormat}`,
          'hh A'
        ).format('HH:mm:ss')
        const endTimeString = moment(
          `${obj.max} ${obj.meridian}`,
          'hh A'
        ).format('HH:mm:ss')
        return (
          momentDate.format('HH:mm:ss') >= startTimeString
          && momentDate.format('HH:mm:ss') <= endTimeString
        )
      })
    },
    updateDate() {
      const serviceUuid = this.infoModal.registryUuid
      const date = this.editDate.startDate
        .toString()
        .replace(/[GMT0-9-]{8}/g, '')
      const formattedDate = moment.utc(date).format()
      this.axios
        .put(`/services/${serviceUuid}/deliveryDate`, {
          deliveryDate: formattedDate
        })
        .then(() => {
          this.onSuccessDateUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    updateStatus() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`/services/${serviceUuid}/status`, {
          statusId: this.getNextStatus()
        })
        .then((response) => {
          const {
            data: { assigned, provider }
          } = response
          this.onSuccessStatusUpdate(assigned, provider)
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    setDisableDistanceRestriction() {
      const serviceUuid = this.infoModal.registryUuid
      this.$axios
        .put('/services/distance_restriction', {
          serviceUuid,
          disableDistanceRestriction: !this.infoModal.disableDistanceRestriction
        })
        .then((response) => {
          this.onSuccessDisableDistanceRestriction()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    updatePriority() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`/services/${serviceUuid}/priority`, {
          priority: !this.infoModal.priorityFlag
        })
        .then((response) => {
          const { data } = response
          this.infoModal.priorityFlag = data.priorityFlag
          this.onSuccessPriorityUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    getNextStatus() {
      switch (this.infoModal.statusId) {
      case this.STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID:
      case this.STATUS_SERVICES.ASSIGNED.ID:
        return this.STATUS_SERVICES.ACCEPTED.ID
      case this.STATUS_SERVICES.ACCEPTED.ID:
      case this.STATUS_SERVICES.EN_CAMINO.ID:
        return this.STATUS_SERVICES.ARRIVED.ID
      default:
        return this.STATUS_SERVICES_TO_ASSIGN[0]
      }
    },
    updateAmount() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`services/${serviceUuid}/amount`, {
          amount: this.newAmount
        })
        .then(() => {
          this.onSuccessAmountUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    disabledDates(classes, date) {
      const dd = new Date().getDate()
      const mm = new Date().getMonth() + 1
      const yyyy = new Date().getFullYear()
      const today = `${yyyy}-${mm}-${dd}`
      const getCalendarDate = moment(date.getTime()).format()

      if (!classes.disabled) {
        classes.disabled = getCalendarDate < moment(today).format()
      }
      return classes
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    setNewServiceAlert(message) {
      this.newServicesAlert.hasSuccess = true
      this.newServicesAlert.message = message
    },
    defaultNewServiceAlertBehavior(message) {
      this.statusFilterParams(0)
      this.scrollTopSuccess()
      this.setNewServiceAlert(message)
    },
    defaultSuccessBehavior(message) {
      this.statusFilterParams(0)
      this.closeServiceDetailModal()
      this.scrollTopSuccess()
      this.setSuccessMessage(message)
    },
    onSuccessUnlinkService() {
      this.defaultSuccessBehavior('Servicio desvinculado con éxito.')
    },
    onSuccessCompanyAssignation() {
      this.defaultSuccessBehavior('Servicio asignado con éxito.')
    },
    onSuccessCancellation() {
      this.defaultSuccessBehavior('Servicio cancelado con éxito.')
    },
    onSuccessAssignation() {
      this.defaultSuccessBehavior('Servicio asignado con éxito.')
    },
    onSuccessReassignation() {
      this.defaultSuccessBehavior('Servicio reasignado con éxito.')
    },
    onSuccessAddressUpdate() {
      this.defaultSuccessBehavior('Domicilio actualizado con éxito.')
    },
    onSuccessDateUpdate() {
      this.defaultSuccessBehavior('Fecha y hora actualizada con éxito.')
    },
    servicesUpdatedMessage() {
      this.defaultNewServiceAlertBehavior('Hay nuevos servicios creados')
    },
    onSuccessStatusUpdate(assigned, provider = null) {
      if (assigned) {
        this.defaultSuccessBehavior(
          `Estatus actualizado con éxito y fue asignado a ${provider}.`
        )
        return false
      }
      this.defaultSuccessBehavior('Estatus actualizado con éxito.')
      return false
    },
    onSuccessDisableDistanceRestriction() {
      this.defaultSuccessBehavior('Restricción de Distancia actualizada con éxito.')
      return false
    },
    onSuccessPriorityUpdate() {
      this.defaultSuccessBehavior('Prioridad actualizada con éxito.')
      return false
    },
    onSuccessAmountUpdate() {
      this.defaultSuccessBehavior('Monto actualizado con éxito.')
    },
    onSuccessEvidenceUpdate() {
      this.defaultSuccessBehavior('Evidencia actualizada con éxito.')
    },
    onSuccessFinishService() {
      this.defaultSuccessBehavior('Orden de servicio finalizada con éxito.')
    },
    onPromocodeSuccess() {
      this.defaultSuccessBehavior(
        'El código promocinal se actualizó correctamente'
      )
    },
    onPaymentSuccess() {
      this.defaultSuccessBehavior(
        'El método de pago se actualizó correctamente'
      )
    },
    onProviderFilter(value) {
      if (value) {
        this.providerUuid = value.registryUuid
      } else {
        this.providerUuid = ''
      }
      this.filter()
    },
    onStateFilter(value) {
      this.state = value || ''
      this.filter()
    },
    onCompanyFilter(value) {
      this.companyUuid = (value && value.registryUuid) ? value.registryUuid : ''
      this.filter()
    },
    // errors
    onErrorPromocodes(err) {
      this.alertError = true
      this.scrollTopError()
      this.error = getErrorMessage(err)
    },
    onErrorPayment(err) {
      this.alertError = true
      this.scrollTopError()
      this.error = getErrorMessage(err)
    },
    // formats
    formatDate(date) {
      return moment.utc(date, 'YYYYMMDD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
    },
    formatToDatePicker(date) {
      return moment(date)
        .format('DD/MM/YYYY hh:mm A')
    },
    removeDecimals(value) {
      return value ? value / 100 : value
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      if (value) {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    getAddressContact() {
      return this.infoModal.address
        && this.infoModal.address.contacts
        && this.infoModal.address.contacts.length > 0
        ? this.infoModal.address.contacts[0].name
        : this.infoModal.name
    },
    formatProviders(name) {
      return name === null ? 'Sin nombre' : name
    },
    formatAddress(data) {
      let string = ''
      if (data) {
        const numInt = data.numInt ? data.numInt : ''
        string = `${data.street}, ${data.numExt}, ${numInt}, ${data.zipcode}, 
                ${data.suburb}, ${data.municipality}, ${data.state}`
      }
      return string
    },
    formatUnknown(value) {
      if (value === 'Unknown') {
        return 'Desconocido'
      }
      return value
    },
    formatScheduleDay(value) {
      if (!value) {
        return 'No disponible'
      }
      const startTimeMeridianFormat = value.max === '12' ? 'am' : value.meridian
      const startTimeString = `${value.min} ${startTimeMeridianFormat}`
      const endTimeString = `${value.max} ${value.meridian}`

      return `${startTimeString} - ${endTimeString}`
    },
    decimalRounded(number) {
      return number.toFixed(2)
    },
    evidenceFormat(evidence, id) {
      if (!evidence && this.STATUS_SERVICES.FINISHED.ID === id) {
        return 'no-evidence'
      }
      return ''
    },
    // validations
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successServices.focus()
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.resume-card{
 background-color: #fff;
 padding: 0;
 margin: 0;
 > h2{
  margin: 0;
 }
}
.accordeon-icon{
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s
}
.accordeon-icon::after{
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;

}
.accordeon-icon:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.ml-10{
  margin-left: 20%;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-pointer:hover{
  background-color: #c7c5c5;
}
.services {

  .add-service-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .alerts:focus {
    outline: none;
  }
  .evidence-img {
    max-height: 400px;
  }
  .img-card,
  .card-add-evidence {
    padding-top: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .figure-caption {
      background-color: #2a1ab9;
      color: #fff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 14px;
      padding: 4px 0;
    }
    > .date-picker {
      margin-top: 8px;
      padding: 4px;
      background-color: #2a1ab9;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .modal-text-wrapper {
    > div {
      margin-bottom: 8px;
    }
    .amount-requested {
      display: flex;
      > div {
        margin-left: 4px;
        input {
          width: 30%;
          border: 1px solid #ccc;
        }
        button {
          border: 1px solid #5b2ae0;
        }
      }
    }
  }
  .card {
    border: none;
    margin-bottom: 0;
    .card-header,
    .card-footer {
      display: flex;
      p {
        margin: auto 0 auto auto;
      }
    }
    .card-body {
      padding: 12px;
      margin-bottom: 0;
      .filters-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .second-fiters {
          display: flex;
          flex-direction: column;
        }
        .open-filter {
          > div {
            &:first-child {
              display: flex;
              margin-bottom: 16px;
            }
            &:last-child {
              width: 256px;
            }
          }
          .open-filter-input {
            height: 35px;
            box-sizing: content-box;
            padding: 0 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 15px;
            width: calc(100% - 165px);
          }
          button {
            height: 35px;
            border-radius: 4px;
            margin-left: 4px;
          }
        }
        .status-filter {
          margin: 8px auto 0 auto;
          div {
            padding: 0 16px;
            input {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .sorter-button {
        background-color: #fff;
        border: 1px solid #ccc;
        margin-left: 7px;
        outline: none;
      }
    }
  }
  .edit-address {
    form {
      > div {
        display: flex;
        margin-bottom: 8px;
        &.latitude-longitude {
          button {
            width: 25px;
            height: 25px;
            border: 0;
            border-radius: 4px;
            margin-left: 4px;
            background-color: #3c4b64;
            color: #fff;
          }
        }
        > div {
          display: flex;
          flex-direction: column;
          width: 50%;
          &:first-child {
            margin-right: 4px;
          }
        }
        input {
          height: 35px;
          box-sizing: border-box;
          padding: 0 16px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
    .edit-address-buttons {
      display: flex;
      .tabs-selector {
        border: 1px solid #ccc;
        display: flex;
        height: 35px;
        border-radius: 4px;
        width: 150px;
        button {
          border: none;
          background-color: #fff;
          outline: none;
          color: #000015;
          width: calc(100% / 2);
          border-right: 1px solid #ccc;
          border-radius: 4px;
          &.active {
            background-color: #5b2ae0;
            color: white;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  .services-overview {
    > .card {
      padding: 16px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      > div {
        padding: 8px;
        margin: 8px 0;
        width: 150px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
        p {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 8px;
        }
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  .card-comments {
    width: 18rem;
    height: 9rem;
  }

  .card-error {
    overflow: none;
    padding: 0;
  }

  .card-error .card-body {
    padding: 0px !important;
  }
  .button-delete-comment, .button-update-comment {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-delete-comment {
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .button-update-comment {
    right: 48px;
    top: 10px;
    width: 64px;
    height: 21px;
  }

  .center-flag {
    text-align: center;
    color: red;
    padding: 22px;
  }
  .red-color {
    color: red
  }
  .center-image {
    display: flex;
    margin-top: 10px;
  }
  .detail-modal {
    .assign-company-wrapper {
      display: flex;
    }
  }
  .time-left-grey {
    background-color: #B0B8C4;
    color: white;
  }
  .time-left-red {
    background-color: #ec225e;
    color: white;
  }
  .time-left-yellow {
    background-color: #ff8300;
    color: white;
  }
  .time-left-green {
    background-color: #72e872;
  }
  .tabs-selector {
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button {
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 2);
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active {
        background-color: #5b2ae0;
        color: white;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  .tf-complements {
    display: block;
    color: #9f9faa;
    font-size: .78rem;
    margin-top: 5px;
  }
  @media (min-width: 992px) {
    .card {
      .card-comments .card-body {
        overflow: auto;
        padding: 0rem;
      }
      .card-body {
        padding: 1.25rem;
        .filters-section {
          flex-direction: row;
          .second-fiters {
            margin: auto 0 auto auto;
            min-width: 330px;
            > div {
              &:first-child {
                margin-bottom: 16px;
              }
              &:last-child {
                display: flex;
              }
            }
          }
          .date-range {
            margin: auto 8px auto 0;
          }
          .status-filter {
            margin: 0;
          }
          .open-filter {
            margin: auto 10px auto 0;
            .open-filter-input {
              font-size: 12px;
              width: 27%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .tabs-selector {
      font-size: 10px;
    }
  }
}
</style>
