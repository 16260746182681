import { store } from '../store/store'

export default function ({ next }) {
  const { token } = store.state.auth
  const hasSession = !!token
  if (hasSession) {
    return next({ path: '/' })
  }
  return next()
}
