import moment from 'moment'

export const weekRangeLabel = (d) => {
  const [start, end] = [moment(d).startOf('isoWeek'), moment(d).endOf('isoWeek')]
  return `${start.date()} ${start.format('MMM').replace('.', '')} a ${end.date()} ${end.format('MMM').replace('.', '')}`
}

export const getWeekLabels = ([min, max], { keys = false } = {}) => {
  const [minDate, maxDate] = [moment(min, 'YYYY-MM-DD'), moment(max, 'YYYY-MM-DD')]
  const labels = []

  let date = moment(minDate)
  while (date.isoWeek() !== maxDate.isoWeek()) {
    const label = weekRangeLabel(date)
    const key = date.isoWeek()
    labels.push({ label, key })
    date = date.add({ week: 1 })
  }

  labels.push({
    label: weekRangeLabel(maxDate),
    key: maxDate.isoWeek()
  })

  return keys
    ? labels
    : labels.map(({ label }) => label)
}

export const getSelectOptions = (year, { baseYear = moment().year } = {}) => {
  const date = moment().set({ year })

  const years = (new Array(1 + baseYear - 2020)).fill(0)
    .map((_, i) => i + 2020)
  const months = (new Array(12)).fill(0)
    .map((_, month) => ({ text: moment(date).set({ month }).format('MMMM'), value: month + 1 }))
  const weeks = (new Array(52)).fill(0)
    .map((_, week) => ({ text: weekRangeLabel(moment(date).isoWeek(week + 1)), value: week + 1 }))

  return {
    years,
    months,
    weeks
  }
}
