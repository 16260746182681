<template>
  <CModal
    :closeOnBackdrop="false"
    :show.sync="isModalActive"
    class="create-sensor">
    <div tabindex="0" ref="CreateSensor">
      <CAlert
        color="danger"
        :closeButton="true"
        :show.sync="isErrorResponse">
        {{error.message}}
      </CAlert>
    </div>
    <template v-slot:header>
      <h5 class="modal-title">Crear sensor</h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <form
      id="createSensor"
      ref="form"
      v-on:submit.prevent="onSubmit">
      <div class="input-form-wrapper">
        <label for="">Número de teléfono</label>
        <input
          type="text"
          placeholder="Teléfono"
          v-model="phoneNumber"
          minlength="10"
          maxlength="10"
          @keypress="onlyNumbers($event)"
          required>
      </div>
      <div class="input-form-wrapper">
        <label for="">Identificador del dispositivo</label>
        <input
          type="text"
          placeholder="Identificador"
          v-model="deviceIdentifier"
          required>
      </div>
    </form>
    <template v-slot:footer>
      <div>
        <button type="button" class="btn btn-secondary mr-1" @click="onCancel">Cancelar</button>
        <button
          type="submit"
          form="createSensor"
          class="btn btn-primary ml-1">Aceptar</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { getErrorMessage } from '../../common/errors'

const initialState = () => ({
  phoneNumber: '',
  deviceIdentifier: '',
  error: {},
  isErrorResponse: false
})

export default {
  name: 'CreateSensor',
  data() {
    return initialState()
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.isErrorResponse = false
        this.resetForm()
      }
    }
  },
  methods: {
    onSubmit() {
      this.axios.post('/customers/sensor', {
        phoneNumber: this.phoneNumber,
        deviceIdentifier: this.deviceIdentifier
      })
        .then(() => {
          this.$emit('onSuccess')
        })
        .catch((err) => {
          this.isErrorResponse = true
          this.error = getErrorMessage(err)
        })
    },
    onCancel() {
      this.$emit('onClose')
    },
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.CreateSensor.focus()
      })
    },
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    // validations
    onlyNumbers(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-sensor{
    form{
        .input-form-wrapper{
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
            label{
                font-weight: bold;
            }
            input{
                padding: 6px 8px;
                width: 100%;
                height: 35px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }
    }
}
</style>
