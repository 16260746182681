<template>
  <div>
    <div class="relative-container">
      <select
        class="map-select"
        v-model="locationSelected"
        name="locations"
        @change="onChangeLocation()"
      >
        <option value="" disabled>Selecciona una ubicación</option>
        <option
          v-for="(location, index) in locations"
          :key="index"
          :value="location.state"
        >
          {{ location.state }}
        </option>
      </select>
      <select
        v-if="!isCompanyAdmin"
        class="map-service-type-select"
        v-model="serviceTypeSelected"
        name="serviceTypeSelect"
        @change="serviceTypeFilterChanged()"
      >
        <option value="">Selecciona una vertical</option>
        <option
          v-for="(serviceType, index) in serviceTypes"
          :key="index"
          :value="serviceType.id"
        >
          {{ serviceType.name }}
        </option>
      </select>
    </div>
    <div id="maps"></div>
  </div>
</template>
<script>
// TODO: Remove JQuery
import { mapGetters } from 'vuex'
import $ from 'jquery'
import { Loader } from 'google-maps'

const loader = new Loader(process.env.VUE_APP_GOOGLE_API_KEY)

let statusArray = []
let map = null
let servicesMarkers = []
let providersMarkers = []
let companiesCoverages = []
let setIntervalControl
let google

export default {
  name: 'Map',
  data() {
    return {
      geojsons: [],
      locations: [],
      serviceTypes: [],
      locationSelected: '',
      serviceTypeSelected: '',
      companyCoverages: {} // [key: string /* companyUuid* */]: geojson
    }
  },
  mounted() {
    this.initMap()
    this.setIntervalFunction()
    const vue = this
    // eslint-disable-next-line func-names
    $(document).on('click', 'ul .click-control', function () {
      vue.toggleService($(this).attr('status'))
    })

    $(document).on('click', 'ul .click-control-coverage', function () {
      $('.all-coverages').css('display', 'block')

      map.data.forEach((feature) => {
        map.data.remove(feature)
      })
      const findCoverage = companiesCoverages.find(
        (coverage) => coverage.name === $(this).attr('name')
      )
      if (findCoverage) {
        map.data.addGeoJson(findCoverage.coverages)
        vue.moveToLocation($(this).attr('lat'), $(this).attr('lng'))
      }

      if ($(this).attr('name') === 'all-coverages') {
        $(this).css('display', 'none')
        companiesCoverages.forEach((coverage) => {
          map.data.addGeoJson(coverage.coverages)
        })
      }
    })
  },
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin']),
    geojson() {
      if (!this.geojsons.length) {
        return {}
      }

      return {
        type: 'FeatureCollection',
        features: this.geojsons.map(({ features }) => features).flat()
      }
    },
    icons() {
      return {
        pipero: {
          url: 'img/markers/pipero.png',
          scaledSize: new google.maps.Size(25, 20),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0) // anchor
        },
        piperoOutdated: {
          url: 'img/markers/pipero-gray.png',
          scaledSize: new google.maps.Size(25, 20),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0) // anchor
        },
        group: {
          url: 'img/markers/group.png',
          scaledSize: new google.maps.Size(25, 30),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0) // anchor
        }
      }
    }
  },
  methods: {
    async initMap() {
      google = await loader.load()

      map = new google.maps.Map(document.getElementById('maps'), {
        zoom: 13,
        center: {
          lat: 19.432395,
          lng: -99.133239
        }
      })
      this.addProvidersToMap()
      this.addServiceToMap()
      this.setSelectLocations()
      this.setSelectServiceType()

      if (!this.isCompanyAdmin) {
        this.getServicesTypes()
        this.loadCompanyCoverages()
      }
    },
    setIntervalFunction() {
      setIntervalControl = setInterval(() => this.realoadMap(), 60 * 1000)
    },
    realoadMap() {
      this.deleteMarkers()
      this.addProvidersToMap()
      this.addServiceToMap()
    },
    async fetcProvidersLocation() {
      try {
        const {
          data: { data }
        } = await this.axios.get(
          'providers?all=true&isOnline=1&isActive=1&includes=coordinates'
        )
        return data
      } catch (err) {
        return null
      }
    },
    async getServicesTypes() {
      try {
        const params = {
          includeCategories: false
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = data.map(
          ({ id, description: name, registryUuid }) => ({
            id,
            name,
            registryUuid
          })
        )
        return this.serviceTypes
      } catch (err) {
        return null
      }
    },
    async fetchServices() {
      try {
        const date = new Date()
        const isoString = date.toISOString().split('T')[0]
        const serviceDate = `${isoString}:${isoString}`
        const {
          data: { data }
        } = await this.axios.get(
          `services?serviceDate=${serviceDate}&all=true&statusId=1&statusId=2&statusId=3&statusId=4&statusId=9&includes=address`
        )
        return data
      } catch (err) {
        return null
      }
    },
    async fetchServiceDetails({ registryUuid }) {
      try {
        const { data } = await this.axios.get(`services/${registryUuid}`)
        return data
      } catch (err) {
        return null
      }
    },
    toLocalTimeZone(date) {
      return new Date(date).toLocaleString('es-MX', {
        timeZone: 'America/Mexico_City'
      })
    },
    async CenterControl(status) {
      const centerControlDiv = document.createElement('div')

      const controlUI = document.createElement('div')
      controlUI.style.backgroundColor = '#fff'
      controlUI.style.border = '2px solid #fff'
      controlUI.style.borderRadius = '3px'
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
      controlUI.style.marginBottom = '22px'
      controlUI.style.marginLeft = '10px'
      controlUI.style.textAlign = 'center'
      controlUI.style.width = '250px'
      controlUI.className = 'title-style'
      controlUI.title = 'Click to recenter the map'
      centerControlDiv.appendChild(controlUI)

      // Set CSS for the control interior.
      const controlText = document.createElement('div')
      controlText.style.color = 'rgb(25,25,25)'
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlText.style.fontSize = '16px'
      controlText.style.lineHeight = '38px'
      controlText.style.paddingLeft = '5px'
      controlText.style.paddingRight = '5px'
      controlText.innerHTML = 'Información'
      controlUI.appendChild(controlText)

      // Set CSS for the control interior.
      const controlBody = document.createElement('div')
      controlBody.style.color = 'rgb(25,25,25)'
      controlBody.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlBody.style.fontSize = '16px'
      controlBody.style.lineHeight = '38px'
      controlBody.style.paddingLeft = '5px'
      controlBody.style.paddingRight = '5px'
      controlBody.style.textAlign = 'left'
      controlBody.id = 'information-control'

      let bodySting = '<li>No hay datos que mostrar</li>'

      if (status.length > 0) {
        controlBody.innerHTML = ''
        bodySting = '<li class="click-control show-option" status="0">Todos</li>'
        status.forEach((value) => {
          const statusAsset = this.getAsset(value.id)
          bodySting += `<li class="${statusAsset.class}" status="${value.id}">${value.name}</li>`
        })
      }

      controlBody.innerHTML += `<ul>${bodySting}</ul>`
      controlUI.appendChild(controlBody)
      map.controls[google.maps.ControlPosition.LEFT_CENTER].push(controlUI)
    },
    removeCompanyCoveragesGeoJson() {
      map.data.forEach((coverage) => {
        map.data.remove(coverage)
      })
    },
    serviceTypeFilterChanged() {
      const element = document.getElementById('coverages-container')
      if (element) {
        element.remove()
      }
      companiesCoverages = []
      this.removeCompanyCoveragesGeoJson()
      // this.realoadMap()
      this.loadCompanyCoverages()
    },
    RightControl(coverages) {
      if (!coverages || coverages.length === 0) {
        return
      }

      const centerControlDiv = document.createElement('div')

      const controlUI = document.createElement('div')
      controlUI.style.backgroundColor = '#fff'
      controlUI.style.border = '2px solid #fff'
      controlUI.style.borderRadius = '3px'
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
      controlUI.style.marginBottom = '22px'
      controlUI.style.marginRight = '10px'
      controlUI.style.textAlign = 'center'
      controlUI.style.width = '250px'
      controlUI.className = 'title-style'
      controlUI.id = 'coverages-container'
      centerControlDiv.appendChild(controlUI)

      // Set CSS for the control interior.
      const controlText = document.createElement('div')
      controlText.style.color = 'rgb(25,25,25)'
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlText.style.fontSize = '16px'
      controlText.style.lineHeight = '38px'
      controlText.style.paddingLeft = '5px'
      controlText.style.paddingRight = '5px'
      controlText.innerHTML = 'Coberturas'
      controlUI.appendChild(controlText)

      // Set CSS for the control interior.
      const controlBody = document.createElement('div')
      controlBody.style.color = 'rgb(25,25,25)'
      controlBody.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlBody.style.fontSize = '16px'
      controlBody.style.lineHeight = '38px'
      controlBody.style.paddingLeft = '5px'
      controlBody.style.paddingRight = '5px'
      controlBody.style.textAlign = 'left'
      controlBody.id = 'coverages-control'

      let bodySting = ''

      if (coverages.length) {
        bodySting
          += '<li class="click-control-coverage all-coverages" name="all-coverages"><span class="dot" style="background: black;" /></span> Todos</li>'
        coverages.forEach((coverage) => {
          const {
            geometry: { coordinates }
          } = coverage.features[0]
          companiesCoverages.push({
            name: coverage.properties.name,
            coverages: coverage
          })
          const lng = coordinates[0][0][0]
          const lat = coordinates[0][0][1]
          bodySting += `<li class="click-control-coverage" name="${coverage.properties.name}" lat="${lat}" lng="${lng}"><span class="dot" style="background: ${coverage.properties.color};" /></span> ${coverage.properties.name}</li>`
        })
        companiesCoverages.forEach((coverage) => {
          map.data.addGeoJson(coverage.coverages)
        })
      }

      controlBody.innerHTML += `<ul>${bodySting}</ul>`
      controlUI.appendChild(controlBody)
      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(controlUI)

      map.data.setStyle((feature) => {
        const SD_NAME = feature.getProperty('SD_NAME')
        if (SD_NAME !== 'GeoJSON') {
          return false
        }
        const color = feature.getProperty('color')
        return {
          fillColor: color,
          strokeWeight: 1
        }
      })
    },
    getAsset(id) {
      switch (id) {
      case 1:
        return {
          class: 'click-control blue-marker',
          asset: 'img/markers/blue-marker.svg'
        }
      case 2:
      case 9:
        return {
          class: 'click-control yellow-marker',
          asset: 'img/markers/yellow-marker.svg'
        }
      case 3:
        return {
          class: 'click-control light-blue-marker',
          asset: 'img/markers/light-blue-marker.svg'
        }
      case 4:
        return {
          class: 'click-control green-marker',
          asset: 'img/markers/green-marker.svg'
        }
      case 5:
        return {
          class: 'click-control grey-marker',
          asset: 'img/markers/grey-marker.png'
        }
      default:
        return {
          class: 'click-control blue-marker',
          asset: 'img/markers/blue-marker.png'
        }
      }
    },
    async addServiceToMap() {
      statusArray = []

      const services = await this.fetchServices()
      if (!services || !services.length) return

      for (let i = 0; i < services.length; i += 1) {
        const service = services[i]
        const statusAsset = this.getAsset(service.statusId)
        const pos = statusArray.map((e) => e.id).indexOf(service.statusId)

        if (pos === -1) {
          statusArray.push({ id: service.statusId, name: service.status })
        }

        const { address } = service
        if (address && address.latitude && address.longitude) {
          const icon = { ...this.icons.group, url: statusAsset.asset }
          const position = new google.maps.LatLng(
            address.latitude,
            address.longitude
          )
          this.addServicesMarker(position, icon, service)
        }
      }

      if (map.controls[google.maps.ControlPosition.LEFT_CENTER].length === 0) {
        await this.CenterControl(statusArray)
      } else {
        let bodySting = '<li>No hay datos que mostrar</li>'
        if (statusArray.length > 0) {
          bodySting = '<li class="click-control show-option" status="0">Todos</li>'
          statusArray.forEach((value) => {
            const statusAsset = this.getAsset(value.id)
            bodySting += `<li class="${statusAsset.class}" status="${value.id}">${value.name}</li>`
          })
        }
        $('#information-control').html(`<ul>${bodySting}</ul>`)
      }
    },
    async addProvidersToMap() {
      const providers = await this.fetcProvidersLocation()
      if (!providers) return
      const locations = []
      providers.forEach((provider) => {
        const { coordinates, state } = provider
        if (coordinates) {
          const timeDiff = Date.now() - new Date(coordinates.updatedAt).getTime()
          const position = new google.maps.LatLng(
            coordinates.latitude,
            coordinates.longitude
          )
          const icon = timeDiff / (1000 * 60 * 60 * 24) >= 2
            ? this.icons.piperoOutdated
            : this.icons.pipero

          this.addProviderMarker(position, icon, provider)
          if (
            locations.findIndex((location) => location.state === state)
              === -1
            && state
          ) {
            locations.push({
              state,
              lat: coordinates.latitude,
              lng: coordinates.longitude
            })
          }
        }
      })
      this.locations = locations
      this.showMarkers()
    },
    formatAddress(address) {
      if (!address) return ''
      return `${address.street} ${address.numExt}, ${address.suburb}<br> ${address.suburb} ${address.municipality}`
    },
    addProviderMarker(location, icon, provider) {
      const marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        icon,
        infowindow: new google.maps.InfoWindow(),
        map,
        position: location
      })


      const { infowindow } = marker;
      const info = [
        `<b>Nombre: </b> ${provider.name}<br>`,
        `<b>Teléfono: </b>${provider.phoneNumber}<br>`,
        `<b>Ultima Actualización: </b>${this.toLocalTimeZone(
          provider.coordinates.updatedAt
        )}`
      ].join('');

      google.maps.event.addListener(marker, 'click', () => {
        this.infoProvider(info, infowindow, marker, map)
      })
      this.infoProvider(info, infowindow, marker, map);

      providersMarkers.push(marker)
    },
    infoProvider(info, infowindow, marker, map){
        infowindow.close(map, marker)
        infowindow.setContent(info)
        infowindow.open(map, marker)
    },
    addServicesMarker(position, icon, service) {
      const marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        map,
        position,
        icon,
        status: service.statusId,
        infowindow: new google.maps.InfoWindow()
      })

      google.maps.event.addListener(marker, 'click', async () => {
        const serviceDetails = await this.fetchServiceDetails(service)
        if (!serviceDetails) return

        const info = [
          `<b>Folio: </b> ${serviceDetails.folio}<br>`,
          `<b>Monto: </b>${serviceDetails.amount}<br>`,
          `<b>Fecha y hora del servicio: </b>${serviceDetails.serviceDate}<br>`,
          `<b>Tipo de Pago: </b>${serviceDetails.paymentType}<br>`,
          `<b>Comisión: </b>${serviceDetails.serviceFee}<br>`,
          `<b>Dirección: </b>${this.formatAddress(serviceDetails.address)}<br>`,
          `<b>Estatus: </b>${serviceDetails.status}`
        ].join('')

        marker.infowindow.setContent(info)
        marker.infowindow.open(map, marker)
      })

      servicesMarkers.push(marker)
    },
    // Sets the map on all markers in the array.
    // eslint-disable-next-line no-shadow
    setMapOnAll(map) {
      // show provders
      for (let i = 0; i < providersMarkers.length; i += 1) {
        providersMarkers[i].setMap(map)
      }

      for (let i = 0; i < servicesMarkers.length; i += 1) {
        servicesMarkers[i].setMap(map)
      }
    },
    setMapOnSelect(array) {
      // show provders
      for (let i = 0; i < array.length; i += 1) {
        array[i].setMap(map)
      }
    },
    // Removes the markers from the map, but keeps them in the array.
    clearMarkers() {
      this.setMapOnAll(null)
    },
    // Shows any markers currently in the array.
    showMarkers() {
      this.setMapOnAll(map)
    },
    // Deletes all markers in the array by removing references to them.
    deleteMarkers() {
      this.clearMarkers()
      providersMarkers = []
      servicesMarkers = []
    },
    toggleService(id) {
      this.clearMarkers()
      clearInterval(setIntervalControl)

      const select = []

      if (['0'].includes(id)) {
        $('.show-option').css('display', 'none')
        this.setIntervalFunction()
        this.setMapOnAll(map)
      } else if (['1', '2', '3', '4', '5', '9'].includes(id)) {
        $('.show-option').css('display', 'block')
        const targetValue = +id
        servicesMarkers.forEach((value) => {
          if (value.status === targetValue) {
            select.push(value)
          }
        })
      }

      this.setMapOnSelect(select)
    },
    setSelectLocations() {
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(
        document.getElementsByName('locations')[0]
      )
    },
    setSelectServiceType() {
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(
        document.getElementsByName('serviceTypeSelect')[0]
      )
    },
    onChangeLocation() {
      const { lat, lng } = this.locations.find(
        (location) => location.state === this.locationSelected
      )
      this.moveToLocation(lat, lng)
    },
    moveToLocation(lat, lng) {
      const center = new google.maps.LatLng(lat, lng)
      map.setZoom(11)
      map.panTo(center)
    },
    mergeGeoJSON(data) {
      return {
        type: 'FeatureCollection',
        features: data.map(({ features }) => features).flat()
      }
    },
    async loadCompanyCoverages() {
      if (this.isCompanyAdmin) {
        try {
          const { data } = await this.axios.get(
            '/providers/companies/coverages'
          )
          data.forEach(({ coordinates }) => this.geojsons.push(coordinates))
        } catch (err) {
          console.log(err)
        } finally {
          map.data.addGeoJson(this.geojson)
        }
      } else {
        try {
          this.geojson = []

          // TODO: Coverages enpoint
          const requestUrl = this.serviceTypeSelected && +this.serviceTypeSelected > 0
            ? `/providers/companies/coverages/all?typeServiceId=${+this.serviceTypeSelected}` : '/providers/companies/coverages/all'

          const { data } = await this.axios.get(requestUrl)

          const companyCoverages = data.map((company) => {
            const properties = {
              // eslint-disable-next-line no-bitwise
              color: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
              SD_NAME: 'GeoJSON',
              name: company.name
            }
            const coverages = this.mergeGeoJSON(
              company.coverages
                .map(({ coordinates }) => coordinates)
                .map((coverage) => ({
                  ...coverage,
                  features: coverage.features.map((feature) => ({
                    ...feature,
                    properties
                  })),
                  properties
                }))
            )

            coverages.properties = properties
            return coverages
          })

          this.companyCoverages = companyCoverages

          this.RightControl(companyCoverages)
        } catch (err) {
          console.log(err)
        } finally {
          map.data.addGeoJson(this.geojson)
        }
      }
    }
  }
}
</script>

<style>
span.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
/* Scoped #maps */
#maps {
  width: 100%;
  height: 800px;
}
#save-widget {
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  background-color: white;
  padding: 10px;
  font-family: Roboto, Arial;
  font-size: 13px;
  margin: 15px;
}
ul {
  list-style-type: none;
}

/*Aceptado*/
ul li.light-blue-marker:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  /*TODO:Change color*/
  background-image: url("~@/assets/markers/light-blue-marker.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}
/*Creado*/
ul li.blue-marker:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  /*TODO:Change color*/
  background-image: url("~@/assets/markers/blue-marker.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}
/*Activo*/
ul li.yellow-marker:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  background-image: url("~@/assets/markers/yellow-marker.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}

/*Terminado*/
ul li.grey-marker:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  /*TODO:Change color*/
  background-image: url("~@/assets/markers/group.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}

/*Llego*/
ul li.green-marker:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  /*TODO:Change color*/
  background-image: url("~@/assets/markers/green-marker.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}

ul li.show-option:before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  background-size: 26px;
  /*TODO:Change color*/
  background-image: url("~@/assets/markers/group.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}

.map-select {
  z-index: 0;
  position: absolute;
  top: 70px !important;
  padding: 10px !important;
  font-size: 15px !important;
  border: 1px solid #ccc;
  font-weight: 400;
  height: 40px;
  border-radius: 4px;
  left: 380px !important;
}

.map-service-type-select {
  z-index: 0;
  position: absolute;
  top: 10px !important;
  padding: 10px !important;
  font-size: 15px !important;
  border: 1px solid #ccc;
  font-weight: 400;
  height: 40px;
  left: 389px !important;
  border-radius: 4px;
}

.click-control {
  cursor: pointer !important;
}

.click-control:hover {
  color: #5b2ade;
}

.click-control-coverage {
  cursor: pointer !important;
}

.click-control-coverage:hover {
  color: #5b2ade;
}

.all-coverages {
  display: none;
}

.show-option {
  display: none;
}

.relative-container {
  position: relative;
}
@media (min-width: 992px) {
  .title-style {
    margin-left: 5px;
    width: 130px;
  }
}

@media (max-width: 415px) {
  .title-style {
    margin-left: 5px !important;
    width: 130px !important;
  }
  ul {
    padding-left: 0px !important;
  }
}
</style>
