<template>
  <div class="rating">
    <div class="card">
      <div class="card-header">
        <h3>Listado de servicios cancelados</h3>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :noItemsView="noItemsView"
          hover
          :sorter="{ external: true }"
          :sorterValue.sync="sort"
          @row-clicked="onRowClicked"
          class="main-table"
        >
          <!-- filters -->
          <template #over-table>
            <div class="filters-section">
              <div>
                <div class="open-filter">
                  <input
                    v-model.trim="openFilterInput"
                    type="text"
                    placeholder="Folio"
                    maxlength="8"
                    v-c-tooltip="'Búsqueda de servicios por folio'"
                    @keypress="alphaNumeric($event)"
                  />
                  <button
                    class="btn btn-primary"
                    v-c-tooltip="
                      'Ejecuta la acción de búsqueda de lo ingresado en el campo'
                    "
                    @click="search()"
                  >
                    Buscar
                  </button>
                  <button
                    class="btn btn-light"
                    @click="resetFilters()"
                    v-c-tooltip="
                      'Limpia los filtros y retorna todas los servicios cancelados'
                    "
                  >
                    Limpiar
                  </button>
                </div>
                <div>
                  <date-range-picker
                    v-model="dateRange"
                    :locale-data="{
                      format: 'dd/mm/yyyy',
                      daysOfWeek: daysOfWeekSpanish,
                      applyLabel: 'Aplicar',
                      cancelLabel: 'Cancelar',
                    }"
                    :ranges="false"
                    opens="left"
                    :append-to-body="false"
                    :date-format="disabledDates"
                    @update="dateRangeFilter"
                    class="date-range"
                    v-c-tooltip="
                      'Filtra los servicios cancelados por el rango de fecha indicado'
                    "
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      <span v-if="!picker.startDate && !picker.endDate">
                        Ej: 01/01/2020 - 03/09/2020
                      </span>
                      <span v-else>
                        {{ formatToDatePicker(picker.startDate) }} -
                        {{ formatToDatePicker(picker.endDate) }}
                      </span>
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </template>
          <!-- custom table fields -->
          <template #folio="{ item }">
            <td>{{ item.folio ? item.folio : "Sin folio" }}</td>
          </template>
          <template #reason="{ item }">
            <td>{{ item.reason || "Sin motivo" }}</td>
          </template>
          <template #createdAt="{ item }">
            <td>{{ (item.createdAt) }}</td>
          </template>
          <template #serviceDate="{ item }">
            <td>{{ formatDate(item.serviceDate) }}</td>
          </template>
          <template #cancelledAt="{ item }">
            <td>{{ (item.cancelledAt) }}</td>
          </template>
        </CDataTable>
        <CPagination :active-page.sync="page" :pages="pages" align="center" />
      </div>
      <div class="card-footer">
        <p>
          Total de registros: <strong>{{ totalRecords }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import queryParser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const fields = [
  { key: 'folio', label: 'Folio', sorter: false },
  { key: 'reason', label: 'Motivo de cancelación', sorter: true },
  { key: 'createdAt', label: 'Fecha de creación', sorter: true },
  { key: 'serviceDate', label: 'Fecha de servicio (Llegada)', sorter: false },
  { key: 'cancelledAt', label: 'Fecha de cancelación', sorter: false }
]
export default {
  name: 'CanceledServices',
  components: {
    DateRangePicker
  },
  data() {
    return {
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      items: [],
      daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      fields,
      companyInfo: {},
      page: 1,
      pages: 0,
      totalRecords: 0,
      average: 0,
      dateRange: {
        startDate: '',
        endDate: ''
      },
      availableDateRange: {
        startDate: '',
        endDate: ''
      },
      startDate: '',
      endDate: '',
      sort: {
        column: 'createdAt',
        asc: false
      },
      activeModal: false,
      infoModal: {},
      alertError: false,
      error: {},
      openFilterInput: '',
      ratingFilterSelected: null,
      citySelected: null,
      stateSelected: null,
      byComments: null,
      cityItems: []
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin'])
  },
  watch: {
    page() {
      this.getCancelledServices()
    },
    sort() {
      this.getCancelledServices()
    },
    openFilterInput(value) {
      this.openFilterInput = this.openFilterInput.toUpperCase()
      if (!value.length || value.length >= 5) {
        this.getCancelledServices()
      }
    },
    stateSelected(value) {
      const cityItems = {
        CDMX: ['CDMX'],
        Jalisco: ['Guadalajara', 'Zapopan', 'San Pedro Tlaquepaque'],
        México: ['Estado de México'],
        Querétaro: ['Querétaro'],
        'Baja California Sur': ['Los Cabos'],
        'San Luis Potosí': ['San Luis Potosí'],
        Colima: ['Colima'],
        Hidalgo: ['Hidalgo'],
        Teoloyucan: ['Teoloyucan'],
        'Nicolás Romero': ['Nicolás Romero'],
        Ecatepec: ['Ecatepec'],
        'Valle de Bravo': ['Valle de Bravo'],
        Toluca: ['Toluca']
      }
      this.cityItems = cityItems[value] || []
      this.citySelected = null
      this.getCancelledServices()
    },
    byComments() {
      this.getCancelledServices()
    }
  },
  async mounted() {
    if (this.isCompanyAdmin) {
      await this.getCompanyInfo()
    } else {
      this.getCancelledServices()
    }
  },
  methods: {
    getCancelledServices() {
      const filters = this.getFilters()
      const queryString = queryParser.parse(filters)

      this.axios
        .get(`services/cancelled?${queryString}`)
        .then((response) => {
          const { data } = response

          this.items = data.data
          this.pages = data.pagination.totalPages
          this.totalRecords = data.pagination.total

          this.items.map((item) => {
            item.createdAt = moment.tz(item.createdAt, 'America/Mexico_City').format('DD/MM/YYYY hh:mm A')
            item.cancelledAt = moment.tz(item.cancelledAt, 'America/Mexico_City').format('DD/MM/YYYY hh:mm A')

            return item
          })

          if (pagination.dates) {
              this.availableDateRange.startDate = new Date(
                `${pagination.dates.min}T00:00:00`
              )
              this.availableDateRange.endDate = new Date(
                `${pagination.dates.max}T00:00:00`
              )
            }

          return this.items
        })
        .catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    getFilters() {
      const options = {
        state: this.state,
        page: this.page,
        serviceTypeUuid: this.serviceTypeSelected
      }
      if (this.startDate && this.endDate) {
        options.serviceDate = `${this.startDate}:${this.endDate}`
      }

      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        const value = this.openFilterInput.trim().toUpperCase()
        this.openFilterInput = value
        options.folio = value
      }

      return options
    },
    async getCompanyInfo() {
      try {
        const path = 'providers/current'
        const { data } = await this.axios.get(path)
        this.companyInfo = data
        this.getCancelledServices()
      } catch (err) {
        this.companyInfo = {}
      }
    },
    formatDate(date) {
      return moment.utc(date, 'YYYYMMDD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
    },
    filter() {
      const options = {
        page: this.page
      }
      if (this.startDate && this.endDate) {
        options.serviceDate = `${this.startDate}:${this.endDate}`
      }
      if (this.sort.asc !== null) {
        options.orderBy = this.sort.column
        options.sortBy = this.sort.asc ? 'ASC' : 'DESC'
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options.folio = this.openFilterInput
      }

      return options
    },
    dateRangeFilter() {
      this.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      this.getCancelledServices()
    },
    openModal(item) {
      this.activeModal = true
      const uuid = item.serviceUuid
      this.axios
        .get(`/services/${uuid}`)
        .then((response) => {
          this.alertError = false
          const { data } = response
          this.infoModal = data
        })
        .catch(() => {
          this.error = getErrorMessage({
            response: { data: { codeApi: 'EA0015' } }
          })
          this.alertError = true
          this.infoModal = {}
        })
    },
    onRowClicked(item) {
      this.openModal(item)
    },
    resetFilters() {
      this.startDate = null
      this.endDate = null
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.page = 1
      this.sort.column = 'createdAt'
      this.sort.asc = false
      this.openFilterInput = ''
      this.getCancelledServices()
    },
    disabledDates(classes, date) {
      const getCalendarDate = moment(date.getTime()).format()

      if (!classes.disabled) {
        classes.disabled = getCalendarDate > moment(this.availableDateRange.endDate).format()
      }
      return classes
    },
    formatToDatePicker(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatProviders(name) {
      return name === null ? 'Sin nombre' : name
    },
    search() {
      if (this.openFilterInput.length || this.openFilterInput.length >= 5) {
        this.getCancelledServices()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  .card {
    .card-header,
    .card-footer {
      display: flex;
      p {
        margin: auto 0 auto auto;
      }
    }
    .card-body {
      .date-range {
        margin-bottom: 8px;
      }
      .filters-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        > div {
          display: flex;
          flex-direction: column;
          &:first-child {
            > div {
              &:last-child {
                .date-range {
                  width: 100%;
                  @media (min-width: 992px) {
                    width: auto;
                  }
                }
                @media (min-width: 992px) {
                  margin-left: auto;
                }
              }
            }
          }
          @media (min-width: 992px) {
            flex-direction: row;
          }
        }
        .open-filter {
          margin-bottom: 16px;
          display: flex;
          input {
            height: 35px;
            box-sizing: content-box;
            padding: 0 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: calc(100% - 165px);
          }
          button {
            height: 35px;
            border-radius: 4px;
            margin-left: 4px;
          }
        }
        .select-filters-wrapper {
          display: flex;
          flex-direction: column;
          @media (min-width: 992px) {
            flex-direction: row;
          }
          .select-filter {
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .text-wrapper {
    > div {
      margin-bottom: 8px;
    }
  }
  .tabs-selector {
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button {
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 3);
      @media (min-width: 992px) {
        width: 53px;
      }
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active {
        background-color: #5b2ae0;
        color: white;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}
</style>
