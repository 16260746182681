<template>
  <div>
    <GeoEditor ref="geomap"></GeoEditor>
  </div>
</template>

<script>
import GeoEditor from './GeoEditor'

export default {
  components: {
    GeoEditor
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: false,
      default: () => false
    },
    registryUuid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loaded: false,
    geojson: {},
    data: {
      coordinates: null
    }
  }),
  watch: {
    registryUuid() {
      this.fetchCoverage()
    }
  },
  mounted() {
    this.fetchCoverage()
  },
  methods: {
    loadGeoJSON() {
      this.$refs.geomap.setGeoJSON(this.geojson)
    },
    clearMap() {
      this.$refs.geomap.clearMap()
    },
    onCancel() {
      this.$emit('close')
    },
    invalidateSize() {
      this.$refs.geomap.invalidateSize()
    },
    toGeoJSON() {
      return this.$refs.geomap.toGeoJSON()
    },
    async fetchCoverage() {
      try {
        const { data } = await this.$axios.get(`/admin/providers/companies/coverages/${this.registryUuid}`)
        this.geojson = data.coordinates
        this.loadGeoJSON()
      } catch (err) {
        console.log(err)
      }
    },
    async onSuccess() {
      const geojson = this.$refs.geomap.toGeoJSON()
      try {
        await this.$axios.put(`/admin/providers/companies/coverages/${this.registryUuid}`, {
          coordinates: geojson
        })
        this.$emit('success')
      } catch (err) {
        console.log(err)
      }
    },
    openModal() {
      this.isModalActive = true
      // Fix map image issues
      this.$nextTick(() => {
        this.$refs.geomap.invalidateSize()
      })
    }
  }
}
</script>

<style scoped>
.geo-modal {
  width: 500px;
}
</style>
