const STATUS_SERVICES = {
  CREATED: { NAME: 'Creado', TYPE: 'primary', ID: 1 },
  ACTIVE: { NAME: 'Validado', TYPE: 'warning', ID: 2 },
  ACCEPTED: { NAME: 'Aceptado', TYPE: 'info', ID: 3 },
  EN_CAMINO: { NAME: 'En camino', TYPE: 'info', ID: 3 },
  ARRIVED: { NAME: 'Llegó', TYPE: 'success', ID: 4 },
  FINISHED: { NAME: 'Terminado', TYPE: 'dark', ID: 5 },
  CANCEL_BY_CLIENT: { NAME: 'Cancelado por cliente', TYPE: 'dark', ID: 6 },
  CANCEL_BY_PROVIDER: { NAME: 'Cancelado por colaborador', TYPE: 'dark', ID: 7 },
  CANCEL_BY_ADMIN_FREE: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  CANCEL_BY_ADMIN_COST: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  ASSIGNED: { NAME: 'Asignado', TYPE: 'success', ID: 9 },
  TAKEN: { NAME: 'Aceptado', TYPE: 'success', ID: 10 },
  PAYMENT_PENDING: { NAME: 'Por pagar', TYPE: 'warning', ID: 11 },
  BUDGET_AWAIT: { NAME: 'En espera de cotización', TYPE: 'primary', ID: 12 },

  1: { TYPE: 'primary' },
  2: { TYPE: 'warning' },
  3: { TYPE: 'info' },
  4: { TYPE: 'success' },
  5: { TYPE: 'dark' },
  6: { TYPE: 'dark' },
  7: { TYPE: 'dark' },
  8: { TYPE: 'dark' },
  9: { TYPE: 'success' },
  10: { TYPE: 'success' },
  11: { TYPE: 'warning' }
}
const PERMISSIONS = {
  SERVICE_READ_ONLY: 'SERVICE_READ_ONLY'
}

const STATUS_SERVICES_FINISHED_SERVICE = [
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
  STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
]

const STATUS_SERVICES_TO_CANCEL_BY_ADMIN = [
  STATUS_SERVICES.CREATED.ID,
  STATUS_SERVICES.ACTIVE.ID,
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.ASSIGNED.ID,
  STATUS_SERVICES.TAKEN.ID
]

const STATUS_SERVICES_FROM_ONE_TO_FIVE = [
  STATUS_SERVICES.CREATED.ID,
  STATUS_SERVICES.ACTIVE.ID,
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.ASSIGNED.ID,
  STATUS_SERVICES.TAKEN.ID
]

const STATUS_SERVICES_TO_ASSIGN = [
  STATUS_SERVICES.ACTIVE.ID
]

const STATUS_SERVICES_TO_REASSIGN = [
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.ASSIGNED.ID,
  STATUS_SERVICES.TAKEN.ID
]
const STATUS_SERVICES_TO_CHANGE_STATUS = [
  STATUS_SERVICES.CREATED.ID
]
const STATUS_SERVICES_TO_EVIDENCE = [
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.TAKEN.ID,
  STATUS_SERVICES.ASSIGNED.ID,
  STATUS_SERVICES.EN_CAMINO.ID
]

const CANCELLED_STATUS = [
  STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
  STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
]

const STATUS_SERVICES_TO_FINISH_SERVICE = [
  STATUS_SERVICES.ACTIVE.ID,
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.ASSIGNED.ID,
  STATUS_SERVICES.TAKEN.ID
]

const STATUS_SERVICES_TO_ASSIGN_COMPANY = [
  STATUS_SERVICES.ACTIVE.ID,
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.ASSIGNED.ID
]

const STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED = [
  STATUS_SERVICES.FINISHED.ID
]

const USER_TYPES = {
  GENERAL_ADMIN: 0,
  COMPANY_ADMIN: 1
}

const ALL_STATUS_SERVICES = [
  STATUS_SERVICES.CREATED.ID,
  STATUS_SERVICES.ACTIVE.ID,
  STATUS_SERVICES.ACCEPTED.ID,
  STATUS_SERVICES.ARRIVED.ID,
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
  STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID
]

const ASSIGN_MODELS = {
  ADMIN: { NAME: 'Admin', ID: 1 },
  APPLICATION: { NAME: 'Aplicación', ID: 2 }
}

const COMPANY_VERTICALS = {
  WATER: { NAME: 'Agua', ID: 3 },
  UPHOLSTERY: { NAME: 'Tapicería', ID: 5 },
  GAS: { NAME: 'Gas', ID: 1 },
  HOUSE_CLEANING: { NAME: 'Limpieza del hogar', ID: 4 }
}

const SERVICE_TYPES = {
  GAS: {
    ID: 1,
    NAME: 'Gas Estacionario',
    REGISTRY_UUID: '3066029c-4713-4e26-aef7-42458bb216ce',
    DESCRIPTION: 'Gas',
    IS_ACTIVE: true,
    CATEGORIES: {
      GAS: {
        ID: 1,
        NAME: 'Estacionario',
        REGISTRY_UUID: '35f21395-253e-4960-9793-1bd1db0a8e99'
      },
      GAS_CYLINDER: {
        ID: 2,
        NAME: 'Cilindro',
        REGISTRY_UUID: '6dd22724-fe6a-4362-9555-ec3157306c40'
      }
    }
  },
  GAS_CYLINDER: {
    ID: 2,
    NAME: 'Gas Cilindro',
    REGISTRY_UUID: 'c352aee9-4217-4dea-acde-7446fe7bc501',
    DESCRIPTION: 'Gas cilindro',
    IS_ACTIVE: true
  },
  WATER: {
    ID: 3,
    REGISTRY_UUID: '2f7c428a-67a9-4060-b1b7-e040f501a0c6',
    DESCRIPTION: 'Agua',
    IS_ACTIVE: true
  },
  HOME_CLEANER: {
    ID: 4,
    REGISTRY_UUID: '9cae23fc-1b18-11ec-9621-0242ac130002',
    DESCRIPTION: 'Limpieza del hogar',
    IS_ACTIVE: true
  },
  UPHOLSTERY: {
    ID: 5,
    REGISTRY_UUID: '4f54a9cb-0943-456b-a900-d8d5569f8297',
    DESCRIPTION: 'Tapicería',
    IS_ACTIVE: true
  },
  FUMIGATION: {
    ID: 6,
    REGISTRY_UUID: '35a04d07-634a-4aee-9037-ab215e9ca361',
    DESCRIPTION: 'Fumigación',
    IS_ACTIVE: true
  },
  RESTAURANT: {
    ID: 28,
    REGISTRY_UUID: 'f49f1fbd-b64d-4dd9-8ea5-1ee28994c1e3',
    DESCRIPTION: 'Restaurantes',
    IS_ACTIVE: true
  }
}

const SERVICE_TYPES_UUID_WITH_REQUIRED_EVIDENCE = [
  SERVICE_TYPES.GAS.REGISTRY_UUID,
  SERVICE_TYPES.GAS_CYLINDER.REGISTRY_UUID,
  SERVICE_TYPES.UPHOLSTERY.REGISTRY_UUID
]

const ALLOWED_DAY_CONFIG = {
  [SERVICE_TYPES.GAS.ID]: 0,
  [SERVICE_TYPES.WATER.ID]: 1,
  [SERVICE_TYPES.HOME_CLEANER.ID]: 3,
  [SERVICE_TYPES.UPHOLSTERY.ID]: 4,
  [SERVICE_TYPES.FUMIGATION.ID]: 1
}

const HIDDEABLE_ROWS = {
  VEHICLE_PLATE: { NAME: 'Placas del vehículo', ID: 1 },
  ASSISTANT_NAME: { NAME: 'Nombre del Asistente', ID: 2 },
  VEHICLE_PHOTO: { NAME: 'Foto del vehículo', ID: 3 }
}

const COMPANIES_WITH_ASSISSTANT_NAME = [
  COMPANY_VERTICALS.GAS.ID,
  COMPANY_VERTICALS.UPHOLSTERY.ID
]

const COMPANIES_WITH_VEHICLE_PHOTO = [
  COMPANY_VERTICALS.GAS.ID,
  COMPANY_VERTICALS.WATER.ID,
  COMPANY_VERTICALS.UPHOLSTERY.ID
]

const COMPANIES_WITH_VEHICLE_PLATE = [
  COMPANY_VERTICALS.GAS.ID,
  COMPANY_VERTICALS.WATER.ID,
  COMPANY_VERTICALS.UPHOLSTERY.ID
]

const COMPANIES_WITH_REQUIRED_ASSISSTANT_NAME = [
  COMPANY_VERTICALS.UPHOLSTERY.ID
]

const COMPANIES_WITH_REQUIRED_VEHICLE_PHOTO = [
  COMPANY_VERTICALS.WATER.ID,
  COMPANY_VERTICALS.UPHOLSTERY.ID
]

const COMPANIES_WITH_REQUIRED_VEHICLE_PLATE = [
]

const PAYMENT_TYPES = {
  CASH: 1,
  TERMINAL: 2,
  CARD: 3,
  LINK: 4,
  TRANSFERENCIA: 5,
  KUESKIPAY: 6,
  CREDIT: 8
}

const SERVICE_ORIGIN = {
  MESSENGER: { id: 1, name: 'messenger', display: 'Messenger' },
  CALL: { name: 'call', display: 'Llamada' },
  WHATSAPP: { name: 'whatsapp', display: 'WhatsApp' },
  ADMIN: { name: 'admin', display: 'Administrador' },
  CALL_BOT: { name: 'call_bot', display: 'Bot Llamada' },
  RECURRENCE: { name: 'recurrence', display: 'Suscripción' },
  WHATSAPP_BOT: { name: 'whatsapp_bot', display: 'Bot WhatsApp' }
}

const CANCELLATION_RESPONSABLE = {
  CLIENT: { id: 1, name: 'Cliente' },
  GAS_CARRIER: { id: 2, name: 'Gasero' },
  OKBOY: { id: 3, name: 'Okboy' }
}

const SCHEDULE_TYPE = {
  WEEKDAY: { id: 0, name: 'Weekday' },
  SATURDAY: { id: 1, name: 'Saturday' },
  SUNDAY: { id: 2, name: 'Sunday' }
}

const RECURRENCE_PERIOD_TYPE = {
  DAYS: { id: 1, name: 'days' },
  WEEKS: { id: 2, name: 'week' },
  MONTHS: { id: 3, name: 'month' }
}

const VALIDATIONS = {
  MIN_TEXT_LENGTH: 5
}

const STATUS_PREORDERS = {
  CREATED: { id: 1, name: 'Creado' },
  REJECTED: { id: 2, name: 'Rechazado' },
  CANCELED: { id: 3, name: 'Cancelado' },
  FINISHED: { id: 4, name: 'Terminado' },
  VALIDATED: { id: 5, name: 'Validado' },
  DURING_VALIDATION: { id: 6, name: 'En Validación' },
}

const SERVICE_COMMENT_TYPES = {
  CUSTOMER_COMMENT: 1,
  ADMIN_SPECIFICATION: 2
}

const SERVICE_TYPE_VIEW = {
  DEFAULT_VIEW: 1,
  MULTIPLE_CATEGORIES: 2,
  DIGITAL_SERVICE_FETCH_BILL: 3,
  MULTIPLE_TAGS_VIEW: 4
}

const RECURRENCE_TYPE = {
  days: 'Diaria',
  week: 'Semanal',
  weeks: 'Semanal',
  month: 'Mensual',
  months: 'Mensual'
}

const DEVICES = {
  iOS: 'ios',
  Android: 'android',
  Admin: 'admin',
  Web: 'web'
}

const PAYMENT_LINK_STATUS = {
  0: 'Creado',
  1: 'En progreso',
  2: 'Finalizado'
}

// TODO: convert enum to folder and create file status services

export {
  STATUS_SERVICES,
  STATUS_SERVICES_TO_CANCEL_BY_ADMIN,
  STATUS_SERVICES_FROM_ONE_TO_FIVE,
  STATUS_SERVICES_TO_ASSIGN,
  STATUS_SERVICES_TO_REASSIGN,
  STATUS_SERVICES_TO_CHANGE_STATUS,
  STATUS_SERVICES_TO_EVIDENCE,
  CANCELLED_STATUS,
  STATUS_SERVICES_TO_FINISH_SERVICE,
  USER_TYPES,
  ALL_STATUS_SERVICES,
  STATUS_SERVICES_TO_ASSIGN_COMPANY,
  STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED,
  STATUS_SERVICES_FINISHED_SERVICE,
  ASSIGN_MODELS,
  COMPANIES_WITH_ASSISSTANT_NAME,
  COMPANIES_WITH_VEHICLE_PHOTO,
  COMPANIES_WITH_VEHICLE_PLATE,
  COMPANIES_WITH_REQUIRED_ASSISSTANT_NAME,
  COMPANIES_WITH_REQUIRED_VEHICLE_PHOTO,
  COMPANIES_WITH_REQUIRED_VEHICLE_PLATE,
  HIDDEABLE_ROWS,
  PAYMENT_TYPES,
  SERVICE_ORIGIN,
  SERVICE_TYPES,
  ALLOWED_DAY_CONFIG,
  CANCELLATION_RESPONSABLE,
  SERVICE_TYPES_UUID_WITH_REQUIRED_EVIDENCE,
  PERMISSIONS,
  SCHEDULE_TYPE,
  RECURRENCE_PERIOD_TYPE,
  VALIDATIONS,
  STATUS_PREORDERS,
  SERVICE_COMMENT_TYPES,
  SERVICE_TYPE_VIEW,
  RECURRENCE_TYPE,
  DEVICES,
  PAYMENT_LINK_STATUS
}
