<template>
  <div>
    <ClientIncrementChart></ClientIncrementChart>
    <ClientSatisfactionChart></ClientSatisfactionChart>
  </div>
</template>

<script>
import ClientSatisfactionChart from '../components/ClientSatisfactionChart'
import ClientIncrementChart from '../components/ClientIncrementChart'

export default {
  components: {
    ClientSatisfactionChart,
    ClientIncrementChart
  }
}
</script>
