<template>
  <div class="promocodes">
    <div tabindex="0" ref="successPromocodes">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
      <CAlert
        color="warning"
        :closeButton="true"
        :show.sync="RepeatedError.hasError">
        {{RepeatedError.message}}
      </CAlert>
      <CAlert
        color="warning"
        :closeButton="true"
        :show.sync="MasiveError.hasError">
        {{MasiveError.message}}
      </CAlert>
    </div>
    <div class="card">
      <div>
        <button
          id="add_promocode"
          type="button"
          @click="openCreatePromocode"
          class="btn-primary add-button"
        >+
        </button>
        <b-tooltip target="add_promocode" placement="left">
          Abre el modal para dar de alta un nuevo código promocional
        </b-tooltip>
      </div>

      <div class="card-header">
        <h3>Listado de códigos promocionales</h3>
        <p>Total de códigos: <strong>{{pagination.totalRecords}}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :clickableRows="true"
          @row-clicked="onRowClicked"
          :noItemsView="noItemsView"
          hover
          class="main-table"
        >
          <!-- filter-->
          <template #over-table>
            <div class="filter-by-name">
              <div>
                <input
                  v-model.trim="openFilterInput"
                  type="text"
                  placeholder="Buscar por código"
                  @keypress.enter="search()"
                  v-c-tooltip="'Búsqueda de código promocional por nombre'"
                >
              </div>
              <div>
                <button
                  class="btn btn-primary"
                  @click="search()"
                  v-c-tooltip="'Ejecuta la acción de búsqueda de lo ingresado en el campo'"
                >
                  Buscar
                </button>
                <button
                  class="btn btn-light ml-2"
                  @click="resetFilters()"
                  v-c-tooltip="'Limpia el filtro de búsqueda por nombre del código promocional'"
                >
                  Limpiar
                </button>
              </div>
            </div>
            <br>
          </template>
          <!-- table data -->
          <template #expiration="{item}">
            <td>
              {{ formatDate(item.expiration) }}
            </td>
          </template>
          <template #active="{item}">
            <td>
              {{ item.active ? 'Activo' : 'Inactivo' }}
            </td>
          </template>
          <template #discount="{item}">
            <td>
              ${{ formatMoney(item.discount) }}
            </td>
          </template>
          <template #minPurchase="{item}">
            <td>
              ${{ formatMoney(item.minPurchase) }}
            </td>
          </template>
          <template #show_details="{item}">
            <td class="py-2">
              <CButton
                :id="`detail_${item.id}`"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="onRowClicked(item)"
              >
                {{ Boolean(item._toggled) ? 'Hide' : 'Detalle' }}
              </CButton>
              <b-tooltip :target="`detail_${item.id}`" placement="left">
                Despliega el modal de detalle/edición de la información del código promocional
              </b-tooltip>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="pagination.page"
          :pages="pagination.pages"
          align="center"
        />
        <CreatePromocode
          :isModalActive="createPromocode.isActive"
          @onSuccess="onSuccessCreatePromocode"
          @onSuccessMassive="onSuccessMassive"
          @onError="onErrorCreatePromocode"
          @onClose="closeCreatePromocode"
          @onErrorRepeated="onErrorRepeated"
        />
        <EditPromocode
          :isModalActive="editPromocode.isActive"
          :uuid="editPromocode.uuid"
          @onSuccess="onSuccessEditPromocode"
          @onClose="closeEditPromocode"
        />
      </div>
      <div class="card-footer">
        <p>Total de servicios: <strong>{{ pagination.totalRecords }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
import queryparser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import CreatePromocode from '../components/promocodes/CreatePromocode'
import EditPromocode from '../components/promocodes/EditPromocode'

const fields = [
  { key: 'code', label: 'Código' },
  { key: 'expiration', label: 'Fecha de expiración' },
  { key: 'active', label: 'Estatus' },
  { key: 'discount', label: 'Descuento' },
  { key: 'minPurchase', label: 'Mínimo de compra' },
  { key: 'show_details', label: 'Acción', _style: 'width:1%' }
]

export default {
  name: 'Promocodes',
  components: {
    CreatePromocode,
    EditPromocode
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      fields,
      items: [],
      openFilterInput: '',
      pagination: {
        page: 1,
        pages: 0,
        totalRecords: 0
      },
      success: {
        hasSuccess: false,
        message: ''
      },
      MasiveError: {
        hasError: false,
        message: ''
      },
      RepeatedError: {
        hasError: false,
        message: ''
      },
      error: {},
      createPromocode: {
        isActive: false
      },
      editPromocode: {
        isActive: false,
        uuid: ''
      }
    }
  },
  watch: {
    'pagination.page': function () {
      this.getPromocodes()
    },
    openFilterInput(value) {
      this.openFilterInput = this.openFilterInput.toUpperCase()
      if (!value.length || value.length >= 3) {
        this.getPromocodes()
      }
    }
  },
  mounted() {
    this.getPromocodes()
  },
  methods: {
    onRowClicked(item) {
      return item ? this.openEditPromocode(item) : this.openCreatePromocode()
    },
    filters() {
      const options = {
        page: this.pagination.page
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options.code = this.openFilterInput
      }
      return options
    },
    getPromocodes() {
      const queryString = queryparser.parse(this.filters())
      this.axios.get(`promocodes?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data.map((item, id) => ({ ...item, id }))
          this.pagination.pages = data.pagination.totalPages
          this.pagination.totalRecords = data.pagination.total
        }).catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    search(value) {
      if (!value.length || value.length >= 5) {
        this.getPromocodes()
      }
    },
    resetFilters() {
      this.openFilterInput = ''
      this.pagination.page = 1
      this.getPromocodes()
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formatDate(date) {
      return moment
        .utc(date, 'YYYYMMDD HH:mm:ss')
        .format('DD/MM/YYYY hh:mm A')
    },
    openCreatePromocode() {
      this.createPromocode.isActive = true
      this.resetErrors()
    },
    closeCreatePromocode() {
      this.createPromocode.isActive = false
    },
    openEditPromocode(item) {
      this.editPromocode.isActive = true
      this.editPromocode.uuid = item.registryUuid
    },
    closeEditPromocode() {
      this.editPromocode.isActive = false
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    onSuccessCreatePromocode() {
      this.getPromocodes()
      this.closeCreatePromocode()
      this.scrollTopSuccess()
      this.setSuccessMessage('Código promocional creado con éxito')
    },
    onSuccessEditPromocode() {
      this.getPromocodes()
      this.closeEditPromocode()
      this.scrollTopSuccess()
      this.setSuccessMessage('Código promocional actualizado con éxito')
    },
    onSuccessMassive(value) {
      this.getPromocodes()
      this.closeCreatePromocode()
      this.success.hasSuccess = true
      if (value.code.length === 0) {
        this.success.message = 'Todos tus códigos promocionales se agregaron exitosamente'
        return
      }
      this.success.message = 'Algunos de tus códigos promocionales se agregaron exitosamente'
    },
    onErrorCreatePromocode(value) {
      this.getPromocodes()
      this.closeCreatePromocode()
      if (value.code.length > 0) {
        this.MasiveError.hasError = true
        this.MasiveError.message = value.message
      }
    },
    onErrorRepeated(value) {
      this.getPromocodes()
      this.closeCreatePromocode()
      if (value.code.length > 0) {
        this.RepeatedError.hasError = true
        this.RepeatedError.message = value.message
      }
    },
    resetErrors() {
      this.success.hasSuccess = false
      this.success.message = ''
      this.success.hasExistingCodes = false
      this.success.existingCodesMessage = ''
      this.MasiveError.hasError = false
      this.MasiveError.message = ''
      this.RepeatedError.hasError = false
      this.RepeatedError.message = false
    },
    // Animation
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successPromocodes.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .promocodes{
        .add-button {
            position: fixed;
            bottom: 4%;
            right: 44px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            z-index: 1031;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .filter-by-name{
            @media(min-width: 992px){
            display: flex;
            }
            >div{
                &:first-child{
                    margin-bottom: 8px;
                    @media(min-width: 992px){
                    margin-bottom: 0;
                    }
                    input{
                    width: 100%;
                    height: 35px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 0 16px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    }
                }
                &:last-child{
                    @media(min-width: 992px){
                    display: flex;
                    margin-left: 8px;
                    }
                    button{
                    width: calc(50% - 4px);
                    @media(min-width: 992px){
                        width: auto;
                    }
                    &:last-child{
                        margin-left: 8px;
                    }
                    }
                }
            }
        }
        .card{
            .card-header,
            .card-footer{
                display: flex;
                p{
                margin: auto 0 auto auto;
                }
            }
        }
    }
</style>
