<template>
  <div class="chargeback">
    <div tabindex="0" ref="successchargeback">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div class="card-header">
        <h3>Listado de servicios con contracargos</h3>
        <p>Total registros: <strong>{{ allRecords }}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="false"
          :clickableRows="false"
          :noItemsView="noItemsView"
          hover
          class="main-table">
          <template #createdAt="{item}">
            <td> {{ formatDate(item.createdAt) }} </td>
          </template>
          <template #address="{item}">
            <td> {{ formatAddress(item.address) }} </td>
          </template>
          <template #number="{item}">
            <td> {{ item.number || ""}} </td>
          </template>
          <template #email="{item}">
            <td> {{ item.email || ""}} </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

const fields = [
  { key: 'folio', label: 'Folio', sorter: true },
  { key: 'phoneNumber', label: 'Teléfono', sorter: true },
  { key: 'email', label: 'Correo electrónico', sorter: true },
  { key: 'number', label: 'Tarjeta', sorter: false },
  { key: 'address', label: 'Dirección', sorter: false },
  { key: 'createdAt', label: 'Fecha de registro', sorter: true }
]

export default {
  name: 'Chargeback',
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      items: [],
      fields,
      page: 1,
      pages: 0,
      allRecords: 0,
      success: {
        hasSuccess: false,
        message: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const params = {}
        const {
          data: {
            data,
            pagination: {
              total,
              totalPages
            }
          }
        } = await this.axios.get('/services/chargeback', { params })
        this.items = data
        this.pages = totalPages
        this.allRecords = total
      } catch (error) {
        this.items = []
        this.pages = 0
        this.allRecords = 0
      }
    },
    // formats
    formatDate(date) {
      if (!date) {
        return 'No disponible'
      }
      return moment
        .utc(date, 'YYYYMMDD HH:mm:ss')
        .format('DD/MM/YYYY hh:mm A')
    },
    formatAddress(data) {
      let string = ''
      if (data) {
        const numInt = data.numInt ? data.numInt : ''
        string = `${data.street}, ${data.numExt}, ${numInt}, ${data.zipcode}, 
                ${data.suburb}, ${data.municipality}, ${data.state}`
      }
      return string
    }
  }
}
</script>

<style lang="scss" scoped>
    .chargeback{
        .add-button {
            position: fixed;
            bottom: 4%;
            right: 44px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            z-index: 1031;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .card{
            .card-header,
            .card-footer{
                display: flex;
                p{
                    margin: auto 0 auto auto;
                }
            }
        }
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 100%;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
    }
</style>
