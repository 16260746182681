import moment from 'moment-timezone'
import 'moment/locale/es'

export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export function weeklyLabels(dates) {
  const min = moment(dates[0], 'YYYY-MM-DD').week()
  const max = moment(dates[1], 'YYYY-MM-DD').week()
  let labels = []

  if (min < max) {
    labels = (new Array(max - min).fill(min))
      .map((v, i) => `${v + i}`)
  } else {
    labels = (new Array(52 - min).fill(min + 1))
      .map((v, i) => `${v + i}`)
      .concat((new Array(max).fill(0)).map((_, i) => `${i + 1}`))
  }

  return labels.map((v) => `Semana ${v}`)
}

export function weeklyDayNumbersLabels(d, weekNumber) {
  const date = moment(d).isoWeek(weekNumber).startOf('isoWeek')
  const endDay = moment(date).endOf('isoWeek').format('D')
  const labels = []

  let ref = moment(date)
  while (ref.format('D') !== endDay) {
    labels.push(ref.format('D'))
    ref = moment(ref).add({ day: 1 })
  }
  labels.push(endDay)

  return labels
}

// TODO: Clean
export function monthlyLabels(dates) {
  const min = moment(dates[0], 'YYYY-MM-DD').month()
  const max = moment(dates[1], 'YYYY-MM-DD').month()

  const monthsLabels = [''].concat(months)
  let labels = []

  if (min < max) {
    labels = (new Array(max - min + 1).fill(min + 1))
      .map((v, i) => v + i)
  } else {
    labels = (new Array(12 - min).fill(min + 1))
      .map((v, i) => v + i)
      .concat((new Array(max).fill(0)).map((_, i) => i + 1))
  }

  return labels.map((i) => monthsLabels[i])
}
