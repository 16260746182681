import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import formModel from './models/formModel'
import company from './modules/company'
import provider from './modules/provider'
import auth from './modules/auth'
import axios from 'axios';
import { STATUS_PREORDERS } from '../common/enums'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    loader: false, // Bandera para activar el "loader"
    landingCounter: 1, // Contador para el formulario
    contadorDatos: 0, // Contador para la primer parte del formulario
    coverageFlag: false, //Flag para determinar la zona de covertura
    pagoTarjetaMaximoFlag: false, // Flag para saber si ya no tiene permitidos intentos para pago con tarjeta.
    thanksModal: false, // Activador para el modal de agradecimiento de feedback
    servicioSolicitado: false, // Bandera para el modal servicioSolicitado
    conoceMasModal: false, // Activador para el modal de conoce más
    otherTipModal: false,
    completeLitersModal: false, // Activador para el modal de litros completos
    message: '', // Mensaje del snackbar
    showMessage: false, // v-model del snackbar
    seguirServicioModal: false, // Flag del modal seguir servicio
    seguirServicioNumberUuid: '', // Numero de telefono que mandamos a sigue tu servicio para cargar los datos.
    coordenadasCenterMap: { //Coordenadas por default para centrar el map
      lat: 19.4269903,
      lng: -99.1676463,
    },
    coordenadasSigueServicio: { // Coordenadas para Sigue tu servicio
      coordenadasServicio: {
        lat: 19.42699,
        lng: -99.167646,
      },
      coordenadasProveedor: {
        lat: 19.42660,
        lng: -99.16761,
      },
    },    
    //Modelo del formulario
    ...formModel,
    lengthOrder: 0,
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    incrementLengthOrder(state, payload) {
      state.lengthOrder = 0;
      state.lengthOrder = Number(payload.created) + Number(payload.validated);
    }
  },
  actions: {
    async getPreordersLength({ commit }) {
      const { VUE_APP_SERVERLESS_BASE_URL, VUE_APP_X_API_KEY: apiKey } = process.env;
      const url = VUE_APP_SERVERLESS_BASE_URL;
      const { data } = await axios.get(`${url}services/preorders/summary`, {
        headers: {
          'x-api-key': apiKey
        }
      });
      commit('incrementLengthOrder', data);
    }
  },
  modules: {
    company,
    provider,
    auth
  }
})
