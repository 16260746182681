<template>
  <div class="card">
    <div class="card-header">
      <h3>Listado de Carrusel</h3>
    </div >
    <button
      type="button"
      class="btn-primary add-service-button"
      @click="resetInputs"
      v-b-modal.modal-xl
      variant="primary"
    >+</button>
    <div class="card-body">
      <CDataTable
        :items="filterCarrusel"
        :fields="fields"
        :sorter="false"
        :clickableRows="true"
        :noItemsView="noItemsView"
        hover
        class="main-table"
      >
        <!-- table filters -->
        <template #over-table>
          <div class="filters-wrapper">
            <div class="open-filter">
              <h3>Buscar de Carrusel</h3>
              <div class="open-filter-wrapper">
                <input
                  v-model.trim="openFilterInput"
                  type="text"
                  :placeholder="'Título'"
                  maxlength="30"
                  v-c-tooltip="'Búsqueda de Carrusel por Cualquier Propiedad'"
                  @keyup="filterSearch"
                  class="form-control"
                >
              </div>
            </div>
          </div>
        </template>
        <template #title="{item}">
          <td>
            {{ item.title }}
          </td>
        </template>
        <template #active="{item}">
          <td>
            {{ item.active ? 'Activo' : 'Inactivo' }}
          </td>
        </template>
        <template #actions="{item}">
          <td>
            <button v-b-modal.modal-xl @click="onRowClick(item)" class="btn-width btn btn-success mr-1">Editar</button>
            <button class="delete-btn-width btn btn-danger ml-1" @click="setModalItem(item)" v-b-modal.modal-sm >Eliminar</button>
          </td>
        </template>
      </CDataTable>
      <!-- <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        /> -->
    </div>
    <div class="card-footer text-right">
      <p>Total de Carruseles: <strong>{{ totalCarrusel }}</strong></p>
    </div>
    <div> <!-- Modal delete carrusel -->
      <b-modal
        id="modal-sm"
        title="Confirmar Eliminar"
        hide-footer
      >
        <div class="text center column w-100 " >
          <div>
            <p>¿Desea Eliminar el Carrusel {{ modalItem.title }} ?</p>
          </div><hr>
          <div class="d-flex justify-content-end">
            <b-button
              class="btn btn-secondary button-modal-sm ml-3 mt-2"
              block
              @click="$bvModal.hide('modal-sm')"
            >Cancelar</b-button>
            <b-button
              class="btn btn-danger button-modal-sm ml-3"
              block
              variant="danger"
              @click="deleteCarousel(modalItem)"
            >Eliminar</b-button>
          </div>
        </div>
      </b-modal>
      <!-- Modal create carrusel -->
      <b-modal
        id="modal-xl"
        size="xl"
        :title="isEditing ? 'Editar Carrusel' : 'Crear Carrusel' "
        hide-footer
      >
        <div class="container w-100">
          <form
            @submit="checkForm"
            class="form"
          >
            <div class="d-flex justify-content-center" >
              <b-form-checkbox v-model="active" size="lg" switch><b>Activo</b></b-form-checkbox>
            </div>
            <div class="row">
              <div class="column w-50 ml-5 mr-5">
                <div class="w-100 ">
                  <div>
                    <label><b>Título</b></label>
                    <input
                      class="form-control"
                      v-model="title"
                      type="text">
                  </div>
                  <div>
                    <label><b>Descripción</b></label>
                    <textarea
                      v-model="body"
                      class="form-control"
                      cols="83"
                      rows="2"
                    ></textarea>
                  </div>
                  <div>
                    <label><b>Texto del Botón</b></label>
                    <input
                      v-model="buttonText"
                      class="form-control"
                      type="text">
                  </div>
                  <div>
                    <label><b>Acción de Botón</b></label>
                    <textarea
                      v-model="buttonAction"
                      class="form-control"
                      cols="83"
                      rows="7"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="column w-25 ml-5 ">
                <div class="w-75">
                  <div class="mb-4">
                    <label><b>Color de texto del título </b></label>
                    <input class="form-control" v-model="titleTextColor" type="color">
                  </div>
                  <div class="mb-4">
                    <label><b>Color de texto del cuerpo</b></label>
                    <input v-model="bodyTextColor" class="form-control" type="color">
                  </div>
                  <div class="mb-4">
                    <label><b>Color del texto del botón</b></label>
                    <input v-model="buttonTextColor" class="form-control" type="color">
                  </div>
                  <div class="mb-4">
                    <label><b>Color de fondo del botón</b></label>
                    <input v-model="buttonBackgroundColor" class="form-control" type="color">
                  </div>
                  <div class="mb-4">
                    <label><b>Color de fondo de la tarjeta</b></label>
                    <input
                      v-model="cardBackgroundColor"
                      class="form-control"
                      type="color">
                  </div>
                </div>
              </div>
              <div class="row w-100">
                <div class="w-100 row ml-5">
                  <div  class="input-container photo w-80 ml-3 mr-5">
                    <label for=""><b>Imagen de Fondo</b></label>
                    <div class="text-center mb-3" v-if="!urlPhoto">
                      <img
                        v-if="cardBackgroundImage"
                        class="resolution"
                        :src="cardBackgroundImage" />
                    </div>
                    <InputImage
                      ref="imageInput"
                      :width="200"
                      :height="200"
                      :disabled="isCompanyAdmin"
                      :isRequired="isRequired(urlPhoto)"
                      @format="invalidFormat"
                      @input="onProviderImageInput"
                      @change="() => imageUpdated(true)"
                      v-c-tooltip="'Corresponde a la foto del colaborador'">
                    </InputImage>
                    <small class="form-text text-muted">Dimensiones 200x200 px</small>
                  </div>
                  <div class="w-80 input-container ml-3 photo mr-5">
                    <label for=""><b>Icono</b></label>
                    <div class="text-center mb-3" v-if="!urlPhoto">
                      <img
                        class="resolution"
                        :src="cardIconImage" />
                    </div>
                    <InputImage
                      ref="imageInput"
                      :width="200"
                      :height="200"
                      :disabled="isCompanyAdmin"
                      :isRequired="isRequired(urlPhoto)"
                      @format="invalidFormat"
                      @input="onProviderImageInput"
                      @change="() => imageUpdated(true)"
                      v-c-tooltip="'Corresponde a la foto del colaborador'">
                    </InputImage>
                    <small class="form-text text-muted">Dimensiones 200x200 px</small>
                  </div>
                </div>
              </div>
            </div> <hr>
            <div class="ml-75 text center row w-100 " >
              <b-button
                class="mt-3 btn btn-secondary button-modal"
                block
                @click="$bvModal.hide('modal-xl')"
              >Cancelar</b-button>
              <button
                class=" text-white mt-3 ml-4 btn btn-primary button-modal"
                block
                :disabled="btnDisabled"
              >{{ isEditing ? "Editar" : "Crear" }}</button>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import InputImage from '../components/InputImage'
import { fileToImage } from '../utils'

const fields = [
  {
    key: 'title',
    label: 'Titulo',
    _style: 'width: 29%;',
    sorter: false,
    filter: false
  },
  {
    key: 'body',
    label: 'Contenido',
    _style: 'width: 35%;',
    sorter: false,
    filter: false
  },
  {
    key: 'buttonText',
    label: 'Texto del Botón',
    sorter: false,
    filter: false
  },
  {
    key: 'active',
    label: 'Estatus',
    sorter: false,
    filter: false
  },
  {
    key: 'actions',
    label: 'Acciones',
    sorter: false,
    filter: false
  }

]

export default {

  name: 'Carrusel',
  components: {
    InputImage
  },
  data() {
    return {
      urlPhoto: null,
      isEditing: false,
      editId: null,
      modalData: null,
      carrusel: null,
      totalCarrusel: 0,
      items: null,
      openFilterInput: null,
      modalItem: {},
      isCompanyAdmin: false,
      fields,
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      btnDisabled: false,
      title: '',
      body: '',
      titleTextColor: '',
      bodyTextColor: '',
      buttonText: '',
      buttonAction: '',
      buttonTextColor: '',
      buttonBackgroundColor: '',
      cardBackgroundColor: '',
      cardBackgroundImage: '',
      cardIconImage: '',
      backgroundImageUrl: '',
      active: false,
      imgFondoBase64: '',
      imgFondo: '',
      imgIconoBase64: '',
      imgIcono: '',
      filterCarrusel: []
    }
  },
  mounted() {
    this.getCarousel()
  },
  methods: {
    async getCarousel() {
      try {
        const response = await this.$serverless.get('/services/carousel')
        this.carrusel = response.data
        this.filterCarrusel = response.data
        this.items = this.carrusel
        this.totalCarrusel = this.filterCarrusel.length
      } catch (e) {
        console.log(e)
      }
    },
    filterSearch() {
      this.totalCarrusel = this.filterCarrusel.length
      this.filterCarrusel = this.carrusel.filter((carousel) => carousel.title.toLowerCase().includes(this.openFilterInput.toLowerCase())
          || carousel.body.toLowerCase().includes(this.openFilterInput.toLowerCase())
          || carousel.buttonText.toLowerCase().includes(this.openFilterInput.toLowerCase()))
    },
    setModalItem(item) {
      this.modalItem = item
    },
    async deleteCarousel(el) {
      try {
        await this.$serverless.delete(`/services/carousel/${el.registryUuid}`)
        this.getCarousel()
        this.$bvModal.hide('modal-sm')
      } catch (e) {
        console.log(e)
      }
    },
    onRowClick(el) {
      this.btnDisabled = false
      this.isEditing = true
      this.editId = el.registryUuid
      this.title = el.title
      this.body = el.body
      this.titleTextColor = el.titleTextColor
      this.bodyTextColor = el.bodyTextColor
      this.buttonText = el.buttonText
      this.buttonAction = el.buttonAction
      this.buttonTextColor = el.buttonTextColor
      this.buttonBackgroundColor = el.buttonBackgroundColor
      this.cardBackgroundImage = el.cardBackgroundImage
      this.cardIconImage = el.cardIconImage
      this.active = el.active
    },
    resetInputs() {
      this.isEditing = false
      this.title = ''
      this.body = ''
      this.titleTextColor = ''
      this.bodyTextColor = ''
      this.buttonText = ''
      this.buttonAction = ''
      this.buttonTextColor = ''
      this.buttonBackgroundColor = ''
      this.cardBackgroundColor = ''
      this.cardBackgroundImage = ''
      this.cardIconImage = ''
      this.active = false
      this.btnDisabled = false
    },
    async checkForm(e) {
      e.preventDefault()
      try {
        if (this.imgFondo) {
          this.imgFondoBase64 = await fileToImage(this.photoFondo)
        }
        if (this.imgIcono) {
          this.imgIconoBase64 = await fileToImage(this.imgIcono)
        }
        this.btnDisabled = true
        const data = {
          title: this.title,
          titleTextColor: this.titleTextColor,
          body: this.body,
          bodyTextColor: this.bodyTextColor,
          buttonText: this.buttonText,
          buttonAction: this.buttonAction,
          buttonTextColor: this.buttonTextColor,
          buttonBackgroundColor: this.buttonBackgroundColor,
          cardBackgroundColor: this.cardBackgroundColor,
          cardBackgroundImage: this.imgFondoBase64 ? this.imgFondoBase64.src : this.cardBackgroundImage,
          cardIconImage: this.imgIconoBase64 ? this.imgIconoBase64.src : this.cardIconImage,
          active: this.active
        }
        if (this.isEditing) {
          await this.$serverless.put(`/services/carousel/${this.editId}`, data)
        } else {
          await this.$serverless.post('/services/carousel', data)
        }
        this.getCarousel()
        this.$bvModal.hide('modal-xl')
      } catch (e) {
        console.log(e)
      }
    },
    isRequired(urlPhoto) {
      return urlPhoto
    },
    invalidFormat() {
      const err = ERRORS_MESSAGES.INVALID_IMAGE
      this.alertError = true
      this.error = err
      this.$emit('onError', err)
      this.scrollTopError()
    },
    onProviderImageInput(img) {
      if (img) {
        this.alertError = false
        this.error = {}
      }
      if (!this.photoFondo) {
        this.photoFondo = img
        this.imgFondo = img
      }

      if (this.photoFondo !== img) {
        this.photoIcono = img
        this.imgIcono = img
      }
    },
    imageUpdated(isProvider) {
      this.urlPhoto = true
    }
  }
}
</script>
<style>
.w-80{
  width: 80%;
}
.resolution{
  width: 300px;
  height: auto;
}
.ml-75{
  margin-left: 75%;
}
.button-modal{
  width: 10%; height: 5%;
}
.button-modal-sm{
  width: 20%; height: 5%;
}
.btn-width{
  width: 57px;
  font-size: 12px;
}
.delete-btn-width{
  width: 69px;
  font-size: 12px;
}
.add-service-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
  }
  textarea {
    resize: none;
}
</style>
