<template>
  <CModal :closeOnBackdrop="false" :show.sync="isModalActive" size="xl">
    <template v-slot:header>
      <h5 class="modal-title">{{ isEditing ? 'Editar' : 'Crear'}} Precio de Producto</h5>
      <button
        type="button"
        :disabled="isRequesting"
        aria-label="Close"
        class="close"
        @click="onCancel"
      >
        ×
      </button>
    </template>
    <CAlert :color="'danger'" :closeButton="true" :show.sync="hasError">
      {{ error }}
    </CAlert>
    <form id="createProductPrice" ref="form" @submit="onSubmit">
      <div class="row">
        <div class="ml-5 div-50-perc">
          <div class="service-type-container">
            <div class="form-group">
              <label>Tipo de Servicio</label>
              <v-select
                label="description"
                :options="serviceTypes"
                @input="serviceTypeOnChange"
                v-model="serviceType"
                :disabled="isEditing"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!serviceType"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="form-group">
              <label>Empresa</label>
              <v-select
                label="name"
                :options="companies"
                @input="companyOnChange"
                v-model="company"
                :disabled="isEditing"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!company"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="form-group">
              <label>Categoría</label>
              <v-select
                label="name"
                :options="categories"
                @input="categoryOnChange"
                v-model="category"
                :disabled="isEditing || loadingShowcase"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!category"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
            <div class="form-group" v-if="showcase && showcase.length > 0 || productShowCase">
              <label>Producto</label>
              <v-select
                :label="'name' ? 'name' : 'description'"
                :options="showcase"
                @input="selectShowcase"
                v-model="productShowCase"
                :disabled="isEditing"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!productShowCase"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="w-45 column">
          <div class="mb-45 form-group">
            <label>Precio del Producto</label>
            <input
              class="form-control form-control form-control-sm"
              type="number"
              v-model="price"
              required
              min="1"
              step=".01"
              @change="validateInputs"
            />
          </div>
          <div class="form-group">
            <label class="">Minimo de Compra</label>
            <input
              class="form-control form-control-sm"
              type="number"
              v-model="minPrice"
              required
              min="1"
              step=".01"
              @change="validateInputs"
            />
          </div>
        </div>
      </div>
    </form>
    <div v-if="errorMessage" class="text-center bg-danger w-50 m-auto rounded">
      <label class="text-white font-12 mt-2">{{errorMessage.toUpperCase()}}</label>
    </div>
    <template v-slot:footer>
      <CSpinner v-if="isRequesting" color="warming" size="sm" />
      <div v-else>
        <button
          type="button"
          class="btn btn-secondary mr-1"
          :disabled="isRequesting"
          @click="onCancel"
        >
          Cerrar
        </button>
        <button
          type="submit"
          :disabled="disableSubmit()"
          form="createProductPrice"
          class="btn btn-primary ml-1"
          v-c-tooltip="'Crea el precio de producto con los datos ingresados'"
        >
          {{ isEditing ? 'Editar' : 'Crear'}}
        </button>
      </div>
    </template>
  </CModal>
</template>
<script>
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'CreateProductPrice',
  components: {
    vSelect
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean
    },
    editProductId: {
      type: String
    }
  },
  data: () => ({
    loadingShowcase: false,
    isRequesting: false,
    isErrorResponse: false,
    disabledBtn: true,
    serviceType: null,
    category: null,
    errorMessage: null,
    company: null,
    price: null,
    minPrice: null,
    productShowCase: null,
    serviceTypes: [],
    categories: [],
    companies: [],
    servicePricing: [],
    showcase: [],
    date: moment(),
    dateRange: {
      startDate: moment(),
      endDate: null
    },
    error: null,
    hasError: false,
    latestCompany: {}
  }),
  watch: {
    isModalActive() {
      if (this.isModalActive) {
        if (!this.isEditing) {
          return this.getServicesTypes()
        }
        this.editingForm()
      }
    }
  },
  methods: {
    async editingForm() {
      try {
        this.errorMessage = null
        if (!this.isEditing) {
          this.errorMessage = 'A ocurrido un error recargue su pagina!'
          return this.errorMessage
        }
        const { data } = await this.$serverless.get(`/products/prices/${this.editProductId}`)
        this.productShowCase = data.product_showcase

        this.company = data.company
        this.category = data.product_showcase.service_type_category.name
        this.serviceType = data.company.serviceType.description
        this.minPrice = data.minPurchase / 100
        this.price = data.price / 100
      } catch (error) {
        this.errorMessage = error.message
        return this.errorMessage
      }
    },
    disableSubmit() {
      return 
        (Number(this.minPrice) < 1 && Number(this.price) < 1 || Number(this.minPrice) < Number(this.price)) ||
        !this.productShowCase
    },
    validateInputs() {
      if (this.price && this.minPrice && this.productShowCase && this.company) {
        if (Number(this.minPrice) < Number(this.price)) {
          this.disabledBtn = true
          this.errorMessage = 'El Minimo no puede ser menor que el Precio del Producto'
        } else {
          this.disabledBtn = false
          this.errorMessage = null
        }
      } else {
        this.disabledBtn = true
      }
    },
    async getCompaniesByServiceTypeId() {
      try {
        const query = `typeServicesId=${this.serviceType.id}`
        if (this.company) this.latestCompany = this.company
        const { data } = await this.$serverless.get(
          `/ally/company/available?${query}`
        )
        data.companies.forEach((company) => {
          if (company.activeServices) {
            company.name = `${company.name} (${company.activeServices})`
          }
        })
        this.companies = data.companies

        if (
          !this.companies.find(
            (obj) => this.company && obj.registryUuid === this.company.registryUuid
          )
        ) {
          this.company = this.companies.length > 0
            ? this.companies.find(
              (obj) => obj.registryUuid === this.latestCompany.registryUuid
            )
              ? this.latestCompany
              : this.companies[0]
            : null
          if (
            this.company
          && this.latestCompany.registryUuid !== this.company.registryUuid
          ) {
            await this.updateServicePricingData()
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    clearAllSelects() {
      this.clearServicesData()
      this.clearProductData()
    },
    clearProductData() {
      this.company = null
      this.category = null
      this.serviceType = null
    },
    clearServicesData() {
      this.showcase = []
      this.servicePricing = []
      this.productShowCase = null
    },
    selectShowcase(product) {
      try {
        if (product) {
          this.productShowCase = product
          if (!this.productShowCase) {
            this.disabledBtn = true
          } else {
            this.disabledBtn = false
          }
        }
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async getCategoriesByServiceType(serviceTypeUuid) {
      try {
        this.loadingShowcase = true
        const { data } = await this.$serverless.get(
          `/products/categories/${serviceTypeUuid}?priceListNotRequired=true`
        )
        this.servicePricing = data
        this.loadingShowcase = false
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async updateServicePricingData() {
      try {
        if (!this.serviceType || !this.company) {
          return null
        }
        await this.getCategoriesByServiceType(
          this.serviceType.registryUuid
        )
        this.setPricingData()
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    categoryOnChange() {
      try {
        this.clearServicesData()
        this.updateServicePricingData()
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    companyOnChange() {
      try {
        this.clearServicesData()
        this.updateServicePricingData()
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async serviceTypeOnChange(serviceType) {
      try {
        this.clearAllSelects()
        this.serviceType = serviceType
        await this.setServiceSelects(serviceType)
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async getServiceTypeCompanies() {
      try {
        this.isRequesting = true
        await this.getCompaniesByServiceTypeId()
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    setPricingData() {
      try {
        const category = this.servicePricing.find(
          (obj) => +obj.id === +this.category.id
        )
        if (!category) {
          return false
        }
        this.showcase = category.productShowCase
        this.productShowCase = this.showcase[0]
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    async setServiceSelects(serviceType) {
      try {
        this.categories = serviceType.categories
        this.serviceType = serviceType
        this.category = serviceType.categories[0]

        await this.getServiceTypeCompanies(serviceType.registryUuid)
        await this.updateServicePricingData()
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    async getServicesTypes() {
      try {
        this.isRequesting = true
        const params = {
          includeCategories: true
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.serviceTypes = data
        const serviceType = this.serviceTypes[0]
        this.setServiceSelects(serviceType)
      } catch (err) {
        this.isErrorResponse = true
      } finally {
        this.isRequesting = false
      }
    },
    onCancel() {
      this.clearAllSelects()
      this.minPrice = null
      this.price = null
      this.$emit('onClose')
    },
    async onSubmit(e) {
      e.preventDefault()
      try {
        this.isRequesting = true
        const data = {
          companyUuid: this.company.registryUuid,
          productShowcaseUuid: this.productShowCase.registryUuid,
          price: this.price * 100,
          minPurchase: this.minPrice * 100,
          isActive: true
        }
        if (this.isEditing) {
          await this.$serverless.put(`/products/prices/${this.editProductId}`, data)
        } else {
          await this.$serverless.post('/products/prices', data)
        }
        this.clearAllSelects()
        this.getServicesTypes()
        this.minPrice = null
        this.price = null
        this.isRequesting = false
        return this.$emit('onSuccess')
      } catch (error) {
        return console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.w-45 {
  width: 44%;
}
.mb-45 {
  margin-bottom: 3.9%;
}
.service-type-container {
  width: 95%;
}
.div-50-perc {
  width: 50%;
  padding: 0%;
  margin: 0%;
  height: 100%;
  float: left;
}
button.close {
  color: black !important;
}
.form-group label {
  font-weight: bold;
}
.date-range {
  width: 100%;
}
.error {
  color: red;
}
input:focus:invalid {
  border-color: red;
}
</style>
