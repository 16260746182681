<template>
  <div class="login" v-bind:style="{height: `${viewPortHeight}px`}">
    <div class="content">
      <div v-show="hasError" class="show-alert">
        <br>
        <CAlert close-button :color="error.type || 'danger'">{{error.message}}</CAlert>
      </div>
      <div class="form-card">
        <div class="content-icon">
          <span class="content-icon__text">¡Bienvenido a </span>
          <img
            src="@/assets/images/DarkWaddiLogo.svg"
            alt=""
            class="icon-waddi"
          >
        </div>
        <form v-on:submit.prevent="onSubmit">
          <div class="group">
            <label for="">Cuenta*</label>
            <input
              required
              type="text"
              maxlength="20"
              placeholder="Cuenta"
              v-model="auth.accountCode"
            >
          </div>
          <div class="group">
            <label for="">Correo*</label>
            <input
              required
              type="email"
              maxlength="255"
              placeholder="Correo electrónico"
              v-model="auth.email"
            >
          </div>
          <div class="group">
            <label for="">Contraseña*</label>
            <input
              required
              type="password"
              placeholder="Contraseña"
              v-model="auth.password"
              minlength="8"
              @keypress="onPasswordInput"
            >
          </div>
          <div class="custom-checkbox hidden">
            <input type="checkbox" v-model="auth.checked">
            <label>Acepto la <a href="https://www.aliados.okboy.app/pol%C3%ADtica-de-privacidad" target="_blank" rel="noopener noreferrer">política de privacidad</a>. </label>
          </div>
          <button v-if="!isRequesting">Comenzar</button>
          <center class="spin" v-else>
            <CSpinner color="black" size="sm"/>
          </center>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getErrorMessage, ERRORS_MESSAGES } from '../common/errors'

export default {
  name: 'Login',
  data: () => ({
    isRequesting: false,
    auth: {},
    error: {},
    viewPortHeight: null
  }),
  computed: {
    ...mapState('auth', ['isAuthenticated']),
    hasError() {
      return Object.keys(this.error).length
    }
  },
  mounted() {
    const isSessionExpired = localStorage.getItem('sessionExpired')
    if (isSessionExpired) {
      this.error = ERRORS_MESSAGES.EXPIRED_SESSION
      localStorage.removeItem('sessionExpired')
    }
    this.viewPortHeight = window.innerHeight
  },
  methods: {
    onPasswordInput(ev) {
      const { key } = ev
      if (!/^[A-Za-z0-9]$/.test(key)) {
        ev.preventDefault()
      }
    },
    async onSubmit() {
      this.isRequesting = true
      this.error = {}
      try {
        await this.$store.dispatch('auth/login', this.auth)
        if (this.isAuthenticated) {
          this.$router.push(JSON.parse(localStorage.getItem('defaultRoute')))
        }
      } catch (err) {
        this.error = getErrorMessage(err)
      } finally {
        this.isRequesting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/style.scss';

/* @font-face {
    font-family: 'Mulish-Regular';
    src: url("../assets/fonts/Mulish-Regular.ttf") format('truetype');
} */
  .login{
    position: relative;
    min-height: 100vh;
    background-color: $white-second;
    >img{
      position: absolute;
      max-width: 100%;
    }
    .background-shape-top{
      top: 0;
      left: 0;
    }
    .background-shape-bottom{
      bottom: 0;
      right: 0;
    }
    .content{
      display: grid;
      place-items: center;
      padding: 0 24px;
      position: relative;
      z-index: 1;
      min-height: 100vh;
      @media screen and (max-width: 600px) {
        padding: 0 1rem;
      }
      .form-card {
        width: 365px;
        height: 567px;
        background-color: $white-third;
        box-shadow: 0px 4px 8px rgba(188, 180, 243, 0.5);
        border-radius: 30px;
        border: 1px solid #000000;
        margin-top: -8.2rem;
        padding: 0 1.1rem;
        padding-top: 3.86rem;
        @media screen and (max-width: 600px) {
          width: 100%;
          padding: 0 1rem;
          padding-top: 3.86rem;
        }
        @media screen and (max-width: 1900px) {
          margin-top: -2.7rem;
        }
        .content-icon {
          display: flex;
          align-items: center;
          gap: .6rem;
          width: 100%;
          margin-bottom: 2rem;
          .content-icon__text {
            color: $wadd-primary;
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 2.1875rem;
          }
          .icon-waddi {
            width: 36%;
            object-fit: cover;
          }
        }
      }
      form{
        margin: 0 auto;
        .group{
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1rem;
          label{
            color: $wadd-purple;
            margin-bottom: 0.5rem;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.1875rem;
          }
          input{
            width: 100%;
            height: 40px;
            max-width: 325px;
            background-color: #F9F9F9;
            border: 1px solid #AFADBA;
            border-radius: 7px;
            padding-left: 1.2rem;
            &::placeholder{
              color: $gray4;
              font-size: 1rem;
              font-weight: 500;
            }
            &:focus-visible, &:focus {
              outline: transparent;
            }
            @media screen and (max-width: 600px) {
              max-width: initial;
            }
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        .group:nth-of-type(3) {
          margin-bottom: 0rem;
        }
        .spin {
          margin-top: calc(56px - 16px);
        }
        button{
          width: 100%;
          max-width: 325px;
          height: 40px;
          background-color: $wadd-purple2;
          border-radius: 15px;
          border: 0;
          margin-top: 2.6rem;
          font-size: 1rem;
          line-height: 1.25rem;
          text-align: center;
          font-weight: 700;
          color: $white;
          @media screen and (max-width: 600px) {
              max-width: initial;
          }
        }
        button:disabled{
          background-color: $gray3
        }
        input[type="checkbox"]{
          margin-right: 1rem;
        }
      }
    }
  }
  .show-alert {
    position: fixed;
    top: 21px;
  }

  .hidden {
    display: none;
  }
</style>
