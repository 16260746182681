import { render, staticRenderFns } from "./AssignReassignService.vue?vue&type=template&id=43caa512&scoped=true&"
import script from "./AssignReassignService.vue?vue&type=script&lang=js&"
export * from "./AssignReassignService.vue?vue&type=script&lang=js&"
import style0 from "./AssignReassignService.vue?vue&type=style&index=0&id=43caa512&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43caa512",
  null
  
)

export default component.exports