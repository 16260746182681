import { getErrorCodeAPI } from '../utils'

export const ERRORS_MESSAGES = {
  UNKNOWN: (error) => ({
    message: `Se produjo un error desconocido. "${error.message ? `${error.message}` : ''}" Inténtelo de nuevo más tarde`,
    type: 'danger'
  }),
  EXPIRED_SESSION: {
    message: 'Su sesión ha expirado.',
    type: 'danger'
  },
  EA0000: {
    message: 'Se produjo un error inesperado. Inténtelo de nuevo más tarde.',
    type: 'danger'
  },
  EA0006: {
    message: 'La contraseña no tiene el formato requerido.',
    type: 'danger'
  },
  EA0007: {
    message: 'Usuario no válido.',
    type: 'danger'
  },
  EA0195: {
    message: 'Usuario no válido.',
    type: 'danger'
  },
  EA0014: {
    message: 'Ya existe un servicio activo para este usuario.',
    type: 'danger'
  },
  EA0015: {
    message: 'La orden de servicio es inválida o fue eliminada.',
    type: 'danger'
  },
  EA0016: {
    message: 'El colaborador ya tiene un servicio aceptado.',
    type: 'danger'
  },
  EA0026: {
    message: 'Código promocional inactivo.',
    type: 'danger'
  },
  EA0027: {
    message: 'Formato de código promocional no válido.',
    type: 'danger'
  },
  EA0028: {
    message: 'Cliente no encontrado.',
    type: 'danger'
  },
  EA0037: {
    message: 'Pago rechazado.',
    type: 'danger'
  },
  EA0038: {
    message: 'Falta escribir el nombre o no es válido.',
    type: 'danger'
  },
  EA0039: {
    message: 'Falta escribir el apellido o no es válido.',
    type: 'danger'
  },
  EA0040: {
    message: 'Falta escribir el nombre corto o no es válido.',
    type: 'danger'
  },
  EA0041: {
    message: 'Falta escribir el número de teléfono o no es válido.',
    type: 'danger'
  },
  EA0042: {
    message: 'Compañía no válida.',
    type: 'danger'
  },
  EA0042C: {
    message: 'Debes seleccionar una compañía.',
    type: 'danger'
  },
  EA0043: {
    message: 'Falta escribir el password o no es válido.',
    type: 'danger'
  },
  EA0044: {
    message: 'Imagen no válida.',
    type: 'warning'
  },
  EA0045: {
    message: 'Falta escribir las placas o no es válida.',
    type: 'danger'
  },
  EA0046: {
    message: 'Falta escribir el nombre del asistente o no es válido.',
    type: 'danger'
  },
  EA0047: {
    message: 'Cancelación rechazada, intente de nuevo más tarde.',
    type: 'danger'
  },
  EA0048: {
    message: 'Falta escribir las zonas o no son válidas.',
    type: 'warning'
  },
  EA0049: {
    message: 'Falta escribir el horario o no es válido.',
    type: 'warning'
  },
  EA0050: {
    message: 'Falta seleccionar el estatus o no es válido.',
    type: 'danger'
  },
  EA0051: {
    message: 'Logo no válido.',
    type: 'danger'
  },
  EA0052: {
    message: 'Nombre no válido.',
    type: 'danger'
  },
  EA0053: {
    message: 'Precio por unidad no válido.',
    type: 'danger'
  },
  EA0054: {
    message: 'El correo ya está registrado.',
    type: 'danger'
  },
  EA0054C: {
    message: 'Todos los campos marcados con * son requeridos.',
    type: 'danger'
  },
  EA0055: {
    message: 'No es posible desactivar un colaborador con un servicio en proceso.',
    type: 'danger'
  },
  EA0056: {
    message: 'Falta escribir el municipio o no es válido.',
    type: 'danger'
  },
  EA0057: {
    message: 'Falta escribir la colonia o no es válida.',
    type: 'danger'
  },
  EA0058: {
    message: 'Falta escribir el código postal o no es válido.',
    type: 'danger'
  },
  EA0059: {
    message: 'Falta escribir la calle o no es válida.',
    type: 'danger'
  },
  EA0060: {
    message: 'Falta escribir el número exterior o no es válido.',
    type: 'danger'
  },
  EA0061: {
    message: 'Falta escribir el número interior o no es válido.',
    type: 'danger'
  },
  EA0062: {
    message: 'Falta escribir la latitud o no es válida.',
    type: 'danger'
  },
  EA0063: {
    message: 'Falta escribir la longitud o no es válida.',
    type: 'danger'
  },
  EA0065: {
    message: 'El email no tiene el formato requerido.',
    type: 'danger'
  },
  EA0066: {
    message: 'La contraseña no tiene el formato requerido.',
    type: 'danger'
  },
  EA0067: {
    message: 'Código de verificación no encontrado.',
    type: 'danger'
  },
  EA0068: {
    message: 'El código de verificación no es igual.',
    type: 'danger'
  },
  EA0069: {
    message: 'Falta escribir la referencia o no es válida.',
    type: 'danger'
  },
  EA0070: {
    message: 'Falta escribir el tipo de domicilio o no es válido.',
    type: 'danger'
  },
  EA0071: {
    message: 'Código de verificacion obligatorio.',
    type: 'danger'
  },
  EA0072: {
    message: 'Número de teléfono obligatorio.',
    type: 'danger'
  },
  EA0073: {
    message: 'El código de verificacion debe tener 6 caracteres.',
    type: 'danger'
  },
  EA0074: {
    message: 'La fecha u hora seleccionada no es válida.',
    type: 'danger'
  },
  EA0077: {
    message: 'Código promocional expirado.',
    type: 'danger'
  },
  EA0078: {
    message: 'El código promocional ya se ha usado.',
    type: 'danger'
  },
  EA0079: {
    message: 'El código promocional no puede ser usado.',
    type: 'danger'
  },
  EA0086: {
    message: 'Los datos del código promocional son inválidos.',
    type: 'danger'
  },
  EA0087: {
    message: 'Falta una fecha de expiración o es inválida.',
    type: 'danger'
  },
  EA0088: {
    message: 'Falta el descuento o es inválido.',
    type: 'danger'
  },
  EA0089: {
    message: 'Código promocional no encontrado.',
    type: 'danger'
  },
  EA0090: {
    message: 'El código promocional ya existe.',
    type: 'danger'
  },
  EA0094: {
    message: 'El colaborador es requerido.',
    type: 'danger'
  },
  EA0098: {
    message: 'El dispositivo del cliente ya se encuentra registrado.',
    type: 'danger'
  },
  EA0105: {
    message: 'No se puede ingresar un monto menor al permitido.',
    type: 'danger'
  },
  EA0114: {
    message: 'El servicio a asginar no puede ser posterior a 3 días de la fecha actual.',
    type: 'danger'
  },
  EA0116: {
    message: 'El teléfono del cliente ya se encuentra registrado',
    type: 'danger'
  },
  EA0119: {
    message: 'El identificador ingresado ya se encuentra asociado a otro dispositivo"',
    type: 'danger'
  },
  // local errors
  EAF0001: {
    message: 'Razón de cancelación obligatorio.',
    type: 'danger',
    code: 'EAF0001'
  },
  EAF0002: {
    message: 'La contraseña no coincide.',
    type: 'danger',
    code: 'EAF0002'
  },
  EAF0003: {
    message: 'No puedes seleccionar más de 31 días.',
    type: 'danger',
    code: 'EAF0003'
  },
  EAF0004: {
    message: 'Debes escribir al menos 1 número de teléfono.',
    type: 'danger',
    code: 'EAF0004'
  },
  EAF0005: {
    message: 'Los números telefónicos ingresados deben tener 10 dígitos.',
    type: 'danger',
    code: 'EAF0005'
  },
  EA0101: {
    message: 'Configuración no válida.',
    type: 'danger',
    code: 'EA0101'
  },
  EA0120: {
    message: 'Método de pago no válido',
    type: 'danger',
    code: 'EA0120',
    codeApi: 'EA0120'
  },
  EA0123: {
    message: 'La fecha del servicio está por expirar o ya ha expirado.',
    type: 'danger',
    code: 'EA0123'
  },
  EA0112: {
    message: 'Estado no válido',
    type: 'danger',
    code: 'EA0112'
  },
  EA0080: {
    message: 'Evidencia no válida, imagen o fecha no válidas',
    type: 'danger',
    code: 'EA0080'
  },
  EA0139: {
    message: 'Fecha u hora sin servicio',
    type: 'danger',
    code: 'EA0139'
  },
  EA0138: {
    message: 'Empresa no tiene cobertura en esta zona',
    type: 'danger',
    code: 'EA0138'
  },
  INVALID_IMAGE: {
    message: 'Imagen inválida. Los formatos permitidos son PNG, JPG y JPEG.',
    type: 'danger'
  },
  RETURN_MONEY: {
    message: 'Verifica ya haber realizado el reembolso a nuestro cliente al haber cancelado.',
    type: 'warning'
  },
  CHANGE_DATE: {
    message: 'La fecha ha expirado, por favor asigna una nueva.',
    type: 'warning'
  },
  INVALID_PAYMENT_TYPE: {
    message: 'Tipo de pago no válido',
    type: 'warning',
    codeApi: 'INVALID_PAYMENT_TYPE'
  },
  CHANGE_PAYMENT_TYPE: {
    message: 'Debes elegir otro método de pago al actual.',
    type: 'warning',
    codeApi: 'CHANGE_PAYMENT_TYPE'
  },
  NO_SERVICE: {
    message: 'Fecha u hora sin servicio.',
    type: 'danger'
  },
  INVALID_DATA: {
    message: 'Uno o más de los campos cuentan con un valor invalido.',
    type: 'danger'
  },
  SERVICE_REQUIRED: {
    message: 'Tipo de servicio requerido',
    type: 'danger'
  },
  CUSTOMER_ALREADY_HAS_RECURRENCE: {
    codeApi: 'EA0181',
    message: 'El cliente ya cuenta con una suscripción con ese tipo de servicio',
    type: 'danger'
  }
}

export function getErrorMessage(err) {
  const codeAPI = getErrorCodeAPI(err)
  return ERRORS_MESSAGES[codeAPI] || ERRORS_MESSAGES.UNKNOWN(err)
}
