export const createInstance = (axios, url) => {
  const { VUE_APP_BASE_URL: baseURL, VUE_APP_X_API_KEY: apiKey } = process.env
  const instanceOptions = {
    baseURL: url || baseURL,
    headers: {
      'x-api-key': apiKey
    }
  }
  return axios.create(instanceOptions)
}

export const createServerlessInstance = (axios, url) => {
  const { VUE_APP_SERVERLESS_BASE_URL: baseURL, VUE_APP_X_API_KEY: apiKey } = process.env
  const instanceOptions = {
    baseURL: url || baseURL,
    headers: {
      'x-api-key': apiKey
    }
  }
  return axios.create(instanceOptions)
}