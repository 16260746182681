<template>
  <div class="table-report">
    <div class="section-title">
      <h3>{{formatUnknown(state)}}: </h3>
      <span v-if="data">
        Total: {{data.total}}
      </span>
    </div>
    <CDataTable
      :items="items"
      :fields="fields"
      :clickableRows="true"
      :noItemsView="noItemsView"
      hover
      class="main-table"
    >
    </CDataTable>
  </div>
</template>

<script>

const fields = [
  { key: 'provider', label: 'Colaborador' },
  { key: 'total', label: 'Total' }
]

export default {
  name: 'TableReport',
  props: {
    data: {
      type: Object,
      required: true
    },
    state: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      fields
    }
  },
  computed: {
    items() {
      const providers = []
      Object.keys(this.data.providers).forEach((key) => {
        providers.push({ provider: this.data.providers[key].name, total: this.data.providers[key].total })
      })
      return providers
    }
  },
  methods: {
    formatUnknown(value) {
      if (value === 'Unknown') {
        return 'Desconocido'
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
    .table-report{
        .section-title{
            display: flex;
            margin-bottom: 8px;
            h3{
            margin-bottom: 0;
            }
            span{
            margin: auto 0 auto auto;
            padding: 4px 8px;
            background-color: #303034;
            color: #FFFFFF;
            border-radius: 4px;
            }
        }
    }
</style>
