<template>
  <div>
    <img class="mx-auto d-block" ref="image"/>
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input form-input"
        name="image"
        ref="input"
        @change="setImage"
        :accept="acceptedTypes"
        @input="onFileInput"
        :disabled="disabled"/>
      <label 
        class="custom-file-label" 
        for="customFileLang"
      >{{ imageName ? nameImage[1] : "Seleccionar Archivo" }}</label>
    </div>
    <slot/>
  </div>
</template>

<script>
import { fileToImage, dataURLtoFile } from '../utils'

export default {
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: () => true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    acceptedTypes: {
      type: String,
      required: false,
      default: () => 'image/x-png,image/jpeg,image/png'
    }
  },
  data: () => ({
    isValidImage: false,
    image: null,
    imageName: null,
    completeName: null,
    nameImage: null,

  }),
  methods: {
    setImage(e){
       this.imageName = {...this.imageName, [e.target.name]: e.target.value}
       this.completeName = this.imageName.image.split('fakepath')
       this.nameImage = this.completeName[1].split('\\')
    },
    reset() {
      this.sendImage(null)
    },
    validateImage({ width, height }) {
      return width === this.width && height === this.height
    },
    async onFileInput(ev) {
      const { target: { files: [file] } } = ev

      try {
        if (!file) {
          return this.sendImage(null)
        }

        if (file.type && !this.acceptedTypes.split(',').includes(file.type)) {
          this.$emit('format', {})
          ev.target.value = null
          return this.sendImage(null)
        }
        const image = await fileToImage(file)
        if (!image) {
          ev.target.value = null
          return this.sendImage(null)
        }
        this.image = image
        this.resizeImage()
        } catch (err) {
          this.$emit('error', err)
        }
    },
    resizeImage() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const { image } = this
      canvas.width = this.width
      canvas.height = this.height
      if (!this.validateImage(image)) {
        if (image.width > image.height) {
          const newHeight = image.height * (canvas.width / image.width)
          const offset = (canvas.height - newHeight) / 2
          context.drawImage(image, 0, 0, image.width, image.height, 0, offset, canvas.width, newHeight)
        } else {
          const newWidth = image.width * (canvas.height / image.height)
          const offset = (canvas.width - newWidth) / 2
          context.drawImage(image, 0, 0, image.width, image.height, offset, 0, newWidth, canvas.height)
        }
      } else {
        context.drawImage(image, 0, 0)
      }
      return this.previewImage(canvas.toDataURL())
    },
    async previewImage(dataURL) {
      if (this.showPreview) {
        this.$refs.image.src = dataURL
      }
      const file = await dataURLtoFile(dataURL)
      this.sendImage(file)
    },
    sendImage(file) {
      this.$emit('change')
      if (file) {
        this.$emit('input', this.$refs.input.files[0])
      } else {
        this.$emit('input', null)
        this.$refs.image.src = ''
        this.$refs.input.type = 'text'
        this.$refs.input.type = 'file'
      }
    }
  }
}
</script>

<style lang="css" scoped>
img[src=''] {
    visibility: hidden;
}
</style>
