<template>
    <ReactSiteFrame :src="src" />
</template>
<script>
import ReactSiteFrame from '../components/react/iframe'
    
export default {
  name: 'Calls',
  components: {
      ReactSiteFrame
  },
  data() {
      return {
          src: '/view/calls'
      }
  }
}
</script>