<template>
  <input
    type="text"
    class="form-control"
    :required="required"
    v-model="realValue"
    @keypress="onKeyPress"
    @blur="onBlur"
    v-on:keyup.enter="onEnter"
    :pattern="pattern"
    :title="title"
  >
</template>

<script>
export default {
  name: 'DecimalInput',
  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    min: {
      type: Number,
      required: false,
      default: () => Number.MIN_SAFE_INTEGER
    },
    max: {
      type: Number,
      required: false,
      default: () => Number.MAX_SAFE_INTEGER
    },
    maxDecimals: {
      type: Number,
      required: false,
      default: () => 2
    },
    invalidMessage: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.realValue) {
        this.realValue = this.value
      }
    },
    realValue(newValue, oldValue) {
      if (newValue === '') {
        return this.onChange()
      }

      if (newValue === '.') {
        this.realValue = '0.'
        return this.onChange()
      }

      if (/^0{2,}/.test(newValue)) {
        this.realValue = oldValue
      }

      const regex = new RegExp(this.pattern)
      if (!regex.test(newValue)) {
        this.realValue = oldValue
      }

      const decimalValue = +newValue
      if (decimalValue < this.min || decimalValue > this.max) {
        this.realValue = oldValue
      }

      return this.onChange()
    }
  },
  data: () => ({
    realValue: ''
  }),
  computed: {
    title() {
      return this.invalidMessage
        ? this.invalidMessage
        : `Ingresa un valor entre ${this.min} y ${this.max}`
    },
    pattern() {
      return `^\\d*(\\.|(\\.\\d{1,${this.maxDecimals}}))?$`
    },
    hasDecimalPoint() {
      return this.realValue.includes('.')
    },
    decimals() {
      return this.hasDecimalPoint
        ? this.realValue.split('.')[1]
        : ''
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.realValue)
    },
    onBlur() {
      this.$emit('blur')
    },
    onEnter() {
      this.$emit('KeyPressEnter')
    },
    onKeyPress(ev) {
      const { key } = ev
      if (!/^[0-9.]+$/.test(key) || (key === '.' && this.hasDecimalPoint)) {
        ev.preventDefault()
      }

      if (!this.realValue.length && ['.', '0'].includes(key)) {
        this.realValue = '0.'
        ev.preventDefault()
      }
    },
    storeData() {
      this.$store.state.company.pricePerLiter = this.realValue
    }
  }
}
</script>
