<template>
  <ReactSiteFrame :src="src" />
</template>
<script>
import ReactSiteFrame from '../components/react/iframe'

export default {
  name: 'Page404',
  components: {
    ReactSiteFrame
  },
  data() {
    return {
      src: '/view/404'
    }
  }
}
</script>
