<template>
  <div class="services-promocodes">
    <strong class="mr-2">Código promocional:</strong>
    <vSelect
      class="promocodes-select"
      :options="items"
      :map-keydown="handlers"
      v-model="promocodeSelected"
      v-c-tooltip="'Enter para buscar un código promocional que no esté en la lista.'"
      @search:blur="resetOnBlur()">
      <template #no-options>
        No se encontraron coincidencias, favor de presionar ENTER para buscar otras opciones o verifique el código
      </template>
    </vSelect>
    <button
      class="btn-primary"
      @click="editPromocode()"
      v-c-tooltip="'Para actualizar o agrega el código de descuento ingrese el nombre o parte de él, depués presiona ENTER, dé clic en la lista sobre el código que desea aplicar y de clic en el botón para aplicarlo. Si el servicio cuenta con un código de descuento y desea eliminarlo, deje el campo vacío y de clic en el botón para dejar de aplicar el código promocional'">
      <CIcon name="cil-pencil"></CIcon>
    </button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { getErrorMessage } from '../../common/errors'
import queryparser from '../../utils/queryparser'

let vue = null
export default {
  name: 'Promocodes',
  components: {
    vSelect
  },
  data() {
    return {
      items: [],
      promocodeSelected: ''
    }
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    serviceUuid: {
      type: String,
      required: true
    },
    promocode: {
      type: String,
      required: false
    }
  },
  watch: {
    isModalActive(value) {
      if (value) {
        this.promocodeSelected = this.promocode ? this.promocode : ''
        this.getPromocodes()
      }
    },
    promocodeSelected(value) {
      if (value === null) {
        this.getPromocodes()
      }
    }
  },
  mounted() {
    this.getPromocodes()
    this.promocodeSelected = this.promocode ? this.promocode : ''
    vue = this
  },
  methods: {
    handlers: (map, vm) => ({
      ...map,
      13: () => {
        if (!vm.search) {
          return
        }
        if (vm.search.length >= 3) {
          vm.search = vm.search.toUpperCase()
          vue.getPromocodes(vm.search)
        }
      }
    }),
    filter(value) {
      const options = {
        perPage: 50,
        active: 1
      }
      if (value && value.trim() !== '') {
        options.code = value
      }
      return options
    },
    getPromocodes(value) {
      const queryString = queryparser.parse(this.filter(value))
      this.axios.get(`promocodes?${queryString}`)
        .then((response) => {
          const { data } = response
          this.items = data.data.map((obj) => {
            let promocodeName = {}
            promocodeName = obj.code
            return promocodeName
          })
        }).catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    editPromocode() {
      this.axios.put(`services/${this.serviceUuid}/code`, {
        code: this.promocodeSelected
      })
        .then(() => {
          this.$emit('onSuccess')
        }).catch((err) => {
          this.$emit('onError', err)
        })
    },
    resetOnBlur() {
      if (this.items.length === 1) {
        this.getPromocodes()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.services-promocodes{
    display: flex;
    strong{
        margin: auto 0;
    }
    .promocodes-select{
        width: 37%;
    }
    button{
      border: 1px solid #5B2AE0;
    }
}
</style>
