<template>
  <div class="rating">
    <div class="card">
      <div class="card-header">
        <h3>Listado de suscripciónes</h3>
      </div>
      <div>
        <button
          id="add_service"
          type="button"
          @click="openCreateModal"
          class="btn-primary add-recurrence-button"
        >+
        </button>
        <b-tooltip target="add_service" placement="left">
          Abre el modal para crear un nuevo pedido
        </b-tooltip>
      <div tabindex="0" ref="successServices" class="alerts">
        <CAlert
          color="success"
          :closeButton="true"
          :show.sync="success.hasSuccess"
        >
          {{ success.message }}
        </CAlert>
      </div>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :noItemsView="noItemsView"
          hover
          :sorter="{ external: true }"
          :sorterValue.sync="sort"
          @row-clicked="onRowClicked"
          class="main-table"
        >
          <!-- filters -->
          <template #over-table>
            <div class="filters-section">
              <div>
                <div class="open-filter">
                  <input
                    v-model.trim="openFilterInput"
                    type="text"
                    placeholder="Teléfono"
                    maxlength="10"
                    v-c-tooltip="'Búsqueda de suscripciónes por número telefónico del cliente'"
                    @keypress="alphaNumeric($event)"
                  />
                  <button
                    class="btn btn-primary"
                    v-c-tooltip="
                      'Ejecuta la acción de búsqueda de lo ingresado en el campo'
                    "
                    @click="search()"
                  >
                    Buscar
                  </button>
                  <button
                    class="btn btn-light"
                    @click="resetFilters()"
                    v-c-tooltip="
                      'Limpia los filtros y retorna todas las suscripciónes'
                    "
                  >
                    Limpiar
                  </button>
                </div>
                <div>
                  <date-range-picker
                    v-model="dateRange"
                    :locale-data="{
                      format: 'dd/mm/yyyy',
                      daysOfWeek: daysOfWeekSpanish,
                      applyLabel: 'Aplicar',
                      cancelLabel: 'Cancelar',
                    }"
                    :ranges="false"
                    opens="left"
                    :append-to-body="false"
                    @update="dateRangeFilter"
                    class="date-range"
                    v-c-tooltip="
                      'Filtra las suscripciónes por el rango de fecha indicado'
                    "
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      <span v-if="!picker.startDate && !picker.endDate">
                        Ej: 01/01/2020 - 03/09/2020
                      </span>
                      <span v-else>
                        {{ formatToDatePicker(picker.startDate) }} -
                        {{ formatToDatePicker(picker.endDate) }}
                      </span>
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </template>
          <!-- custom table fields -->
          <template #customerPhoneNumber="{ item }">
            <td>{{ item.customerPhoneNumber }}</td>
          </template>
          <template #recurrencePeriod="{ item }">
            <td>{{ translatePeriodType(item.recurrencePeriodType) }}</td>
          </template>
          <template #startDate="{ item }">
            <td>{{ formatDate(item.startDate) }}</td>
          </template>
          <template #endDate="{ item }">
            <td>{{ item.endDate ? formatDate(item.endDate) : 'Sin definir' }}</td>
          </template>
          <template #nextPreorderDate="{ item }">
            <td>{{ item.nextPreorderDate ? formatDate(item.nextPreorderDate) : 'Sin definir' }}</td>
          </template>
          <template #active="{ item }">
            <td>
              <div class="status" :class="item.active ? 'is-active' : 'is-inactive'" ></div>
            </td>
          </template>
        </CDataTable>
        <CPagination :active-page.sync="page" :pages="pages" align="center" />
      </div>
      <div class="card-footer">
        <p>
          Total de registros: <strong>{{ totalRecords }}</strong>
        </p>
      </div>
    </div>
    
    <CreateRecurrence
      v-if="createModal.isActive"
      :isModalActive="createModal.isActive"
      @onClose="closeCreateModal"
      @onSuccess="onSuccessCreation"
      :infoModal="createModal.data"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import queryParser from '../utils/queryparser'
import { getErrorMessage } from '../common/errors'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import CreateRecurrence from '../components/recurrences/CreateRecurrence'
import {
  VALIDATIONS,
  RECURRENCE_TYPE
} from '../common/enums.js'

const fields = [
  { key: 'customerPhoneNumber', label: 'Teléfono', sorter: false },
  { key: 'recurrencePeriod', label: 'Suscripción', sorter: true },
  { key: 'startDate', label: 'Inicio suscripción', sorter: true },
  { key: 'endDate', label: 'Fin suscripción', sorter: true },
  { key: 'nextPreorderDate', label: 'Siguiente preorden', sorter: true },
  { key: 'active', label: 'Activo', sorter: false }
]
export default {
  name: 'Recurrences',
  components: {
    DateRangePicker,
    CreateRecurrence
  },
  data() {
    return {
      noItemsView: {
        noResults: 'Registros no encontrados.',
        noItems: 'Registros no encontrados.'
      },
      createModal: {
        isActive: false
      },
      items: [],
      daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      fields,
      page: 1,
      pages: 0,
      totalRecords: 0,
      dateRange: {
        startDate: '',
        endDate: ''
      },
      availableDateRange: {
        startDate: '',
        endDate: ''
      },
      startDate: '',
      endDate: '',
      sort: {
        column: 'createdAt',
        asc: false
      },
      activeModal: false,
      alertError: false,
      error: {},
      openFilterInput: '',
      success: {
        hasSuccess: false,
        message: ''
      },
    }
  },
  computed: {
    ...mapGetters('auth')
  },
  watch: {
    page() {
      this.getRecurrencies()
    },
    sort() {
      this.getRecurrencies()
    },
    openFilterInput(value) {
      this.openFilterInput = this.openFilterInput.toUpperCase()
      if (!value.length || value.length >= VALIDATIONS.MIN_TEXT_LENGTH) {
        this.getRecurrencies()
      }
    }
  },
  async mounted() {
      this.getRecurrencies()
  },
  methods: {
    async onSuccessCreation() {
      this.closeCreateModal()
      this.setSuccessMessage('Suscripción creada con éxito.')
      this.scrollTopSuccess()
      await this.filter()
      this.getRecurrencies()
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    openCreateModal() {
      this.createModal.isActive = true
    },
    getRecurrencies() {
      const filters = this.getFilters()
      const queryString = queryParser.parse(filters)

      this.axios
        .get(`recurrence?${queryString}`)
        .then((response) => {
          const { data } = response

          this.items = data.data
          this.pages = data.pagination.totalPages
          this.totalRecords = data.pagination.total

          this.items.map((item) => {
            item.createdAt = moment.tz(item.createdAt, 'America/Mexico_City').format('DD/MM/YYYY hh:mm A')
            item.cancelledAt = moment.tz(item.cancelledAt, 'America/Mexico_City').format('DD/MM/YYYY hh:mm A')

            return item
          })

          if (data.pagination.dates) {
              this.availableDateRange.startDate = new Date(
                `${data.pagination.dates.min}T00:00:00`
              )
              this.availableDateRange.endDate = new Date(
                `${data.pagination.dates.max}T00:00:00`
              )
            }

          return this.items
        })
        .catch((err) => {
          this.error = getErrorMessage(err)
        })
    },
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successServices.focus()
      })
    },
    getFilters() {
      const options = {
        state: this.state,
        page: this.page
      }
      if (this.startDate && this.endDate) {
        options.nextPreorderDate = `${this.startDate}:${this.endDate}`
      }

      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        const value = this.openFilterInput.trim().toUpperCase()
        this.openFilterInput = value
        options.customerPhoneNumber = value
      }

      return options
    },
    formatDate(date) {
      return moment.utc(date, 'YYYYMMDD HH:mm:ss').format('DD/MM/YYYY')
    },
    filter() {
      const options = {
        page: this.page
      }
      if (this.startDate && this.endDate) {
        options.nextPreorderDate = `${this.startDate}:${this.endDate}`
      }
      if (this.sort.asc !== null) {
        options.orderBy = this.sort.column
        options.sortBy = this.sort.asc ? 'ASC' : 'DESC'
      }
      if (this.openFilterInput && this.openFilterInput.trim() !== '') {
        options.customerPhoneNumber = this.openFilterInput
      }

      return options
    },
    dateRangeFilter() {
      this.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      this.getRecurrencies()
    },
    alphaNumeric(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-zÑñÁÉÍÓÚÜáéíóúü0-9 ]$/.test(key)) {
        e.preventDefault()
      }
    },
    async openModal(item) {
      await this.$axios
        .get(`/recurrence/detail/${item.registryUuid}`)
        .then((response) => {
          this.alertError = false
          const { data } = response
          this.createModal.data = data
          this.createModal.isActive = true
        })
        .catch(() => {
          this.error = getErrorMessage({
            response: { data: { codeApi: 'EA0015' } }
          })
          this.alertError = true
          this.createModal.data = {}
        })
    },
    translatePeriodType(type) {
      return RECURRENCE_TYPE[type]
    },
    closeCreateModal() {
      this.createModal.isActive = false
      this.createModal.data = {}
    },
    onRowClicked(item) {
      this.openModal(item)
    },
    resetFilters() {
      this.startDate = null
      this.endDate = null
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.page = 1
      this.sort.column = 'createdAt'
      this.sort.asc = false
      this.openFilterInput = ''
      this.getRecurrencies()
    },
    formatToDatePicker(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    search() {
      if (this.openFilterInput.length || this.openFilterInput.length >= VALIDATIONS.MIN_TEXT_LENGTH) {
        this.getRecurrencies()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-table {
  cursor: pointer;
}
.add-recurrence-button {
  position: fixed;
  bottom: 4%;
  right: 44px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1031;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 5px;
}

.status {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-left: 15%;

  &.is-inactive {
    background-color: red;
  }

  &.is-active {
    background-color: green;
  }
}
.rating {
  .card {
    .card-header,
    .card-footer {
      display: flex;
      p {
        margin: auto 0 auto auto;
      }
    }
    .card-body {
      .date-range {
        margin-bottom: 8px;
      }
      .filters-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        > div {
          display: flex;
          flex-direction: column;
          &:first-child {
            > div {
              &:last-child {
                .date-range {
                  width: 100%;
                  @media (min-width: 992px) {
                    width: auto;
                  }
                }
                @media (min-width: 992px) {
                  margin-left: auto;
                }
              }
            }
          }
          @media (min-width: 992px) {
            flex-direction: row;
          }
        }
        .open-filter {
          margin-bottom: 16px;
          display: flex;
          input {
            height: 35px;
            box-sizing: content-box;
            padding: 0 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: calc(100% - 165px);
          }
          button {
            height: 35px;
            border-radius: 4px;
            margin-left: 4px;
          }
        }
        .select-filters-wrapper {
          display: flex;
          flex-direction: column;
          @media (min-width: 992px) {
            flex-direction: row;
          }
          .select-filter {
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .text-wrapper {
    > div {
      margin-bottom: 8px;
    }
  }
  .tabs-selector {
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button {
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 3);
      @media (min-width: 992px) {
        width: 53px;
      }
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active {
        background-color: #5b2ae0;
        color: white;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}
</style>
