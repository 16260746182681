var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rating"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('CDataTable',{staticClass:"main-table",attrs:{"items":_vm.items,"fields":_vm.fields,"noItemsView":_vm.noItemsView,"hover":"","sorter":{ external: true },"sorterValue":_vm.sort},on:{"update:sorterValue":function($event){_vm.sort=$event},"update:sorter-value":function($event){_vm.sort=$event},"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"over-table",fn:function(){return [_c('div',{staticClass:"filters-section"},[_c('div',[_c('div',{staticClass:"open-filter"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.openFilterInput),expression:"openFilterInput",modifiers:{"trim":true}},{name:"c-tooltip",rawName:"v-c-tooltip",value:('Búsqueda de servicios por folio'),expression:"'Búsqueda de servicios por folio'"}],attrs:{"type":"text","placeholder":"Folio","maxlength":"8"},domProps:{"value":(_vm.openFilterInput)},on:{"keypress":function($event){return _vm.alphaNumeric($event)},"input":function($event){if($event.target.composing)return;_vm.openFilterInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(
                    'Ejecuta la acción de búsqueda de lo ingresado en el campo'
                  ),expression:"\n                    'Ejecuta la acción de búsqueda de lo ingresado en el campo'\n                  "}],staticClass:"btn btn-primary",on:{"click":function($event){return _vm.search()}}},[_vm._v(" Buscar ")]),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(
                    'Limpia los filtros y retorna todas los servicios cancelados'
                  ),expression:"\n                    'Limpia los filtros y retorna todas los servicios cancelados'\n                  "}],staticClass:"btn btn-light",on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Limpiar ")])]),_c('div',[_c('date-range-picker',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(
                    'Filtra los servicios cancelados por el rango de fecha indicado'
                  ),expression:"\n                    'Filtra los servicios cancelados por el rango de fecha indicado'\n                  "}],staticClass:"date-range",attrs:{"locale-data":{
                    format: 'dd/mm/yyyy',
                    daysOfWeek: _vm.daysOfWeekSpanish,
                    applyLabel: 'Aplicar',
                    cancelLabel: 'Cancelar',
                  },"ranges":false,"opens":"left","append-to-body":false,"date-format":_vm.disabledDates},on:{"update":_vm.dateRangeFilter},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [(!picker.startDate && !picker.endDate)?_c('span',[_vm._v(" Ej: 01/01/2020 - 03/09/2020 ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatToDatePicker(picker.startDate))+" - "+_vm._s(_vm.formatToDatePicker(picker.endDate))+" ")])]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)])])]},proxy:true},{key:"folio",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.folio ? item.folio : "Sin folio"))])]}},{key:"reason",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.reason || "Sin motivo"))])]}},{key:"createdAt",fn:function({ item }){return [_c('td',[_vm._v(_vm._s((item.createdAt)))])]}},{key:"serviceDate",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.serviceDate)))])]}},{key:"cancelledAt",fn:function({ item }){return [_c('td',[_vm._v(_vm._s((item.cancelledAt)))])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.page,"pages":_vm.pages,"align":"center"},on:{"update:activePage":function($event){_vm.page=$event},"update:active-page":function($event){_vm.page=$event}}})],1),_c('div',{staticClass:"card-footer"},[_c('p',[_vm._v(" Total de registros: "),_c('strong',[_vm._v(_vm._s(_vm.totalRecords))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Listado de servicios cancelados")])])
}]

export { render, staticRenderFns }