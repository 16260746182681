import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilChartLine,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilBuilding,
  cilAccountLogout,
  cilMoney,
  cilCopy,
  cibWhatsapp,
  cilX,
  cibOpenstreetmap,
  cilFlagAlt,
  cilRestaurant,
  cilChatBubble,
  cilAddressBook,
  cilWindowMaximize,
  cilMoodGood,
  cilClipboard,
  cilGraph,
  cilBadge,
  cilReload,
  cilScrubber,
  cilBarChart
} from '@coreui/icons'

export const iconsSet = {
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilChartLine,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilBuilding,
  cilAccountLogout,
  cilMoney,
  cilCopy,
  cibWhatsapp,
  cilX,
  cibOpenstreetmap,
  cilFlagAlt,
  cilRestaurant,
  cilReload,
  cilChatBubble,
  cilAddressBook,
  cilWindowMaximize,
  cilMoodGood,
  cilClipboard,
  cilGraph,
  cilBadge,
  cilScrubber,
  cilBarChart
}
