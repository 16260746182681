<template>
  <div class="sensors">
    <div tabindex="0" ref="successSesnsors">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div>
        <button
          id="add_sensor"
          type="button"
          @click="onOpenCreateModal"
          class="btn-primary add-button"
        >+
        </button>
      </div>
      <div class="card-header">
        <h3>Listado de sensores</h3>
        <p>Total registros: <strong>{{ allRecords }}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="false"
          :clickableRows="true"
          :noItemsView="noItemsView"
          hover
          class="main-table">
          <template #createdAt="{item}">
            <td> {{ formatDate(item.createdAt) }} </td>
          </template>
          <template #customerName="{item}">
            <td v-if="item.customerName"> {{ item.customerName }} </td>
            <td v-else> Sin nombre </td>
          </template>
          <template #show_details="{item}">
            <td>
              <CButton
                :id="`detail_${item.id}`"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="onRowClicked(item)"
              >
                {{ Boolean(item._toggled) ? 'Hide' : 'Detalle' }}
              </CButton>
            </td>
          </template>
          <template #delete="{item}">
            <td>
              <CButton
                :id="`detail_${item.id}`"
                color="danger"
                variant="outline"
                square
                size="sm"
                @click="onOpenDeleteModal(item)"
              >
                Eliminar
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
        <CreateSensor
          :isModalActive="createSensor.isActive"
          @onSuccess="onSuccessCreateSensor"
          @onClose="onCloseCreateModal"></CreateSensor>
        <EditSensor
          :isModalActive="editSensor.isActive"
          :item="editSensor"
          @onSuccess="onSuccessEditSensor"
          @onClose="onCloseEditModal"></EditSensor>
        <DeleteSensor
          :isModalActive="deleteSensor.isActive"
          :uuid="deleteSensor.uuid"
          @onSuccess="onSuccessDeleteSensor"
          @onClose="onCloseDeleteModal"></DeleteSensor>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CreateSensor from '../components/sensors/CreateSensor'
import EditSensor from '../components/sensors/EditSensor'
import DeleteSensor from '../components/sensors/DeleteSensor'

const fields = [
  { key: 'createdAt', label: 'Fecha de registro' },
  { key: 'customerName', label: 'Nombre' },
  { key: 'customerPhone', label: 'Teléfono' },
  { key: 'deviceIdentifier', label: 'Dispositivo' },
  { key: 'show_details', label: 'Acción', _style: 'width:1%' },
  { key: 'delete', label: 'Acción', _style: 'width:1%' }
]

export default {
  name: 'Sensors',
  components: {
    CreateSensor,
    EditSensor,
    DeleteSensor
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      items: [],
      fields,
      page: 1,
      pages: 0,
      allRecords: 0,
      createSensor: {
        isActive: false
      },
      editSensor: {
        isActive: false,
        item: []
      },
      deleteSensor: {
        isActive: false,
        uuid: ''
      },
      success: {
        hasSuccess: false,
        message: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.axios.get('/customers/sensor')
        .then((response) => {
          this.items = []
          const { data } = response
          this.pages = data.pagination.totalPages
          this.allRecords = data.pagination.total
          // filling object
          Object.keys(data.data).forEach((sensor) => {
            this.items.push({
              createdAt: data.data[sensor].createdAt,
              customerName: data.data[sensor].customer.name,
              customerPhone: data.data[sensor].customer.phoneNumber,
              deviceIdentifier: data.data[sensor].deviceIdentifier,
              customerUuid: data.data[sensor].customerUuid
            })
          })
        })
    },
    onRowClicked(item) {
      return item ? this.onOpenEditModal(item) : this.onOpenCreateModal()
    },
    onOpenCreateModal() {
      this.createSensor.isActive = true
    },
    onCloseCreateModal() {
      this.createSensor.isActive = false
    },
    onOpenEditModal(item) {
      this.editSensor.isActive = true
      this.editSensor.item = item
    },
    onCloseEditModal() {
      this.editSensor.isActive = false
    },
    onOpenDeleteModal(item) {
      this.deleteSensor.isActive = true
      this.deleteSensor.uuid = item.customerUuid
    },
    onCloseDeleteModal() {
      this.deleteSensor.isActive = false
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    onSuccessCreateSensor() {
      this.getData()
      this.onCloseCreateModal()
      this.setSuccessMessage('Sensor creado con éxito')
    },
    onSuccessEditSensor() {
      this.getData()
      this.onCloseEditModal()
      this.setSuccessMessage('Sensor editado con éxito')
    },
    onSuccessDeleteSensor() {
      this.getData()
      this.onCloseDeleteModal()
      this.setSuccessMessage('Sensor eliminado con éxito')
    },
    // formats
    formatDate(date) {
      if (!date) {
        return 'No disponible'
      }
      return moment
        .utc(date, 'YYYYMMDD HH:mm:ss')
        .format('DD/MM/YYYY hh:mm A')
    }
  }
}
</script>

<style lang="scss" scoped>
    .sensors{
        .add-button {
            position: fixed;
            bottom: 4%;
            right: 44px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            z-index: 1031;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .card{
            .card-header,
            .card-footer{
                display: flex;
                p{
                    margin: auto 0 auto auto;
                }
            }
        }
    }
</style>
