import Vue from 'vue'
// import vuetify from './plugins/vuetify'
import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import ToggleButton from 'vue-js-toggle-button'
import VueDocReader from 'vue-doc-reader'

import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App'
import { store } from './store/store'
import router from './router'
import { iconsSet as icons } from './assets/icons/icons'
import CoreuiVue from './plugins/coreui'
import axiosPlugin from './plugins/axios'
import VueGeolocation from 'vue-browser-geolocation'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import CodeView from "vue-code-view";
import VueIntercom from 'vue-intercom';

window.axios = axios
Vue.config.devtools = process.env.NODE_ENV !== 'production'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(axiosPlugin, axios)
Vue.use(BootstrapVue)
Vue.use(CodeView)
Vue.use(ToggleButton)
Vue.use(VueIntercom, { appId: process.env.VUE_APP_X_INTERCOM_APP_ID })
// Vue.use(vuetify)
Vue.component('vue-doc-reader', VueDocReader)

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_API_KEY,
//     libraries: 'places'
//   }
// })

Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD3Lt1KM-kDNRBpmtScC-sLtMBFu6BpM-k',
    libraries: 'places'
  }
})

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  icons,
  components: {
    App
  },
  template: '<App/>'
})
