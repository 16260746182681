<template>
  <div class="serviceTypes">
    <div tabindex="0" ref="successServiceType">
      <CAlert
        color="success"
        :closeButton="true"
        :show.sync="success.hasSuccess">
        {{success.message}}
      </CAlert>
    </div>
    <div class="card">
      <div>
        <button
          id="add_servicesType"
          type="button"
          @click="onOpenCreateModal"
          class="btn-primary add-button"
        >+
        </button>
      </div>
      <div class="card-header">
        <h3>Listado de tipos de servicio</h3>
        <p>Total registros: <strong>{{ allRecords }}</strong></p>
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :sorter="false"
          :clickableRows="true"
          :noItemsView="noItemsView"
          hover
          class="main-table">
          <template #createdAt="{item}">
            <td> {{ formatDate(item.createdAt) }} </td>
          </template>
          <template #description="{item}">
            <td v-if="item.description"> {{ item.description }} </td>
            <td v-else> Sin nombre </td>
          </template>

          <template #show_details="{item}">
            <td class="py-2">
              <div class="d-flex">
                <button
                  :id="`detail_${item.id}`"
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  @click="onRowClicked(item)"
                >
                  {{ item._toggled ? 'Hide' : 'Detalle' }}
                </button>
                <b-tooltip :target="`detail_${item.id}`" placement="left">
                  Despliega el modal de detalle/edición de la información del tipo de servicio
                </b-tooltip>
              </div>
            </td>
          </template>
          <template #isActive="{item}">
            <td>
              <b-form-checkbox
                v-model="item.isActive"
                name="check-button"
                @change="activeServiceType(item)"
                disabled
                switch>
                {{item.isActive ? 'Activado' : 'Desactivado'}}
              </b-form-checkbox>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :active-page.sync="page"
          :pages="pages"
          align="center"
        />
        <CreateServiceType
          :isModalActive="createServiceType.isActive"
          @onSuccess="onSuccessCreateServiceType"
          @onClose="onCloseCreateModal"></CreateServiceType>
        <EditServiceType
          :isModalActive="editServiceType.isActive"
          :registryUuid="editServiceType.registryUuid"
          @onErrorInital="onCloseEditModal"
          @onClose="onCloseEditModal"
          @onSuccess="onSuccessEditServiceType"
        />

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CreateServiceType from '../components/serviceTypes/CreateServiceType'
import EditServiceType from '../components/serviceTypes/EditServiceType'
// import DeleteServiceType from '../components/sensors/DeleteSensor'

const fields = [
  { key: 'createdAt', label: 'Fecha de registro', sorter: true },
  { key: 'description', label: 'Nombre', sorter: true },
  {
    key: 'show_details',
    label: 'Acción',
    _style: 'width:10%',
    sorter: false
  },
  {
    key: 'isActive',
    label: 'Estatus',
    _style: 'width:10%',
    sorter: true
  }
]

export default {
  name: 'ServiceType',
  components: {
    CreateServiceType,
    EditServiceType
  },
  data() {
    return {
      noItemsView: { noResults: 'Registros no encontrados.', noItems: 'Registros no encontrados.' },
      items: [],
      fields,
      page: 1,
      pages: 0,
      allRecords: 0,
      createServiceType: {
        isActive: false
      },
      editServiceType: {
        isActive: false,
        egistryUuid: null
      },
      deleteServiceType: {
        isActive: false,
        uuid: ''
      },
      success: {
        hasSuccess: false,
        message: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const params = {
          includeCategories: false,
          all: true
        }
        const { data } = await this.axios.get('/services/types', { params })
        this.items = data
        this.pages = 1
        this.allRecords = data.length
      } catch (error) {
        console.log(error)
      }
    },
    async activeServiceType() {
    },
    onRowClicked(item) {
      return item ? this.onOpenEditModal(item) : this.onOpenCreateModal()
    },
    onOpenCreateModal() {
      this.createServiceType.isActive = true
    },
    onCloseCreateModal() {
      this.createServiceType.isActive = false
    },
    onOpenEditModal({ registryUuid }) {
      this.editServiceType.isActive = true
      this.editServiceType.registryUuid = registryUuid
    },
    onCloseEditModal() {
      this.editServiceType.isActive = false
      this.editServiceType.registryUuid = null
    },
    onOpenDeleteModal(item) {
      this.deleteServiceType.isActive = true
      this.deleteServiceType.uuid = item.customerUuid
    },
    onCloseDeleteModal() {
      this.deleteServiceType.isActive = false
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    async onSuccessCreateServiceType() {
      await this.getData()
      this.onCloseCreateModal()
      this.setSuccessMessage('Tipo de servicio creado con éxito')
    },
    async onSuccessEditServiceType() {
      await this.getData()
      this.onCloseEditModal()
      this.setSuccessMessage('Tipo de servicio editado con éxito')
    },
    async onSuccessDeleteServiceType() {
      await this.getData()
      this.onCloseDeleteModal()
      this.setSuccessMessage('Tipo de servicio eliminado con éxito')
    },
    // formats
    formatDate(date) {
      if (!date) {
        return 'No disponible'
      }
      return moment
        .utc(date, 'YYYYMMDD HH:mm:ss')
        .format('DD/MM/YYYY hh:mm A')
    }
  }
}
</script>

<style lang="scss" scoped>
    .serviceTypes{
        .add-button {
            position: fixed;
            bottom: 4%;
            right: 44px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            z-index: 1031;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .card{
            .card-header,
            .card-footer{
                display: flex;
                p{
                    margin: auto 0 auto auto;
                }
            }
        }
  .tabs-selector{
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button{
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: 100%;
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active{
        background-color: #5B2AE0;
        color: white;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
    }
</style>
